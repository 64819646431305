/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MobileStep } from './MobileStep';
import {
    MobileStepFromJSON,
    MobileStepFromJSONTyped,
    MobileStepToJSON,
} from './MobileStep';

/**
 * 
 * @export
 * @interface GetUiCompanyDataDto
 */
export interface GetUiCompanyDataDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    showWatermark: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUiCompanyDataDto
     */
    bgColor: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiCompanyDataDto
     */
    primaryColor: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiCompanyDataDto
     */
    secondaryColor: string;
    /**
     * 
     * @type {string}
     * @memberof GetUiCompanyDataDto
     */
    contactType: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactLastnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactFirstnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactEmailRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactPhoneRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isCompanyNameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isCompanyLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isCompanyWebsiteRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactLinkedinProfileRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUiCompanyDataDto
     */
    isContactJobPositionRequired: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUiCompanyDataDto
     */
    totalNumberOfQuestions: number;
    /**
     * 
     * @type {Array<MobileStep>}
     * @memberof GetUiCompanyDataDto
     */
    mobileStepsToGetCompanyInfo: Array<MobileStep>;
    /**
     * 
     * @type {Array<MobileStep>}
     * @memberof GetUiCompanyDataDto
     */
    mobileStepsToGetPersonInfo: Array<MobileStep>;
}

/**
 * Check if a given object implements the GetUiCompanyDataDto interface.
 */
export function instanceOfGetUiCompanyDataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "showWatermark" in value;
    isInstance = isInstance && "bgColor" in value;
    isInstance = isInstance && "primaryColor" in value;
    isInstance = isInstance && "secondaryColor" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "isContactLastnameRequired" in value;
    isInstance = isInstance && "isContactFirstnameRequired" in value;
    isInstance = isInstance && "isContactLocationRequired" in value;
    isInstance = isInstance && "isContactEmailRequired" in value;
    isInstance = isInstance && "isContactPhoneRequired" in value;
    isInstance = isInstance && "isCompanyNameRequired" in value;
    isInstance = isInstance && "isCompanyLocationRequired" in value;
    isInstance = isInstance && "isCompanyWebsiteRequired" in value;
    isInstance = isInstance && "isContactLinkedinProfileRequired" in value;
    isInstance = isInstance && "isContactJobPositionRequired" in value;
    isInstance = isInstance && "totalNumberOfQuestions" in value;
    isInstance = isInstance && "mobileStepsToGetCompanyInfo" in value;
    isInstance = isInstance && "mobileStepsToGetPersonInfo" in value;

    return isInstance;
}

export function GetUiCompanyDataDtoFromJSON(json: any): GetUiCompanyDataDto {
    return GetUiCompanyDataDtoFromJSONTyped(json, false);
}

export function GetUiCompanyDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUiCompanyDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'showWatermark': json['showWatermark'],
        'bgColor': json['bgColor'],
        'primaryColor': json['primaryColor'],
        'secondaryColor': json['secondaryColor'],
        'contactType': json['contactType'],
        'isContactLastnameRequired': json['isContactLastnameRequired'],
        'isContactFirstnameRequired': json['isContactFirstnameRequired'],
        'isContactLocationRequired': json['isContactLocationRequired'],
        'isContactEmailRequired': json['isContactEmailRequired'],
        'isContactPhoneRequired': json['isContactPhoneRequired'],
        'isCompanyNameRequired': json['isCompanyNameRequired'],
        'isCompanyLocationRequired': json['isCompanyLocationRequired'],
        'isCompanyWebsiteRequired': json['isCompanyWebsiteRequired'],
        'isContactLinkedinProfileRequired': json['isContactLinkedinProfileRequired'],
        'isContactJobPositionRequired': json['isContactJobPositionRequired'],
        'totalNumberOfQuestions': json['totalNumberOfQuestions'],
        'mobileStepsToGetCompanyInfo': ((json['mobileStepsToGetCompanyInfo'] as Array<any>).map(MobileStepFromJSON)),
        'mobileStepsToGetPersonInfo': ((json['mobileStepsToGetPersonInfo'] as Array<any>).map(MobileStepFromJSON)),
    };
}

export function GetUiCompanyDataDtoToJSON(value?: GetUiCompanyDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'showWatermark': value.showWatermark,
        'bgColor': value.bgColor,
        'primaryColor': value.primaryColor,
        'secondaryColor': value.secondaryColor,
        'contactType': value.contactType,
        'isContactLastnameRequired': value.isContactLastnameRequired,
        'isContactFirstnameRequired': value.isContactFirstnameRequired,
        'isContactLocationRequired': value.isContactLocationRequired,
        'isContactEmailRequired': value.isContactEmailRequired,
        'isContactPhoneRequired': value.isContactPhoneRequired,
        'isCompanyNameRequired': value.isCompanyNameRequired,
        'isCompanyLocationRequired': value.isCompanyLocationRequired,
        'isCompanyWebsiteRequired': value.isCompanyWebsiteRequired,
        'isContactLinkedinProfileRequired': value.isContactLinkedinProfileRequired,
        'isContactJobPositionRequired': value.isContactJobPositionRequired,
        'totalNumberOfQuestions': value.totalNumberOfQuestions,
        'mobileStepsToGetCompanyInfo': ((value.mobileStepsToGetCompanyInfo as Array<any>).map(MobileStepToJSON)),
        'mobileStepsToGetPersonInfo': ((value.mobileStepsToGetPersonInfo as Array<any>).map(MobileStepToJSON)),
    };
}

