import { RequirementsParametersApi, type UpdateGenericRequirementParamsDto, type UpdateUrlWebhookDto } from 'api';

interface SetRequirementParametersProps {
  updateGenericRequirementParamsDto: UpdateGenericRequirementParamsDto;
  accessToken: string;
}

interface SetWebhookParametersProps {
  updateWebhookParametersDto: UpdateUrlWebhookDto;
  accessToken: string;
}

export class RequirementParametersService {
  private readonly requirementsParametersClient: RequirementsParametersApi;

  constructor() {
    this.requirementsParametersClient = new RequirementsParametersApi();
  }

  public async saveRequirementParameters({
    updateGenericRequirementParamsDto,
    accessToken,
  }: SetRequirementParametersProps): Promise<UpdateGenericRequirementParamsDto> {
    return this.requirementsParametersClient.requirementsParametersControllerUpdateRequirementParameters(
      {
        updateGenericRequirementParamsDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async saveAutomatisationParameters({
    updateWebhookParametersDto,
    accessToken,
  }: SetWebhookParametersProps): Promise<UpdateUrlWebhookDto> {
    return this.requirementsParametersClient.requirementsParametersControllerUpdateWebhook(
      {
        updateUrlWebhookDto: updateWebhookParametersDto,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
