import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function MoreIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={concatClassNames(iconSizeStyle[size], 'feather feather-more-vertical')}
      viewBox="0 0 24 24"
      stroke={color}
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="1"></circle>
      <circle cx="12" cy="5" r="1"></circle>
      <circle cx="12" cy="19" r="1"></circle>
    </svg>
  );
}
