import { DisckoApisContext, MaintenanceContext } from 'App';
import {
  type FormOrchestratorQuestionAnswerDto,
  type FormOrchestratorResponseDto,
  type GetGenericRequirementContactDto,
  type GetGenericRequirementIdParamsDto,
  type GetUiCompanyDataDto,
  type ReceiveQuestionsInputsDto,
  type SetupProjectInputsDto,
} from 'api';

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

import { ScreebProvider } from '@screeb/sdk-react';

import { RichText } from 'components/RichText/RichText';
import { Modal } from 'components/core/Modal/Modal';

import concatClassNames from 'utils/classNames';

import { ClientInfoForm } from './DisckoClientsInfoForm';
import { DisckoFormComment } from './DisckoFormComment';
import { DisckoFormEndRequirement } from './DisckoFormEndRequirement';
import { DisckoFormProject } from './DisckoFormProject';
import { DisckoFormQuestions } from './DisckoFormQuestions';

/* -------------------------------------------------- interfaces -------------------------------------------------- */

export interface RequirementSynthesis {
  synthesisContext?: string[];
  goals?: string[];
  audience?: string[];
  stakeholders?: string[];
  constraints?: string[];
  successFactors?: string[];
  solutions?: string[];
  references?: string[];
  kpis?: string[];
  abstract?: string;
  comment?: string;
  clientMaturity?: string;
  priceRange?: string;
  projectType?: string;
  projectMaturity?: string;
  deadline?: { deadlineCategory?: string; date?: string };
  step?: string;
  projectSubject?: string;
  questions?: FormOrchestratorQuestionAnswerDto[];
}

export interface DisckoFormProps {
  setNextStep?: (nextStep: string) => void;
  onClickPrevious?: () => void;
}

export interface Question {
  index: number;
  question: string;
  placeholder: string[];
  mandatory?: boolean;
}

/* ------------------------------------------------ Set up Context ------------------------------------------------ */

export interface RequirementQuestions {
  urlRedirect?: string;
}
interface ContextProps {
  openAiQuestionsFormToDisplay: Question[];
  contextRequirement: RequirementSynthesis;
  questionsToSave: FormOrchestratorQuestionAnswerDto[];
  setSynthesis: (value: RequirementSynthesis) => void;
  setupProject: (setupProjectInputs: SetupProjectInputsDto) => Promise<FormOrchestratorResponseDto>;
  sendQuestions: (sendQuestionsInputs: ReceiveQuestionsInputsDto) => Promise<FormOrchestratorResponseDto>;
  initRequirement: (clientsInfo: GetGenericRequirementContactDto) => Promise<GetGenericRequirementIdParamsDto>;
  requirementId?: string;
  requirementParams?: RequirementQuestions;
  setRequirementParams: (value: GetGenericRequirementIdParamsDto) => void;
  isLargeScreen?: boolean;
  companyIsAllowedToUseDiscko?: boolean;
  reset: () => void;
  waterMark?: boolean;
  setWaterMark?: (value: boolean) => void;
  setUiSettings: (value: GetUiCompanyDataDto) => void;
  uiSettings?: GetUiCompanyDataDto;
  form: boolean;
}

const context: ContextProps = {
  companyIsAllowedToUseDiscko: undefined,

  openAiQuestionsFormToDisplay: [],
  questionsToSave: [],
  contextRequirement: {
    synthesisContext: [],
    goals: [],
    constraints: [],
    successFactors: [],
    kpis: [],
    stakeholders: [],
    references: [],
    solutions: [],
    audience: [],
    abstract: '',
    deadline: { deadlineCategory: 'flexible' },
    priceRange: 'abstain',
    projectType: 'Website',
    projectMaturity: 'IDEA',
    clientMaturity: 'BEGINNER',
    projectSubject: '',
    questions: [],
    step: '',
    comment: '',
  },
  setSynthesis: ({
    synthesisContext,
    goals,
    constraints,
    successFactors,
    audience,
    abstract,
    stakeholders,
    kpis,
    references,
    solutions,
  }: RequirementSynthesis) => {
    context.contextRequirement.synthesisContext = synthesisContext;
    context.contextRequirement.goals = goals;
    context.contextRequirement.constraints = constraints;
    context.contextRequirement.successFactors = successFactors;
    context.contextRequirement.audience = audience;
    context.contextRequirement.abstract = abstract;
    context.contextRequirement.stakeholders = stakeholders;
    context.contextRequirement.kpis = kpis;
    context.contextRequirement.references = references;
    context.contextRequirement.solutions = solutions;
  },
  setupProject: async (): Promise<FormOrchestratorResponseDto> => {
    return {
      message: {
        content: '',
        role: '',
      },
      step: '',
    };
  },
  sendQuestions: async (): Promise<FormOrchestratorResponseDto> => {
    return {
      message: {
        content: '',
        role: '',
      },
      step: '',
    };
  },
  initRequirement: async (clientsInfo: GetGenericRequirementContactDto): Promise<GetGenericRequirementIdParamsDto> => {
    return {
      id: '',
      step: Step.CONTACT,
    };
  },
  setRequirementParams: (value: GetGenericRequirementIdParamsDto) => {
    context.requirementId = value.id;
    context.requirementParams = {
      urlRedirect: value.urlRedirect,
    };
  },
  reset: () => {
    context.requirementId = undefined;
    context.openAiQuestionsFormToDisplay = [];
  },
  isLargeScreen: true,
  waterMark: true,
  setWaterMark: (value: boolean) => {
    context.waterMark = value;
  },
  setUiSettings: (value: GetUiCompanyDataDto) => {
    context.uiSettings = {
      showWatermark: value.showWatermark,
      bgColor: value.bgColor,
      primaryColor: value.primaryColor,
      secondaryColor: value.secondaryColor,
      contactType: 'BOTH',
      isContactLastnameRequired: true,
      isContactFirstnameRequired: true,
      isContactLocationRequired: true,
      isContactEmailRequired: true,
      isContactPhoneRequired: true,
      isCompanyNameRequired: true,
      isCompanyLocationRequired: true,
      isCompanyWebsiteRequired: true,
      isContactJobPositionRequired: true,
      isContactLinkedinProfileRequired: true,
      totalNumberOfQuestions: 6,
      mobileStepsToGetCompanyInfo: [],
      mobileStepsToGetPersonInfo: [],
    };
  },
  form: false,
};

export const disckoContext: React.Context<ContextProps> = createContext(context);

/* --------------------------------------------------- Component -------------------------------------------------- */

export enum Step {
  CONTACT = 'contact',
  PROJECT = 'project',
  QUESTION = 'questions',
  COMMENT = 'comment',
  END = 'end',
}

export function Discko(): JSX.Element {
  const { requirementService, companyService } = useContext(DisckoApisContext);
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();

  const [hasCompanyEnoughCredits, setHasCompanyEnoughCredits] = useState<boolean>();

  const [activeForm, setActiveForm] = useState<string>(Step.CONTACT);

  const [requirementParams, setRequirementParams] = useState<GetGenericRequirementIdParamsDto>();

  const [showWatermark, setShowWatermark] = useState<boolean>(true);

  const [uiSettings, setUiSettings] = useState<GetUiCompanyDataDto>();

  const { maintenanceInfos } = useContext(MaintenanceContext);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const companyId: string | undefined = searchParams.get('companyid') ?? undefined;

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const { data: companyCreditsData } = useQuery({
    queryKey: ['company', companyId],
    queryFn: async () => companyService.isCompanyIsAllowedToUseDiscko(companyId ?? ''),
    enabled: companyId !== undefined,
  });
  // const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
  //   ['company', user?.sub],
  //   requirementService.,
  // );

  useEffect(() => {
    if (companyCreditsData !== undefined) {
      setHasCompanyEnoughCredits(companyCreditsData);
    }
  }, [companyCreditsData]);

  // voir avec AFI si c'est bien ici que je dois le faire et de cette façon + pourquoi la queryKey ligne 216 est nommée de cette façon

  const { data: companyUiData } = useQuery({
    queryKey: ['companyUiData', companyId],
    queryFn: async () => companyService.getCompanyUiData(companyId ?? ''),
    enabled: companyId !== undefined,
  });

  useEffect(() => {
    if (companyUiData !== undefined) {
      setShowWatermark(companyUiData.showWatermark);
      setUiSettings(companyUiData);
    }
  }, [companyUiData]);

  /* --------------------------------------------------- functions -------------------------------------------------- */

  const { openAiService } = useContext(DisckoApisContext);
  context.setupProject = async (openAiInputs: SetupProjectInputsDto): Promise<FormOrchestratorResponseDto> => {
    if (companyId === undefined) {
      navigate('/');
    } else {
      if (requirementParams === undefined) console.error('requirementId is undefined');
      else {
        return await openAiService.setupProject(companyId, {
          requirementId: requirementParams.id,
          step: activeForm.toString(),
          clientMaturity: openAiInputs.clientMaturity,
          projectMaturity: openAiInputs.projectMaturity,
          projectDescription: openAiInputs.projectDescription,
          deadlineCategory: openAiInputs.deadlineCategory,
          deadlineDate: openAiInputs.deadlineDate,
          priceRange: openAiInputs.priceRange,
        });
      }
    }
    return {
      step: '',
      message: {
        content: '',
        role: '',
      },
    };
  };
  context.sendQuestions = async (openAiInputs: ReceiveQuestionsInputsDto): Promise<FormOrchestratorResponseDto> => {
    if (companyId === undefined) {
      navigate('/');
    } else {
      if (requirementParams === undefined) console.error('requirementId is undefined');
      else {
        return await openAiService.sendQuestion(companyId, {
          requirementId: requirementParams.id,
          step: activeForm.toString(),
          questions: openAiInputs.questions,
        });
      }
    }
    return {
      step: '',
      message: {
        content: '',
        role: '',
      },
    };
  };
  context.initRequirement = async (
    clientsInfo: GetGenericRequirementContactDto,
  ): Promise<GetGenericRequirementIdParamsDto> => {
    if (companyId === undefined) console.error('companyId is undefined');
    return await requirementService.initRequirement(clientsInfo, companyId ?? '');
  };
  context.requirementId = requirementParams?.id;
  context.setRequirementParams = setRequirementParams;
  context.requirementParams = {
    urlRedirect: requirementParams?.urlRedirect ?? undefined,
  };
  context.isLargeScreen = isLargeScreen ?? true;
  context.companyIsAllowedToUseDiscko = hasCompanyEnoughCredits;
  context.setUiSettings = setUiSettings;
  context.uiSettings = {
    showWatermark,
    bgColor: uiSettings?.bgColor ?? '#FFFFFF',
    primaryColor: uiSettings?.primaryColor ?? '#000000',
    secondaryColor: uiSettings?.secondaryColor ?? '#DBDBDB',
    contactType: uiSettings?.contactType ?? 'BOTH',
    isContactLastnameRequired: uiSettings?.isContactLastnameRequired ?? true,
    isContactFirstnameRequired: uiSettings?.isContactFirstnameRequired ?? true,
    isContactLocationRequired: uiSettings?.isContactLocationRequired ?? true,
    isContactEmailRequired: uiSettings?.isContactEmailRequired ?? true,
    isContactPhoneRequired: uiSettings?.isContactPhoneRequired ?? true,
    isCompanyNameRequired: uiSettings?.isCompanyNameRequired ?? true,
    isCompanyLocationRequired: uiSettings?.isCompanyLocationRequired ?? true,
    isCompanyWebsiteRequired: uiSettings?.isCompanyWebsiteRequired ?? true,
    totalNumberOfQuestions: uiSettings?.totalNumberOfQuestions ?? 6,
    mobileStepsToGetCompanyInfo: uiSettings?.mobileStepsToGetCompanyInfo ?? [],
    mobileStepsToGetPersonInfo: uiSettings?.mobileStepsToGetPersonInfo ?? [],
    isContactJobPositionRequired: uiSettings?.isContactJobPositionRequired ?? true,
    isContactLinkedinProfileRequired: uiSettings?.isContactLinkedinProfileRequired ?? true,
  };

  /* ---------------------------------------------- Create Requirement ---------------------------------------------- */

  if (isLargeScreen === undefined) return <div></div>;

  if (!uiSettings) {
    return <></>;
  }

  return (
    <ScreebProvider autoInit websiteId="988babb4-d9ee-4aa0-ba2b-da236c484619">
      <Modal isOpen={hasCompanyEnoughCredits === false}>
        <RichText
          fragments={[
            {
              content: "Malheureusement, la solution n'est pas disponible pour le moment.",
              contentType: 'p',
            },
            {
              content: 'Nous reviendrons vers vous avec une solution dans les plus brefs délais.',
              contentType: 'p',
            },
          ]}
        ></RichText>
      </Modal>
      <div
        className={concatClassNames(
          'flex flex-col gap-1 py-4 h-screen relative',
          'items-stretch flex-grow',
          'overflow-auto',
        )}
        style={{ backgroundColor: uiSettings?.bgColor }}
      >
        <disckoContext.Provider
          value={{
            ...context,
            form: true,
          }}
        >
          {maintenanceInfos.beginningDate !== undefined && maintenanceInfos.endDate !== undefined && <div></div>}
          {activeForm === Step.CONTACT && isLargeScreen !== undefined && <ClientInfoForm setNextStep={setActiveForm} />}
          {activeForm === Step.PROJECT && <DisckoFormProject setNextStep={setActiveForm} />}
          {activeForm === Step.QUESTION && <DisckoFormQuestions setNextStep={setActiveForm} />}
          {activeForm === Step.COMMENT && <DisckoFormComment setNextStep={setActiveForm} />}
          {activeForm === Step.END && <DisckoFormEndRequirement />}

          {showWatermark && (
            <div className={concatClassNames(isLargeScreen ? 'pl-16' : 'pl-4 text-xs')}>
              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: 'Propulsé par ',
                    color: 'black',
                    size: 'sm',
                  },
                  {
                    contentType: 'link',
                    position: 'justify',
                    content: 'Discko.io',
                    color: 'black',
                    weight: 'bold',
                    size: 'sm',
                    onClick: () => window.open('https://discko.io', '_blank'),
                  },
                ]}
              ></RichText>
            </div>
          )}
        </disckoContext.Provider>
      </div>
    </ScreebProvider>
  );
}
