/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  FormOrchestratorResponseDto,
  ReceiveQuestionsInputsDto,
  SetupProjectInputsDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    FormOrchestratorResponseDtoFromJSON,
    FormOrchestratorResponseDtoToJSON,
    ReceiveQuestionsInputsDtoFromJSON,
    ReceiveQuestionsInputsDtoToJSON,
    SetupProjectInputsDtoFromJSON,
    SetupProjectInputsDtoToJSON,
} from '../models';

export interface FormOrchestratorControllerReceiveQuestionsRequest {
    companyId: string;
    receiveQuestionsInputsDto: ReceiveQuestionsInputsDto;
}

export interface FormOrchestratorControllerSetupProjectRequest {
    companyId: string;
    setupProjectInputsDto: SetupProjectInputsDto;
}

/**
 * 
 */
export class FormOrchestratorApi extends runtime.BaseAPI {

    /**
     */
    async formOrchestratorControllerReceiveQuestionsRaw(requestParameters: FormOrchestratorControllerReceiveQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormOrchestratorResponseDto>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formOrchestratorControllerReceiveQuestions.');
        }

        if (requestParameters.receiveQuestionsInputsDto === null || requestParameters.receiveQuestionsInputsDto === undefined) {
            throw new runtime.RequiredError('receiveQuestionsInputsDto','Required parameter requestParameters.receiveQuestionsInputsDto was null or undefined when calling formOrchestratorControllerReceiveQuestions.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/FormOrchestrator/receiveQuestions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReceiveQuestionsInputsDtoToJSON(requestParameters.receiveQuestionsInputsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormOrchestratorResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async formOrchestratorControllerReceiveQuestions(requestParameters: FormOrchestratorControllerReceiveQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormOrchestratorResponseDto> {
        const response = await this.formOrchestratorControllerReceiveQuestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formOrchestratorControllerSetupProjectRaw(requestParameters: FormOrchestratorControllerSetupProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormOrchestratorResponseDto>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formOrchestratorControllerSetupProject.');
        }

        if (requestParameters.setupProjectInputsDto === null || requestParameters.setupProjectInputsDto === undefined) {
            throw new runtime.RequiredError('setupProjectInputsDto','Required parameter requestParameters.setupProjectInputsDto was null or undefined when calling formOrchestratorControllerSetupProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/FormOrchestrator/setupProject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetupProjectInputsDtoToJSON(requestParameters.setupProjectInputsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormOrchestratorResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async formOrchestratorControllerSetupProject(requestParameters: FormOrchestratorControllerSetupProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormOrchestratorResponseDto> {
        const response = await this.formOrchestratorControllerSetupProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
