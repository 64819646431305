import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { AutomateSettings } from 'components/AutomateSettings/AutomateSettings';
import { PlanManagement } from 'components/CurrentPlan/PlanManagement';
import { FormCustomizationManagement } from 'components/FormCustomizationManagement/FormCustomizationManagement';
import { MembersManagement } from 'components/Members Management/MembersManagement';
import { PersonalNotificationsSettings } from 'components/PersonalNotificationsSettings/PersonalNotificationsSettings';
import { RichText } from 'components/RichText/RichText';
import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { Modal } from 'components/core/Modal/Modal';
import { P } from 'components/core/P';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

export function SettingsPage(): JSX.Element {
  const { companyService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();

  const [untrackedChangesModalOpen, setUntrackedChangesModalOpen] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [categoryTarget, setCategoryTarget] = useState<string>('');

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const canceled: string | undefined = searchParams.get('canceled') ?? undefined;
  const success: string | undefined = searchParams.get('success') ?? undefined;

  const openCustomizationPage: string | undefined = searchParams.get('formCustomization') ?? undefined;
  let alreadyNotify: boolean = false;

  useEffect(() => {
    console.log('openCustomizationPage', openCustomizationPage);
    if (openCustomizationPage === 'true') {
      setOpened('Personnalisation du formulaire');
    }
  }, [openCustomizationPage]);

  useEffect(() => {
    if (canceled === 'true' && !alreadyNotify) {
      alreadyNotify = true;
      toast.error('Le paiement à échoué');
    }
    if (success === 'true' && !alreadyNotify) {
      alreadyNotify = true;
      toast.success('Le paiement a été effectué avec succès. Les modifications peuvent prendre quelques minutes.');
    }
  }, [canceled, success]);

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);

  async function getCompanyIdByUserId(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
    ['company', user?.sub],
    getCompanyIdByUserId,
  );

  interface SubCategory {
    name: string;
    iconSource: string;
  }
  const [hovered, setHovered] = useState<string>();

  const [opened, setOpened] = useState<string>('Votre abonnement');

  const subCategories: SubCategory[] = [
    {
      name: 'Votre abonnement',
      iconSource: '/imgs/GIFs/billing.gif',
    },
    {
      name: 'Intégration',
      iconSource: '/imgs/GIFs/play.gif',
    },
    {
      name: 'Equipe',
      iconSource: '/imgs/GIFs/team.gif',
    },
    {
      name: 'Personnalisation du formulaire',
      iconSource: '/imgs/GIFs/prompt.gif',
    },
    {
      name: 'Notifications',
      iconSource: '/imgs/GIFs/notification.gif',
    },
    {
      name: 'Connecteurs',
      iconSource: '/imgs/GIFs/brain.gif',
    },
  ];
  return (
    <BasePage>
      <>
        <Modal isOpen={untrackedChangesModalOpen} setIsOpen={setUntrackedChangesModalOpen} title="Attention">
          <div className="flex flex-col gap-4">
            <Text
              position="left"
              weight="normal"
              size="sm"
              content="Vous avez des modifications non enregistrées. Si vous continuez, elles seront perdues."
            />
            <div className="flex flex-row gap-4">
              <Button
                content="Annuler"
                color="#000000"
                onClick={() => {
                  setUntrackedChangesModalOpen(false);
                }}
              />
              <Button
                content="Continuer"
                color="#60A5FA"
                onClick={() => {
                  setUntrackedChangesModalOpen(false);
                  setIsFormDirty(false);
                  setOpened(categoryTarget);
                }}
              />
            </div>
          </div>
        </Modal>
        {isLargeScreen && (
          <div className="h-full grid grid-cols-[2fr_8fr] gap-8 p-8">
            <div className="flex flex-col gap-8">
              <Text content="Votre espace" position="left" size="xl" weight="bold" />
              <>
                {subCategories.map((subCategory, index) => (
                  <div
                    key={index}
                    className={concatClassNames(
                      'flex flex-row items-center gap-4 cursor-pointer',
                      subCategory.name === hovered || subCategory.name === opened ? 'bg-white/50 ' : '',
                      'py-2 px-2 rounded-r-lg',
                    )}
                    onMouseEnter={() => {
                      setHovered(subCategory.name);
                    }}
                    onMouseLeave={() => {
                      setHovered(undefined);
                    }}
                    onClick={() => {
                      if (isFormDirty) {
                        setCategoryTarget(subCategory.name);
                        setUntrackedChangesModalOpen(true);
                      } else {
                        setOpened(subCategory.name);
                      }
                    }}
                  >
                    <img src={subCategory.iconSource} alt="icon" className="h-10 w-10 " />
                    <Text
                      content={subCategory.name}
                      position="left"
                      size="lg"
                      weight="bold"
                      color={subCategory.name === opened ? 'blue-400' : 'black'}
                    />
                  </div>
                ))}
              </>
            </div>
            <>
              {companyStatus !== 'loading' && companyStatus !== 'idle' && (
                <>
                  {opened === 'Votre abonnement' && (
                    <div className="flex flex-col gap-8 overflow-auto">
                      <Text content="Gérer votre tarif et vos factures" position="left" size="3xl" weight="bold" />
                      <PlanManagement />
                    </div>
                  )}
                  {opened === 'Intégration' && (
                    <div className="flex flex-col gap-8 overflow-auto">
                      <Text content="Intégrer Discko à votre site web" position="left" size="3xl" weight="bold" />
                      <div className="p-4 flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                          <P
                            position="left"
                            weight="normal"
                            text="Vous pouvez insérer un lien vers le formulaire Discko directement depuis votre site web."
                          />
                          <div className="relative inline-block ">
                            <Button
                              height="fit"
                              width="fit"
                              content="Récupérer le lien à insérer sur votre site"
                              iconName="link"
                              iconPosition="left"
                              onClick={async () => {
                                await navigator.clipboard.writeText(
                                  `${window.location.protocol}//${window.location.host}/new/?companyid=${
                                    companyData?.id ?? ''
                                  }`,
                                );
                                toast.success('Le lien a bien été copié.');
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-4">
                          <RichText
                            fragments={[
                              { contentType: 'p', content: "1. En tant qu'iFrame. ", size: 'lg', weight: 'bold' },
                              {
                                contentType: 'p',
                                size: 'lg',
                                content:
                                  "Intégrez directement le formulaire Discko sur l'une de vos pages en utilisant un iFrame pour une intégration fluide.",
                              },
                            ]}
                          />
                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2 whitespace-pre-wrap">
                            {companyStatus === 'success' &&
                              `<iframe src="${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}" />`}
                            {companyStatus !== 'success' && `<iframe src="https://votre-lien-discko.com" />`}
                          </code>
                        </div>
                        <div className="flex flex-col gap-4">
                          <RichText
                            fragments={[
                              {
                                contentType: 'p',
                                content: '2. En tant que lien ou bouton. ',
                                size: 'lg',
                                weight: 'bold',
                              },
                              {
                                contentType: 'p',
                                size: 'lg',
                                content:
                                  'Vous pouvez aussi choisir de rediriger vos utilisateurs vers le formulaire Discko en utilisant un simple lien ou un bouton cliquable.',
                              },
                            ]}
                          />

                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2">
                            {companyStatus === 'success' &&
                              `
                              <a href="${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}" target="_blank" rel="noreferrer">
                              Accéder au formulaire Discko
                              </a>`}
                            {companyStatus !== 'success' &&
                              `
                                <a href="https://votre-lien-discko.com" target="_blank" rel="noreferrer">
                                Accéder au formulaire Discko
                                </a>`}
                          </code>
                          <code className="bg-gray-500 text-white p-4 ml-4 rounded-lg flex flex-row gap-2">
                            {companyStatus === 'success' &&
                              `
                            <button onclick="window.open('${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}', '_blank')">
                              Accéder au formulaire Discko
                            </button>`}
                            {companyStatus !== 'success' &&
                              `
                            <button onclick="window.open('https://votre-lien-discko.com', '_blank')">
                              Accéder au formulaire Discko
                            </button>`}
                          </code>
                        </div>
                      </div>
                    </div>
                  )}
                  {companyStatus === 'success' && opened === 'Equipe' && (
                    <div className="flex flex-col gap-8 overflow-auto">
                      <Text content="Inviter les membres de votre équipe" position="left" size="3xl" weight="bold" />
                      <MembersManagement />
                    </div>
                  )}
                  {companyStatus === 'success' && opened === 'Personnalisation du formulaire' && (
                    <FormCustomizationManagement
                      companyData={companyData}
                      onUpgrade={() => {
                        setOpened('Votre abonnement');
                      }}
                      onDirtyChange={setIsFormDirty}
                    />
                  )}
                  {companyStatus === 'success' && opened === 'Notifications' && (
                    <div className="flex flex-col gap-8 overflow-auto">
                      <Text content="Paramétrer les notifications" position="left" size="3xl" weight="bold" />
                      <PersonalNotificationsSettings />
                    </div>
                  )}
                  {companyStatus === 'success' && opened === 'Connecteurs' && (
                    <div className="flex flex-col gap-8 overflow-auto">
                      <AutomateSettings companyData={companyData} />
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        )}

        {!isLargeScreen && (
          <div className="">
            <div className="flex flex-col items-center justify-between">
              <>
                {subCategories.map((subCategory, index) => (
                  <div key={index} className="relativex w-full align-middle">
                    <div
                      className={concatClassNames(
                        'flex flex-row items-center align-top gap-4 cursor-pointer w-full',
                        subCategory.name === hovered || subCategory.name === opened ? 'bg-white/50 ' : '',
                        'py-2 px-2 rounded-r-lg',
                      )}
                      onMouseEnter={() => {
                        setHovered(subCategory.name);
                      }}
                      onMouseLeave={() => {
                        setHovered(undefined);
                      }}
                      onClick={() => {
                        if (opened === subCategory.name) setOpened('');
                        else setOpened(subCategory.name);
                      }}
                    >
                      <img src={subCategory.iconSource} alt="icon" className="h-10 w-10 " />
                      <Text
                        content={subCategory.name}
                        position="left"
                        size="lg"
                        weight="bold"
                        color={subCategory.name === opened ? 'blue-400' : 'black'}
                      />
                      <div className="flex-1" />
                      <div className="shrink-0">
                        {subCategory.name === opened
                          ? getIcon('chevronUp', '#42A5F5', 'smd')
                          : getIcon('chevronDown', '#000000', 'smd')}
                      </div>
                    </div>
                    {opened === 'Votre abonnement' &&
                      companyStatus === 'success' &&
                      subCategory.name === 'Votre abonnement' && (
                        <div className="p-4 flex flex-col gap-8 w-full overflow-auto  align-middle text-sm">
                          {companyData.isSubscribed &&
                            'Pour une meilleure expérience, la gestion de votre abonnement se fait uniquement sur ordinateur.'}
                          {!companyData.isSubscribed &&
                            "Pour une meilleure expérience, l'abonnement à Discko se fait uniquement depuis ordinateur."}
                        </div>
                      )}
                    {opened === 'Intégration' && companyStatus === 'success' && subCategory.name === 'Intégration' && (
                      <div className="p-4 flex flex-col gap-8 w-full overflow-auto  align-middle text-sm">
                        {'Pour une meilleure expérience, cette section est accessible uniquement depuis un ordinateur.'}
                      </div>
                    )}
                    {opened === 'Equipe' && companyStatus === 'success' && subCategory.name === 'Equipe' && (
                      <div className="p-4 flex flex-col gap-8 w-full overflow-auto  align-middle">
                        <MembersManagement />
                      </div>
                    )}

                    {opened === 'Personnalisation du formulaire' &&
                      companyStatus === 'success' &&
                      subCategory.name === 'Personnalisation du formulaire' && (
                        <div className="p-4 flex flex-col gap-8 w-full overflow-auto align-middle">
                          <FormCustomizationManagement
                            companyData={companyData}
                            onUpgrade={() => {
                              setOpened('Votre abonnement');
                            }}
                            onDirtyChange={setIsFormDirty}
                          />
                        </div>
                      )}
                    {companyStatus === 'success' &&
                      opened === 'Notifications' &&
                      subCategory.name === 'Notifications' && (
                        <div className="p-4 flex flex-col gap-12 w-full overflow-auto">
                          <PersonalNotificationsSettings />
                        </div>
                      )}
                    {companyStatus === 'success' && opened === 'Connecteurs' && subCategory.name === 'Connecteurs' && (
                      <div className="p-4 flex flex-col gap-8 w-full overflow-auto">
                        <AutomateSettings companyData={companyData} />
                      </div>
                    )}
                  </div>
                ))}
              </>
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
