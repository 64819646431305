/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import { DisckoApisContext } from 'App';
import { type Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as Yup from 'yup';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import { RichText, type TextProps } from 'components/RichText/RichText';
import { Button } from 'components/core/Button/Button';
import { H3Title } from 'components/core/H3Title';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';
import { Title } from 'components/core/Title/Title';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

import { type DisckoFormProps, Step, disckoContext } from './DisckoPage';

interface DisckoFormCommentModel {
  comment?: string;
}

const schema: Yup.ObjectSchema<DisckoFormCommentModel> = Yup.object().shape({
  comment: Yup.string(),
});

/* --------------------------------------------------- API calls -------------------------------------------------- */

export function DisckoFormComment({ setNextStep }: DisckoFormProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entering, setEntering] = useState<boolean>(true);
  const [searchParams] = useSearchParams();

  const { requirementId, contextRequirement, isLargeScreen, requirementParams, uiSettings } = useContext(disckoContext);
  const { requirementService } = useContext(DisckoApisContext);

  const sliderRef: React.MutableRefObject<SwiperType | undefined> = useRef<SwiperType>();

  const { register, handleSubmit, watch } = useForm<DisckoFormCommentModel>({
    resolver: yupResolver(schema),
  });

  const watchComment: string = watch('comment') ?? '';

  /* --------------------------------------------------- variables -------------------------------------------------- */

  const companyId: string | undefined = searchParams.get('companyid') ?? undefined;

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  const { data, status, refetch, isRefetching } = useQuery({
    queryKey: ['openai', 'addComment'],
    queryFn: async () => {
      return await requirementService.commentRequirement(requirementId ?? '', watchComment, companyId ?? '');
    },
    enabled: false,
    cacheTime: 0,
  });

  const onSubmitForm: SubmitHandler<DisckoFormCommentModel> = async (data) => {
    setIsLoading(true);
    await refetch();
  };

  useEffect(() => {
    if (status === 'error') {
      setIsLoading(false);
      toast.error('Une erreur est survenue. Votre synthèse a été envoyée sans votre commentaire.');
      return;
    }
    if (status !== 'success' || (isRefetching !== undefined && isRefetching)) return;
    delay(1000)
      .then(() => {
        if (setNextStep != null) setNextStep(Step.END);
        return true;
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
      });
  }, [status, data, isRefetching]);

  useEffect(() => {
    if (entering && document.getElementById('form4') != null) {
      setEntering(false);
    }
  });

  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto" style={{ background: uiSettings?.bgColor }}>
      <form
        id="form4"
        className={concatClassNames(
          'flex flex-col gap-6 overflow-auto flex-grow items-stretch w-full',
          'transition all duration-700',
          entering ? 'opacity-0' : 'opacity-100',
          isLargeScreen ? 'px-16' : '',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {isLargeScreen === true && (
          <>
            <div
              className=" p-8 rounded-lg h-full flex flex-col gap-6 overflow-auto"
              style={{ background: uiSettings?.bgColor }}
            >
              <div>
                <Text
                  content="Merci pour vos réponses ! Voici la synthèse de votre projet que nous avons reçue."
                  size="lg"
                  weight="bold"
                  color={uiSettings?.primaryColor}
                />
                <Text
                  content="Vous pouvez ajouter des commentaires si vous le souhaitez."
                  size="lg"
                  weight="bold"
                  color={uiSettings?.primaryColor}
                />
              </div>
              <div>
                <div className="border-l-1 pl-6 relative" style={{ borderColor: uiSettings?.primaryColor }}>
                  <br />
                  {contextRequirement !== undefined && (
                    <div className="flex flex-col gap-4">
                      {contextRequirement.synthesisContext && (
                        <>
                          <H3Title
                            text="Contexte et origine du projet"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.synthesisContext.map((item, index) => (
                            <Text
                              key={`synthesis-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}

                      {contextRequirement.goals && (
                        <>
                          <H3Title
                            text="Objectifs du projet"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.goals.map((item, index) => (
                            <Text
                              key={`goals-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}

                      {contextRequirement.audience && (
                        <>
                          <H3Title
                            text="Public cible"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.audience.map((item, index) => (
                            <Text
                              key={`audience-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}

                      {contextRequirement.constraints && (
                        <>
                          <H3Title
                            text="Contraintes et limites"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.constraints.map((item, index) => (
                            <Text
                              key={`constraints-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}

                      {contextRequirement.successFactors && (
                        <>
                          <H3Title
                            text="Facteurs clefs de succès"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.successFactors.map((item, index) => (
                            <Text
                              key={`successFactors-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}
                      {contextRequirement.references && (
                        <>
                          <H3Title
                            text="Inspirations et références"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.references.map((item, index) => (
                            <Text
                              key={`references-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}
                      {contextRequirement.solutions && (
                        <>
                          <H3Title
                            text="Solutions attendues"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.solutions.map((item, index) => (
                            <Text
                              key={`solutions-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}
                      {contextRequirement.stakeholders && (
                        <>
                          <H3Title
                            text="Acteurs impliqués"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.stakeholders.map((item, index) => (
                            <Text
                              key={`stakeholders-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}
                      {contextRequirement.kpis && (
                        <>
                          <H3Title
                            text="Suivi et indicateurs de performance"
                            color={uiSettings?.primaryColor}
                            position="justify"
                            weight="bold"
                          />
                          {contextRequirement.kpis.map((item, index) => (
                            <Text
                              key={`kpis-${index}`}
                              content={item}
                              size="lg"
                              color={uiSettings?.primaryColor}
                              position="justify"
                            />
                          ))}
                        </>
                      )}
                      {contextRequirement.abstract && (
                        <Text
                          content={contextRequirement.abstract}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                          weight="bold"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <br />
              <TextArea
                label="Elements libres que vous souhaitez ajouter ou corriger"
                rows={2}
                placeholder="Ajoutez des commentaires"
                {...register('comment')}
              />
            </div>
            <div className="flex flex-row justify-end ">
              <div className="flex flex-row gap-4 items-center">
                {!isLoading && <Button content="Terminer" width="lg" type="submit" color={uiSettings?.primaryColor} />}
                {isLoading && (
                  <Button iconName="spinCircle" type="button" width="lg" height="sm" iconAnimation="spin" disabled />
                )}
              </div>
            </div>
          </>
        )}
        {isLargeScreen === false && (
          <div
            className={concatClassNames(
              'flex flex-col gap-6 w-full',
              'py-4',
              'flex-grow items-stretch',
              'overflow-auto',
            )}
          >
            <div className={concatClassNames('flex items-center', 'flex-grow items-stretch', '')}>
              <Swiper
                slidesPerView={1}
                centeredSlides={true}
                spaceBetween={5}
                navigation={true}
                modules={[Pagination, Navigation]}
                wrapperClass="flex flex-row flex-grow items-stretch h-full"
                onSwiper={(it: SwiperType) => (sliderRef.current = it)}
                pagination={{
                  clickable: true,
                }}
              >
                <SwiperSlide
                  className={concatClassNames(
                    '!flex items-center',
                    'justify-center',
                    'px-8',
                    'flex-col',
                    'gap-5',
                    'h-full',
                    'overflow-auto',
                  )}
                >
                  <Text content="Merci pour vos réponses !" size="lg" weight="bold" />
                  {requirementParams?.urlRedirect !== undefined && requirementParams.urlRedirect !== '' && (
                    <Button
                      content="Prenez rendez-vous avec nous"
                      height="fit"
                      width="fit"
                      type="button"
                      onClick={() => window.open(requirementParams?.urlRedirect, '_blank')}
                    />
                  )}
                  <Button
                    content="Voir la synthèse"
                    height="fit"
                    width="fit"
                    type="button"
                    onClick={() => sliderRef.current?.slideNext()}
                  />
                  <div onClick={() => sliderRef.current?.slideNext()}>
                    {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                  </div>
                </SwiperSlide>
                {contextRequirement.synthesisContext && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Contexte et origine du projet"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.synthesisContext.map((item, index) => (
                        <Text
                          key={`synthesis-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.goals && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Objectifs du projet"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.goals.map((item, index) => (
                        <Text
                          key={`goals-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.audience && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title text="Public cible" color={uiSettings?.primaryColor} position="justify" weight="bold" />
                      {contextRequirement.audience.map((item, index) => (
                        <Text
                          key={`audience-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.constraints && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Contraintes et limites"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.constraints.map((item, index) => (
                        <Text
                          key={`constraints-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.successFactors && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Facteurs clefs de succès"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.successFactors.map((item, index) => (
                        <Text
                          key={`successFactors-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.references && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Inspirations et références"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.references.map((item, index) => (
                        <Text
                          key={`references-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.solutions && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Solutions attendues"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.solutions.map((item, index) => (
                        <Text
                          key={`solutions-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.stakeholders && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Acteurs impliqués"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.stakeholders.map((item, index) => (
                        <Text
                          key={`stakeholders-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.kpis && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <>
                      <H3Title
                        text="Suivi et indicateurs de performance"
                        color={uiSettings?.primaryColor}
                        position="justify"
                        weight="bold"
                      />
                      {contextRequirement.kpis.map((item, index) => (
                        <Text
                          key={`kpis-${index}`}
                          content={item}
                          size="lg"
                          color={uiSettings?.primaryColor}
                          position="justify"
                        />
                      ))}
                    </>
                  </SwiperSlide>
                )}
                {contextRequirement.abstract && (
                  <SwiperSlide
                    className={concatClassNames(
                      'h-full flex-grow justify-center',
                      '!flex flex-col',
                      'gap-8',
                      'px-8',
                      'h-full',
                      'overflow-auto',
                    )}
                  >
                    <Text
                      content={contextRequirement.abstract}
                      size="lg"
                      color={uiSettings?.primaryColor}
                      position="justify"
                      weight="bold"
                    />
                  </SwiperSlide>
                )}
                <SwiperSlide className={concatClassNames('px-16', '!flex flex-col gap-4', 'justify-center')}>
                  <TextArea
                    label="Elements libres que vous souhaitez ajouter ou corriger"
                    rows={3}
                    placeholder="Ajoutez des commentaires"
                    {...register('comment')}
                    height="full"
                  />
                  {!isLoading && (
                    <Button
                      content="Terminer"
                      type="submit"
                      borderColor="black"
                      borderWidth="2"
                      borderWidthOnHover="2"
                      borderColorOnHover="black"
                      height="sm"
                    />
                  )}
                  {isLoading && (
                    <Button
                      iconName="spinCircle"
                      type="button"
                      height="sm"
                      iconAnimation="spin"
                      disabled
                      borderWidthOnHover="2"
                      borderColor="gray-50"
                      borderWidth="2"
                    />
                  )}
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
