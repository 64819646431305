import React from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';
import { H1Title } from 'components/core/H1Title';

export function BackOfficeCGUPage(): JSX.Element {
  return (
    <BasePage>
      <div className="max-w-3xl mx-auto py-8">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <H1Title position="left" text="Conditions Générales d'Utilisation"></H1Title>
        </div>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">Introduction</h3>
          <p>{`VEUILLEZ LIRE ATTENTIVEMENT CES CONDITIONS GÉNÉRALES D’UTILISATION (« CGU ») AVANT TOUTE UTILISATION DE LA SOLUTION « DISCKO » proposée par la société par actions simplifiée SPECKS, immatriculée au RCS de Nantes sous le numéro 980 269 062, dont le siège social est sis 1 rue Pauline Roland à Nantes (44200).`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">1. Acceptation des conditions</h3>
          <p>{`En accédant et en utilisant la solution DISCKO, vous acceptez sans réserve les présentes Conditions Générales d'Utilisation (CGU). Si vous ne les acceptez pas, veuillez ne pas utiliser nos services.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">2. Accès à DISCKO</h3>
          <p>{`DISCKO est accessible 24/7, sauf en cas de force majeure ou d'événements hors de notre contrôle, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement de nos services.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">3. Utilisation des services</h3>
          <p>{`L'utilisateur s'engage à utiliser les services de DISCKO de manière conforme aux lois et règlements en vigueur, ainsi qu'aux présentes CGU. Toute utilisation frauduleuse ou abusive est interdite et pourra entraîner la suspension ou la résiliation de l'accès aux services.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">4. Comptes utilisateurs</h3>
          <p>{`Vous êtes responsable de la confidentialité de vos identifiants de connexion et de toutes les actions effectuées depuis votre compte. Vous devez informer immédiatement la société SPECKS de toute utilisation non autorisée de votre compte.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">5. Propriété intellectuelle</h3>
          <p>{`Tout le contenu présent sur DISCKO (textes, images, graphismes, logos, icônes, sons, code, etc.) est protégé par les lois relatives à la propriété intellectuelle et est la propriété exclusive de DISCKO ou de ses partenaires. Toute reproduction, distribution, modification ou utilisation de ce contenu sans autorisation préalable est interdite.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">6. Responsabilité</h3>
          <p>{`SPECKS ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation ou de l'incapacité à utiliser le site, des erreurs, des virus ou autres éléments nuisibles présents sur la solution. SPECKS s'efforce d'assurer l'exactitude des informations présentées, sans toutefois garantir leur exhaustivité ou leur mise à jour constante.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">7. Données personnelles</h3>
          <p>{`Les informations personnelles collectées par DISCKO sont traitées conformément à notre politique de confidentialité, disponible sur notre site. Vous disposez d'un droit d'accès, de rectification et d'opposition aux données vous concernant, que vous pouvez exercer en nous contactant.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">8. Modification des CGU</h3>
          <p>{`SPECKS se réserve le droit de modifier les présentes CGU à tout moment. Les modifications entreront en vigueur dès leur publication sur le site. Nous vous encourageons à consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">9. Loi applicable et juridiction</h3>
          <p>{`Les présentes CGU sont soumises au droit français. En cas de litige, et après tentative de résolution amiable, compétence exclusive est attribuée aux tribunaux français.`}</p>
        </div>

        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h3 className="text-xl font-bold mb-4">10. Contact</h3>
          <p>
            {`Pour toute question relative aux présentes CGU, vous pouvez nous contacter à l'adresse suivante : `}
            <a href="mailto:contact@discko.io" className="text-blue-500">
              contact@discko.io
            </a>
          </p>
        </div>
      </div>
    </BasePage>
  );
}
