import React from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';

export function PrivacyPolicyWidget(): JSX.Element {
  return (
    <div className="max-w-3xl mx-auto py-8 text-justify">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-4">Politique de Confidentialité</h2>
        <h3 className="text-xl font-bold mb-4">Introduction</h3>
        <p>{`VEUILLEZ LIRE ATTENTIVEMENT CETTE POLITIQUE DE CONFIDENTIALITÉ AVANT TOUTE UTILISATION DE LA SOLUTION « DISCKO » proposée par la société par actions simplifiée SPECKS, immatriculée au RCS de Nantes sous le numéro 980 269 062, dont le siège social est sis 1 rue Pauline Roland à Nantes (44200).`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Introduction</h3>
        <p>{`La présente politique de confidentialité décrit comment DISCKO collecte, utilise et protège les informations personnelles que vous nous fournissez lorsque vous utilisez notre site web et nos services. Nous nous engageons à protéger votre vie privée et à garantir la sécurité de vos données personnelles.`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Données collectées</h3>
        <ul>
          <li>
            <strong>Informations personnelles :</strong>{' '}
            {`Dans le cadre de l'exploitation de ses services, DISCKO
              procède à la collecte de certaines informations personnelles concernant ses utilisateurs, incluant mais
              sans s'y limiter, le nom et l'adresse e-mail de l'utilisateur. En outre, DISCKO travaille en collaboration
              avec des partenaires tiers de confiance, Auth0 pour l'authentification et Stripe pour la facturation, qui
              sont amenés à collecter des informations supplémentaires telles que les noms, prénoms, adresses de
              facturation et informations de paiement des utilisateurs. Ces procédures de collecte de données sont
              réalisées conformément aux lois et réglementations en vigueur. Pour plus de détails, veuillez consulter la`}
            <a href="https://stripe.com/en-fr/privacy" target="_blank" rel="noopener noreferrer">
              Politique de Confidentialité de Stripe.
            </a>
          </li>
          <li>
            <strong>Données collectées via OpenAI :</strong>{' '}
            {`Nous utilisons les services d'OpenAI pour améliorer et
              personnaliser l'expérience utilisateur. En utilisant notre produit, certaines données peuvent être
              envoyées à OpenAI, telles que des requêtes textuelles et des informations de session. OpenAI traite ces
              données conformément à sa propre politique de confidentialité. Pour plus de détails, veuillez consulter la`}
            <a href="https://openai.com/policies/privacy-policy" target="_blank" rel="noopener noreferrer">
              {"Politique de Confidentialité d'OpenAI."}
            </a>
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Utilisation des données</h3>
        <ul>
          <li>
            <strong>Fournir nos services :</strong> Cela inclut le traitement des commandes et la gestion de vos comptes
            utilisateurs.
          </li>
          <li>
            <strong>Améliorer nos services :</strong>
            {`Nous analysons les données d’utilisation pour optimiser et
              améliorer nos services.`}
          </li>
          <li>
            <strong>Communication :</strong>
            {`Nous vous envoyons des emails concernant les mises à jour, les offres
              spéciales ou d'autres informations pertinentes.`}
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Partage des données</h3>
        <p>{`Vos informations peuvent être partagées avec des tiers uniquement dans les cas suivants :`}</p>
        <ul>
          <li>
            <strong>Prestataires de services :</strong> Par exemple, nous partageons vos informations avec Stripe pour
            le traitement des paiements et de la contractualisation.
          </li>
          <li>
            <strong>Conformité légale :</strong> Nous pouvons être tenus de partager vos informations lorsque cela est
            requis par la loi.
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Sécurité des données</h3>
        <p>{`Nous utilisons des mesures de sécurité techniques et organisationnelles pour protéger vos informations contre l'accès non autorisé, la divulgation, la modification ou la destruction. Ces mesures incluent des pare-feux, le cryptage des données et des contrôles d'accès stricts.`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 ">
        <h3 className="text-xl font-bold mb-4 ">Vos droits</h3>
        <p>{`En vertu du Règlement Général sur la Protection des Données (RGPD), vous êtes investis des droits ci-après :`}</p>
        <br />
        <ul className="list-inside list-disc flex flex-col gap-2">
          <li>
            <strong>Accès :</strong>
            {` Vous pouvez demander l'accès aux données personnelles que nous détenons à votre
              sujet.`}
          </li>
          <li>
            <strong>Rectification :</strong> Vous pouvez demander la correction de toute donnée inexacte ou incomplète.
          </li>
          <li>
            <strong>Suppression :</strong> Vous pouvez demander la suppression de vos données personnelles.
          </li>
          <li>
            <strong>Opposition :</strong> Vous pouvez vous opposer au traitement de vos données personnelles dans
            certaines conditions.
          </li>
        </ul>
        <br />
        <p>
          {`Pour exercer ces droits, veuillez nous contacter par courrier à l'adresse suivante : SAS SPECKS, 1 rue Pauline Roland, 44200 NANTES, ou par email à `}
          <a href="mailto:contact@discko.io" className="font-bold ">
            contact@discko.io
          </a>
          .
        </p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Modifications de la politique de confidentialité</h3>
        <p>{`SPECKS se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page et entreront en vigueur immédiatement. Nous vous encourageons à consulter régulièrement cette page pour rester informé des mises à jour.`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Contact</h3>
        <p>
          {`Pour toute question concernant cette politique de confidentialité, veuillez nous contacter à `}
          <a href="mailto:contact@discko.io" className="font-bold">
            contact@discko.io
          </a>
          .
        </p>
      </div>
    </div>
  );
}
