/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetGenericRequirementIdParamsDto
 */
export interface GetGenericRequirementIdParamsDto {
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementIdParamsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementIdParamsDto
     */
    urlRedirect?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementIdParamsDto
     */
    step: string;
}

/**
 * Check if a given object implements the GetGenericRequirementIdParamsDto interface.
 */
export function instanceOfGetGenericRequirementIdParamsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "step" in value;

    return isInstance;
}

export function GetGenericRequirementIdParamsDtoFromJSON(json: any): GetGenericRequirementIdParamsDto {
    return GetGenericRequirementIdParamsDtoFromJSONTyped(json, false);
}

export function GetGenericRequirementIdParamsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGenericRequirementIdParamsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'step': json['step'],
    };
}

export function GetGenericRequirementIdParamsDtoToJSON(value?: GetGenericRequirementIdParamsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'urlRedirect': value.urlRedirect,
        'step': value.step,
    };
}

