/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  ErrorInternalServer,
  ErrorUnauthorizedRequest,
  GetDisckoCompaniesDto,
  GetDisckoCompanyDto,
  GetFullDisckoCompaniesDto,
  GetIsDisckoCompanyValidDto,
  GetUiCompanyDataDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    ErrorInternalServerFromJSON,
    ErrorInternalServerToJSON,
    ErrorUnauthorizedRequestFromJSON,
    ErrorUnauthorizedRequestToJSON,
    GetDisckoCompaniesDtoFromJSON,
    GetDisckoCompaniesDtoToJSON,
    GetDisckoCompanyDtoFromJSON,
    GetDisckoCompanyDtoToJSON,
    GetFullDisckoCompaniesDtoFromJSON,
    GetFullDisckoCompaniesDtoToJSON,
    GetIsDisckoCompanyValidDtoFromJSON,
    GetIsDisckoCompanyValidDtoToJSON,
    GetUiCompanyDataDtoFromJSON,
    GetUiCompanyDataDtoToJSON,
} from '../models';

export interface DisckoCompanyControllerAddFreeCreditsRequest {
    id: string;
    creditsToAdd: number;
}

export interface UnauthDisckoCompanyControllerFindByIdRequest {
    id: string;
}

export interface UnauthDisckoCompanyControllerGetUiCompanyDataRequest {
    id: string;
}

/**
 * 
 */
export class DisckoCompaniesApi extends runtime.BaseAPI {

    /**
     */
    async disckoCompanyControllerAddFreeCreditsRaw(requestParameters: DisckoCompanyControllerAddFreeCreditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoCompanyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disckoCompanyControllerAddFreeCredits.');
        }

        if (requestParameters.creditsToAdd === null || requestParameters.creditsToAdd === undefined) {
            throw new runtime.RequiredError('creditsToAdd','Required parameter requestParameters.creditsToAdd was null or undefined when calling disckoCompanyControllerAddFreeCredits.');
        }

        const queryParameters: any = {};

        if (requestParameters.creditsToAdd !== undefined) {
            queryParameters['creditsToAdd'] = requestParameters.creditsToAdd;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/DisckoCompanies/{id}/credits`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoCompanyControllerAddFreeCredits(requestParameters: DisckoCompanyControllerAddFreeCreditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoCompanyDto> {
        const response = await this.disckoCompanyControllerAddFreeCreditsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoCompanyControllerFindAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFullDisckoCompaniesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/DisckoCompanies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFullDisckoCompaniesDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoCompanyControllerFindAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFullDisckoCompaniesDto> {
        const response = await this.disckoCompanyControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoCompanyControllerFindByUserIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDisckoCompaniesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/DisckoCompanies/by-userid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisckoCompaniesDtoFromJSON(jsonValue));
    }

    /**
     */
    async disckoCompanyControllerFindByUserId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDisckoCompaniesDto> {
        const response = await this.disckoCompanyControllerFindByUserIdRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async disckoCompanyControllerIsAdminRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/DisckoCompanies/is-admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async disckoCompanyControllerIsAdmin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.disckoCompanyControllerIsAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async unauthDisckoCompanyControllerFindByIdRaw(requestParameters: UnauthDisckoCompanyControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetIsDisckoCompanyValidDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unauthDisckoCompanyControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/DisckoCompanies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIsDisckoCompanyValidDtoFromJSON(jsonValue));
    }

    /**
     */
    async unauthDisckoCompanyControllerFindById(requestParameters: UnauthDisckoCompanyControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetIsDisckoCompanyValidDto> {
        const response = await this.unauthDisckoCompanyControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unauthDisckoCompanyControllerGetUiCompanyDataRaw(requestParameters: UnauthDisckoCompanyControllerGetUiCompanyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUiCompanyDataDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling unauthDisckoCompanyControllerGetUiCompanyData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/DisckoCompanies/ui/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUiCompanyDataDtoFromJSON(jsonValue));
    }

    /**
     */
    async unauthDisckoCompanyControllerGetUiCompanyData(requestParameters: UnauthDisckoCompanyControllerGetUiCompanyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUiCompanyDataDto> {
        const response = await this.unauthDisckoCompanyControllerGetUiCompanyDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
