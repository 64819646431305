import { DisckoApisContext, MaintenanceContext } from 'App';
import { type GetDisckoCompanyDto, type GetGenericRequirementsDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { RequirementsWidget } from 'pages/RequirementsPages/RequirementsWidget';

import { CreditsWidget } from 'components/WidgetCredits/CreditsWidget';
import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

export function HomePage(): JSX.Element {
  const navigate: NavigateFunction = useNavigate();
  const { companyService, stripeService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const { requirementService } = useContext(DisckoApisContext);

  const [searchParams] = useSearchParams();
  const success: string | undefined = searchParams.get('success') ?? undefined;
  let alreadyNotify: boolean = false;

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  async function getCompanyIdByUserId(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
    ['company', user?.sub],
    getCompanyIdByUserId,
  );

  function handleOnClickStartRequirement(): void {
    if (companyStatus !== 'success') return;
    navigate('/new?companyid=' + companyData.id, {});
  }

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  async function getRequirements(): Promise<GetGenericRequirementsDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await requirementService.findAll(accessToken);
  }

  const { data: requirementsData, status: requirementsStatus } = useQuery<GetGenericRequirementsDto, Error>(
    'requirements',
    getRequirements,
  );

  useEffect(() => {
    if (success === 'true' && !alreadyNotify) {
      alreadyNotify = true;
      toast.success('Félicitations ! Votre abonnement a bien été pris en compte.');
    }
  }, [success]);

  return (
    <BasePage>
      <>
        {isLargeScreen === true && (
          <div className={concatClassNames('flex', 'flex-col w-full', 'p-12 pt-10', 'gap-6')}>
            <Text content="Bienvenue sur Discko !" position="left" size="2xl" weight="bold" />
            <div className={concatClassNames('grid grid-rows-2 grid-cols-6 gap-4', 'h-full')}>
              <div
                className={concatClassNames(
                  'shadow-xl',
                  'p-4',
                  'row-span-2',
                  'col-span-3',
                  'flex flex-col gap-4 items-center align-middle justify-center',
                  companyStatus === 'success' &&
                    (companyData.credits > 0 ||
                      companyData.isSubscribed ||
                      companyData.hasAccessToStandardFeaturesUntil >= new Date() ||
                      companyData.hasAccessToPremiumFeaturesUntil >= new Date())
                    ? 'bg-white'
                    : 'bg-blue-400',
                )}
              >
                {companyStatus === 'success' &&
                  (companyData.credits > 0 ||
                    companyData.isSubscribed ||
                    companyData.hasAccessToStandardFeaturesUntil >= new Date() ||
                    companyData.hasAccessToPremiumFeaturesUntil >= new Date()) &&
                  companyData.activityDescription !== null && (
                    <>
                      <Text
                        position="center"
                        size="lg"
                        weight="bold"
                        content="Vous avez un prospect au téléphone ou face à vous ?"
                      />
                      <Text
                        position="center"
                        size="lg"
                        content="Générez une synthèse de son besoin en quelques minutes."
                      />

                      {companyStatus === 'success' &&
                        companyData.credits < 1 &&
                        !companyData.isSubscribed &&
                        companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                        companyData.hasAccessToStandardFeaturesUntil < new Date() && (
                          <p className={concatClassNames('text-red-500')}>{'Rechargez vos crédits pour continuer'}</p>
                        )}
                      <Button
                        height="fit"
                        width="fit"
                        content={isLargeScreen ? 'Démarrer une expression de besoin' : 'Démarrer'}
                        color="#42A5F5"
                        disabled={
                          companyStatus !== 'success' ||
                          (companyData.credits < 1 &&
                            !companyData.isSubscribed &&
                            companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                            companyData.hasAccessToStandardFeaturesUntil < new Date()) ||
                          companyData.activityDescription === null
                        }
                        onClick={handleOnClickStartRequirement}
                      />
                      <br />
                      <Text
                        position="center"
                        size="lg"
                        weight="bold"
                        content="🗓️ Vous avez programmé un premier rendez-vous avec un prospect ?"
                      />
                      <Text
                        position="center"
                        size="lg"
                        content="Aidez-le à préparer cet échange et personnalisez votre accompagnement."
                      />
                      <div className="relative flex flex-col items-center">
                        {companyStatus === 'success' &&
                          companyData.credits < 1 &&
                          !companyData.isSubscribed &&
                          companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                          companyData.hasAccessToStandardFeaturesUntil < new Date() && (
                            <p className={concatClassNames('text-red-500')}>{'Rechargez vos crédits pour continuer'}</p>
                          )}
                        <Button
                          height="fit"
                          width="fit"
                          content="Copier le lien"
                          color="#42A5F5"
                          iconName="link"
                          iconPosition="left"
                          disabled={
                            companyStatus !== 'success' ||
                            (companyData.credits < 1 &&
                              !companyData.isSubscribed &&
                              companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                              companyData.hasAccessToStandardFeaturesUntil < new Date()) ||
                            companyData.activityDescription === null
                          }
                          onClick={async () => {
                            await navigator.clipboard.writeText(
                              `${window.location.protocol}//${window.location.host}/new/?companyid=${companyData.id}`,
                            );
                            toast.success('Le lien a bien été copié.');
                          }}
                        />
                      </div>
                    </>
                  )}
                {companyStatus === 'success' &&
                  (companyData.credits > 0 ||
                    companyData.isSubscribed ||
                    companyData.hasAccessToStandardFeaturesUntil >= new Date() ||
                    companyData.hasAccessToPremiumFeaturesUntil >= new Date()) &&
                  companyData.activityDescription === null && (
                    <div className="flex flex-col gap-4 items-center m-16">
                      <Text
                        content="🚀 Bienvenue sur votre espace Discko !"
                        position="center"
                        textBreak="wordsAll"
                        weight="bold"
                      ></Text>
                      <p className="text-lg text-center">
                        Avant de commencer, prenez un moment pour configurer votre activité dans vos paramètres. Cette
                        étape est nécessaire pour accéder aux fonctionnalités et utiliser la solution. Vous pourrez
                        alors démarrer sans attendre ! 😊
                      </p>
                      <Button
                        content="Accéder à mon espace"
                        color="#42A5F5"
                        height="fit"
                        width="fit"
                        onClick={() => {
                          navigate('/settings?formCustomization=true');
                        }}
                      />
                    </div>
                  )}
                {companyStatus === 'success' &&
                  companyData.credits < 1 &&
                  !companyData.isSubscribed &&
                  companyData.hasAccessToStandardFeaturesUntil < new Date() &&
                  companyData.hasAccessToPremiumFeaturesUntil < new Date() && (
                    <>
                      <div className={concatClassNames('flex flex-row gap-6 p-4 items-start')}>
                        <div className="flex flex-col gap-2 rounded-lg p-2 text-white">
                          <div className="bg-gray-100 p-1 px-2 rounded-lg w-fit">
                            <Text position="left" size="md" color="white" content="Vous n'êtes pas abonné à Discko." />
                          </div>
                          <div className="flex flex-col align-middle gap-4 items-start w-full">
                            <p className="text-xl font-bold">{'Envie de booster votre prospection ?'}</p>
                            <p className="uppercase text-xl font-bold">{'Choisissez notre formule sans engagement'}</p>
                            <div className="flex flex-row items-center gap-1">
                              <Text
                                content={companyData.isOldPrice ? '25€' : '60€'}
                                size="5xl"
                                weight="bold"
                                color="white"
                              />
                              <p className="text-base text-white"> HT/mois</p>
                            </div>
                          </div>
                          <br />

                          <div className="flex flex-col items-center">
                            <div className="flex flex-col justify-start text-md w-full">
                              <ul>
                                <li>{'✅ Expressions de besoin illimitées.'}</li>
                                <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                                <li>{'✅ Personnalisation du formulaire.'} </li>
                              </ul>
                            </div>
                            <br />
                            <Button
                              color="#FFFFFF"
                              content="Souscrire dès maintenant"
                              textSize="lg"
                              onClick={() => {
                                window.open('/settings', '_self');

                                // setClickedOnSubscribe(true);
                              }}
                            ></Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
              {companyStatus === 'success' &&
                companyData.credits > 0 &&
                !companyData.isSubscribed &&
                companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                companyData.hasAccessToStandardFeaturesUntil < new Date() && (
                  <div
                    className={concatClassNames(
                      ' col-span-3',
                      'flex items-center justify-center',
                      'shadow-lg',
                      'bg-blue-400 rounded-sm border-blue-400 border-1',
                    )}
                  >
                    <CreditsWidget
                      isNotEnoughCreditError={
                        companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed
                      }
                    />
                  </div>
                )}
              <div
                className={concatClassNames(
                  'col-span-3',
                  companyStatus === 'success' && companyData.credits > 0 && !companyData.isSubscribed
                    ? 'row-span-1'
                    : 'row-span-2',
                  'p-4',
                  'flex items-center',
                  'bg-white',
                  'shadow-2xl',
                )}
              >
                <RequirementsWidget />
              </div>
            </div>
          </div>
        )}
        {isLargeScreen === false && (
          <div className={concatClassNames('flex', 'flex-col', 'items-center', 'w-full', 'gap-3')}>
            <Text content="Bienvenue sur Discko !" position="left" size="2xl" weight="bold" textBreak="all" />
            <div className={concatClassNames('flex flex-col gap-6', 'h-full w-full')}>
              <div
                className={concatClassNames(
                  'h-fit w-full',
                  'flex items-center justify-center',
                  'shadow-lg',
                  'bg-blue-400 rounded-sm',
                )}
              >
                {companyStatus === 'success' && companyData.credits > 0 && (
                  <div className={concatClassNames('py-6')}>
                    <CreditsWidget
                      isNotEnoughCreditError={
                        companyStatus === 'success' && companyData.credits < 1 && !companyData.isSubscribed
                      }
                    />
                  </div>
                )}
                {companyStatus === 'success' &&
                  companyData.credits <= 0 &&
                  !companyData.isSubscribed &&
                  companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                  companyData.hasAccessToStandardFeaturesUntil < new Date() && (
                    <div className={concatClassNames('flex flex-col', 'px-1 py-6', 'items-center justify-center')}>
                      <Text
                        position="center"
                        size="xl"
                        weight="bold"
                        color="white"
                        content="Vous n'êtes pas abonné à Discko."
                      />
                      <div className={concatClassNames('flex flex-row gap-6 p-4 items-start')}>
                        <div className="flew bg-blue-400 rounded-lg p-6 text-white">
                          <div className="flex flex-col align-middle gap-4 items-start w-full">
                            <p className="text-lg font-bold">{'Envie de booster votre prospection ?'}</p>
                            <p className="uppercase text-lg font-bold">{'Choisissez notre formule sans engagement'}</p>
                            <div className="flex flex-row items-center gap-1">
                              <Text
                                content={companyData.isOldPrice ? '25€' : '35€'}
                                size="3xl"
                                weight="bold"
                                color="white"
                              />
                              <p className="text-base text-white"> HT/mois</p>
                            </div>
                          </div>
                          <br />

                          <div className="flex flex-col items-center">
                            <div className="flex flex-col justify-start text-lg w-full">
                              <ul>
                                <li>{'✅ Expressions de besoin illimitées.'}</li>
                                <li>{"✅ Jusqu'à 10 administrateurs."}</li>
                                <li>{'✅ Personnalisation du formulaire.'} </li>
                              </ul>
                            </div>
                            <br />
                            <Button
                              color="#FFFFFF"
                              content="Souscrire dès maintenant"
                              onClick={() => {
                                window.open('/settings', '_self');

                                // setClickedOnSubscribe(true);
                              }}
                            ></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {requirementsStatus === 'success' && requirementsData.requirements.length > 0 && (
                <div
                  className={concatClassNames('flex flex-row', 'gap-4 items-center', 'bg-white rounded-sm', ' p-2')}
                  onClick={() => {
                    navigate('/requirements');
                  }}
                >
                  <div className={concatClassNames('h-3 w-3 bg-red-500 rounded-full')}></div>

                  <Text
                    size="sm"
                    weight="bold"
                    content={`${requirementsData?.requirements
                      .filter((requirement) => !requirement.read)
                      .length.toString()} nouvelles expressions de besoin`}
                  />
                </div>
              )}
              <Button
                content="Démarrer une expression de besoin"
                color="#42A5F5"
                onClick={() => {
                  navigate('/start-requirement');
                }}
                height="fit"
                disabled={
                  companyStatus !== 'success' ||
                  (companyData.credits < 1 &&
                    !companyData.isSubscribed &&
                    companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                    companyData.hasAccessToStandardFeaturesUntil < new Date()) ||
                  companyData.activityDescription === null
                }
              />
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
