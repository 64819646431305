import colors from 'tailwindcss/colors';

import React from 'react';

import { type LetterSpacing, Text, type TextColor, type TextPosition, type TextSize } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

import { getTextColor } from '../Button/Button';
import { type ChipBgColor } from './Chip.props';

const bgColorStyle: Record<ChipBgColor, string> = {
  white: 'bg-white',
  transparent: 'bg-transparent',
  cyan: 'bg-blue-400',
  red: 'bg-red-500',
  orange: 'bg-orange-400',
  green: 'bg-green-500',
  yellow: 'bg-yellow-400',
  'miscelleanous-blue': 'bg-miscelleanous-blue',
  'yellow-400': 'bg-warning-400',
  'green-500': 'bg-success-500',
  'red-500': 'bg-error-500',
  'purple-500': 'bg-purple-500',
  'bg-secondary-500': 'bg-secondary-500',
  'blue-100': 'bg-blue-100',
  'purple-100': 'bg-purple-100',
  'lime-100': 'bg-lime-100',
  'pink-100': 'bg-pink-100',
  'orange-100': 'bg-orange-100',
  'indigo-100': 'bg-indigo-100',
  'red-100': 'bg-red-100',
  'yellow-100': 'bg-yellow-100',
  'green-100': 'bg-green-100',
  'cyan-100': 'bg-cyan-100',
  'pink-200': 'bg-pink-200',
  'purple-200': 'bg-purple-200',
  'lime-200': 'bg-lime-200',
  'orange-200': 'bg-orange-200',
  'indigo-200': 'bg-indigo-200',
  'red-200': 'bg-red-200',
  'yellow-200': 'bg-yellow-200',
  'green-200': 'bg-green-200',
  'cyan-200': 'bg-cyan-200',
  'pink-300': 'bg-pink-300',
  'purple-300': 'bg-purple-300',
  'lime-300': 'bg-lime-300',
  'emerald-200': 'bg-emerald-200',
  'orange-300': 'bg-orange-300',
  'indigo-300': 'bg-indigo-300',
  'red-300': 'bg-red-300',
  'yellow-300': 'bg-yellow-300',
  'green-300': 'bg-green-300',
  'cyan-300': 'bg-cyan-300',
  'pink-400': 'bg-pink-400',
  'purple-400': 'bg-purple-400',
  'lime-400': 'bg-lime-400',
  'orange-400': 'bg-orange-400',
  'indigo-400': 'bg-indigo-400',
  'red-400': 'bg-red-400',
  'green-400': 'bg-green-400',
  'cyan-400': 'bg-cyan-400',
  'pink-500': 'bg-pink-500',
  'lime-500': 'bg-lime-500',
  'orange-500': 'bg-orange-500',
  'indigo-500': 'bg-indigo-500',
  'yellow-500': 'bg-yellow-500',
  'cyan-500': 'bg-cyan-500',
};

export type ChipPaddingTop = 'none' | 'sm' | 'md';

export const paddingTopStyle: Record<ChipPaddingTop, string> = {
  none: 'pt-0',
  sm: 'pt-0.5',
  md: 'pt-1',
};

export type ChipPaddingBottom = 'none' | 'sm' | 'md';

export const paddingBottomStyle: Record<ChipPaddingBottom, string> = {
  none: 'pb-0',
  sm: 'pb-0.5',
  md: 'pb-1',
};

export type ChipPaddingHorizontal = 'xs' | 'sm' | 'md' | 'lg';

export const paddingHorizontalStyle: Record<ChipPaddingHorizontal, string> = {
  xs: 'px-0.5',
  sm: 'px-1',
  md: 'px-2',
  lg: 'px-2.5',
};

export type ChipPaddingRight = 'none' | 'sm' | 'md';

export const paddingRightStyle: Record<ChipPaddingRight, string> = {
  none: 'pr-0',
  sm: 'pr-1',
  md: 'pr-2',
};

export type ChipBorderColor =
  | 'none'
  | 'yellow'
  | 'gray-50'
  | 'miscelleanous-blue'
  | 'red-500'
  | 'green-500'
  | 'yellow-400'
  | 'black'
  | 'blue'
  | 'cyan'
  | 'gray-100'
  | 'gray-200'
  | 'gray'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow'
  | 'gradient2-to'
  | 'pink'
  | 'blue-300'
  | 'purple-300'
  | 'lime-300'
  | 'pink-300'
  | 'pink-100'
  | 'pink-200'
  | 'yellow-300'
  | 'orange-300'
  | 'red-300'
  | 'cyan-300'
  | 'indigo-300'
  | 'green-300';

const borderColorStyle: Record<ChipBorderColor, string> = {
  none: '',
  'gray-50': 'border border-gray-50',
  'miscelleanous-blue': 'border border-miscelleanous-blue',
  'red-500': 'border border-error-500',
  'green-500': 'border border-success-500',
  'yellow-400': 'border border-warning-400',
  black: 'border border-[#000000]',
  blue: 'border border-[#2D3389]',
  cyan: 'border border-[#61AFFE]',
  'gray-100': 'border border-[#E5E5E5]',
  'gray-200': 'border border-[#929292]',
  gray: 'border border-[#6D6D6D]',
  green: 'border border-[#12B76A]',
  orange: 'border border-[#F79009]',
  purple: 'border border-[#462689]',
  red: 'border border-[#D92D20]',
  white: 'border border-[#FFFFFF]',
  yellow: 'border border-[#FBD34B]',
  'gradient2-to': 'border border-[#6D3A8A]',
  pink: 'border border-[#D83389]',
  'blue-300': 'border border-[#61AFFE]',
  'purple-300': 'border border-[#462689]',
  'lime-300': 'border border-[#A5C63B]',
  'pink-300': 'border border-[#D83389]',
  'yellow-300': 'border border-[#FBD34B]',
  'orange-300': 'border border-[#F79009]',
  'red-300': 'border border-[#D92D20]',
  'cyan-300': 'border border-[#61AFFE]',
  'green-300': 'border border-[#12B76A]',
  'indigo-300': 'border border-[#6D3A8A]',
  'pink-100': 'border border-pink-100',
  'pink-200': 'border border-pink-200',
};

export type ChipProps =
  | {
      icon?: IconName;
      iconColor?: IconColor;
      textColor?: TextColor;
      textTracking?: LetterSpacing;
      label: string;
      textPosition?: TextPosition;
      iconSize?: IconSize;
      textSize?: TextSize;
      borderColor?: ChipBorderColor;
      bgColor?: ChipBgColor;
      paddingTop?: ChipPaddingTop;
      paddingBottom?: ChipPaddingBottom;
      paddingHorizontal?: ChipPaddingHorizontal;
      paddingRight?: ChipPaddingRight;
      loading?: false;
      tooltipMessage?: string;
      onClick?: () => void;
    }
  | {
      icon?: never;
      iconColor?: never;
      textColor?: never;
      textTracking?: never;
      label?: never;
      textPosition?: never;
      iconSize?: never;
      textSize?: never;
      borderColor?: never;
      bgColor?: never;
      paddingTop?: never;
      paddingBottom?: never;
      paddingHorizontal?: never;
      paddingRight?: never;
      loading: true;
      tooltipMessage?: never;
      onClick?: never;
    };

export function Chip({
  icon,
  iconColor = 'red',
  iconSize = 'lg',
  textTracking = 'normal',
  textSize = 'base',
  label = '',
  textPosition = 'center',
  loading = false,
  borderColor = 'gray-50',
  bgColor = 'white',
  paddingTop = 'none',
  paddingBottom = 'none',
  paddingHorizontal = 'sm',
  paddingRight,
  onClick,
}: ChipProps): JSX.Element {
  if (loading) return <div className="w-28 h-6 rounded-lg bg-gray-50 animate-pulse" />;

  function tailwindToHex(colorName: string): string {
    const [color, shade] = colorName.split('-') as [keyof typeof colors, string]; // Ex: "lime-300"
    return colors[color]?.[shade as keyof (typeof colors)[keyof typeof colors]] || null;
  }

  return (
    <div
      className={concatClassNames(
        'flex flex-row w-fit',
        bgColorStyle[bgColor],
        borderColorStyle[borderColor],
        'items-center gap-1 rounded-md content-center',
        paddingTopStyle[paddingTop],
        paddingBottomStyle[paddingBottom],
        paddingHorizontalStyle[paddingHorizontal],
        paddingRight !== undefined ? paddingRightStyle[paddingRight] : '',
        'align-middle',
        'h-fit',
        'gap-2',
        onClick != null ? 'cursor-pointer' : '',
      )}
      onClick={onClick != null ? onClick : undefined}
    >
      {icon !== undefined && getIcon(icon, iconColor, iconSize)}
      <Text
        content={label}
        size={textSize}
        color={getTextColor(tailwindToHex(bgColor))}
        tracking={textTracking}
        position={textPosition}
      />
    </div>
  );
}
