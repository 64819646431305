/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorBadRequest,
  UpdateGenericRequirementParamsDto,
  UpdateUrlWebhookDto,
} from '../models';
import {
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    UpdateGenericRequirementParamsDtoFromJSON,
    UpdateGenericRequirementParamsDtoToJSON,
    UpdateUrlWebhookDtoFromJSON,
    UpdateUrlWebhookDtoToJSON,
} from '../models';

export interface RequirementsParametersControllerUpdateProjectQuestionsRequest {
    updateGenericRequirementParamsDto: UpdateGenericRequirementParamsDto;
}

export interface RequirementsParametersControllerUpdateRequirementParametersRequest {
    updateGenericRequirementParamsDto: UpdateGenericRequirementParamsDto;
}

export interface RequirementsParametersControllerUpdateWebhookRequest {
    updateUrlWebhookDto: UpdateUrlWebhookDto;
}

/**
 * 
 */
export class RequirementsParametersApi extends runtime.BaseAPI {

    /**
     */
    async requirementsParametersControllerUpdateProjectQuestionsRaw(requestParameters: RequirementsParametersControllerUpdateProjectQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateGenericRequirementParamsDto>> {
        if (requestParameters.updateGenericRequirementParamsDto === null || requestParameters.updateGenericRequirementParamsDto === undefined) {
            throw new runtime.RequiredError('updateGenericRequirementParamsDto','Required parameter requestParameters.updateGenericRequirementParamsDto was null or undefined when calling requirementsParametersControllerUpdateProjectQuestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements-parameters/form-parameters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGenericRequirementParamsDtoToJSON(requestParameters.updateGenericRequirementParamsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateGenericRequirementParamsDtoFromJSON(jsonValue));
    }

    /**
     */
    async requirementsParametersControllerUpdateProjectQuestions(requestParameters: RequirementsParametersControllerUpdateProjectQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateGenericRequirementParamsDto> {
        const response = await this.requirementsParametersControllerUpdateProjectQuestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requirementsParametersControllerUpdateRequirementParametersRaw(requestParameters: RequirementsParametersControllerUpdateRequirementParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateGenericRequirementParamsDto>> {
        if (requestParameters.updateGenericRequirementParamsDto === null || requestParameters.updateGenericRequirementParamsDto === undefined) {
            throw new runtime.RequiredError('updateGenericRequirementParamsDto','Required parameter requestParameters.updateGenericRequirementParamsDto was null or undefined when calling requirementsParametersControllerUpdateRequirementParameters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements-parameters/generic-requirement-parameters`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGenericRequirementParamsDtoToJSON(requestParameters.updateGenericRequirementParamsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateGenericRequirementParamsDtoFromJSON(jsonValue));
    }

    /**
     */
    async requirementsParametersControllerUpdateRequirementParameters(requestParameters: RequirementsParametersControllerUpdateRequirementParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateGenericRequirementParamsDto> {
        const response = await this.requirementsParametersControllerUpdateRequirementParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requirementsParametersControllerUpdateWebhookRaw(requestParameters: RequirementsParametersControllerUpdateWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUrlWebhookDto>> {
        if (requestParameters.updateUrlWebhookDto === null || requestParameters.updateUrlWebhookDto === undefined) {
            throw new runtime.RequiredError('updateUrlWebhookDto','Required parameter requestParameters.updateUrlWebhookDto was null or undefined when calling requirementsParametersControllerUpdateWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/requirements-parameters/webhook`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUrlWebhookDtoToJSON(requestParameters.updateUrlWebhookDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUrlWebhookDtoFromJSON(jsonValue));
    }

    /**
     */
    async requirementsParametersControllerUpdateWebhook(requestParameters: RequirementsParametersControllerUpdateWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUrlWebhookDto> {
        const response = await this.requirementsParametersControllerUpdateWebhookRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
