/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactParameters } from './ContactParameters';
import {
    ContactParametersFromJSON,
    ContactParametersFromJSONTyped,
    ContactParametersToJSON,
} from './ContactParameters';

/**
 * 
 * @export
 * @interface GetGenericRequirementParametersDto
 */
export interface GetGenericRequirementParametersDto {
    /**
     * 
     * @type {number}
     * @memberof GetGenericRequirementParametersDto
     */
    numberOfQuestions: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetGenericRequirementParametersDto
     */
    isWatermarkRemoved: boolean;
    /**
     * 
     * @type {ContactParameters}
     * @memberof GetGenericRequirementParametersDto
     */
    contactParameters: ContactParameters;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementParametersDto
     */
    step: string;
}

/**
 * Check if a given object implements the GetGenericRequirementParametersDto interface.
 */
export function instanceOfGetGenericRequirementParametersDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numberOfQuestions" in value;
    isInstance = isInstance && "isWatermarkRemoved" in value;
    isInstance = isInstance && "contactParameters" in value;
    isInstance = isInstance && "step" in value;

    return isInstance;
}

export function GetGenericRequirementParametersDtoFromJSON(json: any): GetGenericRequirementParametersDto {
    return GetGenericRequirementParametersDtoFromJSONTyped(json, false);
}

export function GetGenericRequirementParametersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGenericRequirementParametersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfQuestions': json['numberOfQuestions'],
        'isWatermarkRemoved': json['isWatermarkRemoved'],
        'contactParameters': ContactParametersFromJSON(json['contactParameters']),
        'step': json['step'],
    };
}

export function GetGenericRequirementParametersDtoToJSON(value?: GetGenericRequirementParametersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfQuestions': value.numberOfQuestions,
        'isWatermarkRemoved': value.isWatermarkRemoved,
        'contactParameters': ContactParametersToJSON(value.contactParameters),
        'step': value.step,
    };
}

