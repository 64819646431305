/* eslint-disable max-len */
import React from 'react';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function TitleIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.0535 28.3606C31.6543 28.3606 32.2303 28.122 32.6553 27.697C33.0799 27.2724 33.3185 26.696 33.3185 26.0956V17.0347C33.3185 16.1336 32.9606 15.2693 32.3235 14.6322C31.6863 13.995 30.822 13.6367 29.921 13.6367H27.6556C26.8466 13.6367 26.0988 14.0685 25.694 14.7696C25.2895 15.4704 25.2895 16.3339 25.694 17.0346C26.0989 17.7353 26.8466 18.1671 27.6556 18.1671H28.7881L28.7885 26.0954C28.7885 26.6958 29.0271 27.2722 29.4517 27.6968C29.8768 28.1218 30.4527 28.3604 31.0535 28.3604L31.0535 28.3606Z"
        fill={color}
      />
      <path
        d="M18.5899 28.3618C19.1907 28.3618 19.7667 28.1232 20.1917 27.6981C20.6164 27.2735 20.855 26.6972 20.855 26.0967V2.31234C20.855 1.50335 20.4232 0.755535 19.7225 0.350736C19.0217 -0.0537608 18.1582 -0.0537608 17.4575 0.350736C16.7563 0.755626 16.3246 1.50335 16.3246 2.31234V11.3733H4.99871V2.31234C4.99871 1.50335 4.56696 0.755535 3.86622 0.350736C3.16546 -0.0537608 2.30193 -0.0537608 1.60124 0.350736C0.90048 0.755626 0.46875 1.50335 0.46875 2.31234V26.0967C0.46875 26.9057 0.9005 27.6535 1.60124 28.0583C2.302 28.4628 3.16553 28.4628 3.86622 28.0583C4.56698 27.6535 4.99871 26.9057 4.99871 26.0967V15.9034H16.3246V26.0967C16.3246 26.6972 16.5636 27.2735 16.9882 27.6981C17.4129 28.1232 17.9892 28.3618 18.59 28.3618H18.5899Z"
        fill={color}
      />
    </svg>
  );
}
