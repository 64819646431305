import React, { useCallback, useState } from 'react';
import { type QueryClient, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';

import { Chip } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';
import { P } from 'components/core/P';
import { Text, type TextSize } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

export interface ProjectType {
  type: string;
  color: string;
}

type RequirementRowProps =
  | {
      id?: string;
      name?: string;
      loading?: boolean;
      title?: false;
      createdAt: string;
      projectType: any;
      chipSize?: TextSize;
      read?: boolean;
      setRightClickedProduct?: (id: string) => void;
      setPoints?: (points: { x: number; y: number }) => void;
      setSortBy?: never;
    }
  | {
      id?: never;
      name?: never;
      createdAt?: never;
      read?: never;
      chipSize?: TextSize;
      projectType?: never;
      loading?: never;
      title: true;
      setRightClickedProduct?: never;
      setPoints?: never;
      setSortBy: (category: string) => void;
    }
  | {
      id?: never;
      name?: never;
      chipSize?: never;
      projectType?: never;
      createdAt?: never;
      read?: never;
      loading: true;
      title?: never;
      setRightClickedProduct?: never;
      setPoints?: never;
      setSortBy?: never;
    };

export function RequirementRow({
  id = '',
  name = '',
  projectType = { type: 'Autre', color: 'cyan-200' },
  createdAt = '',
  read = false,
  loading = false,
  title = false,
  chipSize = 'base',
  setRightClickedProduct,
  setPoints,
  setSortBy,
}: RequirementRowProps): JSX.Element {
  const navigate: NavigateFunction = useNavigate();

  const queryClient: QueryClient = useQueryClient();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------- Right Click Menu ----------------------------------------------- */

  function handleClick(): void {
    if (loading) return;
    if (id === undefined) console.error('id or product is null or undefined');
    else {
      navigate('/requirements/' + id);
    }
  }

  return (
    <>
      {isLargeScreen === true && (
        <div>
          {loading ? (
            <div className={concatClassNames('bg-gray-25', 'rounded-lg', 'flex flex-row items-center')}>
              <div className="flex flex-row items-center justify-start p-2">
                <div className={concatClassNames('w-[35%]')}>
                  <Text loading />
                </div>
                <div className={concatClassNames('w-[35%]')}>
                  <Text loading />
                </div>
              </div>
            </div>
          ) : (
            <>
              {title ? (
                <div className={concatClassNames('bg-white', 'flex flex-row items-center')}>
                  <div className="flex flex-row items-center justify-start p-2 w-full">
                    <div className={concatClassNames('w-[25%] flex flex-row gap-4 items-center')}>
                      <Text content="Client" size="sm" />
                    </div>
                    <div
                      className={concatClassNames(
                        chipSize === 'base' ? 'w-[40%]' : 'w-[60%]',
                        'flex flex-row gap-4 items-center',
                      )}
                    >
                      <Text content="Type de projet" size="sm" />
                      <div
                        onClick={() => setSortBy?.('projectType')}
                        className={concatClassNames('hover:cursor-pointer')}
                      >
                        {getIcon('sort', '#3A3A3A', 'sm')}
                      </div>
                    </div>

                    <div className={concatClassNames('w-[25%] flex flex-row gap-4 items-center')}>
                      <Text content="Date" size="sm" />
                      <div
                        onClick={() => setSortBy?.('createdAt')}
                        className={concatClassNames('hover:cursor-pointer')}
                      >
                        {getIcon('sort', '#3A3A3A', 'sm')}
                      </div>
                    </div>
                    {chipSize === 'base' && (
                      <div className={concatClassNames('w-[10%] flex flex-row gap-4 items-center')}>
                        <Text content="Lu" size="sm" />
                        <div onClick={() => setSortBy?.('read')} className={concatClassNames('hover:cursor-pointer')}>
                          {getIcon('sort', '#3A3A3A', 'sm')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  onClick={handleClick}
                  className={concatClassNames(
                    read ? 'bg-slate-500/10' : 'bg-slate-500/30',
                    'hover:bg-slate-300',
                    'cursor-pointer',
                    'rounded-lg',
                    'flex flex-row items-center',
                    'h-full',
                  )}
                >
                  {!read && <div className="h-full w-4 bg-slate-500 rounded-l-lg z-10"></div>}
                  <div className="flex flex-row items-center justify-start p-2 w-full gap-2">
                    <div className={concatClassNames('w-[25%]')}>
                      <Text
                        content={name}
                        position="left"
                        size={chipSize === 'base' ? 'lg' : 'sm'}
                        weight={read ? 'normal' : 'bold'}
                        color={read ? 'gray-200' : 'black'}
                      />
                    </div>
                    <div className={concatClassNames(chipSize === 'base' ? 'w-[40%]' : 'w-[60%]')}>
                      <div className="flex flex-row gap-2 items-center">
                        <Chip
                          label={projectType.type}
                          bgColor={projectType.color as ChipBgColor}
                          paddingHorizontal="md"
                          paddingTop="sm"
                          paddingBottom="sm"
                          textPosition="center"
                          // borderColor={category.color as ChipBorderColor}
                        />
                      </div>
                    </div>
                    <div className={concatClassNames('w-[25%]')}>
                      <Text content={createdAt} size="sm" color={read ? 'black' : 'black'} />
                    </div>
                    {chipSize === 'base' && (
                      <div className={concatClassNames('w-[10%]')}>
                        {!read && <div className={concatClassNames('h-4 w-4 bg-red-500 rounded-full')}></div>}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {isLargeScreen === false && (
        <div>
          {loading ? (
            <div className={concatClassNames('bg-gray-25', 'rounded-lg', 'flex flex-row items-center')}>
              <div className="flex flex-row items-center justify-start p-2">
                <div className={concatClassNames('w-10')}>
                  <Text loading />
                </div>
                <div className={concatClassNames('w-20')}>
                  <Text loading />
                </div>
              </div>
            </div>
          ) : (
            <>
              {title ? (
                <div className={concatClassNames('bg-white', 'flex flex-row items-center')}>
                  <div className="flex flex-row items-center  justify-start p-2">
                    <div className={concatClassNames('w-10 flex flex-row gap-4 items-center')}>
                      <Text content="Client" size="sm" />
                    </div>
                    <div className={concatClassNames('w-20 flex flex-row gap-4 items-center')}>
                      <Text content="Type de projet" size="sm" />
                      <div
                        onClick={() => setSortBy?.('projectType')}
                        className={concatClassNames('hover:cursor-pointer')}
                      >
                        {getIcon('sort', '#3A3A3A', 'sm')}
                      </div>
                    </div>

                    <div className={concatClassNames('w-20 flex flex-row gap-4 items-center')}>
                      <Text content="Date" size="sm" />
                      <div
                        onClick={() => setSortBy?.('createdAt')}
                        className={concatClassNames('hover:cursor-pointer')}
                      >
                        {getIcon('sort', '#3A3A3A', 'sm')}
                      </div>
                    </div>
                    <div className={concatClassNames('w-[35%] flex flex-row gap-4 items-center')}>
                      <Text content="Lu" size="sm" />
                      <div onClick={() => setSortBy?.('read')} className={concatClassNames('hover:cursor-pointer')}>
                        {getIcon('sort', '#3A3A3A', 'sm')}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={handleClick}
                  className={concatClassNames(
                    read ? 'bg-slate-100' : 'bg-slate-200',
                    'hover:bg-slate-300',
                    'cursor-pointer',
                    'rounded-lg',
                    'flex flex-row items-center',
                    'h-full',
                  )}
                >
                  <div className="flex flex-row gap-2 items-center justify-start h-full m-2 ml-0">
                    {!read && <div className="h-full w-2 bg-slate-500 rounded-l-lg z-10"></div>}
                    <div className={concatClassNames('w-36 ml-1')}>
                      <Text content={name} size="base" weight={read ? 'normal' : 'bold'} />
                    </div>
                    <div className={concatClassNames('w-24')}>
                      <Text content={createdAt} size="xs" />
                    </div>
                    {!read && <div className={concatClassNames('h-2 w-2 bg-red-500 rounded-full')}></div>}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
