/* eslint-disable @typescript-eslint/typedef */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable no-control-regex */

/* eslint-disable prefer-regex-literals */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type SetupProjectInputsDto } from 'api';
import * as Yup from 'yup';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import RadioButtons from 'components/RadioButtons/RadioButtons';
import { RichText } from 'components/RichText/RichText';
import { Button, getTextColor } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { type Completion, Stepsbar } from 'components/core/StepsBar/Stepsbar';
import TextArea from 'components/core/TextArea/TextArea';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

import { type DisckoFormProps, type Question, disckoContext } from './DisckoPage';

/* -------------------------------------------------- interfaces -------------------------------------------------- */

type PriceRange = '0-10' | '10-20' | '20-50' | '50-100' | '100-300' | '300+' | 'abstain';

type DeadlineCategory = 'fixedDate' | 'flexible' | 'phases' | 'beforeEvent' | 'other';

interface Deadline {
  deadlineCategory?: DeadlineCategory;
  date?: string;
}

const projectMaturityOptions: string[] = ['IDEA', 'DESIGN', 'DEVELOPMENT', 'MAINTENANCE'];

const projectMaturityStyleOptions: string[] = [
  'Idée ou concept',
  'Besoin travaillé et clairement défini',
  'Projet déjà initié',
  "Reprise et amélioration d'un projet existant",
];

const projectMaturityOptionsMap: Map<string, string> = new Map<string, string>();
projectMaturityOptionsMap.set('IDEA', 'Besoin travaillé et clairement défini');
projectMaturityOptionsMap.set('DESIGN', 'Besoin travaillé et clairement défini');
projectMaturityOptionsMap.set('DEVELOPMENT', 'Projet déjà initié');
projectMaturityOptionsMap.set('MAINTENANCE', "Reprise et amélioration d'un projet existant");

const clientMaturityOptions: string[] = ['BEGINNER', 'INTERMEDIATE', 'EXPERT'];

const clientMaturityStyleOptions: string[] = ['Débutant', 'Intermédiaire', 'Avancé'];

const clientMaturityOptionsMap: Map<string, string> = new Map<string, string>();
clientMaturityOptionsMap.set('BEGINNER', 'Débutant');
clientMaturityOptionsMap.set('INTERMEDIATE', 'Intermédiaire');
clientMaturityOptionsMap.set('EXPERT', 'Avancé');

const priceRangeOptions: PriceRange[] = ['abstain', '0-10', '10-20', '20-50', '50-100', '100-300', '300+'];

const priceRangeStyleOptions: string[] = [
  'Je ne sais pas',
  'Moins de 10k€',
  '10 à 20k€',
  '20 à 50k€',
  '50 à 100k€',
  '100 à 300k€',
  'Plus de 300k€',
];

const priceRangeOptionsMap: Map<PriceRange, string> = new Map<PriceRange, string>();
priceRangeOptionsMap.set('abstain', 'Je ne sais pas');
priceRangeOptionsMap.set('0-10', 'Moins de 10k€');
priceRangeOptionsMap.set('10-20', '10 à 20k€');
priceRangeOptionsMap.set('20-50', '20 à 50k€');
priceRangeOptionsMap.set('50-100', '50 à 100k€');
priceRangeOptionsMap.set('100-300', '100 à 300k€');
priceRangeOptionsMap.set('300+', 'Plus de 300k€');

const deadlineCategoryOptions: DeadlineCategory[] = ['fixedDate', 'flexible', 'beforeEvent', 'phases', 'other'];

const deadlineCategoryStyleOptions: string[] = [
  'Période fixe',
  'Flexible',
  'Avant un événement important',
  'Plusieurs phases avec des jalons spécifiques',
  'Autre',
];

const deadlineCategoryOptionsMap: Map<DeadlineCategory, string> = new Map<DeadlineCategory, string>();
deadlineCategoryOptionsMap.set('fixedDate', 'Période fixe');
deadlineCategoryOptionsMap.set('flexible', 'Flexible');
deadlineCategoryOptionsMap.set('beforeEvent', 'Avant un événement important');
deadlineCategoryOptionsMap.set('phases', 'Plusieurs phases avec des jalons spécifiques');
deadlineCategoryOptionsMap.set('other', 'Autre');

const deadlineCategoryDatePlaceholderOptions: string[] = [
  "Ex : à l'automne prochain",
  '',
  'Ex: avant le salon du 08/01/2026',
  'Ex: Une première version dans les trois prochains mois, puis une version finale dans les six mois suivants',
  'Toute autre contrainte ou préférence de délai que vous pourriez avoir.',
];

interface DisckoForm1Model {
  projectDescription: string;
  clientMaturity?: string;
  priceRange?: PriceRange;
  projectMaturity?: string;
  deadline?: { deadlineCategory?: DeadlineCategory; date?: string };
}
const schema: Yup.ObjectSchema<DisckoForm1Model> = Yup.object().shape({
  projectDescription: Yup.string()
    .required('Champ obligatoire')
    .min(10, 'La description doit contenir au moins 10 caractères')
    .max(500, 'La description doit contenir au maximum 500 caractères'),

  // required only when askClientMaturity (in the context) is true
  clientMaturity: Yup.mixed<string>().test('isclientMaturity', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askClientMaturity)
      if (value == null || value === undefined || value.length === 0) return false;
    return true;
  }),
  // required only when askPriceRange (in the context) is true
  priceRange: Yup.mixed<PriceRange>().test('ispriceRange', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askPriceRange)
      if (value == null || value === undefined || value.length === 0) return false;
    return true;
  }),

  // required only when askProjectMaturity (in the context) is true
  projectMaturity: Yup.mixed<string>().test('isProjectMaturityRequired', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askProjectMaturity) {
      if (value == null || value === undefined || value.length === 0) return false;
    }
    return true;
  }),
  // required only when askDeadline (in the context) is true
  deadline: Yup.object({
    deadlineCategory: Yup.mixed<DeadlineCategory>().test(
      'isDeadlineRequired',
      'Champ obligatoire',
      (value, { options }) => {
        if (options.context?.requirementParams?.askDeadline) {
          if (value == null || value === undefined || value.length === 0) return false;
        }
        return true;
      },
    ),
    date: Yup.string().when(['deadline.deadlineCategory'], (deadlineCategory, schema) => {
      if (String(deadlineCategory) !== 'flexible') return schema.notRequired();
      return schema.required('Champ obligatoire');
    }),
  }).test('isDeadlineRequired', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askDeadline) {
      if (value == null || value === undefined || value.deadlineCategory?.length === 0) return false;
    }
    return true;
  }),
});

export function DisckoFormProject({ setNextStep }: DisckoFormProps): JSX.Element {
  /* ---------------------------------------------------- Context --------------------------------------------------- */
  const {
    openAiQuestionsFormToDisplay,
    // messages,
    setupProject,
    contextRequirement,
    setSynthesis: setContextRequirement,
    isLargeScreen,
    requirementParams,
    uiSettings,
  } = useContext(disckoContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [animation, setAnimation] = useState<'visible' | 'invisible'>('visible');

  const [nextMobileSteps, setNextMobileSteps] = useState<number[]>([1]);
  const [isAlertModalOpened, setIsAlertModalOpened] = useState<boolean>(false);
  const [swap, setSwap] = useState<boolean>(false);
  const [entering, setEntering] = useState<boolean>(true);
  const [requestHasError, setRequestHasError] = useState<boolean>(false);

  /* ---------------------------------------------- Setup mobile steps ---------------------------------------------- */

  useEffect(() => {
    const nextMobileSteps: number[] = [];
    nextMobileSteps.push(5);
    setNextMobileSteps(nextMobileSteps);
  }, []);

  /* ------------------------------------------ Go to the top mobile steps ------------------------------------------ */
  const formZoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formZoneRef.current !== null) {
      formZoneRef.current.scrollTop = 0;
    }
  }, [nextMobileSteps]);

  /* ------------------------------------------------ Form Management ----------------------------------------------- */

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<DisckoForm1Model>({
    context: {
      requirementParams,
    },
    resolver: yupResolver(schema, {}),
  });

  const watchProjectDescription: string = watch('projectDescription');
  const watchDeadline: Deadline | undefined = watch('deadline');

  let isFormValid: boolean = true;
  let isMobileFormValid: boolean = true;

  if (
    watchProjectDescription === undefined ||
    watchProjectDescription.length < 10 ||
    watchProjectDescription.length > 500
  ) {
    isFormValid = false;
  }

  if (
    (nextMobileSteps[0] === 3 &&
      watchDeadline?.deadlineCategory !== undefined &&
      watchDeadline.deadlineCategory !== 'flexible' &&
      watchDeadline.date === '') ||
    (nextMobileSteps[0] === 5 &&
      (watchProjectDescription === undefined ||
        watchProjectDescription.length < 10 ||
        watchProjectDescription.length > 500))
  ) {
    isMobileFormValid = false;
  }

  const onSubmitForm: SubmitHandler<DisckoForm1Model> = async (data, event) => {
    event?.preventDefault();
    event?.stopPropagation();

    setContextRequirement({
      clientMaturity: data.clientMaturity,
      priceRange: data.priceRange !== undefined ? priceRangeOptionsMap.get(data.priceRange) : undefined,
      projectMaturity:
        data.projectMaturity !== undefined ? projectMaturityOptionsMap.get(data.projectMaturity) : undefined,
      deadline:
        data.deadline !== undefined
          ? {
              deadlineCategory:
                data.deadline.deadlineCategory !== undefined
                  ? deadlineCategoryOptionsMap.get(data.deadline.deadlineCategory)
                  : undefined,
              date: data.deadline.date,
            }
          : undefined,
      projectSubject: data.projectDescription,
      questions: contextRequirement.questions,
    });

    await openAICall(
      data.projectDescription,
      data.deadline?.deadlineCategory !== undefined
        ? deadlineCategoryOptionsMap.get(data.deadline.deadlineCategory)
        : undefined,
      data.deadline !== undefined ? data.deadline.date : undefined,
      data.projectMaturity !== undefined ? projectMaturityOptionsMap.get(data.projectMaturity) : undefined,
      data.clientMaturity !== undefined ? clientMaturityOptionsMap.get(data.clientMaturity) : undefined,
      data.priceRange !== undefined ? priceRangeOptionsMap.get(data.priceRange) : undefined,
    );
  };

  /* --------------------------------------------------- API call --------------------------------------------------- */
  const [setupProjectInputs, setSetupProjectInputs] = useState<SetupProjectInputsDto>({
    requirementId: '',
    step: '',
  });
  const [sendFom, setSendForm] = useState<boolean>(false);

  const { data, status, isRefetching } = useQuery({
    queryKey: ['openai', 'setupProject'],
    queryFn: async () => {
      setSendForm(false);
      return await setupProject(setupProjectInputs);
    },
    enabled: sendFom,
    cacheTime: 0,
  });

  async function openAICall(
    projectDescription: string,
    deadlineCategory?: string,
    deadlineDate?: string,
    projectMaturity?: string,
    clientMaturity?: string,
    priceRange?: string,
  ): Promise<void> {
    setIsLoading(true);
    contextRequirement.projectSubject = projectDescription;
    contextRequirement.clientMaturity = clientMaturity;
    contextRequirement.projectMaturity = projectMaturity;
    contextRequirement.deadline = { deadlineCategory, date: deadlineDate };
    contextRequirement.priceRange = priceRange;
    setSetupProjectInputs({
      requirementId: '',
      step: '',
      projectDescription,
      clientMaturity,
      projectMaturity,
      deadlineCategory,
      deadlineDate,
      priceRange,
    });
    setSendForm(true);
  }

  useEffect(() => {
    if (status === 'error') {
      setRequestHasError(true);
      setIsLoading(false);
    }
    if (status !== 'success' || (isRefetching !== undefined && isRefetching)) return;
    // response cleaning

    // messages.push(data.message);
    const cleanedOpenAiResponse: Question[] = convertStringToQuestions(data.message);

    // openAiQuestionsForm.splice(0, openAiQuestionsForm.length);
    cleanedOpenAiResponse.forEach((question: Question) => {
      openAiQuestionsFormToDisplay.push(question);
    });
    // mandatoryQuestions = data.message.mandatoryQuestions;

    setIsLoading(false);
    setSwap(true);
    delay(1000)
      .then(() => {
        if (setNextStep != null) setNextStep(data.step);
        return true;
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
      });
  }, [status, data, isRefetching]);

  useEffect(() => {
    if (entering && document.getElementById('form1') != null) {
      setEntering(false);
    }
  });

  function convertStringToQuestions(response: any): Question[] {
    if (!response.questions) {
      return [];
    }
    return response.questions.map((question, index): Question => {
      return {
        index,
        question: question.question,
        placeholder: question.examples,
        mandatory: index < response.numberMandatoryQuestions,
      };
    });
  }

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto" style={{ background: uiSettings?.bgColor }}>
      <Modal title="Attention" isOpen={isAlertModalOpened} setIsOpen={setIsAlertModalOpened}>
        <p>
          {
            "Nous n'avons pas compris votre besoin. Veuillez réessayer en expliquant bien votre objectif et la nature de votre projet."
          }
        </p>
      </Modal>
      <Modal isOpen={requestHasError}>
        <RichText
          fragments={[
            {
              content: "Malheureusement, la solution n'est pas disponible pour le moment.",
              contentType: 'p',
            },
            {
              content:
                'Vos coordonnées nous ont bien été transmises. Nous reviendrons vers vous avec une solution dans les plus brefs délais.',
              contentType: 'p',
            },
          ]}
        />
      </Modal>

      <form
        id="form1"
        className={concatClassNames(
          'flex flex-col px-8 gap-6 overflow-auto',
          'transition all duration-700',
          swap ? 'opacity-0' : 'opacity-100',
          'flex-grow items-stretch',
          'justify-center',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {isLargeScreen === true && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                // 'bg-white/70',
                'p-8 h-full overflow-y-auto',
                'rounded-lg',
              )}
            >
              <TextArea
                rows={3}
                isMandatory
                label="Pour quelle raison sollicitez-vous nos services ?"
                placeholder="Décrivez votre besoin en quelques mots"
                watchLength={watchProjectDescription?.length ?? 0}
                {...register('projectDescription')}
                value={watchProjectDescription}
                error={errors.projectDescription != null ? errors.projectDescription.message : undefined}
                disabled={isLoading}
              />

              {/* <Controller
                name="clientMaturity"
                control={control}
                defaultValue={'BEGINNER'}
                render={({ field: { value, onChange } }) => (
                  <RadioButtons
                    label="Quel est votre niveau d'expertise technique ?"
                    values={clientMaturityOptions}
                    selected={value}
                    onChange={onChange}
                    valuesDisplay={clientMaturityStyleOptions}
                    error="Champ obligatoire"
                    isMandatory
                    disabled={isLoading}
                  />
                )}
              /> */}
            </div>
            <div className="flex flex-row gap-10 justify-around items-center ">
              <Stepsbar
                completionFrom={
                  uiSettings?.totalNumberOfQuestions === 6
                    ? '1/4'
                    : uiSettings?.totalNumberOfQuestions === 12
                    ? '1/6'
                    : uiSettings?.totalNumberOfQuestions === 18
                    ? '1/8'
                    : uiSettings?.totalNumberOfQuestions === 24
                    ? '1/10'
                    : '0%'
                }
                completionTo={
                  uiSettings?.totalNumberOfQuestions === 6
                    ? '1/2'
                    : uiSettings?.totalNumberOfQuestions === 12
                    ? '1/3'
                    : uiSettings?.totalNumberOfQuestions === 18
                    ? '1/4'
                    : uiSettings?.totalNumberOfQuestions === 24
                    ? '1/5'
                    : '0%'
                }
                animate
                primaryColor={uiSettings?.primaryColor ?? '#000000'}
                secondaryColor={uiSettings?.secondaryColor ?? '#DBDBDB'}
              ></Stepsbar>

              {!isLoading && (
                <Button
                  color={uiSettings?.primaryColor}
                  content="Suivant"
                  width="lg"
                  type="submit"
                  disabled={!isFormValid}
                  borderColor="black"
                  borderColorOnHover="black"
                  borderWidth="2"
                  borderWidthOnHover="2"
                />
              )}
              {isLoading && (
                <Button
                  iconName="spinCircle"
                  width="lg"
                  type="button"
                  iconAnimation="spin"
                  disabled
                  borderWidth="2"
                  borderColor="gray-50"
                />
              )}
            </div>
          </>
        )}

        {isLargeScreen === false && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                // 'bg-white/70',
                'px-8 py-4 overflow-auto',
                'rounded-lg',
                'transition-all duration-700',
                animation === 'invisible' ? 'opacity-0' : 'opacity-100',
              )}
            >
              <div ref={formZoneRef} className="flex flex-col gap-10 overflow-auto">
                {nextMobileSteps[0] === 5 && (
                  <>
                    <TextArea
                      rows={8}
                      isMandatory
                      label="Pour quelle raison sollicitez-vous nos services ?"
                      placeholder="Décrivez votre besoin en quelques mots"
                      watchLength={watchProjectDescription?.length ?? 0}
                      {...register('projectDescription')}
                      value={watchProjectDescription}
                      error={errors.projectDescription != null ? errors.projectDescription.message : undefined}
                      disabled={isLoading}
                    />

                    {/* <Controller
                      name="clientMaturity"
                      control={control}
                      defaultValue={'BEGINNER'}
                      render={({ field: { value, onChange } }) => (
                        <RadioButtons
                          label="Quel est votre niveau d'expertise technique ?"
                          values={clientMaturityOptions}
                          selected={value}
                          onChange={onChange}
                          valuesDisplay={clientMaturityStyleOptions}
                          error="Champ obligatoire"
                          isMandatory
                          disabled={isLoading}
                        />
                      )}
                    /> */}
                  </>
                )}
              </div>
              {isMobileFormValid && !isLoading && nextMobileSteps.length > 1 && (
                <div
                  className="flex justify-end"
                  onClick={() => {
                    setAnimation('invisible');
                    delay(700)
                      .then(() => {
                        setNextMobileSteps(nextMobileSteps.slice(1));
                        setAnimation('visible');
                        return true;
                      })
                      .catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
                      });
                  }}
                >
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </div>
              )}
              {isMobileFormValid && !isLoading && nextMobileSteps.length === 1 && (
                <button className="flex justify-end" type="submit" disabled={!isMobileFormValid}>
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </button>
              )}
              {!isMobileFormValid && !isLoading && (
                <div className="flex justify-end ">
                  {getIcon('arrowRight', uiSettings?.secondaryColor ?? '#b6b6b6', 'lg')}
                </div>
              )}
              {isLoading && (
                <div className={concatClassNames('flex justify-end')}>
                  <div className="h-fit w-fit animate-spin">
                    {getIcon('spinCircle', getTextColor(uiSettings?.secondaryColor ?? '#929292', true), 'lg')}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
