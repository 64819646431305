import React, { forwardRef, useContext, useState } from 'react';
import uuid from 'react-uuid';

import { disckoContext } from 'pages/RequirementForm/DisckoPage';

import concatClassNames from 'utils/classNames';

import { MaxCharVisualizer } from '../MaxCharacterVisualizer/MaxCharacterVisualizer';
import {
  Text,
  type TextColor,
  type TextPosition,
  type TextSize,
  type TextWeight,
  textColorStyle,
  textPositionStyle,
  textSizeStyle,
} from '../Text/Text';

type TextAreaHeight = 'full' | 'fit' | 'md';

const textAreaHeightStyle: Record<TextAreaHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  md: 'h-64',
};

interface TextAreaProps {
  label?: string;
  labelSize?: TextSize;
  labelColor?: TextColor;
  labelWeight?: TextWeight;
  value?: string;
  textSize?: TextSize;
  textColor?: TextColor;
  textPosition?: TextPosition;
  placeholder?: string;
  height?: TextAreaHeight;
  disabled?: boolean;
  error?: string;
  errorSize?: TextSize;
  rows?: number;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  readonly?: boolean;
  isComplete?: boolean;
  watchLength?: number;
  isMandatory?: boolean;
  propositions?: string[];
  handleClickOnProposition?: (proposition: string) => void;
  setValue?: (name: any, value: string) => void;
  watchValue?: string;
  testMode?: boolean;
  testPrimaryColor?: string;
  testSecondaryColor?: string;
}

function TextAreaBackOffice(
  {
    label,
    labelSize = 'xl',
    labelColor = 'black',
    labelWeight = 'bold',
    value,
    textSize = 'base',
    textColor = 'black',
    textPosition = 'left',
    placeholder,
    height = 'full',
    disabled = false,
    error,
    errorSize = 'xs',
    rows = 3,
    onChange,
    name,
    readonly,
    isComplete,
    watchLength,
    isMandatory = false,
    propositions,
    setValue,
    handleClickOnProposition,
    watchValue = '',
    testMode = false,
    testPrimaryColor = '#000000',
    testSecondaryColor = '#dbdbdb',
  }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement>,
): JSX.Element {
  const { isLargeScreen, uiSettings } = useContext(disckoContext);
  if (isLargeScreen === undefined) return <div></div>;

  return (
    <div
      className={concatClassNames(
        'flex flex-col',
        propositions !== undefined ? 'gap-4' : 'gap-2',
        ' relative',
        // height === 'full' ? 'h-full' : '',
      )}
    >
      {label !== undefined && (
        <div
          className={concatClassNames('flex', isLargeScreen ? 'flex-row gap-2 items-center' : 'flex-col items-start')}
        >
          <div className={concatClassNames('flex flex-row', 'items-center', 'gap-2')}>
            <Text content={'👉 ' + label} size={labelSize} color="black" position="left" weight={labelWeight} />
            {isMandatory && <Text content="*" size={labelSize} color="#f04438" position="left" weight="light" />}
          </div>
          <div>
            {isMandatory && <Text content="(Obligatoire)" size="sm" color="#f04438" position="left" weight="light" />}
            {!isMandatory && (
              <Text
                content="(Facultatif)"
                size="sm"
                color={testMode ? testPrimaryColor : uiSettings?.primaryColor}
                position="left"
                weight="light"
              />
            )}
          </div>
        </div>
      )}
      <div
        className={concatClassNames(
          'flex justify-start',
          isLargeScreen ? 'flex-row gap-2 items-center' : 'flex-col items-start gap-1',
        )}
      >
        {propositions != null && (
          <Text
            content="Suggestions : "
            size="base"
            textBreak="none"
            whitespace="nowrap"
            color={testMode ? testPrimaryColor : uiSettings?.primaryColor}
          />
        )}
        {propositions?.map((proposition) => {
          return (
            <button
              key={uuid()}
              disabled={disabled}
              className={concatClassNames('w-fit p-1   text-black underline text-left hover:text-black/70')}
              onClick={(e) => {
                e.preventDefault();
                const valueToSet: string = watchValue?.length > 0 ? watchValue + ', ' + proposition : proposition;
                setValue?.(name, valueToSet);
              }}
              style={{ color: testMode ? testPrimaryColor : uiSettings?.primaryColor }}
              // AFI : comment gérer le onHover ici ?
            >
              {proposition}
            </button>
          );
        })}
      </div>
      <textarea
        className={concatClassNames(
          'bg-white',
          'px-3.5 py-2.5',
          'border-b-2 border-1 rounded-lg font-bold',
          'focus:outline-none focus:ring-0 focus:border-b-4',
          textSizeStyle[textSize],
          textAreaHeightStyle[height],
          // error === undefined ? 'border-black' : 'border-error-500',
          isComplete !== undefined && !isComplete ? 'border-orange-500' : 'border-black',
          textColorStyle[textColor],
          'placeholder:text-gray-300 placeholder: placeholder:font-light placeholder:italic',
          'resize-none',
          textPositionStyle[textPosition],
        )}
        placeholder={placeholder !== undefined ? placeholder : ''}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        rows={rows}
        readOnly={readonly}
        style={{
          borderColor: error !== undefined ? '#f04438' : '#000000',
        }}
      />

      {error !== undefined && <Text content={error} position="left" size={errorSize} color="#f04438" />}
      {watchLength !== undefined && (
        <MaxCharVisualizer maxCharVisualizer={500} watchLength={watchLength !== undefined ? watchLength : 0} />
      )}
    </div>
  );
}

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(TextAreaBackOffice);
