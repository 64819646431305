import React, { useEffect, useState } from 'react';

import concatClassNames from 'utils/classNames';

export type Completion =
  | '1/4'
  | '1/2'
  | '3/4'
  | '1/6'
  | '1/3'
  | '2/3'
  | '5/6'
  | '1/10'
  | '1/5'
  | '3/10'
  | '2/5'
  | '3/5'
  | '7/10'
  | '4/5'
  | '9/10'
  | '1/8'
  | '3/8'
  | '5/8'
  | '7/8'
  | '100%'
  | '0%';

const completionType: Record<Completion, string> = {
  '100%': 'w-full',
  '1/4': 'w-1/4',
  '1/2': 'w-1/2',
  '3/4': 'w-3/4',
  '1/6': 'w-1/6',
  '1/3': 'w-1/3',
  '2/3': 'w-2/3',
  '1/10': 'w-[10%]',
  '1/5': 'w-1/5',
  '3/10': 'w-[30%]',
  '2/5': 'w-2/5',
  '3/5': 'w-3/5',
  '7/10': 'w-[70%]',
  '4/5': 'w-4/5',
  '9/10': 'w-[90%]',
  '1/8': 'w-[12.5%]',
  '3/8': 'w-[37.5%]',
  '5/8': 'w-[62.5%]',
  '7/8': 'w-[87.5%]',
  '5/6': 'w-5/6',
  '0%': 'w-0',
};

export interface StepsbarProps {
  completionTo: Completion;
  completionFrom: Completion;
  animate?: boolean; // Ajouter une prop pour activer l'animation
  primaryColor: string;
  secondaryColor: string;
}

export function Stepsbar({
  completionTo,
  completionFrom,
  animate = true,
  primaryColor,
  secondaryColor,
}: StepsbarProps): JSX.Element {
  const [widthClass, setWidthClass] = useState(completionType[completionFrom]); // Commence par une largeur nulle

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setWidthClass(completionType[completionTo]);
      }, 100); // Délai pour démarrer l'animation (ajustez selon vos besoins)
      return () => {
        clearTimeout(timer);
      };
    } else {
      setWidthClass(completionType[completionTo]);
    }
  }, [completionTo, animate]);

  return (
    <div className="flex rounded-full h-[50%] w-full " style={{ background: secondaryColor }}>
      <div
        className={concatClassNames(
          'flex h-full rounded-l-full transition-width duration-1000 ease-in-out  ',
          completionTo === '100%' ? 'rounded-r-full' : 'rounded-r-none',
          widthClass,
        )}
        style={{ background: primaryColor }}
      >
        .
      </div>
    </div>
  );
}
