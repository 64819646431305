import { DisckoApisContext } from 'App';
import { type GetGenericRequirementDto, type GetGenericRequirementsDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';

import { BasePage } from 'components/core/BasePage/BasePage';
import { Title } from 'components/core/Title/Title';
import { RequirementRow } from 'components/requirements/RequirementRow';

import concatClassNames from 'utils/classNames';
import { type CompletionType } from 'utils/getCompletionTypeChip';

export function formatDate(dateToFormat: Date): string {
  const day: string = ('0' + String(dateToFormat.getDate())).slice(-2);
  const monthNumber: string = ('0' + String(dateToFormat.getMonth() + 1)).slice(-2);
  const fullYear: string = String(dateToFormat.getFullYear());
  const hour: string = ('0' + String(dateToFormat.getHours())).slice(-2);
  const minutes: string = ('0' + String(dateToFormat.getMinutes())).slice(-2);
  const dateAndTime: string = `${day}/${monthNumber}/${fullYear} à ${hour}:${minutes}`;
  return dateAndTime;
}

export function RequirementsPage(): JSX.Element {
  /* --------------------------------------------------- contexts --------------------------------------------------- */

  const { requirementService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently } = useAuth0();

  const [isCompanyNameSortedAsc, setIsCompanyNameSortedAsc] = useState<boolean>(false);
  const [displayedRequirements, setDisplayedRequirements] = useState<any>([]);
  const [objRequirements, setObjRequirements] = useState<any>([]);

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  async function getRequirements(): Promise<GetGenericRequirementsDto> {
    const accessToken: string = await getAccessTokenSilently();
    const req: GetGenericRequirementsDto = await requirementService.findAll(accessToken);
    return req;
  }

  const { data: requirementsData, status: requirementsStatus } = useQuery<GetGenericRequirementsDto, Error>(
    'requirements',
    getRequirements,
  );

  useEffect(() => {
    if (requirementsStatus === 'success') {
      setDisplayedRequirements(requirementsData.requirements);
      console.log(requirementsData.requirements);
    }
  }, [requirementsData, requirementsStatus]);

  /* ---------------------------------------------------- Sorting --------------------------------------------------- */

  function sortRequirements(sortBy: string): void {
    switch (sortBy) {
      case 'agencyClient.companyName':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.agencyClient.companyName.toUpperCase() > b.agencyClient.companyName.toUpperCase()
                ? 1
                : b.agencyClient.companyName.toUpperCase() > a.agencyClient.companyName.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.agencyClient.companyName.toUpperCase() < b.agencyClient.companyName.toUpperCase()
                ? 1
                : b.agencyClient.companyName.toUpperCase() < a.agencyClient.companyName.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }

        break;
      case 'projectType':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.projectType.type.toUpperCase() > b.projectType.type.toUpperCase()
                ? 1
                : b.projectType.type.toUpperCase() > a.projectType.type.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.projectType.type.toUpperCase() < b.projectType.type.toUpperCase()
                ? 1
                : b.projectType.type.toUpperCase() < a.projectType.type.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
      case 'completionType':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.completionType.toUpperCase() > b.completionType.toUpperCase()
                ? 1
                : b.completionType.toUpperCase() > a.completionType.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.completionType.toUpperCase() < b.completionType.toUpperCase()
                ? 1
                : b.completionType.toUpperCase() < a.completionType.toUpperCase()
                ? -1
                : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
      case 'createdAt':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.finishedAt > b.finishedAt ? 1 : b.finishedAt > a.finishedAt ? -1 : 0,
            ),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) =>
              a.finishedAt < b.finishedAt ? 1 : b.finishedAt < a.finishedAt ? -1 : 0,
            ),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
      case 'read':
        if (!isCompanyNameSortedAsc) {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) => (a.read > b.read ? 1 : b.read > a.read ? -1 : 0)),
          );
          setIsCompanyNameSortedAsc(true);
        } else {
          setDisplayedRequirements(
            displayedRequirements.sort((a, b) => (a.read < b.read ? 1 : b.read < a.read ? -1 : 0)),
          );
          setIsCompanyNameSortedAsc(false);
        }
        break;
    }
  }

  function handleSortBy(sortBy: string): void {
    sortRequirements(sortBy);
  }

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */
  return (
    <BasePage>
      <>
        {isLargeScreen === true && (
          <div className={concatClassNames('h-full', 'w-full', 'flex flex-col', 'p-12 pb-2 overflow-auto')}>
            <div className="bg-white flex flex-col h-full w-full rounded-lg overflow-auto p-4">
              <Title content="Vos expressions de besoin"></Title>
              <br />
              <div className={concatClassNames('flex flex-col flex-wrap w-full overflow-auto')}>
                <ul className={concatClassNames('flex flex-col w-full gap-3', 'overflow-auto')}>
                  <RequirementRow title setSortBy={handleSortBy} />

                  {requirementsStatus === 'success' &&
                    displayedRequirements.length > 0 &&
                    displayedRequirements.map((requirement: any) => (
                      <RequirementRow
                        key={requirement.id}
                        id={requirement.id}
                        name={
                          requirement.contact.companyName && requirement.contact.companyName !== ''
                            ? requirement.contact.companyName
                            : requirement.contact.contactLastname && requirement.contact.contactLastname !== ''
                            ? requirement.contact.contactLastname
                            : requirement.contact.contactEmail && requirement.contact.contactEmail !== ''
                            ? requirement.contact.contactEmail
                            : requirement.contact.companyWebsite && requirement.contact.companyWebsite !== ''
                            ? requirement.contact.companyWebsite
                            : requirement.contact.contactLinkedinProfile &&
                              requirement.contact.contactLinkedinProfile !== ''
                            ? requirement.contact.contactLinkedinProfile
                            : requirement.contact.contactFirstName && requirement.contact.contactFirstName !== ''
                            ? requirement.contact.contactFirstName
                            : requirement.contact.contactPhone && requirement.contact.contactPhone !== ''
                            ? requirement.contact.contactPhone
                            : requirement.contact.companyLocation && requirement.contact.companyLocation !== ''
                            ? requirement.contact.companyLocation
                            : requirement.contact.contactLocation && requirement.contact.contactLocation !== ''
                            ? requirement.contact.contactLocation
                            : requirement.contact.contactJobPosition && requirement.contact.contactJobPosition !== ''
                            ? requirement.contact.contactJobPosition
                            : ''
                        }
                        read={requirement.read}
                        createdAt={formatDate(requirement.finishedAt)}
                        projectType={requirement.projectType}
                      />
                    ))}
                  {requirementsStatus === 'success' && requirementsData.requirements.length === 0 && (
                    <div
                      className={concatClassNames(
                        'flex flex-row items-center justify-center',
                        'h-full',
                        'text-center',
                        'text-gray-500',
                      )}
                    >
                      Aucune expression de besoin
                    </div>
                  )}
                  {requirementsStatus === 'loading' && <RequirementRow loading />}
                </ul>
              </div>
            </div>
          </div>
        )}
        {isLargeScreen === false && (
          <div className={concatClassNames('h-full', 'w-full', 'flex flex-col', 'bg-white p-4 overflow-auto')}>
            <Title size="h5" content="Vos expressions de besoin" textColor="#000000"></Title>
            <br />
            <div className={concatClassNames('flex flex-col flex-wrap w-full overflow-auto')}>
              <ul className={concatClassNames('flex flex-col w-full gap-3', 'overflow-auto')}>
                {requirementsStatus === 'success' &&
                  displayedRequirements.length > 0 &&
                  displayedRequirements.map((requirement: any) => (
                    <RequirementRow
                      key={requirement.id}
                      id={requirement.id}
                      name={
                        requirement.contact.companyName && requirement.contact.companyName !== ''
                          ? requirement.contact.companyName
                          : requirement.contact.contactLastname && requirement.contact.contactLastname !== ''
                          ? requirement.contact.contactLastname
                          : requirement.contact.contactEmail && requirement.contact.contactEmail !== ''
                          ? requirement.contact.contactEmail
                          : requirement.contact.companyWebsite && requirement.contact.companyWebsite !== ''
                          ? requirement.contact.companyWebsite
                          : requirement.contact.contactLinkedinProfile &&
                            requirement.contact.contactLinkedinProfile !== ''
                          ? requirement.contact.contactLinkedinProfile
                          : requirement.contact.contactFirstName && requirement.contact.contactFirstName !== ''
                          ? requirement.contact.contactFirstName
                          : requirement.contact.contactPhone && requirement.contact.contactPhone !== ''
                          ? requirement.contact.contactPhone
                          : requirement.contact.companyLocation && requirement.contact.companyLocation !== ''
                          ? requirement.contact.companyLocation
                          : requirement.contact.contactLocation && requirement.contact.contactLocation !== ''
                          ? requirement.contact.contactLocation
                          : requirement.contact.contactJobPosition && requirement.contact.contactJobPosition !== ''
                          ? requirement.contact.contactJobPosition
                          : ''
                      }
                      projectType={requirement.projectType}
                      read={requirement.read}
                      createdAt={formatDate(requirement.finishedAt)}
                    />
                  ))}
                {requirementsStatus === 'success' && requirementsData.requirements.length === 0 && (
                  <div
                    className={concatClassNames(
                      'flex flex-row items-center justify-center',
                      'h-full',
                      'text-center',
                      'text-gray-500',
                    )}
                  >
                    Aucune expression de besoin
                  </div>
                )}
                {requirementsStatus === 'loading' && <RequirementRow loading />}
              </ul>
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
