import { get } from 'http';
import tinycolor from 'tinycolor2';

import React, { type CSSProperties, useContext } from 'react';

import { disckoContext } from 'pages/RequirementForm/DisckoPage';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

import { type IconColor, type IconName, type IconSize } from 'icons/Icon.props';

import { type CursorTypes, cursorStyle } from '../CoreStyle';
import { Text, type TextColor, type TextColorOnHover, type TextSize, type TextWeight } from '../Text/Text';

type ButtonWidth = 'full' | 'fit' | '1/2' | 'lg';

const buttonWidthStyle: Record<ButtonWidth, string> = {
  full: 'w-full',
  fit: 'w-fit',
  '1/2': 'w-1/2',
  lg: 'w-72',
};

type ButtonHeight = 'full' | 'fit' | 'sm';

const buttonHeightStyle: Record<ButtonHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  sm: 'h-11',
};

type ButtonBgColor =
  | 'gradient1'
  | 'gradient2'
  | 'grey'
  | 'white'
  | 'black'
  | 'blue-100'
  | 'blue-200'
  | 'blue-300'
  | 'blue-400'
  | 'gradient3'
  | 'secondary-500'
  | 'secondary-400'
  | 'secondary-300'
  | 'secondary-200'
  | 'secondary-100'
  | 'green-500'
  | 'green-300'
  | 'red-500'
  | 'red-300'
  | 'red-700'
  | 'none';

const buttonBgColorStyle: Record<ButtonBgColor, string> = {
  gradient1: 'bg-gradient-to-r from-gradient1-from to-gradient1-to',
  gradient2: 'bg-gradient-to-r from-gradient2-from to-gradient2-to',
  gradient3: 'bg-[#073B4C]',
  grey: 'bg-gray-25',
  white: 'bg-white',
  black: 'bg-black',
  'blue-100': 'bg-blue-100',
  'blue-200': 'bg-blue-200',
  'blue-300': 'bg-blue-300',
  'blue-400': 'bg-blue-400',
  'secondary-500': 'bg-secondary-500',
  'secondary-400': 'bg-secondary-400',
  'secondary-300': 'bg-secondary-300',
  'secondary-200': 'bg-secondary-200',
  'secondary-100': 'bg-secondary-100',
  'red-500': 'bg-red-500',
  'red-700': 'bg-red-700',
  'red-300': 'bg-red-300',
  'green-500': 'bg-green-500',
  'green-300': 'bg-green-300',
  none: 'bg-none',
};

const buttonBgColorAsClickedStyle: Record<ButtonBgColor, string> = {
  gradient1: 'active:bg-gradient1-to',
  gradient2: 'active:bg-gradient2-to',
  gradient3: 'active:bg-[#073B4C]',
  grey: 'active:bg-gray-25',
  white: 'active:bg-white',
  black: 'active:bg-black',
  'blue-100': 'active:bg-blue-100',
  'blue-200': 'active:bg-blue-200',
  'blue-300': 'active:bg-blue-300',
  'blue-400': 'active:bg-blue-400',
  'secondary-500': 'active:bg-secondary-500',
  'secondary-400': 'active:bg-secondary-400',
  'secondary-300': 'active:bg-secondary-300',
  'secondary-200': 'active:bg-secondary-200',
  'secondary-100': 'active:bg-secondary-100',
  'red-500': 'active:bg-red-500',
  'red-300': 'active:bg-red-300',
  'red-700': 'active:bg-red-700',
  'green-500': 'active:bg-green-500',
  'green-300': 'active:bg-green-300',
  none: '',
};

type ButtonBgColorOnHover =
  | 'gray-25'
  | 'gray-200'
  | 'blue-300'
  | 'blue-400'
  | 'none'
  | 'white'
  | 'red-500'
  | 'secondary-300'
  | 'red-200'
  | 'red-300'
  | 'red-400'
  | 'red-700'
  | 'green-300'
  | 'green-500';

const buttonBgColorStyleOnHover: Record<ButtonBgColorOnHover, string> = {
  'gray-25': 'hover:bg-gray-25',
  'blue-300': 'hover:bg-blue-300',
  'blue-400': 'hover:bg-blue-400',
  'gray-200': 'hover:bg-gray-200',
  'red-200': 'hover:bg-red-200',
  'red-300': 'hover:bg-red-300',
  'red-400': 'hover:bg-red-400',
  'red-500': 'hover:bg-red-500',
  'red-700': 'hover:bg-red-700',
  'green-300': 'hover:bg-green-300',
  'green-500': 'hover:bg-green-500',
  'secondary-300': 'hover:bg-secondary-300',
  white: 'hover:bg-white',
  none: '',
};

type ButtonBorderColor = 'grey' | 'grey-500' | 'none' | 'black' | 'gray-50';

type ButtonBorderWidth = 'none' | '1' | '2';

type ButtonWhitespace = 'pre' | 'normal' | 'nowrap';

const buttonWhitespaceStyle: Record<ButtonWhitespace, string> = {
  pre: 'whitespace-pre',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
};

type ButtonIconAnimation = 'none' | 'spin';

const buttonIconAnimationStyle: Record<ButtonIconAnimation, string> = {
  none: '',
  spin: 'animate-spin',
};

type ButtonPosition = 'none' | 'fixedb';

const buttonPositionStyle: Record<ButtonPosition, string> = {
  none: '',
  fixedb: 'sticky bottom-2 right-0 left-0',
};

type ButtonHorizontalPadding = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

const buttonHorizontalPaddingStyle: Record<ButtonHorizontalPadding, string> = {
  '0': 'px-0 lg:px-0',
  '1': 'px-1 lg:px-2',
  '2': 'px-2 lg:px-4',
  '3': 'px-3 lg:px-6',
  '4': 'px-4 lg:px-8',
  '5': 'px-5 lg:px-10',
  '6': 'px-6 lg:px-12',
  '7': 'px-7 lg:px-14',
  '8': 'px-8 lg:px-16',
  '9': 'px-9 lg:px-18',
  '10': 'px-10 lg:px-20',
  '11': 'px-11 lg:px-22',
  '12': 'px-12 lg:px-24',
};

interface ButtonProps {
  content?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onHover?: React.MouseEventHandler<HTMLButtonElement>;
  iconName?: IconName;
  iconPosition?: 'left' | 'right';
  width?: ButtonWidth;
  height?: ButtonHeight;

  textSize?: TextSize;
  borderColor?: ButtonBorderColor;
  borderColorOnHover?: ButtonBorderColor;
  borderWidth?: ButtonBorderWidth;
  borderWidthOnHover?: ButtonBorderWidth;
  iconAnimation?: ButtonIconAnimation;
  whitespace?: ButtonWhitespace;
  position?: ButtonPosition;
  cursor?: CursorTypes;
  error?: boolean;
  iconSize?: IconSize;
  textWeight?: TextWeight;
  underline?: boolean;
  horizontalPadding?: ButtonHorizontalPadding;
  loading?: boolean;
  color?: string;
}

export function getTextColor(bgColor: string, disabledEffect?: boolean): string {
  const black: string = '#000000';
  const white: string = '#FFFFFF';
  const grey: string = '#8d8d8d';
  const lightGrey: string = '#757575';

  // 1. Obtenir la luminance (entre 0 et 1)
  const luminance: number = tinycolor(bgColor).getLuminance();

  // 2. Calculer la saturation (entre 0 et 1)
  const hsv: tinycolor.ColorFormats.HSV = tinycolor(bgColor).toHsv(); // Renvoie {h, s, v}
  const saturation: number = hsv.s;

  // 3. Calculer un score combiné avec pondération
  // Poids ajustés pour accorder plus d'importance à la luminance
  const weightedScore: number = luminance * 0.8 + saturation * 0.2;

  // 4. Définir un seuil dynamique pour trancher
  const threshold: number = 0.5; // Ajustable selon les tests réels

  // 5. Choisir noir ou blanc
  if (disabledEffect) {
    return weightedScore > threshold ? grey : lightGrey;
  } else {
    return weightedScore > threshold ? black : white;
  }
}

export function Button({
  content,
  type,
  disabled,
  iconName,
  iconPosition = 'right',
  onClick,
  onHover,
  whitespace = 'pre',
  width = 'full',
  height = 'full',
  textSize = 'base',
  iconAnimation = 'none',
  position = 'none',
  cursor = 'pointer',
  error = false,
  iconSize = 'sm',
  textWeight = 'normal',
  underline = false,
  horizontalPadding,
  loading = false,
  color = '#60A5FA',
}: ButtonProps): JSX.Element {
  const { uiSettings, form } = useContext(disckoContext);

  const _color: string = form && uiSettings ? uiSettings.primaryColor : color;

  const bgColor: string = tinycolor(_color).toHexString();
  const bgColorOnHover: string = tinycolor(_color).darken(10).toHexString();
  const bgColorAsClicked: string = tinycolor(_color).lighten(20).toHexString();
  // const bgColorAsClicked: string = bgColorOnHover;
  const bgColorOnLoading: string = bgColorAsClicked;

  const [isButtonHovered, setIsButtonHovered] = React.useState(false);
  const [isButtonActive, setIsButtonActive] = React.useState(false);

  // const iconColor: IconColor = textColor === '#FFFFFF' ? 'white' : 'black';

  const backgroundColor: string = loading
    ? bgColorOnLoading
    : disabled
    ? '#dbdbdb'
    : isButtonActive
    ? bgColorAsClicked
    : isButtonHovered
    ? bgColorOnHover
    : bgColor;
  const textColor: string = getTextColor(backgroundColor, disabled);

  return (
    <button
      style={{
        backgroundColor,
        color: textColor,
      }}
      className={concatClassNames(
        'flex',
        'flex-row',
        buttonWidthStyle[width],
        buttonHeightStyle[height],
        buttonWhitespaceStyle[whitespace],
        'gap-2.5',
        'justify-center',
        'items-center',
        'align-middle',
        horizontalPadding !== undefined ? buttonHorizontalPaddingStyle[horizontalPadding] : 'px-4',
        'py-2.5',
        'rounded-lg',
        buttonPositionStyle[position],
        cursorStyle[cursor],
        'disabled:pointer-events-none',
      )}
      disabled={disabled}
      type={type}
      onClick={loading ? undefined : onClick}
      // add on hover at on mouse enter
      onMouseEnter={(e) => {
        onHover?.(e);
        setIsButtonHovered(true);
      }}
      onMouseLeave={() => {
        setIsButtonHovered(false);
      }}
      onMouseDown={() => {
        setIsButtonActive(true);
      }}
      onMouseUp={() => {
        setIsButtonActive(false);
      }}
    >
      {loading && (
        <div className="animate-spin">
          {getIcon('spinCircle', getTextColor(uiSettings?.secondaryColor ?? '#dbdbdb', true), 'sm')}
        </div>
      )}
      {!loading && (
        <>
          {iconName !== undefined && iconPosition === 'left' && (
            <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
              {isButtonHovered
                ? getIcon(iconName, getTextColor(bgColorOnHover), iconSize)
                : getIcon(iconName, getTextColor(bgColor), iconSize)}
            </div>
          )}
          {content !== undefined && (
            <Text
              content={content}
              size={textSize}
              color={textColor}
              weight={textWeight}
              whitespace="pre-line"
              position="center"
              textDecoration={underline ? 'underline' : 'no-underline'}
            />
          )}
          {iconName !== undefined && iconPosition === 'right' && (
            <div className={concatClassNames(buttonIconAnimationStyle[iconAnimation])}>
              {isButtonHovered
                ? getIcon(iconName, getTextColor(bgColorOnHover), iconSize)
                : getIcon(iconName, getTextColor(bgColor), iconSize)}
            </div>
          )}
        </>
      )}
    </button>
  );
}
