import React from 'react';

import { BasePage } from 'components/core/BasePage/BasePage';

export function TermsWidget(): JSX.Element {
  return (
    <div className="max-w-3xl mx-auto py-8 text-justify">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-4">Mentions légales</h2>
        <h3 className="text-xl font-bold mb-4">Introduction</h3>
        <p>{`VEUILLEZ LIRE ATTENTIVEMENT CES MENTIONS LEGALES AVANT TOUTE UTILISATION DE LA SOLUTION « DISCKO » proposée par la société par actions simplifiée SPECKS, immatriculée au RCS de Nantes sous le numéro 980 269 062, dont le siège social est sis 1 rue Pauline Roland à Nantes (44200).`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">{"Identification de l'éditeur"}</h3>
        <ul>
          <li>
            <strong>Nom de la société :</strong> SPECKS
          </li>
          <li>
            <strong>Forme juridique :</strong> Société par Actions Simplifiée (SAS)
          </li>
          <li>
            <strong>Capital social :</strong> 60 000€
          </li>
          <li>
            <strong>Adresse du siège social :</strong> 1 rue Pauline Roland, Nantes (44200)
          </li>
          <li>
            <strong>Email :</strong> <a href="mailto:contact@discko.io">contact@discko.io</a>
          </li>
          <li>
            <strong>{'Numéro d’immatriculation au RCS :'}</strong> 980 269 062 R.C.S. Nantes
          </li>
          <li>
            <strong>Numéro de TVA intracommunautaire :</strong> FR95980269062
          </li>
          <li>
            <strong>Directeur de la publication :</strong> Amandine Musseau
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Hébergement</h3>
        <ul>
          <li>
            <strong>Hébergeur :</strong> AWS
          </li>
          <li>
            <strong>Forme juridique :</strong> Société à responsabilité limitée
          </li>
          <li>
            <strong>Adresse du siège social :</strong> Tour Carpe Diem, 31 Pl. des Corolles, 92400 Courbevoie
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Conception et développement</h3>
        <ul>
          <li>
            <strong>Agence de développement :</strong> SPECKS
          </li>
          <li>
            <strong>{'Adresse de l’agence :'}</strong> 1 rue Pauline Roland, Nantes (44200)
          </li>
          <li>
            <strong>{'Email de l’agence :'}</strong>
            <a href="mailto:contact@discko.io">contact@discko.io</a>
          </li>
        </ul>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Propriété intellectuelle</h3>
        <p>{`Le solution DISCKO et son contenu (textes, images, graphismes, logo, icônes, sons, code, etc.) sont protégés par les lois en vigueur sur la propriété intellectuelle. SPECKS et/ou ses partenaires sont propriétaires de l'ensemble de ces contenus. Une licence limitée, non-exclusive, révocable, et sans droit de sous-licencier est accordée aux utilisateurs pour l’accès, la navigation, et l’utilisation de la solution. Toute exploitation non autorisée des contenus constitue une contrefaçon et est passible de poursuites judiciaires.`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Données personnelles</h3>
        <p>
          {`Les utilisateurs disposent d’un droit d’accès, de rectification et d’opposition aux données personnelles les concernant, conformément au Règlement Général sur la Protection des Données (RGPD). Pour exercer ces droits, les utilisateurs peuvent contacter SPECKS par courrier à l’adresse du siège social ou par email à `}
          <a href="mailto:contact@discko.io">contact@discko.io</a>
          {`. La politique de confidentialité complète est disponible sur la page dédiée à cet effet sur notre site, solution ou en cliquant ici.`}
        </p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Responsabilités</h3>
        <p>{`DISCKO donc SPECKS ne peut être tenue responsable des dommages directs ou indirects résultant de l'utilisation ou de l'incapacité à utiliser le site. SPECKS s'efforce d'assurer l'exactitude des informations présentées, sans toutefois garantir leur exhaustivité ou leur mise à jour constante. SPECKS ne sera pas tenue pour responsable pour toute réclamation qui provient de: (i) l'utilisation par le client des produits, services, solutions autrement que conformément aux Conditions Générales de Service (y compris notamment l'utilisation de la solution par un individu autre qu'utilisateur autorisé), ou encore (ii) l'utilisation des produits, services, solutions non conforme aux dispositions légales et réglementaires.`}</p>
      </div>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h3 className="text-xl font-bold mb-4">Mise à jour des mentions légales</h3>
        <p>{`Les présentes mentions légales peuvent être modifiées à tout moment et sans préavis. Les utilisateurs sont invités à les consulter régulièrement.`}</p>
      </div>
    </div>
  );
}
