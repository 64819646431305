/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function CircleCheckIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      stroke={concatClassNames(color)}
      className={concatClassNames(iconSizeStyle[size])}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill={concatClassNames(color)}
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337l-17 17-17-17-64-64-17-17L160 222.1l17 17 47 47L335 175l17-17L385.9 192l-17 17z"
      />
    </svg>
  );
}
