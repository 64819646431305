/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetGenericRequirementContactDto
 */
export interface GetGenericRequirementContactDto {
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactType?: GetGenericRequirementContactDtoContactTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactLastname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactFirstname?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    companyWebsite?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    companyLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactLinkedinProfile?: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementContactDto
     */
    contactJobPosition?: string;
}


/**
 * @export
 */
export const GetGenericRequirementContactDtoContactTypeEnum = {
    Person: 'PERSON',
    Company: 'COMPANY',
    Both: 'BOTH'
} as const;
export type GetGenericRequirementContactDtoContactTypeEnum = typeof GetGenericRequirementContactDtoContactTypeEnum[keyof typeof GetGenericRequirementContactDtoContactTypeEnum];


/**
 * Check if a given object implements the GetGenericRequirementContactDto interface.
 */
export function instanceOfGetGenericRequirementContactDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetGenericRequirementContactDtoFromJSON(json: any): GetGenericRequirementContactDto {
    return GetGenericRequirementContactDtoFromJSONTyped(json, false);
}

export function GetGenericRequirementContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGenericRequirementContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactLocation': !exists(json, 'contactLocation') ? undefined : json['contactLocation'],
        'contactType': !exists(json, 'contactType') ? undefined : json['contactType'],
        'contactLastname': !exists(json, 'contactLastname') ? undefined : json['contactLastname'],
        'contactFirstname': !exists(json, 'contactFirstname') ? undefined : json['contactFirstname'],
        'contactEmail': !exists(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactPhone': !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyWebsite': !exists(json, 'companyWebsite') ? undefined : json['companyWebsite'],
        'companyLocation': !exists(json, 'companyLocation') ? undefined : json['companyLocation'],
        'contactLinkedinProfile': !exists(json, 'contactLinkedinProfile') ? undefined : json['contactLinkedinProfile'],
        'contactJobPosition': !exists(json, 'contactJobPosition') ? undefined : json['contactJobPosition'],
    };
}

export function GetGenericRequirementContactDtoToJSON(value?: GetGenericRequirementContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactLocation': value.contactLocation,
        'contactType': value.contactType,
        'contactLastname': value.contactLastname,
        'contactFirstname': value.contactFirstname,
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'companyName': value.companyName,
        'companyWebsite': value.companyWebsite,
        'companyLocation': value.companyLocation,
        'contactLinkedinProfile': value.contactLinkedinProfile,
        'contactJobPosition': value.contactJobPosition,
    };
}

