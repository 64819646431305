/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function BoxIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={iconSizeStyle[size]} viewBox="0 0 128 128" fill={color}>
      <g id="Layer_26" data-name="Layer 26">
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-1"
          d="M88.12,13.76a1.2,1.2,0,0,1,1.51-.8C92.79,13.94,87.09,17.08,88.12,13.76Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-1"
          d="M119.72,34.48a1.2,1.2,0,0,1,1.51-.79C124.39,34.66,118.69,37.8,119.72,34.48Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-1"
          d="M59.05,28.56a1.21,1.21,0,0,1,1.51-.79C63.72,28.74,58,31.88,59.05,28.56Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M27.28,86.88q0-3.31,0-6.65c0-.81-.11-1.62-.11-2.43q0-4.29,0-8.6c0-1.27-.05-2.55-.11-3.81L26.92,63a16.64,16.64,0,0,1,.22-2.44c.08-.51.51-.44,1,0a3.67,3.67,0,0,1,1.13,2.12c.17,3.27.26,6.55.14,9.88-.09,2,.14,4,.21,6.08l.18,6.73c0,1.38,0,2.76,0,4.14,0,2.09,0,4.2,0,6.28l.15,4.8a9.19,9.19,0,0,1,0,1c-.11,1.32-.28,1.73-.75,2a3.49,3.49,0,0,1-.31.18c-.35.11-.63-.44-.79-1.4a70,70,0,0,1-.71-8.79c-.07-2.22-.15-4.45-.22-6.68Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M45.11,106.06q3.09,1.05,6.24,2c.76.23,1.55.37,2.31.6,2.7.83,5.4,1.72,8.11,2.59l3.63,1.09c.78.26,1.56.44,2.33.78.39.16.77.32,1.14.51a10,10,0,0,1,1,.75c.43.34.09.66-.52.87a4.65,4.65,0,0,1-1.05.21,2.82,2.82,0,0,1-1.21-.11c-2.9-1-6.08-1.69-9.18-2.73-1.86-.63-3.89-.92-5.84-1.44-2.15-.55-4.29-1.15-6.42-1.77l-3.94-1.2c-2-.63-3.95-1.33-5.91-2-.76-.27-1.52-.5-2.3-.72s-1.53-.51-2.3-.77a7.22,7.22,0,0,1-1-.38c-1.2-.63-1.43-1.11-1.28-1.91a4.44,4.44,0,0,1,.12-.55c.2-.57.92-.77,1.85-.59,1.31.36,2.65.77,4,1.23s2.68.91,4.06,1.38c2,.72,4.08,1.44,6.17,2.09Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M91,109.14c5.45-1.59,10.85-3.44,16.24-5.33,2-.69,3.9-1.46,5.86-2.17l5.18-1.89.4-.15c.06,0,.15,0,.19-.09a1.49,1.49,0,0,0,0-.21l0-.5.14-2.75.55-11c.16-3.26.26-6.54.35-9.78.11-4.16.19-8.34.49-12.5.09-1.29.4-1.11.7,0a23.34,23.34,0,0,1,.61,5.53c0,8.51-.1,17-.54,25.57-.06,1.29-.12,2.58-.16,3.88L121,100a2.87,2.87,0,0,1-.16.73l0,.18h0s-.26-.17-.14-.09v0l0,0,0,.06-.24.26-.12.14-.19.08-.37.16-.56.24c-2.44,1-4.9,2-7.35,2.92q-8.1,3.1-16.36,5.79c-3.4,1.08-6.8,2.14-10.35,2.9l-1.26.23a11.64,11.64,0,0,1-1.49.14,3.13,3.13,0,0,1-1.66-.32,1,1,0,0,1-.51-.61,4.14,4.14,0,0,1-.13-.82l0-5.9c0-1.86,0-3.72,0-5.57,0-4.15-.08-8.23,0-12.33,0-.91,0-1.79.06-2.71.24-3.37.53-4.43,1-5.14a3.25,3.25,0,0,1,.33-.46c.34-.31.62,1.09.77,3.56.14,1.84.18,3.69.22,5.56s.12,3.77.12,5.67c0,3.8.07,7.66.11,11.53,0,1.6,0,3.2,0,4.81,0,0,0,0,0,0a21.9,21.9,0,0,0,2.7-.47c1.86-.41,3.74-.94,5.61-1.5Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M46.47,63.72q3.87,1.29,7.78,2.45c1,.28,1.92.48,2.87.78,3.35,1,6.7,2,10.09,3,1.49.43,3,.79,4.52,1.14l2.9.66c1,.25,1.91.56,2.86.89.61.24.44.45-.13.63a7.47,7.47,0,0,1-2.76.3,76.84,76.84,0,0,1-11.95-2.47c-2.35-.71-4.86-1.15-7.29-1.76-2.67-.7-5.32-1.46-8-2.25l-4.87-1.48c-2.46-.76-4.9-1.63-7.33-2.45-1.9-.64-3.8-1.15-5.66-2-.41-.2-.83-.33-1.24-.58-1.54-.8-1.64-1.81-1.55-2.36a1.78,1.78,0,0,1,.08-.39c.08-.17.29-.19.62-.16a6.57,6.57,0,0,1,1.33.19c1.5.53,3.15,1.07,4.84,1.56l5.11,1.7c2.58.82,5.15,1.74,7.77,2.53Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M116.59,57.67c-5.44-2.15-11-4.12-16.6-6.07l-6.13-2.06L83.08,45.86l-5.4-1.8-.84-.27c-.35-.12-.72-.22-1-.35l.1-2c0-.94.08-1.88.1-2.82a78,78,0,0,0-.57-10,120.11,120.11,0,0,1-1.15-12.88c0-1.35.39-1.19.87-.08a19.52,19.52,0,0,1,1.35,5.58c.46,4.34,1.09,8.7,1.3,13.17.11,2.23.1,4.47,0,6.7l-.05.83,0,.12c0,.06.05.05.08.07l.09,0,.39.13,3.15,1.06c5,1.73,10.29,3.32,15.43,5,5.65,1.88,11.29,3.81,16.91,5.84,1.35.49,2.69,1,4,1.52a10.46,10.46,0,0,1,2.68,1.31c.26.24.57.52.36.85l-.71.87c-.93.69-2.08,1.35-2.65,1.69a148.47,148.47,0,0,1-15.18,7c-4,1.64-7.85,3.29-11.85,4.76-.88.33-1.74.66-2.65.95-3.38,1.05-4.61,1.1-5.5.48a2.11,2.11,0,0,1-.52-.42c-.27-.53.87-1,3.24-1.8,3.5-1.2,7.12-2.58,10.76-4s7.33-2.9,11-4.47c1-.43,2-.86,3-1.32s2-.9,2.94-1.38,1.92-1,2.87-1.46c.49-.24.93-.52,1.39-.78l.09,0h0l0,0s.08,0,0,0l0,0-.48-.19Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M34.47,57c-1.17.39-2.34.78-3.55,1.13l-.46.13-.61.16a6.53,6.53,0,0,1-1.59.24A1.9,1.9,0,0,1,27,58.23c-.18-.14-.18-.39-.26-.59L26.56,57c0-2,.05-2.86.08-4L26.71,49c.15-4.59.25-9.16,0-13.73-.11-2-.33-4.06-.59-6.06-.36-2.57-.84-5.12-1.19-7.69-.09-.8.26-.77.75-.17a8,8,0,0,1,1.44,3.26,78.84,78.84,0,0,1,1.3,8,75.1,75.1,0,0,1,.38,8.08c0,3.23.11,6.58.08,9.87l-.09,5.34v.25a.13.13,0,0,0,.13.17,1.73,1.73,0,0,0,.5,0c.08,0,.56-.15.94-.26l1.17-.37c2.09-.69,4.19-1.43,6.29-2.15,3.18-1.1,6.42-2.13,9.62-3.12,2.49-.78,4.93-1.58,7.45-2.18.56-.14,1.1-.28,1.67-.4a7.46,7.46,0,0,1,2.28-.14,2,2,0,0,1,1.07.38,3.35,3.35,0,0,1,.34.26c.24.29-.57.7-2,1.25-2.17.71-4.4,1.31-6.64,2S47,52.9,44.71,53.64c-3.4,1.08-6.8,2.24-10.22,3.44Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M37.67,16.45c1.67-.31,3.35-.62,5-1,.61-.13,1.2-.32,1.81-.44L51,13.79c1-.19,1.91-.44,2.85-.68a15.31,15.31,0,0,1,3.7-.69c.4,0,.41.34.16.78a2.7,2.7,0,0,1-1.42,1.17,57.83,57.83,0,0,1-7.37,1.79c-1.52.26-3,.78-4.56,1.13-1.68.39-3.37.75-5.06,1.11L36.16,19c-1.58.32-3.18.59-4.74.94-1.23.24-2.4.6-3.63.85A4.32,4.32,0,0,1,27,21c-1,0-1.38-.13-1.64-.57a2.28,2.28,0,0,1-.17-.3c-.12-.33.26-.68.95-1a17.56,17.56,0,0,1,3.18-1c1.08-.28,2.19-.52,3.32-.73l5-1Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M28.46,93.56c-.62.15-15.79-1-22,5.54-6.81,7.25,38.85,13.81,61,14.79L29,102Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M100.57,42.42c-.88,1.81-1.73,3.65-2.5,5.52-.3.68-.5,1.39-.77,2.08-1,2.41-1.92,4.86-2.76,7.34-.36,1.1-.65,2.23-.9,3.34-.3,1.43-.53,2.94-.84,4.23-.1.39-.57.41-1,0A2.51,2.51,0,0,1,91.2,64a2.75,2.75,0,0,1-.13-1.26,42,42,0,0,1,2.45-9c.7-1.72,1.2-3.57,1.86-5.35q1.09-2.91,2.37-5.77c.51-1.17,1.08-2.32,1.61-3.48l1.33-2.6c.47-.85.94-1.7,1.39-2.56.76-1.3,1.35-2.67,2.21-4a6.56,6.56,0,0,1,.56-.85c.86-1,1.29-1.14,1.94-.83a3,3,0,0,1,.44.22c.44.31.42,1,0,1.7-1.19,2.17-2.62,4.33-3.86,6.74-1,1.75-1.89,3.55-2.74,5.39Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M109.88,24.62a.24.24,0,0,0-.07-.13l-.08,0s-.07,0-.22,0a9,9,0,0,0-1.84.69c-.45.22-.85.54-1.29.8a35.09,35.09,0,0,0-4.49,3.42c-.65.55-1.29,1.16-1.9,1.75-.79.75-1.46,1.53-2.24,2.21-.24.21-.6-.06-.8-.57A1.9,1.9,0,0,1,97.19,31a31.36,31.36,0,0,1,2.36-2.47,22.49,22.49,0,0,1,2.61-2.18c1.12-.81,2.14-1.85,3.38-2.65a14.41,14.41,0,0,1,2.17-1.14,8.4,8.4,0,0,1,1.2-.42,3,3,0,0,1,1.7,0,2.79,2.79,0,0,1,1.52,1.38,4.86,4.86,0,0,1,.45,1.66,18.69,18.69,0,0,1,0,2.23c0,.35,0,.7,0,1s0,.7.07,1a13.23,13.23,0,0,0,.68,3c.07.21.17.4.23.6.26.74.12,1-.5,1.46-.15.1-.28.22-.43.31a1.1,1.1,0,0,1-1.52-.53,9.05,9.05,0,0,1-1-3.14c-.07-.53-.12-1.05-.15-1.57l0-.76c0-.22,0-.59,0-.85,0-.81.07-1.5,0-2.14a3.56,3.56,0,0,0-.16-1.19Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M72.8,53c.37,1.29.69,2.59,1,3.89.11.47.27.93.37,1.41.34,1.68.57,3.39.76,5.09.08.76.2,1.51.31,2.26a8.93,8.93,0,0,1,.13,2.92c-.05.31-.47.26-.89,0a1.8,1.8,0,0,1-.93-1.19c-.22-1.85-.55-3.71-.73-5.64A21.06,21.06,0,0,0,72,58.33c-.31-1.26-.66-2.52-1-3.77-.23-.77-.48-1.53-.74-2.29a35.76,35.76,0,0,0-1.36-3.39,21.56,21.56,0,0,0-1.47-2.39c-.11-.17-.23-.34-.33-.52-.36-.67-.32-1,.15-1.39a4.2,4.2,0,0,1,.32-.29.93.93,0,0,1,1.27.26,9.41,9.41,0,0,1,1.52,2.17,22.85,22.85,0,0,1,1.15,2.45c.51,1.25.93,2.54,1.34,3.82Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M63.7,38.86a4,4,0,0,1,1.06-2,2.26,2.26,0,0,1,.74-.42,1.49,1.49,0,0,1,.84,0,3.16,3.16,0,0,1,1.51,1.05c.25.29.55.64.74.88s.3.38.43.57c.89,1.38,1.69,2.75,2.61,4A12.1,12.1,0,0,0,73,44.6c.63.61,1.31,1.11,1.92,1.69.18.18,0,.53-.43.75a1.53,1.53,0,0,1-1.56,0A12.93,12.93,0,0,1,69,42.65a26.56,26.56,0,0,0-2-2.76l-.11-.16,0-.06-.08-.12-.19-.26a.79.79,0,0,0-.27-.21c-.12,0-.21.05-.28.13a.62.62,0,0,0-.12.19,2.44,2.44,0,0,0-.16.5,8.54,8.54,0,0,0-.1,1c0,.56,0,1.17-.13,1.81s-.13,1.16-.18,1.75c-.08.91,0,1.85-.17,2.82a4.72,4.72,0,0,1-.1.64c-.19.81-.54,1-1.19.84-.15,0-.31,0-.45-.09a1.13,1.13,0,0,1-.75-1.09c.08-1.43.24-2.93.44-4.61l.12-.86.07-.88.08-1,.06-.53c0-.3.11-.6.15-.89Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M46.9,54.55c-1.48.37-2.95.77-4.42,1.18l-1.6.47c-1.89.52-3.79,1-5.67,1.59l-2.5.78a15.12,15.12,0,0,1-3.29.84c-.4,0-.32-.22-.07-.48a3.26,3.26,0,0,1,1.26-.84L33.79,57c1.08-.36,2.18-.67,3.29-1,1.32-.35,2.66-.86,4-1.27s3-.87,4.44-1.29l2.75-.75c1.38-.38,2.78-.72,4.15-1.12,1.07-.31,2.09-.69,3.15-1,.23-.07.46-.15.7-.21.88-.19,1.2-.07,1.46.15l.16.16c.13.18-.18.46-.77.8a28.6,28.6,0,0,1-2.81,1.07c-1,.29-2,.56-3,.84l-4.44,1.19Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M100,67.89c1.74-.53,3.48-1.07,5.21-1.66l1.87-.7c2.21-.77,4.41-1.58,6.55-2.52a28.65,28.65,0,0,0,2.77-1.39c1.13-.67,2.22-1.41,3.27-2.11.34-.21.52,0,.46.5a2.86,2.86,0,0,1-.3.84,2.53,2.53,0,0,1-.76.81,33.36,33.36,0,0,1-7.44,3.71c-1.56.56-3.12,1.29-4.69,1.9-1.73.65-3.48,1.27-5.23,1.86L98.45,70.2l-4.92,1.58c-1.25.43-2.46.94-3.69,1.42-.28.1-.54.22-.82.31-1,.34-1.4.3-1.76-.15a1.79,1.79,0,0,1-.25-.31c-.21-.36.12-.81.84-1.15a59.46,59.46,0,0,1,6.85-2.43L100,67.85Z"
        />
        <path
          stroke={color}
          strokeWidth={1}
          className="cls-2"
          d="M37.5,17.88c1.32-.24,2.64-.5,4-.78l1.44-.34,5.09-1c.75-.16,1.5-.36,2.24-.56a18.64,18.64,0,0,1,2.87-.65c.31,0,.32.2.13.49a2.27,2.27,0,0,1-1.12.84,47.57,47.57,0,0,1-5.84,1.49c-1.18.22-2.38.6-3.59.88s-2.66.59-4,.87l-2.46.51-3.73.74c-1,.19-1.89.46-2.85.66a3.32,3.32,0,0,1-.63.11c-.8.06-1.07,0-1.28-.29a2.14,2.14,0,0,1-.13-.19c-.1-.2.19-.44.74-.66a15.66,15.66,0,0,1,2.53-.75q1.3-.33,2.64-.57l4-.79Z"
        />
      </g>
    </svg>
  );
}
