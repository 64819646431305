/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto, type UpdateUrlWebhookDto } from 'api';
import * as Yup from 'yup';

import React, { useContext, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type QueryClient, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from 'components/core/Button/Button';
import { Chip } from 'components/core/Chip/Chip';
import Input from 'components/core/Input/Input';
import { P } from 'components/core/P';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';

/* -------------------------------------------------- Form Schema ------------------------------------------------- */

interface AutomateSettingsFormModel {
  urlWebhook: string | undefined;
}

const schema: Yup.ObjectSchema<AutomateSettingsFormModel> = Yup.object().shape({
  urlWebhook: Yup.string()
    .test('isMakeWebhookUrlNeeded', 'Champ obligatoire', (value, { options }) => {
      if (options.context?.isMakeWebhookActivated)
        if (value == null || value === undefined || value.length === 0) return false;
      return true;
    })
    .url('URL invalide. Avez-vous pensé à préciser le protocole (https://)?'),
});

interface AutomateSettingsProps {
  companyData: GetDisckoCompanyDto;
}

export function AutomateSettings({ companyData }: AutomateSettingsProps): JSX.Element {
  const { getAccessTokenSilently, user } = useAuth0();
  const { requirementParametersService } = useContext(DisckoApisContext);

  const queryClient: QueryClient = useQueryClient();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();

  const [isUrlWebhookActivated, setIsUrlWebhookActivated] = useState<boolean>(false);

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<AutomateSettingsFormModel>({
    context: {
      isUrlWebhookActivated,
    },
    resolver: yupResolver(schema),
  });

  const watchUrlWebhook: string = watch('urlWebhook') ?? '';

  const [originalUrlWebhookActivated, setOriginalUrlWebhookActivated] = useState<boolean>(false);
  const [originalUrlWebhook, setOriginalUrlWebhook] = useState<string | undefined>();

  const onSubmitForm: SubmitHandler<AutomateSettingsFormModel> = async (data) => {
    mutateSaveAutomatedParameters();
  };

  /* -------------------------------- The first time, set values with the companyData ------------------------------- */

  useEffect(() => {
    if (companyData !== undefined) {
      if (companyData.urlWebhook !== undefined && companyData.urlWebhook !== '') {
        setValue('urlWebhook', companyData.urlWebhook);
        setIsUrlWebhookActivated(true);
        setOriginalUrlWebhookActivated(true);
      } else {
        setIsUrlWebhookActivated(false);
      }
      // Save the original values
      setOriginalUrlWebhook(companyData.urlWebhook);
    }
  }, [companyData, companyData.urlWebhook, setValue]);

  function resetToOriginalValues(): void {
    if (companyData !== undefined) {
      setValue('urlWebhook', originalUrlWebhook);
      setIsUrlWebhookActivated(originalUrlWebhookActivated);
    }
  }

  /* ------------------------------------- Check if we activate the save button ------------------------------------- */

  let hasFormChanged: boolean = false;
  if (originalUrlWebhookActivated !== isUrlWebhookActivated || watchUrlWebhook !== originalUrlWebhook) {
    hasFormChanged = true;
  }

  let isFormValid: boolean = false;

  // Check if the form is valid, form is invalid if the checkbox is checked but the url is empty
  if ((watchUrlWebhook === undefined && isUrlWebhookActivated) || (watchUrlWebhook === '' && isUrlWebhookActivated)) {
    isFormValid = false;
  } else {
    isFormValid = true;
  }

  /* -------------------------------------------- Desktop or mobile view -------------------------------------------- */

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* --------------------------------------------------- Save Data -------------------------------------------------- */

  async function saveAutomateParameters(): Promise<UpdateUrlWebhookDto> {
    const accessToken: string = await getAccessTokenSilently();
    if (requirementParametersService === undefined) {
      throw new Error('requirementParametersService is undefined');
    }
    return await requirementParametersService.saveAutomatisationParameters({
      accessToken,
      updateWebhookParametersDto: {
        urlWebhook: isUrlWebhookActivated ? watchUrlWebhook : '',
      },
    });
  }

  const { mutate: mutateSaveAutomatedParameters } = useMutation(saveAutomateParameters, {
    onSuccess: async (savedRequirementsParameters: UpdateUrlWebhookDto) => {
      toast.success('Les modifications ont bien été appliquées');
      queryClient.setQueryData(['company', user?.sub], (oldData: GetDisckoCompanyDto | undefined) => {
        if (oldData === undefined) {
          throw new Error('oldData is undefined');
        }
        oldData.urlWebhook = savedRequirementsParameters.urlWebhook;

        // updateTheData(oldData);
        return oldData;
      });
      return savedRequirementsParameters;
    },
    onError: () => {
      toast.error("Une erreur est survenue lors de l'enregistrement des modifications");
    },
  });

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <form id="customizationForm" onSubmit={handleSubmit(onSubmitForm)}>
      {isLargeScreen === true && (
        <div className={concatClassNames('pl-4 pb-4 flex flex-col gap-6', '', 'rounded-md')}>
          <div className="flex flex-row justify-around">
            <Text content="Connecteurs" position="left" size="3xl" weight="bold" />
            <div className="flex flex-row gap-6 w-full items-end justify-end">
              {hasFormChanged && (
                <Button
                  content="Réinitialiser"
                  width="fit"
                  type="button"
                  borderColor={'black'}
                  onClick={resetToOriginalValues}
                  color="#000000"
                />
              )}
              <Button
                content="Enregistrer vos modifications"
                width="fit"
                color="#FFFFFF"
                type="submit"
                disabled={!hasFormChanged || !isFormValid}
                borderColor={!hasFormChanged ? 'gray-50' : 'black'}
              />
            </div>
          </div>

          <Text
            position="left"
            size="lg"
            color="black"
            weight="bold"
            content="Discko vous offre des connecteurs à des outils tiers pour automatiser vos tâches."
          />
          <div className="flex flex-col gap-4 cursor-pointer bg-white rounded-lg p-4">
            <div className="flex flex-row gap-4 items-center">
              <div className="h-28 w-28 rounded-full flex items-center bg-white">
                <img src="/imgs/make-logo.png" alt="make" />
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <p>
                    <span className="font-bold">Make</span> est une plateforme visuelle qui automatise vos tâches en
                    connectant facilement vos applications entre elles.
                  </p>
                  <p>Utilisez ce connecteur pour faire un lien entre Discko et votre CRM.</p>
                </div>
                <div
                  className="flex flex-row gap-4"
                  onClick={() => {
                    setIsUrlWebhookActivated(!isUrlWebhookActivated);
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={isUrlWebhookActivated}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setIsUrlWebhookActivated(checked);

                      if (!checked) {
                        setValue('urlWebhook', '');
                      }
                    }}
                    value={isUrlWebhookActivated ? 'checked' : ''}
                    className={concatClassNames('cursor-pointer')}
                  />
                  <p className="font-bold">Connecter Discko à Make</p>
                  {/* {originalUrlWebhookActivated && (
                    <>
                      <Chip
                        label="ACTIF"
                        bgColor="white"
                        textColor="green-500"
                        paddingHorizontal="lg"
                        icon="checkCircle"
                        iconSize="sm"
                        iconColor="green"
                        borderColor="none"
                      />
                    </>
                  )} */}
                </div>
              </div>
            </div>

            <div id="urlWebhookInput">
              {isUrlWebhookActivated && (
                <div className="flex flex-col gap-4">
                  <div className="rounded-full flex flex-row gap-4 items-center w-full">
                    <Text
                      position="left"
                      size="lg"
                      color="black"
                      // weight="bold"
                      content="Etape 1 : Depuis votre application Make, ajoutez le module Discko."
                    />
                    <Button
                      height="fit"
                      width="fit"
                      content="Rejoindre Make"
                      type="button"
                      iconPosition="left"
                      onClick={async () => {
                        window.open('https://www.make.com/en/', '_blank');
                      }}
                    />
                  </div>
                  <div>
                    <Text
                      position="left"
                      size="lg"
                      color="black"
                      // weight="bold"
                      content="Etape 2 : Copiez l'URL du webhook que Make vous communique et insérez-la ci-dessous."
                    />
                  </div>
                  <div className="rounded-full flex flex-row gap-4 items-center w-full">
                    <div className="w-fit h-fit">
                      <Text position="left" size="md" color="black" weight="bold" content="URL du webhook :" />
                    </div>
                    <div className="w-[60%]">
                      <Input
                        placeholder="https://hook.eu1.make.com/..."
                        {...register('urlWebhook')}
                        value={watchUrlWebhook ?? ''}
                        error={
                          errors.urlWebhook != null && isSubmitted
                            ? errors.urlWebhook.message
                            : isUrlWebhookActivated && watchUrlWebhook === ''
                            ? 'Champ obligatoire'
                            : undefined
                        }
                      />
                    </div>
                  </div>
                  <Text
                    position="left"
                    size="sm"
                    color="black"
                    weight="light"
                    content="L'URL du webhook est la même pour tous les membres de votre entreprise."
                  />
                  <div>
                    <Text
                      position="left"
                      size="lg"
                      color="black"
                      // weight="bold"
                      content="Etape 3 : Paramétrez votre intégration dans Make en connectant le module Discko au module de votre choix."
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className={concatClassNames('flex flex-col gap-6', 'rounded-md')}>
          <Text
            position="left"
            size="sm"
            color="black"
            content="Discko vous offre des connecteurs à des outils tiers pour automatiser vos tâches."
          />
          <div className="flex flex-col gap-4 cursor-pointer bg-white rounded-lg p-4">
            <div className="flex flex-col gap-4 items-center">
              <div className="h-24 w-24 rounded-full flex items-center bg-white">
                <img src="/imgs/make-logo.png" alt="make" />
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-sm">
                  <span className="font-bold">Make</span> est une plateforme visuelle qui automatise vos tâches en
                  connectant facilement vos applications entre elles.
                </p>
                <p className="text-sm">Utilisez ce connecteur pour faire un lien entre Discko et votre CRM.</p>

                <p className="font-bold text-sm"> Paramétrez cette intégration depuis un ordinateur.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
