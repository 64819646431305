/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateGenericRequirementParamsDto
 */
export interface UpdateGenericRequirementParamsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    urlRedirect?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    businessType: UpdateGenericRequirementParamsDtoBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    activityDescription: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    contactType: UpdateGenericRequirementParamsDtoContactTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isCompanyLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isCompanyNameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isCompanyWebsiteRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactEmailRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactPhoneRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactLastnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactFirstnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactJobPositionRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    isContactLinkedinProfileRequired: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateGenericRequirementParamsDto
     */
    totalNumberOfQuestions: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    urlWebhook?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    primaryColor?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    bgColor?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    secondaryColor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGenericRequirementParamsDto
     */
    watermark: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateGenericRequirementParamsDto
     */
    synthesisItems: string;
}


/**
 * @export
 */
export const UpdateGenericRequirementParamsDtoBusinessTypeEnum = {
    Products: 'PRODUCTS',
    Services: 'SERVICES'
} as const;
export type UpdateGenericRequirementParamsDtoBusinessTypeEnum = typeof UpdateGenericRequirementParamsDtoBusinessTypeEnum[keyof typeof UpdateGenericRequirementParamsDtoBusinessTypeEnum];

/**
 * @export
 */
export const UpdateGenericRequirementParamsDtoContactTypeEnum = {
    Person: 'PERSON',
    Company: 'COMPANY',
    Both: 'BOTH'
} as const;
export type UpdateGenericRequirementParamsDtoContactTypeEnum = typeof UpdateGenericRequirementParamsDtoContactTypeEnum[keyof typeof UpdateGenericRequirementParamsDtoContactTypeEnum];


/**
 * Check if a given object implements the UpdateGenericRequirementParamsDto interface.
 */
export function instanceOfUpdateGenericRequirementParamsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "businessType" in value;
    isInstance = isInstance && "activityDescription" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "isCompanyLocationRequired" in value;
    isInstance = isInstance && "isCompanyNameRequired" in value;
    isInstance = isInstance && "isCompanyWebsiteRequired" in value;
    isInstance = isInstance && "isContactEmailRequired" in value;
    isInstance = isInstance && "isContactPhoneRequired" in value;
    isInstance = isInstance && "isContactLocationRequired" in value;
    isInstance = isInstance && "isContactLastnameRequired" in value;
    isInstance = isInstance && "isContactFirstnameRequired" in value;
    isInstance = isInstance && "isContactJobPositionRequired" in value;
    isInstance = isInstance && "isContactLinkedinProfileRequired" in value;
    isInstance = isInstance && "totalNumberOfQuestions" in value;
    isInstance = isInstance && "watermark" in value;
    isInstance = isInstance && "synthesisItems" in value;

    return isInstance;
}

export function UpdateGenericRequirementParamsDtoFromJSON(json: any): UpdateGenericRequirementParamsDto {
    return UpdateGenericRequirementParamsDtoFromJSONTyped(json, false);
}

export function UpdateGenericRequirementParamsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGenericRequirementParamsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'businessType': json['businessType'],
        'activityDescription': json['activityDescription'],
        'contactType': json['contactType'],
        'isCompanyLocationRequired': json['isCompanyLocationRequired'],
        'isCompanyNameRequired': json['isCompanyNameRequired'],
        'isCompanyWebsiteRequired': json['isCompanyWebsiteRequired'],
        'isContactEmailRequired': json['isContactEmailRequired'],
        'isContactPhoneRequired': json['isContactPhoneRequired'],
        'isContactLocationRequired': json['isContactLocationRequired'],
        'isContactLastnameRequired': json['isContactLastnameRequired'],
        'isContactFirstnameRequired': json['isContactFirstnameRequired'],
        'isContactJobPositionRequired': json['isContactJobPositionRequired'],
        'isContactLinkedinProfileRequired': json['isContactLinkedinProfileRequired'],
        'totalNumberOfQuestions': json['totalNumberOfQuestions'],
        'urlWebhook': !exists(json, 'urlWebhook') ? undefined : json['urlWebhook'],
        'primaryColor': !exists(json, 'primaryColor') ? undefined : json['primaryColor'],
        'bgColor': !exists(json, 'bgColor') ? undefined : json['bgColor'],
        'secondaryColor': !exists(json, 'secondaryColor') ? undefined : json['secondaryColor'],
        'watermark': json['watermark'],
        'synthesisItems': json['synthesisItems'],
    };
}

export function UpdateGenericRequirementParamsDtoToJSON(value?: UpdateGenericRequirementParamsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'urlRedirect': value.urlRedirect,
        'businessType': value.businessType,
        'activityDescription': value.activityDescription,
        'contactType': value.contactType,
        'isCompanyLocationRequired': value.isCompanyLocationRequired,
        'isCompanyNameRequired': value.isCompanyNameRequired,
        'isCompanyWebsiteRequired': value.isCompanyWebsiteRequired,
        'isContactEmailRequired': value.isContactEmailRequired,
        'isContactPhoneRequired': value.isContactPhoneRequired,
        'isContactLocationRequired': value.isContactLocationRequired,
        'isContactLastnameRequired': value.isContactLastnameRequired,
        'isContactFirstnameRequired': value.isContactFirstnameRequired,
        'isContactJobPositionRequired': value.isContactJobPositionRequired,
        'isContactLinkedinProfileRequired': value.isContactLinkedinProfileRequired,
        'totalNumberOfQuestions': value.totalNumberOfQuestions,
        'urlWebhook': value.urlWebhook,
        'primaryColor': value.primaryColor,
        'bgColor': value.bgColor,
        'secondaryColor': value.secondaryColor,
        'watermark': value.watermark,
        'synthesisItems': value.synthesisItems,
    };
}

