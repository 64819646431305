import { DisckoApisContext, type HistoryHandler, MaintenanceContext, useHistoryHandler } from 'App';
import { type GetDisckoCompanyDto } from 'api';

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { type NavigateFunction, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { MaintenanceCheck } from 'components/MaintenanceComponents/MaintenanceCheck';
import { MaintenanceHeader } from 'components/MaintenanceComponents/MaintenanceHeader';
import { Menu } from 'components/Menu/Menu';

import concatClassNames from 'utils/classNames';

interface BaseProps {
  children: JSX.Element | JSX.Element[];
}

export function BasePage({ children }: BaseProps): JSX.Element {
  const historyHandler: HistoryHandler = useHistoryHandler();
  if (historyHandler.actualPath !== window.location.pathname + window.location.search) {
    historyHandler.oldPath = historyHandler.actualPath;
    historyHandler.actualPath = window.location.pathname + window.location.search;
  }

  const { getAccessTokenSilently, user } = useAuth0();
  const { companyService } = useContext(DisckoApisContext);
  const { maintenanceInfos } = useContext(MaintenanceContext);

  async function getCompanyByUserId(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    if (user === undefined) return {} as GetDisckoCompanyDto;
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto, Error>(
    ['company', user?.sub],
    getCompanyByUserId,
  );

  const navigate: NavigateFunction = useNavigate();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <>
      <MaintenanceCheck />
      {isLargeScreen === true && (
        <div className={`flex h-full items-start p-0`}>
          <Menu />
          <div
            className={concatClassNames(
              'flex flex-col flex-1',
              'relative',
              'h-full',
              'overflow-auto',
              'items-stretch',
              // 'p-10',
              'bg-blue-100',
            )}
          >
            <MaintenanceHeader />

            <div className={concatClassNames('flex flex-1 h-full overflow-auto')}>{children}</div>
            <div className={concatClassNames('flex flex-row', 'gap-1', 'justify-center', 'text-xs', 'mt-4')}>
              <a href="/cgu-bo" className="underline cursor-pointer">
                {'CGU'}
              </a>
              <a href="/cgs" className="underline cursor-pointer">
                {'CGS'}
              </a>
              <a href="/privacy-policy" className="underline cursor-pointer">
                {'Politique de confidentialité'}
              </a>
              <a href="/terms" className="underline cursor-pointer">
                {'Mentions légales'}
              </a>
              <p>{'© 2024 SAS Specks. Tous droits réservés.'}</p>
            </div>
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className={`flex flex-col w-full h-screen relative`}>
          <MaintenanceHeader />
          <div className="p-4 pb-0 gap-4 bg-blue-100 flex h-full overflow-auto justify-center">{children}</div>

          <div className="sticky bottom-0 z-10 bg-white w-full">
            <Menu />
          </div>
        </div>
      )}
    </>
  );
}
