import React, { useState } from 'react';
import { type Location, type NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { UserAvatar } from 'components/UserAvatar/UserAvatar';

import concatClassNames from 'utils/classNames';

import Discko from 'icons/Discko';

import { MenuButton } from './MenuButton';

const href: any = {
  Accueil: '/',
};

export function Menu(): JSX.Element {
  const { user } = useAuth0();

  const location: Location = useLocation();
  const currentPath: string = location.pathname;
  const pathSegments: string[] = currentPath.split('/');
  let selectedButton: string = '';

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  switch (pathSegments[1]) {
    case '':
      selectedButton = 'homePage';
      break;
    case 'requirements':
      selectedButton = 'requirements';
      break;
    case 'settings':
      selectedButton = 'settings';
      break;
  }

  const navigate: NavigateFunction = useNavigate();

  function handleMenuButtonClick(id: string): void {
    switch (id) {
      case 'requirements':
        navigate('/requirements');
        break;
      case 'form':
        navigate('/start-requirement');
        break;
      case 'settings':
        navigate('/settings');
        break;
      case 'homePage':
        navigate(href.Accueil);
        break;
    }
  }

  return (
    <>
      {isLargeScreen === true && (
        <div
          className={concatClassNames(
            'flex flex-col justify-between',
            'w-14 min-h-full',
            'bg-blue-400',
            'items-center',
          )}
        >
          <div className="flex flex-col p-8 gap-24 items-center">
            <a href="/">
              <div
                className={concatClassNames(
                  'flex flex-col justify-center items-center',
                  'w-8 h-8',
                  'p-1.5 gap-1.5',
                  'bg-white rounded-2xl',
                )}
              >
                <Discko size="md" color="#42A5F5" />
              </div>
            </a>
            <div className="flex flex-col items-center p-0 gap-14 w-11 h-full">
              <MenuButton
                id="homePage"
                description="Accueil"
                iconName="home"
                isSelected={selectedButton === 'homePage' && true}
                onClick={handleMenuButtonClick}
              />
              <MenuButton
                id="requirements"
                description="Expressions de besoin"
                iconName="message"
                isSelected={selectedButton === 'requirements' && true}
                onClick={handleMenuButtonClick}
              />
              <MenuButton
                id="settings"
                description="Votre profil"
                iconName="settings"
                isSelected={selectedButton === 'settings' && true}
                onClick={handleMenuButtonClick}
              />
            </div>
          </div>

          <div className=" flex flex-col items-center gap-2">
            {user != null ? (
              <>
                <UserAvatar size="sm" imgUrl={user.picture} name={user.name} email={user.email} />
              </>
            ) : (
              <UserAvatar size="sm" loading />
            )}
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className={concatClassNames('flex flex-row justify-around', 'h-14 w-full', 'bg-blue-400', 'items-center')}>
          <MenuButton
            id="homePage"
            description="Accueil"
            iconName="home"
            isSelected={selectedButton === 'homePage' && true}
            onClick={handleMenuButtonClick}
          />
          <MenuButton
            id="form"
            description="Démarrer un formulaire"
            iconName="message"
            isSelected={selectedButton === 'form' && true}
            onClick={handleMenuButtonClick}
          />
          <MenuButton
            id="requirements"
            description="Expressions de besoin"
            iconName="database"
            isSelected={selectedButton === 'requirements' && true}
            onClick={handleMenuButtonClick}
          />
          <MenuButton
            id="settings"
            description="Votre profil"
            iconName="settings"
            isSelected={selectedButton === 'settings' && true}
            onClick={handleMenuButtonClick}
          />
        </div>
      )}
    </>
  );
}
