import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import concatClassNames from 'utils/classNames';
import getIcon from 'utils/getIcon';

type ModalMaxWidth = 'fit' | 'xl' | 'xxl' | 'xxxl' | 'full';

const modalMaxWidthStyle: Record<ModalMaxWidth, string> = {
  fit: 'max-w-fit',
  xl: 'max-w-xl',
  xxl: 'max-w-2xl',
  xxxl: 'max-w-3xl',

  full: 'max-w-full',
};

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
  isOpen: boolean;
  maxWidth?: ModalMaxWidth;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Modal({ title, children, isOpen, maxWidth = 'xl', setIsOpen }: ModalProps): JSX.Element {
  function closeModal(): void {
    setIsOpen?.(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0 bg-black opacity-50" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={concatClassNames(
                    'flex flex-col',
                    'items-stretch w-full',
                    modalMaxWidthStyle[maxWidth],
                    'shadow-modal',
                    'rounded-xl bg-white',
                    'p-6 gap-6',
                  )}
                >
                  {(setIsOpen !== undefined || title !== undefined) && (
                    <div
                      className={concatClassNames(
                        'flex flex-row items-stretch',
                        title === undefined ? 'justify-end' : 'flex flex-row items-stretch justify-between',
                      )}
                    >
                      {title !== undefined && (
                        <div className="flex flex-row gap-2">
                          <h2 className="text-gray-700 text-xl font-semibold">{title}</h2>
                        </div>
                      )}
                      {setIsOpen !== undefined && <button onClick={closeModal}>{getIcon('x', '#DBDBDB', 'md')}</button>}
                    </div>
                  )}
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
