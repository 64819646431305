import React from 'react';
import uuid from 'react-uuid';

import concatClassNames from 'utils/classNames';

export type TextSize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'api'
  | 'base'
  | 'lg'
  | 'xl'
  | '1.5xl'
  | '2xl'
  | '2.5xl'
  | '3xl'
  | 'apiTitleChip';

export type TextWeight = 'light' | 'normal' | 'medium' | 'bold' | 'extrabold' | 'black';
export type LetterSpacing = 'tight' | 'normal' | 'wide';
export type TextPosition = 'left' | 'center' | 'right' | 'justify';
export type TextColor =
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'black'
  | 'pink'
  | 'blue'
  | 'blue-400'
  | 'yellow'
  | 'red-500'
  | 'red'
  | 'cyan'
  | 'green-500'
  | 'purple-400'
  | 'purple-500'
  | 'gradient2-from-light'
  | 'gradient2-from'
  | 'white'
  | 'secondary';

export type TextBackgroundColor =
  | 'gray-100'
  | 'gray-200'
  | 'gray-300'
  | 'gray-400'
  | 'gray-500'
  | 'gray-600'
  | 'gray-700'
  | 'black'
  | 'pink'
  | 'blue'
  | 'yellow'
  | 'red-500'
  | 'red'
  | 'cyan'
  | 'green-500'
  | 'purple-400'
  | 'purple-500'
  | 'gradient2-from'
  | 'transparent'
  | 'bg-white/30'
  | 'white';

export type TextBackgroundRounded = 'none' | 'rounded-md' | 'rounded-lg' | 'rounded-xl' | 'rounded-2xl' | 'rounded-3xl';

export type TextBackgroundHorizontalPadding = 'none' | 'sm';

export type TextWhiteSpace = 'pre' | 'pre-line' | 'pre-wrap' | 'normal' | 'nowrap' | 'wrap' | 'wrap-reverse';
export type TextBreak = 'normal' | 'words' | 'all';

export const textSizeStyle: Record<TextSize, string> = {
  xxs: 'text-[9px]',
  xs: 'text-xs',
  sm: 'text-sm',
  api: 'text-api',
  base: 'text-[20px]',
  lg: 'text-lg',
  xl: 'text-xl',
  '1.5xl': 'text-[22px]',
  '2xl': 'text-xl lg:text-2xl',
  '2.5xl': 'text-[24px] lg:text-[27px]',
  '3xl': 'text-3xl',
  apiTitleChip: 'text-apiTitleChip',
};

export const textWeightStyle: Record<TextWeight, string> = {
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

export const letterSpacingStyle: Record<LetterSpacing, string> = {
  tight: 'tracking-tight',
  normal: 'tracking-normal',
  wide: 'tracking-wide',
};

// TODO : remove gray
export const textColorStyle: Record<TextColor, string> = {
  'gray-100': 'text-gray-100',
  'gray-200': 'text-gray-200',
  'gray-300': 'text-gray-300',
  'gray-400': 'text-gray-400',
  'gray-500': 'text-gray-500',
  'gray-600': 'text-gray-600',
  'gray-700': 'text-gray-700',
  black: 'text-black',
  'blue-400': 'text-blue-400',
  pink: 'text-pink-600',
  blue: 'text-blue-800',
  yellow: 'text-yellow-400',
  'red-500': 'text-red-500',
  red: 'text-red-700',
  cyan: 'text-cyan-500',
  'green-500': 'text-green-500',
  'purple-400': 'text-purple-400',
  'purple-500': 'text-purple-500',
  'gradient2-from-light': 'text-[#cca0c8]',
  // rgb(228 181 223) tranform to hex
  'gradient2-from': 'text-gradient2-from',
  white: 'text-white',
  secondary: 'text-secondary-500',
};

export const textBackgroundColorStyle: Record<TextBackgroundColor, string> = {
  'gray-100': 'bg-gray-100',
  'gray-200': 'bg-gray-200',
  'gray-300': 'bg-gray-300',
  'gray-400': 'bg-gray-400',
  'gray-500': 'bg-gray-500',
  'gray-600': 'bg-gray-600',
  'gray-700': 'bg-gray-700',
  black: 'bg-black',
  pink: 'bg-pink-600',
  blue: 'bg-blue-800',
  yellow: 'bg-yellow-400',
  'red-500': 'bg-red-500',
  red: 'bg-red-700',
  cyan: 'bg-cyan-500',
  'green-500': 'bg-green-500',
  'purple-400': 'bg-purple-400',
  'purple-500': 'bg-purple-500',
  'gradient2-from': 'bg-gradient2-from',
  transparent: 'bg-transparent',
  'bg-white/30': 'bg-white/30',
  white: 'bg-white',
};

export const textBackgroundRoundedStyle: Record<TextBackgroundRounded, string> = {
  none: '',
  'rounded-md': 'rounded-md',
  'rounded-lg': 'rounded-lg',
  'rounded-xl': 'rounded-xl',
  'rounded-2xl': 'rounded-2xl',
  'rounded-3xl': 'rounded-3xl',
};

export const textBackgroundHorizontalPaddingStyle: Record<TextBackgroundHorizontalPadding, string> = {
  none: '',
  sm: 'px-1.5',
};

export const textPositionStyle: Record<TextPosition, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
};

export const textWhiteSpaceStyle: Record<TextWhiteSpace, string> = {
  pre: 'whitespace-pre',
  'pre-line': 'whitespace-pre-line',
  'pre-wrap': 'whitespace-pre-wrap',
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
  wrap: 'whitespace-wrap',
  'wrap-reverse': 'whitespace-wrap-reverse',
};

export const textBreakStyle: Record<TextBreak, string> = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
};

export type TextDecoration = 'underline' | 'underline-4' | 'line-through' | 'no-underline';

export const textDecorationStyle: Record<TextDecoration, string> = {
  underline: 'underline',
  'underline-4': 'underline underline-offset-4',
  'line-through': 'line-through',
  'no-underline': 'no-underline',
};

export type TextMarginTop = 'none' | 'auto';

export const textMarginTop: Record<TextMarginTop, string> = {
  none: 'mt-0',
  auto: 'mt-auto',
};

export type TextMarginRight = 'none' | 'auto';

export const textMarginRight: Record<TextMarginRight, string> = {
  none: 'mr-0',
  auto: 'mr-auto',
};

export type TextMarginBottom = 'none' | 'auto';

export const textMarginBottom: Record<TextMarginBottom, string> = {
  none: 'mb-0',
  auto: 'mb-auto',
};

export type TextMarginLeft = 'none' | 'auto';

export const textMarginLeft: Record<TextMarginLeft, string> = {
  none: 'ml-0',
  auto: 'ml-auto',
};

export type textOverflow = 'none' | 'truncate' | 'ellipsis' | 'clip';

export const textOverflowStyle: Record<textOverflow, string> = {
  none: '',
  truncate: 'truncate',
  ellipsis: 'text-ellipsis overflow-hidden',
  clip: 'text-clip overflow-hidden',
};

export type textSpaceY = 'none' | '2' | '4' | '6' | '8';

export const textSpaceYStyle: Record<textSpaceY, string> = {
  none: '',
  '2': 'space-y-2',
  '4': 'space-y-4',
  '6': 'space-y-6',
  '8': 'space-y-8',
};

/*
 ** p : new Line
 ** span : inline
 ** complex : an array of TextProps encapsulated in a p
 */
type TextType = 'p' | 'span' | 'complex' | 'br' | 'wbr' | 'ul' | 'li' | 'link';

export type TextProps =
  | {
      content?: string | JSX.Element | TextProps[];
      contentType: TextType;
      size?: TextSize;
      weight?: TextWeight;
      color?: string;
      backgroundColor?: TextBackgroundColor;
      backgroundRounded?: TextBackgroundRounded;
      backgroundHorizontalPadding?: TextBackgroundHorizontalPadding;
      tracking?: LetterSpacing;
      position?: TextPosition;
      whitespace?: TextWhiteSpace;
      textBreak?: TextBreak;
      textDecoration?: TextDecoration;
      marginTop?: TextMarginTop;
      marginRight?: TextMarginRight;
      marginBottom?: TextMarginBottom;
      marginLeft?: TextMarginLeft;
      overFlow?: textOverflow;
      hidden?: boolean;
      style?: string;
      onClick?: () => void;
      spaceY?: textSpaceY;
      underline?: TextDecoration;
    }
  | {
      content?: never;
      contentType?: never;
      size?: never;
      weight?: never;
      color?: never;
      backgroundColor?: never;
      backgroundRounded?: never;
      backgroundHorizontalPadding?: never;
      tracking?: never;
      position?: never;
      whitespace?: never;
      textBreak?: never;
      textDecoration?: never;
      marginTop?: never;
      marginRight?: never;
      marginBottom?: never;
      marginLeft?: never;
      overFlow?: never;
      hidden?: boolean;
      style?: never;
      onClick?: () => void;
      spaceY?: never;
      underline?: never;
    };

type RichTextProps =
  | {
      fragments: TextProps[];
      loading?: false;
    }
  | {
      fragments?: never;
      loading: true;
    };

function getSpanTextFragment(fragment: TextProps): JSX.Element {
  return (
    <span
      key={uuid()}
      style={{ color: fragment.color !== undefined ? fragment.color : '' }}
      className={concatClassNames(
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        'w-fit',
        fragment.style !== undefined ? fragment.style : '',

        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {fragment.content as string}
    </span>
  );
}

function getPTextFragment(fragment: TextProps): JSX.Element {
  return (
    <p
      key={uuid()}
      style={{ color: fragment.color !== undefined ? fragment.color : '' }}
      className={concatClassNames(
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        fragment.style !== undefined ? fragment.style : '',

        'w-fit',
        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {typeof fragment.content === 'string' && fragment.content}
      {typeof fragment.content === 'object' &&
        (fragment.content as TextProps[]).map((frag) => getComplexTextFragment(frag))}
    </p>
  );
}

function getUlTextFragment(fragment: TextProps): JSX.Element {
  return (
    <ul
      key={uuid()}
      style={{ color: fragment.color !== undefined ? fragment.color : '' }}
      className={concatClassNames(
        'list-disc list-inside',
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        fragment.style !== undefined ? fragment.style : '',
        fragment.spaceY !== undefined ? textSpaceYStyle[fragment.spaceY] : '',
        'w-fit',
        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {(fragment.content as TextProps[]).map((frag) => getLiTextFragment(frag))}
    </ul>
  );
}

function getLiTextFragment(fragment: TextProps): JSX.Element {
  return (
    <li
      key={uuid()}
      style={{ color: fragment.color !== undefined ? fragment.color : '' }}
      className={concatClassNames(
        fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
        fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
        fragment.backgroundHorizontalPadding !== undefined
          ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
          : '',
        fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
        fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
        fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
        fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
        fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
        fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
        fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
        fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
        fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
        fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
        fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
        fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
        fragment.style !== undefined ? fragment.style : '',

        'w-fit',
        'max-w-full',
      )}
      hidden={fragment.hidden ?? false}
    >
      {fragment.content as string}
    </li>
  );
}

function getLinkTextFragment(fragment: TextProps): JSX.Element {
  return (
    <span key={uuid()}>
      <a
        key={uuid()}
        style={{ color: fragment.color !== undefined ? fragment.color : '' }}
        className={concatClassNames(
          fragment.backgroundColor !== undefined ? textBackgroundColorStyle[fragment.backgroundColor] : '',
          fragment.backgroundRounded !== undefined ? textBackgroundRoundedStyle[fragment.backgroundRounded] : '',
          fragment.backgroundHorizontalPadding !== undefined
            ? textBackgroundHorizontalPaddingStyle[fragment.backgroundHorizontalPadding]
            : '',
          fragment.size !== undefined ? textSizeStyle[fragment.size] : '',
          fragment.weight !== undefined ? textWeightStyle[fragment.weight] : '',
          fragment.tracking !== undefined ? letterSpacingStyle[fragment.tracking] : '',
          fragment.position !== undefined ? textPositionStyle[fragment.position] : '',
          fragment.whitespace !== undefined ? textWhiteSpaceStyle[fragment.whitespace] : '',
          fragment.textBreak !== undefined ? textBreakStyle[fragment.textBreak] : '',
          fragment.textDecoration !== undefined ? textDecorationStyle[fragment.textDecoration] : '',
          fragment.marginTop !== undefined ? textMarginTop[fragment.marginTop] : '',
          fragment.marginRight !== undefined ? textMarginRight[fragment.marginRight] : '',
          fragment.marginBottom !== undefined ? textMarginBottom[fragment.marginBottom] : '',
          fragment.marginLeft !== undefined ? textMarginLeft[fragment.marginLeft] : '',
          fragment.overFlow !== undefined ? textOverflowStyle[fragment.overFlow] : '',
          fragment.style !== undefined ? fragment.style : '',
          fragment.underline !== undefined ? textDecorationStyle[fragment.underline] : '',

          'w-fit',
          'max-w-full',
          'cursor-pointer',
          'hover:text-black/70',
        )}
        hidden={fragment.hidden ?? false}
        onClick={() => {
          fragment.onClick !== undefined ? fragment.onClick() : console.error('No onClick function');
        }}
      >
        {fragment.content as string}
      </a>
    </span>
  );
}

function getComplexTextFragment(fragment: TextProps): string | JSX.Element | JSX.Element[] {
  if (fragment.contentType === 'p') return <span>{'Error, only span is allowed in complex fragments'}</span>;
  else if (fragment.contentType === 'span') return getSpanTextFragment(fragment);
  else if (fragment.contentType === 'br') return <br key={uuid()} />;
  else if (fragment.contentType === 'wbr') return <wbr key={uuid()} />;
  else if (fragment.contentType === 'complex') return getPTextFragment(fragment);
  else if (fragment.contentType === 'ul') return getUlTextFragment(fragment);
  return 'ERROR';
}

function getRichTextFragment(fragment: TextProps): JSX.Element {
  if (fragment.contentType === 'p') {
    return getPTextFragment(fragment);
  } else if (fragment.contentType === 'span') {
    return getSpanTextFragment(fragment);
  } else if (fragment.contentType === 'complex') {
    return getPTextFragment(fragment);
  } else if (fragment.contentType === 'br') {
    return <br key={uuid()} />;
  } else if (fragment.contentType === 'wbr') {
    return <wbr key={uuid()} />;
  } else if (fragment.contentType === 'ul') {
    return getUlTextFragment(fragment);
  } else if (fragment.contentType === 'link') {
    return getLinkTextFragment(fragment);
  }
  return <></>;
}
export function RichText({ fragments, loading = false }: RichTextProps): JSX.Element {
  // TODO : Loading with the correct size
  if (loading) {
    return (
      <div className={concatClassNames('w-40', 'h-5', 'my-0.5', 'bg-gray-50', 'rounded-md', 'animate-pulse')}></div>
    );
  } else {
    return <>{fragments?.map((frag) => getRichTextFragment(frag))}</>;
  }
}
