/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoCompany } from './DisckoCompany';
import {
    DisckoCompanyFromJSON,
    DisckoCompanyFromJSONTyped,
    DisckoCompanyToJSON,
} from './DisckoCompany';

/**
 * 
 * @export
 * @interface GenericRequirement
 */
export interface GenericRequirement {
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    results: string;
    /**
     * 
     * @type {Date}
     * @memberof GenericRequirement
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericRequirement
     */
    finishedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GenericRequirement
     */
    isNotificationsSent: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    step: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenericRequirement
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    projectType: string;
    /**
     * 
     * @type {DisckoCompany}
     * @memberof GenericRequirement
     */
    company: DisckoCompany;
    /**
     * 
     * @type {string}
     * @memberof GenericRequirement
     */
    raw?: string;
}

/**
 * Check if a given object implements the GenericRequirement interface.
 */
export function instanceOfGenericRequirement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "finishedAt" in value;
    isInstance = isInstance && "isNotificationsSent" in value;
    isInstance = isInstance && "step" in value;
    isInstance = isInstance && "read" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "company" in value;

    return isInstance;
}

export function GenericRequirementFromJSON(json: any): GenericRequirement {
    return GenericRequirementFromJSONTyped(json, false);
}

export function GenericRequirementFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericRequirement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contact': json['contact'],
        'results': json['results'],
        'startedAt': (new Date(json['startedAt'])),
        'finishedAt': (new Date(json['finishedAt'])),
        'isNotificationsSent': json['isNotificationsSent'],
        'step': json['step'],
        'read': json['read'],
        'projectType': json['projectType'],
        'company': DisckoCompanyFromJSON(json['company']),
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
    };
}

export function GenericRequirementToJSON(value?: GenericRequirement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contact': value.contact,
        'results': value.results,
        'startedAt': (value.startedAt.toISOString()),
        'finishedAt': (value.finishedAt.toISOString()),
        'isNotificationsSent': value.isNotificationsSent,
        'step': value.step,
        'read': value.read,
        'projectType': value.projectType,
        'company': DisckoCompanyToJSON(value.company),
        'raw': value.raw,
    };
}

