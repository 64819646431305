import React, { forwardRef, useContext, useState } from 'react';

import { disckoContext } from 'pages/RequirementForm/DisckoPage';

import concatClassNames from 'utils/classNames';

import { MaxCharVisualizer } from '../MaxCharacterVisualizer/MaxCharacterVisualizer';
import {
  Text,
  type TextColor,
  type TextPosition,
  type TextSize,
  type TextWeight,
  textColorStyle,
  textPositionStyle,
  textSizeStyle,
} from '../Text/Text';

type InputHeight = 'full' | 'fit' | 'md';

const inputHeightStyle: Record<InputHeight, string> = {
  full: 'h-full',
  fit: 'h-fit',
  md: 'h-64',
};

interface InputProps {
  label?: string;
  labelSize?: TextSize;
  labelColor?: TextColor;
  labelWeight?: TextWeight;
  value?: string;
  textSize?: TextSize;
  textColor?: TextColor;
  textPosition?: TextPosition;
  placeholder?: string;
  height?: InputHeight;
  disabled?: boolean;
  error?: string;
  errorSize?: TextSize;
  rows?: number;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  readonly?: boolean;
  isComplete?: boolean;
  watchLength?: number;
  isMandatory?: boolean;
  inputMode?: 'text' | 'email' | 'url' | 'tel' | 'numeric';
  uppercase?: boolean;
  onSubmit?: React.FormEventHandler<HTMLInputElement>;
  primaryColor?: string;
  secondaryColor?: string;
}

function Input(
  {
    label,
    labelSize = 'lg',
    labelColor = 'black',
    labelWeight = 'light',
    value,
    textSize = 'lg',
    textColor = 'black',
    textPosition = 'justify',
    placeholder,
    height = 'full',
    disabled = false,
    error,
    errorSize = 'xs',
    rows = 3,
    onChange,
    name,
    readonly,
    isComplete,
    watchLength,
    isMandatory = false,
    inputMode = 'text',
    uppercase = false,
    onSubmit,
    primaryColor,
    secondaryColor,
  }: InputProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);
  const { isLargeScreen, uiSettings } = useContext(disckoContext);

  return (
    <div
      className={concatClassNames('flex flex-col', isLargeScreen ?? false ? 'gap-1' : 'gap-2')}
      onFocus={() => {
        setIsHovered(true);
      }}
      onBlur={() => {
        setIsHovered(false);
      }}
    >
      {label !== undefined && (
        <div
          className={concatClassNames(
            'flex',
            isLargeScreen ?? false ? 'flex-row gap-2 items-center b-err' : 'flex-col items-start',
          )}
        >
          <div className="flex flex-row gap-2 items-center">
            <Text
              content={label}
              size={labelSize}
              color={primaryColor ?? 'black'}
              position="left"
              weight={isHovered ? 'bold' : labelWeight}
            />
            {isMandatory && <Text content="*" size={labelSize} color="#f04438" position="left" weight="light" />}
          </div>
          <div>
            {isMandatory && <Text content=" (Obligatoire)" size="sm" color="#f04438" position="left" weight="light" />}
            {!isMandatory && (
              <Text content=" (Facultatif)" size="sm" color={primaryColor ?? 'black'} position="left" weight="light" />
            )}
          </div>
        </div>
      )}
      <input
        className={concatClassNames(
          'border-b-2 ',
          'font-bold',
          textSizeStyle[textSize],
          inputHeightStyle[height],
          'py-2',
          'bg-white',
          // error === undefined ? 'border-black' : 'border-error-500',
          isComplete !== undefined && !isComplete ? 'border-orange-500' : 'border-black',
          textColorStyle[textColor],
          'placeholder:text-gray-300 placeholder: placeholder:font-light placeholder:italic',
          'resize-none',
          'focus:outline-none focus:ring-0 focus:border-b-4',
          'indent-4',
          uppercase ? 'uppercase' : '',
          textPositionStyle[textPosition],
        )}
        style={{ borderColor: error !== undefined ? '#f04438' : uiSettings?.primaryColor }}
        onKeyDown={(e) => {
          if (onSubmit === undefined) return;
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            onSubmit?.(e);
          }
        }}
        inputMode={inputMode}
        placeholder={placeholder !== undefined ? placeholder : ''}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        readOnly={readonly}
      />

      {error !== undefined && <Text content={error} position="left" size={errorSize} color="error-500" />}
      {watchLength !== undefined && (
        <MaxCharVisualizer maxCharVisualizer={500} watchLength={watchLength !== undefined ? watchLength : 0} />
      )}
    </div>
  );
}

export default forwardRef<HTMLInputElement, InputProps>(Input);
