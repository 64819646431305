import {
  FormOrchestratorApi,
  type FormOrchestratorResponseDto,
  type ReceiveQuestionsInputsDto,
  type SetupProjectInputsDto,
} from 'api';

export class FormOrchestratorService {
  private readonly openAiClient: FormOrchestratorApi;

  constructor() {
    this.openAiClient = new FormOrchestratorApi();
  }

  public async setupProject(
    companyId: string,
    {
      requirementId,
      step,
      projectDescription,
      clientMaturity,
      projectMaturity,
      deadlineCategory,
      deadlineDate,
      priceRange,
    }: SetupProjectInputsDto,
  ): Promise<FormOrchestratorResponseDto> {
    return this.openAiClient.formOrchestratorControllerSetupProject({
      setupProjectInputsDto: {
        clientMaturity,
        projectDescription,
        projectMaturity,
        requirementId,
        step,
        deadlineCategory,
        deadlineDate,
        priceRange,
      },
      companyId,
    });
  }

  public async sendQuestion(
    companyId: string,
    { questions, requirementId, step }: ReceiveQuestionsInputsDto,
  ): Promise<FormOrchestratorResponseDto> {
    return this.openAiClient.formOrchestratorControllerReceiveQuestions({
      receiveQuestionsInputsDto: {
        requirementId,
        step,
        questions,
      },
      companyId,
    });
  }
}
