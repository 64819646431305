/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoCompanyDto } from './DisckoCompanyDto';
import {
    DisckoCompanyDtoFromJSON,
    DisckoCompanyDtoFromJSONTyped,
    DisckoCompanyDtoToJSON,
} from './DisckoCompanyDto';

/**
 * 
 * @export
 * @interface GetGenericRequirementDto
 */
export interface GetGenericRequirementDto {
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetGenericRequirementDto
     */
    read: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementDto
     */
    projectType: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementDto
     */
    results: string;
    /**
     * 
     * @type {Date}
     * @memberof GetGenericRequirementDto
     */
    finishedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementDto
     */
    contact: string;
    /**
     * 
     * @type {string}
     * @memberof GetGenericRequirementDto
     */
    companyId: string;
    /**
     * 
     * @type {DisckoCompanyDto}
     * @memberof GetGenericRequirementDto
     */
    company: DisckoCompanyDto;
}

/**
 * Check if a given object implements the GetGenericRequirementDto interface.
 */
export function instanceOfGetGenericRequirementDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "read" in value;
    isInstance = isInstance && "projectType" in value;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "finishedAt" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "company" in value;

    return isInstance;
}

export function GetGenericRequirementDtoFromJSON(json: any): GetGenericRequirementDto {
    return GetGenericRequirementDtoFromJSONTyped(json, false);
}

export function GetGenericRequirementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGenericRequirementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'read': json['read'],
        'projectType': json['projectType'],
        'results': json['results'],
        'finishedAt': (new Date(json['finishedAt'])),
        'contact': json['contact'],
        'companyId': json['companyId'],
        'company': DisckoCompanyDtoFromJSON(json['company']),
    };
}

export function GetGenericRequirementDtoToJSON(value?: GetGenericRequirementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'read': value.read,
        'projectType': value.projectType,
        'results': value.results,
        'finishedAt': (value.finishedAt.toISOString()),
        'contact': value.contact,
        'companyId': value.companyId,
        'company': DisckoCompanyDtoToJSON(value.company),
    };
}

