/* eslint-disable no-control-regex */

/* eslint-disable prefer-regex-literals */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { type SetupProjectInputsDto } from 'api';
import * as Yup from 'yup';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import { type DisckoFormProps, type RequirementQuestions, disckoContext } from 'pages/RequirementForm/DisckoPage';

import RadioButtons from 'components/RadioButtons/RadioButtons';
import { RichText } from 'components/RichText/RichText';
import ScrollbarCustomizer from 'components/ScrollBarCustomizer/ScrollBarCustomizer';
import { Button } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Stepsbar } from 'components/core/StepsBar/Stepsbar';
import TextArea from 'components/core/TextArea/TextArea';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

/* -------------------------------------------------- interfaces -------------------------------------------------- */

type PriceRange = '0-10' | '10-20' | '20-50' | '50-100' | '100-300' | '300+' | 'abstain';

type DeadlineCategory = 'fixedDate' | 'flexible' | 'phases' | 'beforeEvent' | 'other';

interface Deadline {
  deadlineCategory?: DeadlineCategory;
  date?: string;
}

const projectMaturityOptions: string[] = ['IDEA', 'DESIGN', 'DEVELOPMENT', 'MAINTENANCE'];

const projectMaturityStyleOptions: string[] = [
  'Idée ou concept',
  'Besoin travaillé et clairement défini',
  'Projet déjà initié',
  "Reprise et amélioration d'un projet existant",
];

const projectMaturityOptionsMap: Map<string, string> = new Map<string, string>();
projectMaturityOptionsMap.set('IDEA', 'Besoin travaillé et clairement défini');
projectMaturityOptionsMap.set('DESIGN', 'Besoin travaillé et clairement défini');
projectMaturityOptionsMap.set('DEVELOPMENT', 'Projet déjà initié');
projectMaturityOptionsMap.set('MAINTENANCE', "Reprise et amélioration d'un projet existant");

const clientMaturityOptions: string[] = ['BEGINNER', 'INTERMEDIATE', 'EXPERT'];

const clientMaturityStyleOptions: string[] = ['Débutant', 'Intermédiaire', 'Avancé'];

const clientMaturityOptionsMap: Map<string, string> = new Map<string, string>();
clientMaturityOptionsMap.set('BEGINNER', 'Débutant');
clientMaturityOptionsMap.set('INTERMEDIATE', 'Intermédiaire');
clientMaturityOptionsMap.set('EXPERT', 'Avancé');

const priceRangeOptions: PriceRange[] = ['abstain', '0-10', '10-20', '20-50', '50-100', '100-300', '300+'];

const priceRangeStyleOptions: string[] = [
  'Je ne sais pas',
  'Moins de 10k€',
  '10 à 20k€',
  '20 à 50k€',
  '50 à 100k€',
  '100 à 300k€',
  'Plus de 300k€',
];

const priceRangeOptionsMap: Map<PriceRange, string> = new Map<PriceRange, string>();
priceRangeOptionsMap.set('abstain', 'Je ne sais pas');
priceRangeOptionsMap.set('0-10', 'Moins de 10k€');
priceRangeOptionsMap.set('10-20', '10 à 20k€');
priceRangeOptionsMap.set('20-50', '20 à 50k€');
priceRangeOptionsMap.set('50-100', '50 à 100k€');
priceRangeOptionsMap.set('100-300', '100 à 300k€');
priceRangeOptionsMap.set('300+', 'Plus de 300k€');

const deadlineCategoryOptions: DeadlineCategory[] = ['fixedDate', 'flexible', 'beforeEvent', 'phases', 'other'];

const deadlineCategoryStyleOptions: string[] = [
  'Période fixe',
  'Flexible',
  'Avant un événement important',
  'Plusieurs phases avec des jalons spécifiques',
  'Autre',
];

const deadlineCategoryOptionsMap: Map<DeadlineCategory, string> = new Map<DeadlineCategory, string>();
deadlineCategoryOptionsMap.set('fixedDate', 'Période fixe');
deadlineCategoryOptionsMap.set('flexible', 'Flexible');
deadlineCategoryOptionsMap.set('beforeEvent', 'Avant un événement important');
deadlineCategoryOptionsMap.set('phases', 'Plusieurs phases avec des jalons spécifiques');
deadlineCategoryOptionsMap.set('other', 'Autre');

const deadlineCategoryDatePlaceholderOptions: string[] = [
  "Ex : à l'automne prochain",
  '',
  'Ex: avant le salon du 08/01/2026',
  'Ex: Une première version dans les trois prochains mois, puis une version finale dans les six mois suivants',
  'Toute autre contrainte ou préférence de délai que vous pourriez avoir.',
];

interface DisckoForm1Model {
  projectDescription: string;
  clientMaturity?: string;
  priceRange?: PriceRange;
  projectMaturity?: string;
  deadline?: { deadlineCategory?: DeadlineCategory; date?: string };
}
const schema: Yup.ObjectSchema<DisckoForm1Model> = Yup.object().shape({
  projectDescription: Yup.string()
    .required('Champ obligatoire')
    .min(10, 'La description doit contenir au moins 10 caractères')
    .max(500, 'Le nom doit contenir au maximum 500 caractères'),

  // required only when askClientMaturity (in the context) is true
  clientMaturity: Yup.mixed<string>().test('isclientMaturity', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askClientMaturity)
      if (value == null || value === undefined || value.length === 0) return false;
    return true;
  }),
  // required only when askPriceRange (in the context) is true
  priceRange: Yup.mixed<PriceRange>().test('ispriceRange', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askPriceRange)
      if (value == null || value === undefined || value.length === 0) return false;
    return true;
  }),

  // required only when askProjectMaturity (in the context) is true
  projectMaturity: Yup.mixed<string>().test('isProjectMaturityRequired', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askProjectMaturity) {
      if (value == null || value === undefined || value.length === 0) return false;
    }
    return true;
  }),
  // required only when askDeadline (in the context) is true
  deadline: Yup.object({
    deadlineCategory: Yup.mixed<DeadlineCategory>().test(
      'isDeadlineRequired',
      'Champ obligatoire',
      (value, { options }) => {
        if (options.context?.requirementParams?.askDeadline) {
          if (value == null || value === undefined || value.length === 0) return false;
        }
        return true;
      },
    ),
    date: Yup.string().when(['deadline.deadlineCategory'], (deadlineCategory, schema) => {
      if (String(deadlineCategory) !== 'flexible') return schema.notRequired();
      return schema.required('Champ obligatoire');
    }),
  }).test('isDeadlineRequired', 'Champ obligatoire', (value, { options }) => {
    if (options.context?.requirementParams?.askDeadline) {
      if (value == null || value === undefined || value.deadlineCategory?.length === 0) return false;
    }
    return true;
  }),
});

interface MiniatureProps {
  primaryColor: string;
  secondaryColor: string;
  bgColor: string;
  isLargeScreen: boolean;
}

export function Miniature({ isLargeScreen, primaryColor, secondaryColor, bgColor }: MiniatureProps): JSX.Element {
  /* ---------------------------------------------------- Context --------------------------------------------------- */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [animation, setAnimation] = useState<'visible' | 'invisible'>('visible');

  const [nextMobileSteps, setNextMobileSteps] = useState<number[]>([1]);
  const [isAlertModalOpened, setIsAlertModalOpened] = useState<boolean>(false);
  const [swap, setSwap] = useState<boolean>(false);
  const [entering, setEntering] = useState<boolean>(true);
  const [requestHasError, setRequestHasError] = useState<boolean>(false);

  /* ------------------------------------------------ Form Management ----------------------------------------------- */

  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useForm<DisckoForm1Model>({
    context: {},
    resolver: yupResolver(schema, {}),
  });

  const watchProjectDescription: string = watch('projectDescription');

  const isFormValid: boolean = true;
  const isMobileFormValid: boolean = true;

  /* --------------------------------------------------- Rendering -------------------------------------------------- */

  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto scroll-container" style={{ background: bgColor }}>
      <ScrollbarCustomizer color={primaryColor} hoverColor={secondaryColor} />
      <Modal title="Attention" isOpen={isAlertModalOpened} setIsOpen={setIsAlertModalOpened}>
        <p>
          {
            "Nous n'avons pas compris votre besoin. Veuillez réessayer en expliquant bien votre objectif et la nature de votre projet."
          }
        </p>
      </Modal>
      <Modal isOpen={requestHasError}>
        <RichText
          fragments={[
            {
              content: "Malheureusement, la solution n'est pas disponible pour le moment.",
              contentType: 'p',
            },
            {
              content:
                'Vos coordonnées nous ont bien été transmises. Nous reviendrons vers vous avec une solution dans les plus brefs délais.',
              contentType: 'p',
            },
          ]}
        />
      </Modal>

      <div
        className={concatClassNames(
          'flex flex-col px-8 gap-6 overflow-auto',
          'transition all duration-700',
          swap ? 'opacity-0' : 'opacity-100',
          'flex-grow items-stretch',
          'justify-center',
        )}
      >
        {/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {isLargeScreen === true && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                // 'bg-white/70',
                'p-8 h-full overflow-y-auto',
                'rounded-lg',
              )}
            >
              <TextArea
                rows={3}
                isMandatory
                label="Quel projet souhaitez-vous nous confier ?"
                placeholder="Décrivez-le nous en quelques mots"
                watchLength={watchProjectDescription?.length ?? 0}
                {...register('projectDescription')}
                value={watchProjectDescription}
                error={errors.projectDescription != null ? errors.projectDescription.message : undefined}
                disabled={isLoading}
                testMode
                testPrimaryColor={primaryColor}
                testSecondaryColor={secondaryColor}
              />
            </div>
            <div className="flex flex-row gap-10 justify-around items-center ">
              <Stepsbar
                completionFrom="1/4"
                completionTo="1/2"
                animate
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
              ></Stepsbar>

              {!isLoading && (
                <Button
                  color={primaryColor}
                  content="Suivant"
                  width="lg"
                  type="submit"
                  disabled={!isFormValid}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              )}

              {isLoading && (
                <Button
                  iconName="spinCircle"
                  width="lg"
                  type="button"
                  iconAnimation="spin"
                  disabled
                  borderWidth="2"
                  borderColor="gray-50"
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
