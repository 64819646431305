/* eslint-disable @typescript-eslint/typedef */

/* eslint-disable @typescript-eslint/explicit-function-return-type */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */

/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import {
  type FormOrchestratorQuestionAnswerDto,
  type FormOrchestratorResponseDto,
  type ReceiveQuestionsInputsDto,
} from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { RichText } from 'components/RichText/RichText';
import { Button, getTextColor } from 'components/core/Button/Button';
import { Modal } from 'components/core/Modal/Modal';
import { type Completion, Stepsbar } from 'components/core/StepsBar/Stepsbar';
import TextArea from 'components/core/TextArea/TextArea';

import concatClassNames from 'utils/classNames';
import { convertStringToSynthesis } from 'utils/convertStringToSynthesis';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

import { type DisckoFormProps, type Question, type RequirementSynthesis, Step, disckoContext } from './DisckoPage';

interface DisckoFormQuestionsModel {
  answers: Array<{ answer: string }>;
}

export function DisckoFormQuestions({ setNextStep }: DisckoFormProps): JSX.Element {
  const {
    openAiQuestionsFormToDisplay,
    sendQuestions,
    isLargeScreen,
    contextRequirement,
    setSynthesis: setContextRequirement,
    uiSettings,
  } = useContext(disckoContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mobileStep, setMobileStep] = useState<number>(1);
  const [swap, setSwap] = useState<boolean>(false);
  const [entering, setEntering] = useState<boolean>(true);
  const [animation, setAnimation] = useState<'visible' | 'invisible'>('visible');
  const [requestHasError, setRequestHasError] = useState<boolean>(false);
  const [numberQuestionsLeftToAsk, setNumberQuestionsLeftToAsk] = useState<number | undefined>(
    uiSettings?.totalNumberOfQuestions,
  );

  const [openAiInputs, setFormOrchestratorInputs] = useState<ReceiveQuestionsInputsDto>({
    questions: [],
    requirementId: '',
    step: '',
  });
  const [sendFom, setSendForm] = useState<boolean>(false);

  const { data, status, isRefetching } = useQuery({
    queryKey: ['openai', 'sendQuestion'],
    queryFn: async () => {
      setSendForm(false);

      return await sendQuestions(openAiInputs);
    },
    enabled: sendFom,
    cacheTime: 0,
  });

  const [tempQuestions, setTempQuestions] = useState<Question[]>([]);

  useEffect(() => {
    if (status === 'error') {
      setRequestHasError(true);
      setIsLoading(false);
    }
    if (status !== 'success' || (isRefetching !== undefined && isRefetching)) return;

    const openAiResponse: FormOrchestratorResponseDto = data;
    // messages.push(openAiResponse.message);
    const cleanedFormOrchestratorResponse: Question[] = convertStringToQuestions(openAiResponse.message);
    if (openAiResponse.step === Step.COMMENT) {
      const cleanedRequirement: RequirementSynthesis = convertStringToSynthesis(
        openAiResponse.message,
        contextRequirement,
      );
      setContextRequirement(cleanedRequirement);
    }
    // Set the questions in temp mode to keep the previous before the exit animation ends
    setTempQuestions(cleanedFormOrchestratorResponse);
    setIsLoading(false);
    setSwap(true);

    delay(700)
      .then(() => {
        if (setNextStep != null) setNextStep(data.step);
        setNumberQuestionsLeftToAsk((prev) => (prev !== undefined ? prev - 3 : 0));
        return true;
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
      });
  }, [status, data, isRefetching]);

  const { register, watch, handleSubmit, setValue, reset } = useForm<DisckoFormQuestionsModel>({});

  useEffect(() => {
    if (swap) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      delay(700)
        // eslint-disable-next-line promise/always-return
        .then(() => {
          // Set the new questions after the enter animation starts
          openAiQuestionsFormToDisplay.splice(0, openAiQuestionsFormToDisplay.length);
          openAiQuestionsFormToDisplay.push(...tempQuestions);
          reset();
          setSwap(false);
          setMobileStep(1);
          setAnimation('visible');
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
        });
    }
  }, [swap]);

  const watchArray = watch('answers');

  let isFormValid: boolean = true;

  let isMobileFormValid: boolean = true;

  // For each question, check if the mandatory questions are filled
  watchArray?.forEach((answer, index) => {
    if (
      answer.answer === '' &&
      openAiQuestionsFormToDisplay[index] !== undefined &&
      openAiQuestionsFormToDisplay[index].mandatory
    ) {
      isFormValid = false;
    }
  });

  // For each mobile step, check if the mandatory questions are filled
  watchArray?.forEach((answer, index) => {
    if (
      mobileStep > index &&
      openAiQuestionsFormToDisplay[index].mandatory &&
      (answer.answer === undefined || answer.answer === '')
    ) {
      isMobileFormValid = false;
    }
  });

  async function openAICall(data: any): Promise<void> {
    setIsLoading(true);

    const questionsToSave: FormOrchestratorQuestionAnswerDto[] = [];
    openAiQuestionsFormToDisplay.forEach((question: Question, index: number) => {
      questionsToSave.push({
        question: question.question,
        answer: data.answers[index].answer,
        propositions: question.placeholder,
      });
    });
    setFormOrchestratorInputs({
      questions: questionsToSave,
      requirementId: '',
      step: '',
    });
    setSendForm(true);
  }

  function calculationCompletionFrom(
    totalNumberOfQuestions: number | undefined,
    numberQuestionsLeftToAsk: number | undefined,
  ): Completion {
    switch (totalNumberOfQuestions) {
      case 6:
        switch (numberQuestionsLeftToAsk) {
          case 6:
            return '1/2';
          case 3:
            return '3/4';
          default:
            return '1/2';
        }
      case 12:
        switch (numberQuestionsLeftToAsk) {
          case 12:
            return '1/3';
          case 9:
            return '1/2';
          case 6:
            return '2/3';
          case 3:
            return '5/6';
          default:
            return '1/3';
        }
      case 18:
        switch (numberQuestionsLeftToAsk) {
          case 18:
            return '1/4';
          case 15:
            return '3/8';
          case 12:
            return '1/2';
          case 9:
            return '5/8';
          case 6:
            return '3/4';
          case 3:
            return '7/8';
          default:
            return '1/4';
        }
      case 24:
        switch (numberQuestionsLeftToAsk) {
          case 24:
            return '1/5';
          case 21:
            return '3/10';
          case 18:
            return '2/5';
          case 15:
            return '1/2';
          case 12:
            return '3/5';
          case 9:
            return '7/10';
          case 6:
            return '4/5';
          case 3:
            return '9/10';
          default:
            return '1/5';
        }
      default:
        return '0%';
    }
  }

  function calculationCompletionTo(
    totalNumberOfQuestions: number | undefined,
    numberQuestionsLeftToAsk: number | undefined,
  ): Completion {
    switch (totalNumberOfQuestions) {
      case 6:
        switch (numberQuestionsLeftToAsk) {
          case 6:
            return '3/4';
          case 3:
            return '100%';
          default:
            return '3/4';
        }
      case 12:
        switch (numberQuestionsLeftToAsk) {
          case 9:
            return '2/3';
          case 6:
            return '5/6';
          case 3:
            return '100%';
          default:
            return '1/2';
        }
      case 18:
        switch (numberQuestionsLeftToAsk) {
          case 18:
            return '3/8';
          case 15:
            return '1/2';
          case 12:
            return '5/8';
          case 9:
            return '3/4';
          case 6:
            return '7/8';
          case 3:
            return '100%';
          default:
            return '3/8';
        }
      case 24:
        switch (numberQuestionsLeftToAsk) {
          case 24:
            return '3/10';
          case 21:
            return '2/5';
          case 18:
            return '1/2';
          case 15:
            return '3/5';
          case 12:
            return '7/10';
          case 9:
            return '4/5';
          case 6:
            return '9/10';
          case 3:
            return '100%';
          default:
            return '3/10';
        }
      default:
        return '0%';
    }
  }

  /* ------------------------------------------ Go to the top mobile steps ------------------------------------------ */

  const formZoneRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (formZoneRef.current !== null) {
      formZoneRef.current.scrollTop = 0;
    }
  }, [mobileStep]);

  useEffect(() => {
    if (entering && document.getElementById('form2') != null) {
      setEntering(false);
    }
  });

  // TODO : A factoriser
  function convertStringToQuestions(response: any): Question[] {
    if (!response.questions) {
      return [];
    }

    return response.questions.map((question, index) => ({
      index,
      question: question.question,
      placeholder: question.examples,
    }));
  }
  const onSubmitForm: SubmitHandler<DisckoFormQuestionsModel> = async (data) => {
    let isFormValid: boolean = true;
    // check if the x first mandatory questions are filled
    data.answers.forEach((answer, index) => {
      if (openAiQuestionsFormToDisplay?.[index].mandatory === true && answer.answer === '') {
        isFormValid = false;
      }
    });
    if (isFormValid) await openAICall(data);
  };

  const placeholder: string = 'Cliquez sur les suggestions pour les ajouter et complétez votre réponse ici';
  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto" style={{ background: uiSettings?.bgColor }}>
      <Modal isOpen={requestHasError}>
        <RichText
          fragments={[
            {
              content: "Malheureusement, la solution n'est pas disponible pour le moment.",
              contentType: 'p',
            },
            {
              content:
                'Vos coordonnées nous ont bien été transmises. Nous reviendrons vers vous avec une solution dans les plus brefs délais.',
              contentType: 'p',
            },
          ]}
        ></RichText>
      </Modal>

      <br />

      <form
        id="form2"
        className={concatClassNames(
          'flex flex-col gap-6 px-8 overflow-auto',
          'transition all duration-700',
          swap || entering ? 'opacity-0' : 'opacity-100',
          'flex-grow items-stretch',
          'justify-center',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {isLargeScreen === true && (
          <>
            <div className={concatClassNames(' p-8 rounded-lg h-full flex flex-col gap-6 overflow-auto')}>
              <div className="flex flex-col gap-6">
                {openAiQuestionsFormToDisplay.map((question, index) => {
                  return (
                    <TextArea
                      key={index}
                      label={question.question}
                      rows={2}
                      propositions={question.placeholder}
                      placeholder={placeholder}
                      {...register(`answers.${index}.answer`)}
                      setValue={setValue}
                      watchValue={watchArray?.[index]?.answer}
                      isMandatory={question.mandatory}
                      disabled={isLoading}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex flex-row gap-10 justify-around items-center ">
              <Stepsbar
                completionFrom={calculationCompletionFrom(uiSettings?.totalNumberOfQuestions, numberQuestionsLeftToAsk)}
                completionTo={calculationCompletionTo(uiSettings?.totalNumberOfQuestions, numberQuestionsLeftToAsk)}
                animate
                primaryColor={uiSettings?.primaryColor ?? '#000000'}
                secondaryColor={uiSettings?.secondaryColor ?? '#DBDBDB'}
              ></Stepsbar>
              {!isLoading && (
                <Button
                  color={uiSettings?.primaryColor}
                  content="Suivant"
                  width="lg"
                  type="submit"
                  disabled={!isFormValid}
                  borderColor={!isFormValid ? 'gray-50' : 'black'}
                  borderWidth="2"
                  borderWidthOnHover="2"
                  borderColorOnHover="black"
                />
              )}
              {isLoading && (
                <Button
                  iconName="spinCircle"
                  width="lg"
                  type="button"
                  height="sm"
                  iconAnimation="spin"
                  disabled
                  borderWidth="2"
                />
              )}
            </div>
          </>
        )}

        {isLargeScreen === false && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                'px-8 py-4 overflow-auto',
                'rounded-lg',
                'transition all duration-700',
                animation === 'invisible' ? 'opacity-0' : 'opacity-100',
              )}
            >
              <div ref={formZoneRef} className="flex flex-col gap-10 overflow-auto">
                {openAiQuestionsFormToDisplay.map((question, index) => {
                  return (
                    <React.Fragment key={index}>
                      {mobileStep === index + 1 && (
                        <TextArea
                          key={index}
                          label={question.question}
                          rows={8}
                          propositions={question.placeholder}
                          placeholder={placeholder}
                          {...register(`answers.${index}.answer`)}
                          setValue={setValue}
                          watchValue={watchArray?.[index]?.answer}
                          isMandatory={question.mandatory}
                          disabled={isLoading}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
              {isMobileFormValid && !isLoading && mobileStep < openAiQuestionsFormToDisplay.length && (
                <div
                  className="flex justify-end"
                  onClick={() => {
                    setAnimation('invisible');
                    delay(700)
                      .then(() => {
                        setMobileStep(mobileStep + 1);
                        setAnimation('visible');
                        return true;
                      })
                      .catch((error) => {
                        console.error(error);
                        setIsLoading(false);
                        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
                      });
                  }}
                >
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </div>
              )}
              {isMobileFormValid && !isLoading && mobileStep === openAiQuestionsFormToDisplay.length && (
                <button className="flex justify-end" type="submit" disabled={!isFormValid}>
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </button>
              )}
              {!isMobileFormValid && !isLoading && (
                <div className="flex justify-end">
                  {getIcon('arrowRight', uiSettings?.secondaryColor ?? '#b6b6b6', 'lg')}
                </div>
              )}
              {isLoading && (
                <div className={concatClassNames('flex justify-end')}>
                  <div className="h-fit w-fit animate-spin">
                    {getIcon('spinCircle', getTextColor(uiSettings?.secondaryColor ?? '#929292', true), 'lg')}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
