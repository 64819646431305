/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCommentDto,
  ErrorBadRequest,
  GetGenericRequirementContactDto,
  GetGenericRequirementDto,
  GetGenericRequirementIdParamsDto,
  GetGenericRequirementParametersDto,
  GetGenericRequirementsDto,
} from '../models';
import {
    AddCommentDtoFromJSON,
    AddCommentDtoToJSON,
    ErrorBadRequestFromJSON,
    ErrorBadRequestToJSON,
    GetGenericRequirementContactDtoFromJSON,
    GetGenericRequirementContactDtoToJSON,
    GetGenericRequirementDtoFromJSON,
    GetGenericRequirementDtoToJSON,
    GetGenericRequirementIdParamsDtoFromJSON,
    GetGenericRequirementIdParamsDtoToJSON,
    GetGenericRequirementParametersDtoFromJSON,
    GetGenericRequirementParametersDtoToJSON,
    GetGenericRequirementsDtoFromJSON,
    GetGenericRequirementsDtoToJSON,
} from '../models';

export interface AuthRequirementControllerFindByIdRequest {
    id: string;
}

export interface AuthRequirementControllerReadRequest {
    id: string;
}

export interface FormGenericRequirementControllerAddCommentRequest {
    id: string;
    companyId: string;
    addCommentDto: AddCommentDto;
}

export interface FormGenericRequirementControllerGetFormParamsRequest {
    companyId: string;
}

export interface FormGenericRequirementControllerInitRequirementRequest {
    companyId: string;
    getGenericRequirementContactDto: GetGenericRequirementContactDto;
}

/**
 * 
 */
export class GenericRequirementsApi extends runtime.BaseAPI {

    /**
     */
    async authRequirementControllerFindAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/generic-requirements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementsDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerFindAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementsDto> {
        const response = await this.authRequirementControllerFindAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequirementControllerFindByIdRaw(requestParameters: AuthRequirementControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authRequirementControllerFindById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/generic-requirements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerFindById(requestParameters: AuthRequirementControllerFindByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementDto> {
        const response = await this.authRequirementControllerFindByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authRequirementControllerReadRaw(requestParameters: AuthRequirementControllerReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authRequirementControllerRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", []);
        }

        const response = await this.request({
            path: `/generic-requirements/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async authRequirementControllerRead(requestParameters: AuthRequirementControllerReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementDto> {
        const response = await this.authRequirementControllerReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGenericRequirementControllerAddCommentRaw(requestParameters: FormGenericRequirementControllerAddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling formGenericRequirementControllerAddComment.');
        }

        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formGenericRequirementControllerAddComment.');
        }

        if (requestParameters.addCommentDto === null || requestParameters.addCommentDto === undefined) {
            throw new runtime.RequiredError('addCommentDto','Required parameter requestParameters.addCommentDto was null or undefined when calling formGenericRequirementControllerAddComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/generic-requirements/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddCommentDtoToJSON(requestParameters.addCommentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementDtoFromJSON(jsonValue));
    }

    /**
     */
    async formGenericRequirementControllerAddComment(requestParameters: FormGenericRequirementControllerAddCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementDto> {
        const response = await this.formGenericRequirementControllerAddCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGenericRequirementControllerGetFormParamsRaw(requestParameters: FormGenericRequirementControllerGetFormParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementParametersDto>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formGenericRequirementControllerGetFormParams.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/generic-requirements/params`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementParametersDtoFromJSON(jsonValue));
    }

    /**
     */
    async formGenericRequirementControllerGetFormParams(requestParameters: FormGenericRequirementControllerGetFormParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementParametersDto> {
        const response = await this.formGenericRequirementControllerGetFormParamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGenericRequirementControllerInitRequirementRaw(requestParameters: FormGenericRequirementControllerInitRequirementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetGenericRequirementIdParamsDto>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling formGenericRequirementControllerInitRequirement.');
        }

        if (requestParameters.getGenericRequirementContactDto === null || requestParameters.getGenericRequirementContactDto === undefined) {
            throw new runtime.RequiredError('getGenericRequirementContactDto','Required parameter requestParameters.getGenericRequirementContactDto was null or undefined when calling formGenericRequirementControllerInitRequirement.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/generic-requirements/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetGenericRequirementContactDtoToJSON(requestParameters.getGenericRequirementContactDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGenericRequirementIdParamsDtoFromJSON(jsonValue));
    }

    /**
     */
    async formGenericRequirementControllerInitRequirement(requestParameters: FormGenericRequirementControllerInitRequirementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetGenericRequirementIdParamsDto> {
        const response = await this.formGenericRequirementControllerInitRequirementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
