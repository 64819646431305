/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type GetDisckoCompanyDto, type UpdateGenericRequirementParamsDto } from 'api';
import { color } from 'framer-motion';
import * as Yup from 'yup';
import { z } from 'zod';

import React, { useContext, useEffect, useState } from 'react';
import { BlockPicker, ChromePicker, type ColorResult, SketchPicker } from 'react-color';
import { Controller, type SubmitHandler, set, useForm } from 'react-hook-form';
import { type QueryClient, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@material-tailwind/react';

import BackOfficeRadioButtons from 'components/RadioButtons/BackOfficeRadioButtons';
import { MultiSelectRadioButtons } from 'components/RadioButtons/MultiSelectRadioButtons';
import RadioButtons from 'components/RadioButtons/RadioButtons';
import { Button } from 'components/core/Button/Button';
import { Chip, type ChipBorderColor } from 'components/core/Chip/Chip';
import { type ChipBgColor } from 'components/core/Chip/Chip.props';
import { H2Title } from 'components/core/H2Title';
import { H3Title } from 'components/core/H3Title';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Text } from 'components/core/Text/Text';
import TextArea from 'components/core/TextArea/TextArea';
import TextArea_backoffice from 'components/core/TextArea/TextArea_backoffice';

import concatClassNames from 'utils/classNames';
import getOptionChip from 'utils/getBetaChip';
import getIcon from 'utils/getIcon';

import { Miniature } from './Miniature';

interface CustomizationFormModel {
  formQuestions: string[];
  urlRedirect: string | undefined;
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  withdrawWatermark: boolean;
  activityDescription: string;
  contactTypePerson: boolean;
  contactTypeCompany: boolean;
  isContactLastnameRequired: boolean;
  isContactFirstnameRequired: boolean;
  isContactLocationRequired: boolean;
  isContactEmailRequired: boolean;
  isContactPhoneRequired: boolean;
  isCompanyNameRequired: boolean;
  isCompanyLocationRequired: boolean;
  isCompanyWebsiteRequired: boolean;
  isContactLinkedinProfileRequired: boolean;
  isContactJobPositionRequired: boolean;
  totalNumberOfQuestions: number;
  synthesisItems: Array<{ name: string; id: string; description: string }>;
}

const schema: Yup.ObjectSchema<CustomizationFormModel> = Yup.object().shape({
  formQuestions: Yup.array().required('Champ obligatoire'),
  urlRedirect: Yup.string()
    .test('isRedirectUrlNeeded', 'Champ obligatoire', (value, { options }) => {
      if (options.context?.isRedirectionActivated)
        if (value == null || value === undefined || value.length === 0) return false;
      return true;
    })
    .url('URL invalide. Avez-vous pensé à préciser le protocole (https://)?'),
  primaryColor: Yup.string().required('Champ obligatoire'),
  secondaryColor: Yup.string().required('Champ obligatoire'),
  backgroundColor: Yup.string().required('Champ obligatoire'),
  withdrawWatermark: Yup.boolean().required('Champ obligatoire'),
  activityDescription: Yup.string().required('Champ obligatoire'),
  contactTypePerson: Yup.boolean().required('Champ obligatoire'),
  contactTypeCompany: Yup.boolean().required('Champ obligatoire'),
  isContactLastnameRequired: Yup.boolean().required('Champ obligatoire'),
  isContactFirstnameRequired: Yup.boolean().required('Champ obligatoire'),
  isContactLocationRequired: Yup.boolean().required('Champ obligatoire'),
  isContactEmailRequired: Yup.boolean().required('Champ obligatoire'),
  isContactPhoneRequired: Yup.boolean().required('Champ obligatoire'),
  isCompanyNameRequired: Yup.boolean().required('Champ obligatoire'),
  isCompanyLocationRequired: Yup.boolean().required('Champ obligatoire'),
  isCompanyWebsiteRequired: Yup.boolean().required('Champ obligatoire'),

  isContactLinkedinProfileRequired: Yup.boolean().required('Champ obligatoire'),
  isContactJobPositionRequired: Yup.boolean().required('Champ obligatoire'),
  totalNumberOfQuestions: Yup.number().required('Champ obligatoire'),
  synthesisItems: Yup.array().required('Champ obligatoire'),
});

interface FormCustomizationManagementProps {
  companyData: GetDisckoCompanyDto;
  onUpgrade: () => void;
  onDirtyChange: (isDirty: boolean) => void;
}

export function FormCustomizationManagement({
  companyData,
  onUpgrade,
  onDirtyChange,
}: FormCustomizationManagementProps): JSX.Element {
  const [hasPremium, setHasPremium] = useState<boolean>(false);

  /* ------------------------------------------ Controls the checkbox state ----------------------------------------- */

  const [isRedirectionActivated, setIsRedirectionActivated] = useState<boolean>(false);

  /* ----------------------------------------------------- Form ----------------------------------------------------- */

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors, isSubmitted, isDirty },
  } = useForm<CustomizationFormModel>({
    context: {
      isRedirectionActivated,
    },
    resolver: yupResolver(schema),
    defaultValues: {
      contactTypeCompany: ['COMPANY', 'BOTH'].includes(companyData.contactType),
      contactTypePerson: ['PERSON', 'BOTH'].includes(companyData.contactType),
      isContactLastnameRequired: companyData.isContactLastnameRequired,
      isContactFirstnameRequired: companyData.isContactFirstnameRequired,
      isContactLocationRequired: companyData.isContactLocationRequired,
      isContactEmailRequired: companyData.isContactEmailRequired,
      isContactPhoneRequired: companyData.isContactPhoneRequired,
      isCompanyNameRequired: companyData.isCompanyNameRequired,
      isCompanyLocationRequired: companyData.isCompanyLocationRequired,
      isCompanyWebsiteRequired: companyData.isCompanyWebsiteRequired,
      isContactLinkedinProfileRequired: companyData.isContactLinkedinProfileRequired,
      isContactJobPositionRequired: companyData.isContactJobPositionRequired,
      totalNumberOfQuestions: companyData.totalNumberOfQuestions,
      withdrawWatermark: companyData.watermark,
      synthesisItems: (companyData.synthesisItems as any as CustomizationFormModel['synthesisItems']) ?? [],
      primaryColor: companyData.primaryColor,
      secondaryColor: companyData.secondaryColor,
      backgroundColor: companyData.bgColor,
      urlRedirect: companyData.urlRedirect,
      activityDescription: companyData.activityDescription,
      formQuestions: [],
    },
  });

  const { requirementParametersService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);

  const watchFormQuestions: string[] = watch('formQuestions');
  const watchUrlRedirect: string | undefined = watch('urlRedirect');
  const watchBackgroundColor: string = watch('backgroundColor');
  const watchPrimaryColor: string = watch('primaryColor');
  const watchSecondaryColor: string = watch('secondaryColor');
  const watchActivityDescription: string = watch('activityDescription');

  // const watchSetupRedirection: boolean = watch('setupRedirection');

  const [originalUrlRedirectActivated, setOriginalUrlRedirectActivated] = useState<boolean>(false);
  const [originalFormQuestions, setOriginalFormQuestions] = useState<string[]>([]);
  const [originalUrlRedirect, setOriginalUrlRedirect] = useState<string | undefined>('');
  const [originalBackgroundColor, setOriginalBackgroundColor] = useState<string>('#ffffff');
  const [originalPrimaryColor, setOriginalPrimaryColor] = useState<string>('#000000');
  const [originalSecondaryColor, setOriginalSecondaryColor] = useState<string>('#dbdbdb');
  const [originalActivityDescription, setOriginalActivityDescription] = useState<string>('');

  // const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  // const [primaryColor, setPrimaryColor] = useState('#000000');
  // const [secondaryColor, setSecondaryColor] = useState('#dbdbdb');

  /* --------------------------------------- handle leave page without saving --------------------------------------- */

  useEffect(() => {
    onDirtyChange(isDirty);
  }, [isDirty]);
  /* -------------------------------- The first time, set values with the companyData ------------------------------- */
  useEffect(() => {
    if (companyData !== undefined) {
      const formQuestions: string[] = [];

      setValue('formQuestions', formQuestions);

      if (companyData.urlRedirect !== undefined && companyData.urlRedirect !== '') {
        setValue('urlRedirect', companyData.urlRedirect);
        setIsRedirectionActivated(true);
        setOriginalUrlRedirectActivated(true);
      } else {
        setIsRedirectionActivated(false);
      }
      setValue('backgroundColor', companyData.bgColor);
      setValue('primaryColor', companyData.primaryColor);
      setValue('secondaryColor', companyData.secondaryColor);
      setValue('activityDescription', companyData.activityDescription);

      // Save the original values
      setOriginalUrlRedirect(companyData.urlRedirect);
      setOriginalFormQuestions(formQuestions);
      setOriginalBackgroundColor(companyData.bgColor);
      setOriginalPrimaryColor(companyData.primaryColor);
      setOriginalSecondaryColor(companyData.secondaryColor);
      setOriginalActivityDescription(companyData.activityDescription);
    }
  }, [
    companyData,
    companyData.urlRedirect,
    companyData.bgColor,
    companyData.primaryColor,
    companyData.secondaryColor,
    companyData.activityDescription,
    setValue,
  ]);

  /* ----------------------------- Initialize state from company data on component mount ---------------------------- */
  useEffect(() => {
    if (companyData !== undefined) {
      setIsRedirectionActivated(!!companyData.urlRedirect);
      setValue('urlRedirect', companyData.urlRedirect); // Définit l'URL ou une chaîne vide
    }
  }, [companyData, setValue]);

  /* -------------------------------------------- Desktop or mobile view -------------------------------------------- */

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  /* ----------------------------------------------- Check form valid ----------------------------------------------- */

  let isFormValid: boolean = false;

  // Check if the form is valid, form is invalid if the checkbox is checked but the url is empty
  if (
    (watchUrlRedirect === undefined && isRedirectionActivated) ||
    (watchUrlRedirect === '' && isRedirectionActivated) ||
    watch(['contactTypePerson', 'contactTypeCompany']).filter((value) => !!value).length === 0 ||
    watch([
      'isContactFirstnameRequired',
      'isContactLastnameRequired',
      'isContactEmailRequired',
      'isContactPhoneRequired',
      'isContactLocationRequired',
      'isContactLinkedinProfileRequired',
      'isContactJobPositionRequired',
      'isCompanyNameRequired',
      'isCompanyLocationRequired',
      'isCompanyWebsiteRequired',
    ]).filter((value) => !!value).length < 2 ||
    watch('synthesisItems').length < 3 ||
    (watch('totalNumberOfQuestions') === 6 && watch('synthesisItems').length > 3) ||
    (watch('totalNumberOfQuestions') === 12 && watch('synthesisItems').length > 6)
  ) {
    isFormValid = false;
  } else {
    isFormValid = true;
  }

  /* ------------------------------------- Submit form / open confirmation modal ------------------------------------ */

  const onSubmitForm: SubmitHandler<CustomizationFormModel> = async (data) => {
    setPrimaryColorPickerOpen(false);
    setSecondaryColorPickerOpen(false);
    setBackgroundColorPickerOpen(false);
    setIsConfirmationModalOpen(true);
  };

  /* --------------------------------------------------- Save Data -------------------------------------------------- */

  async function saveRequirmentsParameters(): Promise<UpdateGenericRequirementParamsDto> {
    const accessToken: string = await getAccessTokenSilently();
    if (requirementParametersService === undefined) {
      throw new Error('requirementParametersService is undefined');
    }

    const data = watch();

    return await requirementParametersService.saveRequirementParameters({
      accessToken,
      updateGenericRequirementParamsDto: {
        urlRedirect: isRedirectionActivated ? watchUrlRedirect : '',
        bgColor: watchBackgroundColor,
        primaryColor: watchPrimaryColor,
        secondaryColor: watchSecondaryColor,
        activityDescription: watchActivityDescription,
        businessType: 'SERVICES',
        contactType:
          data.contactTypeCompany && data.contactTypePerson ? 'BOTH' : data.contactTypeCompany ? 'COMPANY' : 'PERSON',
        isCompanyLocationRequired: data.isCompanyLocationRequired,
        isCompanyNameRequired: data.isCompanyNameRequired,
        isCompanyWebsiteRequired: data.isCompanyWebsiteRequired,
        isContactEmailRequired: data.isContactEmailRequired,
        isContactFirstnameRequired: data.isContactFirstnameRequired,
        isContactLastnameRequired: data.isContactLastnameRequired,
        isContactLocationRequired: data.isContactLocationRequired,
        isContactJobPositionRequired: data.isContactJobPositionRequired,
        isContactLinkedinProfileRequired: data.isContactLinkedinProfileRequired,
        isContactPhoneRequired: data.isContactPhoneRequired,
        totalNumberOfQuestions: data.totalNumberOfQuestions,
        urlWebhook: '',
        synthesisItems: JSON.stringify(data.synthesisItems),
        watermark: data.withdrawWatermark,
      },
    });
  }

  const { mutate: mutateSaveRequirmentsParameters, isLoading } = useMutation(saveRequirmentsParameters, {
    onSuccess: async (savedRequirementsParameters: UpdateGenericRequirementParamsDto) => {
      setIsConfirmationModalOpen(false);
      toast.success('Les modifications ont bien été appliquées');
      reset({
        activityDescription: savedRequirementsParameters.activityDescription,
        backgroundColor: savedRequirementsParameters.bgColor,
        primaryColor: savedRequirementsParameters.primaryColor,
        secondaryColor: savedRequirementsParameters.secondaryColor,
        contactTypeCompany: ['COMPANY', 'BOTH'].includes(savedRequirementsParameters.contactType),
        contactTypePerson: ['PERSON', 'BOTH'].includes(savedRequirementsParameters.contactType),
        isContactLastnameRequired: savedRequirementsParameters.isContactLastnameRequired,
        isContactFirstnameRequired: savedRequirementsParameters.isContactFirstnameRequired,
        isContactLocationRequired: savedRequirementsParameters.isContactLocationRequired,
        isContactEmailRequired: savedRequirementsParameters.isContactEmailRequired,
        isContactPhoneRequired: savedRequirementsParameters.isContactPhoneRequired,
        isCompanyNameRequired: savedRequirementsParameters.isCompanyNameRequired,
        isCompanyLocationRequired: savedRequirementsParameters.isCompanyLocationRequired,
        isCompanyWebsiteRequired: savedRequirementsParameters.isCompanyWebsiteRequired,
        isContactJobPositionRequired: savedRequirementsParameters.isContactJobPositionRequired,
        isContactLinkedinProfileRequired: savedRequirementsParameters.isContactLinkedinProfileRequired,
        totalNumberOfQuestions: savedRequirementsParameters.totalNumberOfQuestions,
        withdrawWatermark: savedRequirementsParameters.watermark,
        synthesisItems: JSON.parse(savedRequirementsParameters.synthesisItems),
      });

      queryClient.refetchQueries(['company', user?.sub]).catch(console.warn);
      return savedRequirementsParameters;
    },
    onError: () => {
      setIsConfirmationModalOpen(false);
      toast.error("Une erreur est survenue lors de l'enregistrement des modifications");
    },
  });

  /* ----------------------------------------------- Manage scrolling ----------------------------------------------- */

  // useEffect(() => {
  //   if (isRedirectionActivated) {
  //     const element = document.getElementById('urlRedirectInput');
  //     element?.scrollIntoView();
  //   }
  // }, [isRedirectionActivated]);

  /* ------------------------------------------------ color Picker ------------------------------------------------- */

  const [backgroundColorPickerOpen, setBackgroundColorPickerOpen] = useState<boolean>(false);
  const [primaryColorPickerOpen, setPrimaryColorPickerOpen] = useState<boolean>(false);
  const [secondaryColorPickerOpen, setSecondaryColorPickerOpen] = useState<boolean>(false);

  /* -------------------------------------------------- Get categories ------------------------------------------------ */

  const { data: categories } = z
    .array(
      z.object({
        name: z.string().describe('maximum 25 caractères'),
        description: z.string(),
        elements: z.string(),
        color: z.string(),
      }),
    )
    .safeParse(companyData.requirementCategories);

  const contactFields = [
    {
      key: 'isContactLastnameRequired',
      dependsOn: ['contactTypePerson', 'contactTypeCompany'],
      name: 'Nom du contact',
    },
    {
      key: 'isContactFirstnameRequired',
      dependsOn: ['contactTypePerson', 'contactTypeCompany'],
      name: 'Prénom du contact',
    },
    {
      key: 'isContactLocationRequired',
      dependsOn: ['contactTypePerson'],
      name: 'Adresse postale du contact',
    },
    {
      key: 'isContactEmailRequired',
      dependsOn: ['contactTypePerson', 'contactTypeCompany'],
      name: 'Email du contact',
    },
    {
      key: 'isContactPhoneRequired',
      dependsOn: ['contactTypePerson', 'contactTypeCompany'],
      name: 'Téléphone du contact',
    },
    { key: 'isCompanyNameRequired', dependsOn: ['contactTypeCompany'], name: "Nom de l'entreprise" },
    {
      key: 'isCompanyLocationRequired',
      dependsOn: ['contactTypeCompany'],
      name: "Adresse postale de l'entreprise",
    },
    {
      key: 'isCompanyWebsiteRequired',
      dependsOn: ['contactTypeCompany'],
      name: "Site web de l'entreprise",
    },
    {
      key: 'isContactJobPositionRequired',
      dependsOn: ['contactTypeCompany'],
      name: 'Poste du contact',
    },
    {
      key: 'isContactLinkedinProfileRequired',
      dependsOn: ['contactTypeCompany'],
      name: 'Profil Linkedin du contact',
    },
  ] as const;

  useEffect(() => {
    for (const contactField of contactFields) {
      if (contactField.dependsOn.map(watch).every((value) => !value)) {
        setValue(contactField.key, false);
      }
    }
  }, [watch('contactTypePerson'), watch('contactTypeCompany')]);

  /* ------------------------------------------------------ JSX ----------------------------------------------------- */

  return (
    <>
      <Modal title="Attention" isOpen={isConfirmationModalOpen} setIsOpen={setIsConfirmationModalOpen}>
        <>
          <Text
            whitespace="pre-line"
            content={`Vos modifications vont prendre effet instantanément.
            Êtes-vous certain de vouloir poursuivre ?`}
            position="justify"
            size="base"
          />
          <div className="flex flex-row gap-2 justify-end">
            <Button
              onClick={async () => {
                setIsConfirmationModalOpen(false);
              }}
              content="Annuler"
              width="1/2"
              type="button"
              height="sm"
              color="#000000"
              disabled={isLoading}
            />
            <Button
              onClick={async () => {
                mutateSaveRequirmentsParameters();
              }}
              content="Je confirme"
              width="1/2"
              type="submit"
              height="sm"
              color="#60A5FA"
              loading={isLoading}
            />
          </div>
        </>
      </Modal>

      <form id="customizationForm" onSubmit={handleSubmit(onSubmitForm)} className="min-h-0">
        {isLargeScreen === true && (
          <div className={concatClassNames('h-full flex flex-col gap-8', 'rounded-md')}>
            <div className="flex flex-row gap-4">
              <div className="flex-1">
                <Text content="Personnaliser Discko à votre image" position="left" size="3xl" weight="bold" />
              </div>
              <div className="flex flex-row gap-4 items-end justify-end">
                <>
                  {isDirty && !isLoading && (
                    <>
                      <Button
                        content="Réinitialiser"
                        width="fit"
                        height="sm"
                        type="button"
                        onClick={() => {
                          reset({
                            urlRedirect: companyData.urlRedirect,
                            backgroundColor: companyData.bgColor,
                            primaryColor: companyData.primaryColor,
                            secondaryColor: companyData.secondaryColor,
                            activityDescription: companyData.activityDescription,
                            contactTypeCompany: ['COMPANY', 'BOTH'].includes(companyData.contactType),
                            contactTypePerson: ['PERSON', 'BOTH'].includes(companyData.contactType),
                            isContactLastnameRequired: companyData.isContactLastnameRequired,
                            isContactFirstnameRequired: companyData.isContactFirstnameRequired,
                            isContactLocationRequired: companyData.isContactLocationRequired,
                            isContactEmailRequired: companyData.isContactEmailRequired,
                            isContactPhoneRequired: companyData.isContactPhoneRequired,
                            isCompanyNameRequired: companyData.isCompanyNameRequired,
                            isCompanyLocationRequired: companyData.isCompanyLocationRequired,
                            isCompanyWebsiteRequired: companyData.isCompanyWebsiteRequired,
                            isContactLinkedinProfileRequired: companyData.isContactLinkedinProfileRequired,
                            isContactJobPositionRequired: companyData.isContactJobPositionRequired,
                            totalNumberOfQuestions: companyData.totalNumberOfQuestions,
                            withdrawWatermark: companyData.watermark,
                            synthesisItems:
                              (companyData.synthesisItems as any as CustomizationFormModel['synthesisItems']) ?? [],
                          });
                        }}
                        borderColor={'black'}
                        color="#000000"
                        loading={isLoading}
                      />
                    </>
                  )}
                  <Tooltip
                    content={
                      companyData.credits < 1 &&
                      !companyData.isSubscribed &&
                      companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                      companyData.hasAccessToStandardFeaturesUntil < new Date()
                        ? 'Vous devez vous abonner pour accéder à cette fonctionnalité'
                        : 'Enregistrer vos modifications'
                    }
                  >
                    <>
                      <Button
                        content="Enregistrer vos modifications"
                        width="fit"
                        height="sm"
                        type="submit"
                        cursor={
                          companyData.credits < 1 &&
                          !companyData.isSubscribed &&
                          companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                          companyData.hasAccessToStandardFeaturesUntil < new Date()
                            ? 'not-allowed'
                            : 'pointer'
                        }
                        disabled={
                          !isDirty ||
                          !isFormValid ||
                          (companyData.credits < 1 &&
                            !companyData.isSubscribed &&
                            companyData.hasAccessToPremiumFeaturesUntil < new Date() &&
                            companyData.hasAccessToStandardFeaturesUntil < new Date())
                        }
                        borderColor={!isDirty ? 'gray-50' : 'black'}
                        color="#60A5FA"
                      />
                    </>
                  </Tooltip>
                </>
              </div>
            </div>
            <div className="flex-1 min-h-0 overflow-auto flex flex-col gap-8">
              <div id="activity" className="flex flex-col gap-4 bg-white rounded-lg p-4">
                <Text content="Votre activité" size="2xl" weight="bold" />
                <div className="flex flex-col gap-8 p-4 pl-8">
                  <Controller
                    name="activityDescription"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextArea_backoffice
                        placeholder="Décrivez votre activité en quelques mots"
                        value={value}
                        onChange={onChange}
                        label="Décrivez votre activité et l’offre de service de votre entreprise"
                        isMandatory
                      />
                    )}
                  />
                  {categories && (
                    <div className="flex flex-col gap-2">
                      <Text content="Catégories de projets" size="xl" weight="bold" />
                      <p className="text-md">
                        Voici les catégories dans lesquelles vont être classées les projets que vous allez recevoir.
                        Vous pouvez les affiner en précisant votre activité.
                      </p>
                      <div className="flex flex-col gap-4">
                        {(categories ?? []).map((category, n) => (
                          <div className="flex flex-row gap-2 items-center" key={uuid()}>
                            <Chip
                              key={n}
                              label={category.name}
                              bgColor={category.color as ChipBgColor}
                              paddingHorizontal="md"
                              paddingTop="sm"
                              paddingBottom="sm"
                              textPosition="center"
                              borderColor={category.color as ChipBorderColor}
                            />
                            <Text content={category.description} size="sm" weight="light" />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <Text content="👉 Vos clients" size="xl" weight="bold" />
                  <div className="flex flex-col gap-4">
                    <Text content="Quelle typologie de clients est amenée à remplir Discko ?" size="lg" weight="bold" />
                    <div className="flex flex-row gap-4">
                      {[
                        {
                          key: 'contactTypePerson' as const,
                          name: 'Particuliers',
                        },
                        {
                          key: 'contactTypeCompany' as const,
                          name: 'Professionnels',
                        },
                      ].map((option) => (
                        <Controller
                          key={option.key}
                          name={option.key}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <button
                              type="button"
                              onClick={() => {
                                onChange(!value);
                              }}
                              className={concatClassNames(
                                'w-fit px-4 py-2 rounded-md border flex items-center justify-start gap-2',

                                value
                                  ? 'text-white bg-black font-bold border-1 border-black'
                                  : 'text-black bg-blue-gray-50',
                              )}
                            >
                              <input
                                type="checkbox"
                                checked={value}
                                readOnly
                                style={{
                                  backgroundColor: '#fff',
                                  appearance: 'none',
                                  WebkitAppearance: 'none',
                                  MozAppearance: 'none',
                                  display: 'inline - block',
                                  verticalAlign: 'middle',
                                  cursor: 'pointer',
                                }}
                              />
                              {option.name}
                            </button>
                          )}
                        />
                      ))}
                    </div>
                    {watch(['contactTypePerson', 'contactTypeCompany']).filter((value) => !!value).length === 0 && (
                      <p className="flex text-sm  text-error-500 font-bold">
                        Veuillez sélectionner au moins un type de client
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="flex w-full flex-wrap gap-4">
                      <Text
                        content="Sélectionnez les informations de contact que vous souhaitez obtenir :"
                        size="lg"
                        weight="bold"
                      />
                      <div className="flex flex-wrap gap-2 w-full ">
                        {contactFields.map((option) => (
                          <Controller
                            key={option.key}
                            name={option.key}
                            control={control}
                            render={({ field: { value, onChange } }) => {
                              const disabled = option.dependsOn.map(watch).every((value) => !value);

                              return (
                                <button
                                  type="button"
                                  onClick={() => {
                                    onChange(!value);
                                  }}
                                  disabled={disabled}
                                  className={concatClassNames(
                                    'px-4 py-2 rounded-md border flex items-center justify-start gap-2',

                                    value
                                      ? 'text-white bg-black font-bold border-1 border-black'
                                      : 'text-black bg-blue-gray-50',

                                    disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                                    'w-fit',
                                  )}
                                >
                                  <input
                                    type="checkbox"
                                    checked={value}
                                    readOnly
                                    style={{
                                      backgroundColor: '#fff',
                                      appearance: 'none',
                                      WebkitAppearance: 'none',
                                      MozAppearance: 'none',
                                      display: 'inline - block',
                                      verticalAlign: 'middle',
                                      cursor: 'pointer',
                                    }}
                                  />
                                  {option.name}
                                </button>
                              );
                            }}
                          />
                        ))}
                      </div>
                      {watch([
                        'isContactFirstnameRequired',
                        'isContactLastnameRequired',
                        'isContactEmailRequired',
                        'isContactPhoneRequired',
                        'isContactLocationRequired',
                        'isCompanyNameRequired',
                        'isCompanyLocationRequired',
                        'isCompanyWebsiteRequired',
                        'isContactJobPositionRequired',
                        'isContactLinkedinProfileRequired',
                      ]).filter((value) => !!value).length < 2 && (
                        <p className="col-span-full text-sm  text-error-500 font-bold">
                          Veuillez sélectionner au moins deux informations de contact à récupérer
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div id="formContent" className="flex flex-col gap-4 bg-white rounded-lg p-4">
                <Text content="Contenu du formulaire" size="2xl" weight="bold" />
                {/* <Text content="Combien de questions souhaitez-vous poser à votre prospect ?" size="xl" weight="bold" /> */}
                <div id="formContent" className="flex flex-col gap-8 p-4 pl-8">
                  <Controller
                    name="totalNumberOfQuestions"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <RadioButtons
                        values={['6', '12', '18', '24']}
                        selected={value.toString()}
                        onChange={(e) => {
                          onChange(Number(e.target.value));
                        }}
                        label="👉 Combien de questions souhaitez-vous poser à votre prospect ?"
                        testPrimaryColor="#000000"
                        testSecondaryColor="#dbdbdb"
                        testMode
                        isMandatory
                      />
                    )}
                  />
                  {/* <Controller
                name="formQuestions"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultiSelectRadioButtons
                    options={formQuestionsOptions}
                    selectedValues={value}
                    onChange={onChange}
                    label="Sélectionnez les questions préalables que vous souhaitez voir apparaître dans votre formulaire :"
                  />
                )}
              /> */}
                  <div id="synthesisItems" className="flex flex-col gap-4">
                    <Text
                      content="👉 Quelles rubriques souhaitez-vous voir apparaître dans la synthèse du besoin ?"
                      size="xl"
                      weight="bold"
                      position="left"
                    />
                    <div className="flex flex-col gap-4">
                      <Text
                        content="Votre sélection orientera les questions qui seront posées à votre prospect."
                        size="md"
                        weight="light"
                        position="left"
                      />
                    </div>
                    {watch('synthesisItems').length < 3 && (
                      <p className="text-sm  text-error-500 font-bold">
                        Veuillez sélectionner au moins trois rubriques.
                      </p>
                    )}
                    {watch('totalNumberOfQuestions') === 6 && watch('synthesisItems').length === 3 && (
                      <p className="text-sm  text-blue-800">
                        Vous avez atteint le maximum de rubriques sélectionnables : pour 6 questions posées, vous ne
                        pouvez sélectionner que 3 rubriques dans la synthèse.
                      </p>
                    )}
                    {watch('totalNumberOfQuestions') === 6 && watch('synthesisItems').length > 3 && (
                      <p className="text-sm  text-error-500 font-bold">
                        Vous avez dépassé le maximum de rubriques sélectionnables : pour 6 questions posées, vous ne
                        pouvez sélectionner que 3 rubriques dans la synthèse.
                      </p>
                    )}

                    {watch('totalNumberOfQuestions') === 12 && watch('synthesisItems').length === 6 && (
                      <p className="text-sm  text-blue-800">
                        Vous avez atteint le maximum de rubriques sélectionnables : pour 12 questions posées, vous ne
                        pouvez sélectionner que 6 rubriques dans la synthèse.
                      </p>
                    )}
                    {watch('totalNumberOfQuestions') === 12 && watch('synthesisItems').length > 6 && (
                      <p className="text-sm  text-error-500 font-bold">
                        Vous avez dépassé le maximum de rubriques sélectionnables : pour 12 questions posées, vous ne
                        pouvez sélectionner que 6 rubriques dans la synthèse.
                      </p>
                    )}
                    <Controller
                      name="synthesisItems"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="space-y-4">
                          {[
                            {
                              name: 'Contexte et origine du projet',
                              id: 'context',
                              description:
                                'Quelle est la situation actuelle et pourquoi ce projet est-il lancé ? Quels problèmes ou opportunités doivent être adressés ?',
                            },
                            {
                              name: 'Objectifs à atteindre',
                              id: 'goals',
                              description:
                                'Quels sont les résultats concrets attendus ? (Exemple : attirer plus de clients, automatiser une tâche, améliorer un service, etc.)',
                            },
                            {
                              name: 'Public cible',
                              id: 'audience',
                              description:
                                'Qui sont les utilisateurs ou bénéficiaires de ce projet ? (clients, salariés, partenaires, etc.)',
                            },
                            {
                              name: 'Acteurs impliqués',
                              id: 'stakeholders',
                              description:
                                'Qui sont les parties prenantes (décideurs, fournisseurs, partenaires, investisseurs, équipes internes, etc.)',
                            },
                            {
                              name: 'Facteurs clés de succès',
                              id: 'successFactors',
                              description:
                                'Quelles sont les conditions indispensables pour garantir la réussite du projet ? (ressources, délais, qualité, adoption, etc.)',
                            },
                            {
                              name: 'Contraintes et limites',
                              id: 'constraints',
                              description:
                                'Y a-t-il des restrictions budgétaires, techniques, réglementaires, de planning ou autre ?',
                            },

                            {
                              name: 'Solutions attendues',
                              id: 'solutions',
                              description:
                                'Quels types de solutions, services ou produits sont recherchés pour répondre au besoin ?',
                            },
                            {
                              name: 'Inspirations et références',
                              id: 'references',
                              description:
                                'Y a-t-il des exemples ou modèles inspirants à suivre ou, au contraire, à éviter ?',
                            },
                            {
                              name: 'Suivi et indicateurs de performance',
                              id: 'kpis',
                              description:
                                'Comment mesurer le succès de la prestation ? Quels critères seront suivis pour évaluer son efficacité ?',
                            },
                          ].map((item) => (
                            <button
                              key={item.id}
                              type="button"
                              onClick={() => {
                                // Vérifie si 6 questions sont posées et si plus de 3 rubriques sont sélectionnées
                                if (
                                  ((value.length >= 3 && watch('totalNumberOfQuestions') === 6) ||
                                    (value.length >= 6 && watch('totalNumberOfQuestions') === 12)) &&
                                  !value.some(({ id }) => id === item.id)
                                ) {
                                  return; // Empêche l'ajout d'une nouvelle rubrique si la limite de 3 est atteinte
                                }
                                if (value.some(({ id }) => id === item.id)) {
                                  onChange(value.filter(({ id }) => id !== item.id));
                                } else {
                                  onChange([...value, item]);
                                }
                              }}
                              className={concatClassNames(
                                'w-full px-4 py-2 rounded-md border flex flex-row items-center justify-start gap-2 text-start',

                                ((value.length >= 3 && watch('totalNumberOfQuestions') === 6) ||
                                  (value.length >= 6 && watch('totalNumberOfQuestions') === 12)) &&
                                  !value.some(({ id }) => id === item.id)
                                  ? 'text-gray-300 bg-blue-gray-50 cursor-not-allowed opacity-50' // texte gris si désactivé
                                  : 'text-black bg-blue-gray-50',
                              )}
                            >
                              <input
                                type="checkbox"
                                checked={value.some(({ id }) => id === item.id)}
                                readOnly
                                disabled={
                                  value.length >= 3 &&
                                  !value.some(({ id }) => id === item.id) &&
                                  watch('totalNumberOfQuestions') === 6
                                }
                              />
                              <div>
                                <p className="text-md font-bold">{item.name}</p>
                                <p className="text-sm">{item.description}</p>
                              </div>
                            </button>
                          ))}

                          <Text content="👉 Redirection des utilisateurs" size="xl" weight="bold" />
                          <div className="flex flex-col gap-4 pl-12">
                            <div
                              className="flex flex-row gap-4 cursor-pointer"
                              onClick={() => {
                                setIsRedirectionActivated(!isRedirectionActivated);
                              }}
                            >
                              <input
                                type="checkbox"
                                id="checkbox"
                                checked={isRedirectionActivated}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  setIsRedirectionActivated(checked);

                                  if (!checked) {
                                    setValue('urlRedirect', '');
                                  }
                                }}
                                value={isRedirectionActivated ? 'checked' : ''}
                                className={concatClassNames('cursor-pointer')}
                              />
                              <Text
                                position="left"
                                size="lg"
                                color="black"
                                weight="bold"
                                content="Activer la redirection des utilisateurs vers un calendrier en ligne au moment de la synthèse"
                              />
                            </div>
                            <div id="urlRedirectInput">
                              {isRedirectionActivated && (
                                <div className="flex flex-col gap-4">
                                  <div className="rounded-full flex flex-row gap-4 items-center w-full">
                                    <div className="w-[35%] h-fit">
                                      <Text
                                        position="left"
                                        size="lg"
                                        color="black"
                                        weight="bold"
                                        content="URL de redirection :"
                                      />
                                    </div>
                                    <div className="w-full">
                                      <Input
                                        placeholder="https://example.com"
                                        {...register('urlRedirect')}
                                        value={watchUrlRedirect ?? ''}
                                        error={
                                          errors.urlRedirect != null && isSubmitted
                                            ? errors.urlRedirect.message
                                            : isRedirectionActivated && watchUrlRedirect === ''
                                            ? 'Champ obligatoire'
                                            : undefined
                                        }
                                      />
                                    </div>
                                  </div>
                                  <Text
                                    position="left"
                                    size="sm"
                                    color="black"
                                    weight="light"
                                    content="L'URL de redirection est la même pour tous les membres de votre entreprise."
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div
                id="design"
                className={concatClassNames(
                  'flex flex-col gap-4  rounded-lg p-4',
                  companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? 'bg-white' : 'bg-gray-50',
                )}
              >
                <Text
                  content="Apparence du formulaire"
                  size="2xl"
                  weight="bold"
                  color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                />
                <div className="flex flex-col gap-8 w-full p-4 pl-8">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4 items-center">
                      <Text
                        position="left"
                        size="xl"
                        color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                        weight="bold"
                        content="👉 Proposez Discko en marque blanche"
                      />
                      <Tooltip
                        content={
                          companyData.isSubscribed
                            ? 'Vous êtes un VIP ! 😎'
                            : companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                            ? `Vous avez accès à cette fonctionnalité jusqu'au ${companyData.hasAccessToPremiumFeaturesUntil.toLocaleDateString()}. Passée cette date, les valeurs par défaut seront rétablies.`
                            : 'Faites évoluer votre abonnement pour y avoir accès'
                        }
                        className="bg-black text-xs text-white"
                        placement="bottom-start"
                      >
                        <div
                          onClick={() => {
                            if (!companyData.isSubscribed) {
                              onUpgrade();
                            }
                          }}
                          className="cursor-pointer"
                        >
                          <Chip
                            label="Fonctionnalité Premium"
                            bgColor="bg-secondary-500"
                            borderColor="none"
                            textColor="white"
                            paddingHorizontal="md"
                            paddingTop="sm"
                            paddingBottom="sm"
                          />
                        </div>
                      </Tooltip>
                    </div>
                    <Controller
                      control={control}
                      {...register('withdrawWatermark')}
                      render={({ field: { value, onChange } }) => (
                        <div className="flex flex-row gap-4 items-center pl-12">
                          <input
                            type="checkbox"
                            checked={value}
                            onChange={onChange}
                            disabled={companyData.hasAccessToPremiumFeaturesUntil < new Date()}
                            className={concatClassNames(
                              companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                                ? 'cursor-pointer'
                                : 'cursor-not-allowed',
                            )}
                          />
                          <Text
                            content="Retirer la mention 'Propulsé par Discko' présente sur le formulaire."
                            size="lg"
                            weight="bold"
                            color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4 items-center">
                      <Text
                        position="left"
                        size="xl"
                        weight="bold"
                        content="👉 Personnalisez les couleurs de votre formulaire"
                        color={companyData.hasAccessToPremiumFeaturesUntil <= new Date() ? '#929292' : '#000000'}
                      />
                      <Tooltip
                        content={
                          companyData.isSubscribed
                            ? 'Vous êtes un VIP ! 😎'
                            : companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                            ? `Vous avez accès à cette fonctionnalité jusqu'au ${companyData.hasAccessToPremiumFeaturesUntil.toLocaleDateString()}. Passée cette date, les valeurs par défaut seront rétablies.`
                            : 'Faites évoluer votre abonnement pour y avoir accès'
                        }
                        className="bg-black text-xs text-white"
                        placement="bottom-start"
                      >
                        <div
                          onClick={() => {
                            if (!companyData.isSubscribed) {
                              onUpgrade();
                            }
                          }}
                          className="cursor-pointer"
                        >
                          <Chip
                            label="Fonctionnalité Premium"
                            bgColor="bg-secondary-500"
                            borderColor="none"
                            textColor="white"
                            paddingHorizontal="md"
                            paddingTop="sm"
                            paddingBottom="sm"
                          />
                        </div>
                      </Tooltip>
                    </div>

                    {/* <Tooltip
                      className={concatClassNames(
                        'bg-black',
                        'text-white',
                        'w-[20%]',
                        'text-xs',
                        'whitespace-normal transition-none',
                      )}
                      animate={false}
                      placement="bottom-start"
                      content={'Après cette date, les couleurs par défaut seront rétablies.'}
                    >
                      <div className="flex flex-row gap-2">
                        <p className="italic text-xs">
                          {`Vous avez souscrit à cette option jusqu'au ${companyData.addOnParams.customColorsPaidUntil.toLocaleDateString()}`}
                        </p>
                        {getIcon('information', 'black', 'sm')}
                      </div>
                    </Tooltip> */}
                    <div className="flex flex-row gap-4 items-center">
                      <div
                        className="sm:w-[30%] md:w-[40%] lg:w-[50%] w-full h-full relative bg-black"
                        style={{ aspectRatio: '16 / 9', overflow: 'auto', transform: 'scale(0.7)' }}
                      >
                        <Miniature
                          isLargeScreen
                          bgColor={watchBackgroundColor}
                          primaryColor={watchPrimaryColor}
                          secondaryColor={watchSecondaryColor}
                        />
                      </div>
                      <div className="flex flex-col gap-4 items-start justify-start">
                        <div className="flex flex-row gap-4 items-center">
                          <Text
                            position="left"
                            size="lg"
                            weight="bold"
                            color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                            content="Couleur de l'arrière-plan"
                          />
                          <div
                            className={concatClassNames(
                              'w-8 h-8 rounded-sm relative border-1 border-black',
                              companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                                ? 'cursor-pointer'
                                : 'cursor-not-allowed',
                            )}
                            style={{ backgroundColor: watchBackgroundColor }}
                            onClick={() => {
                              if (companyData.hasAccessToPremiumFeaturesUntil >= new Date()) {
                                setBackgroundColorPickerOpen(!backgroundColorPickerOpen);
                                setPrimaryColorPickerOpen(false);
                                setSecondaryColorPickerOpen(false);
                              }
                            }}
                          >
                            {backgroundColorPickerOpen && (
                              <div
                                className="absolute bottom-8  z-10"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <Controller
                                  name="backgroundColor"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <SketchPicker
                                      disableAlpha
                                      color={watchBackgroundColor} // Définit la couleur initiale ou actuelle
                                      onChangeComplete={(color: ColorResult) => {
                                        setValue('backgroundColor', color.hex, { shouldDirty: true });
                                      }}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 items-center">
                          <Text
                            position="left"
                            size="lg"
                            color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                            weight="bold"
                            content="Couleur principale"
                          />
                          <div
                            className={concatClassNames(
                              'w-8 h-8 rounded-sm relative border-1 border-black',
                              companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                                ? 'cursor-pointer'
                                : 'cursor-not-allowed',
                            )}
                            style={{ backgroundColor: watchPrimaryColor }}
                            onClick={() => {
                              if (companyData.hasAccessToPremiumFeaturesUntil >= new Date()) {
                                setPrimaryColorPickerOpen(!primaryColorPickerOpen);
                                setBackgroundColorPickerOpen(false);
                                setSecondaryColorPickerOpen(false);
                              }
                            }}
                          >
                            {primaryColorPickerOpen && (
                              <div
                                className="absolute bottom-8  z-10"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <Controller
                                  name="primaryColor"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <SketchPicker
                                      disableAlpha
                                      color={watchPrimaryColor} // Définit la couleur initiale ou actuelle
                                      onChangeComplete={(color: ColorResult) => {
                                        setValue('primaryColor', color.hex, { shouldDirty: true });
                                      }} // Déclencheur quand une couleur est sélectionnée
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 items-center">
                          <Text
                            position="left"
                            size="lg"
                            color={companyData.hasAccessToPremiumFeaturesUntil >= new Date() ? '#000000' : '#929292'}
                            weight="bold"
                            content="Couleur secondaire"
                          />
                          <div
                            className={concatClassNames(
                              'w-8 h-8 rounded-sm relative border-1 border-black',
                              companyData.hasAccessToPremiumFeaturesUntil >= new Date()
                                ? 'cursor-pointer'
                                : 'cursor-not-allowed',
                            )}
                            style={{ backgroundColor: watchSecondaryColor }}
                            onClick={() => {
                              if (companyData.hasAccessToPremiumFeaturesUntil >= new Date()) {
                                setSecondaryColorPickerOpen(!secondaryColorPickerOpen);
                                setBackgroundColorPickerOpen(false);
                                setPrimaryColorPickerOpen(false);
                              }
                            }}
                          >
                            {secondaryColorPickerOpen && (
                              <div
                                className="absolute bottom-8  z-10"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <Controller
                                  name="secondaryColor"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <SketchPicker
                                      disableAlpha
                                      color={watchSecondaryColor} // Définit la couleur initiale ou actuelle
                                      onChangeComplete={(color: ColorResult) => {
                                        setValue('secondaryColor', color.hex, { shouldDirty: true });
                                      }} // Déclencheur quand une couleur est sélectionnée
                                    />
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isLargeScreen === false && (
          <div>
            <p className="text-sm text-justify">
              {`Discko vous permet de personnaliser le contenu et l'apparence du formulaire à l'image de votre entreprise.
              Paramétrez ces éléments depuis un ordinateur.`}
            </p>
          </div>
        )}
      </form>
    </>
  );
}
