/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type GetDisckoUsersDto } from 'api';

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { RichText } from 'components/RichText/RichText';
import { P } from 'components/core/P';

import concatClassNames from 'utils/classNames';

export function MembersManagement(): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  const { userService } = useContext(DisckoApisContext);

  const { getAccessTokenSilently, user } = useAuth0();

  async function getCompanyUsersByUserId(): Promise<GetDisckoUsersDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await userService.findMyCompanyUsers(accessToken);
  }

  const { data: usersData, status: usersStatus } = useQuery<GetDisckoUsersDto, Error>(
    ['users', user?.sub],
    getCompanyUsersByUserId,
  );

  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <div>
      {isLargeScreen === true && (
        <div className="p-4 flex flex-col gap-6">
          <P
            position="left"
            weight="normal"
            text="Voici les membres de votre équipe actuellement inscrits sur Discko : "
          />

          <ul className={concatClassNames('list-disc list-inside flex flex-col bg-white p-4 rounded-lg')}>
            {usersStatus === 'success' &&
              usersData.users.map((member) => (
                <li key={uuid()} className={concatClassNames('flex flex-row', 'gap-2')}>
                  <span className="font-bold">{member.name}</span> <span></span> <span>{`[${member.email}]`}</span>
                </li>
              ))}
          </ul>
          <div className="flex flex-row gap-2 items-center">
            <P position="justify" weight="normal" text="Pour inviter de nouveaux membres, envoyez-nous un email à " />
            <span
              className="text-gray-500 font-bold cursor-pointer"
              onClick={() => (window.location.href = 'mailto:contact@discko.io')}
            >
              contact@discko.io
            </span>
            <P position="justify" weight="normal" text="avec leurs coordonnées." />
          </div>
        </div>
      )}
      {isLargeScreen === false && (
        <div className="pl-4 flex flex-col gap-2">
          <p className="text-sm text-justify">
            {'Voici les membres de votre équipe actuellement inscrits sur Discko :'}{' '}
          </p>

          <ul className={concatClassNames('list-disc list-inside text-xs')}>
            {usersStatus === 'success' &&
              usersData.users.map((member) => (
                <li key={uuid()}>
                  <span>{member.name}</span> - <span>{member.email}</span>
                </li>
              ))}
          </ul>

          <p className="text-sm text-justify">
            <span className="text-justify"> Pour inviter de nouveaux membres, envoyez-nous un email à </span>
            <span
              className="text-gray-500 font-bold"
              onClick={() => (window.location.href = 'mailto:contact@discko.io')}
            >
              contact@discko.io
            </span>
            <span className="text-justify"> avec leurs coordonnées.</span>
          </p>
        </div>
      )}
    </div>
  );
}
