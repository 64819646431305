import { type GetFullDisckoCompanyDto } from 'api';

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';

import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import getCompletionTypeChip from 'utils/getCompletionTypeChip';
import getIcon from 'utils/getIcon';

type CompanyRowProps =
  | {
      company?: GetFullDisckoCompanyDto;
      loading?: false;
      title?: false;
      setSelectedCompanyId?: (id: string) => void;
      setIsAddCreditModalOpen?: (isOpen: boolean) => void;
    }
  | {
      company?: never;
      loading?: false;
      title: true;
      setSelectedCompanyId?: never;
      setIsAddCreditModalOpen?: never;
    }
  | {
      company?: never;
      loading: true;
      title?: never;
      setSelectedCompanyId?: never;
      setIsAddCreditModalOpen?: never;
    };

export function CompanyRow({
  company,
  loading = false,
  title = false,
  setSelectedCompanyId,
  setIsAddCreditModalOpen,
}: CompanyRowProps): JSX.Element {
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <>
      {loading ? (
        <div className={concatClassNames('bg-gray-25', 'flex flex-row items-center')}>
          <div className="flex flex-row items-start justify-start p-2 w-full gap-2">
            <div className={concatClassNames('w-[35%]')}>
              <Text loading />
            </div>
            <div className={concatClassNames('')}>{getCompletionTypeChip({ loading: true })}</div>
            <div className={concatClassNames('w-[35%]')}>
              <Text loading />
            </div>
          </div>
        </div>
      ) : (
        <>
          {title ? (
            <div className={concatClassNames('bg-white', 'flex flex-row items-center')}>
              <div className="flex flex-row items-start  justify-start p-2 w-full gap-2">
                <div className={concatClassNames('w-[25%] flex flex-row gap-4 items-center')}>
                  <Text content="Client" size="sm" />
                </div>
                <div className={concatClassNames('w-[8%] flex flex-row gap-4 items-center')}>
                  {getIcon('clipboard', '#FFFFFF', 'md')}
                </div>

                <div className={concatClassNames('w-[40%] flex flex-row gap-4 items-center')}>
                  <Text content="Membres" size="sm" />
                </div>

                <div className={concatClassNames('w-[10%] flex items-center', 'break-all', 'justify-center')}>
                  <Text content="m-1" size="sm" />
                </div>
                <div className={concatClassNames('w-[10%] flex items-center', 'break-all', 'justify-center')}>
                  <Text content="m" size="sm" />
                </div>
                <div
                  className={concatClassNames(
                    'w-[25%] flex flex-row gap-4 items-center',
                    'break-all',
                    'justify-center',
                  )}
                >
                  <Text content="Dernier paiement" size="sm" />
                </div>
                <div className={concatClassNames('w-[10%] flex flex-row gap-4 items-center', 'justify-center')}>
                  <Text content="Crédits" size="sm" />
                </div>
                <div className={concatClassNames('w-[8%] flex flex-row gap-4 items-center')}></div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={concatClassNames(
                  'bg-slate-100',
                  'hover:bg-slate-300',
                  'w-full',
                  'cursor-pointer',
                  'flex flex-row items-center',
                )}
              >
                {company !== undefined && (
                  <>
                    <div className="flex flex-row items-start justify-start p-2 w-full gap-2">
                      <div className={concatClassNames('w-[25%]', 'break-all')}>
                        <Text content={company.name} size="base" weight={'normal'} />
                      </div>
                      <div
                        className={concatClassNames(
                          'w-[8%] flex flex-row gap-4 items-start justify-center cursor-pointer',
                        )}
                        onClick={async () => {
                          await navigator.clipboard.writeText(company.id);

                          toast.success(`Vous avez bien copié l'id de l'agence ${company.name}`);
                        }}
                      >
                        {getIcon('clipboard', '#000000', 'md')}
                      </div>
                      <div className={concatClassNames('w-[40%]', 'break-all')}>
                        {company.users.map((user) => (
                          <div key={uuid()} className="flex flex-col">
                            <Text size="sm" content={user.name} position="left" />
                            {user.email}
                          </div>
                        ))}
                      </div>
                      <div className={concatClassNames('w-[10%] flex justify-center')}>
                        {company.requirementsPastMonth}
                      </div>
                      <div className={concatClassNames('w-[10%] flex justify-center')}>
                        {company.requirementsCurrentMonth}
                      </div>
                      <div className={concatClassNames('w-[25%] flex justify-center')}>
                        {company.subscriptionDate?.toLocaleDateString()}
                      </div>
                      <div className="flex flex-col w-[10%] items-center">
                        <div className={concatClassNames('')}>{company.credits === 0 ? '' : company.credits}</div>
                      </div>
                      <div className={concatClassNames('w-[8%]', 'flex', 'justify-center')}>
                        <Button
                          content="+"
                          width="fit"
                          height="fit"
                          onClick={() => {
                            setSelectedCompanyId?.(company.id);
                            setIsAddCreditModalOpen?.(true);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
