import {
  type GetGenericRequirementContactDto,
  type GetGenericRequirementContactDtoContactTypeEnum,
  type MobileStep,
} from 'api';
import * as Yup from 'yup';

import React, { type MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import { FrontOfficeCGU } from 'components/Legal/FrontOfficeCGU';
import { PrivacyPolicyWidget } from 'components/Legal/PrivacyPolicyWidget';
import RadioButtons from 'components/RadioButtons/RadioButtons';
import { RichText } from 'components/RichText/RichText';
import { Button, getTextColor } from 'components/core/Button/Button';
import Input from 'components/core/Input/Input';
import { Modal } from 'components/core/Modal/Modal';
import { Stepsbar } from 'components/core/StepsBar/Stepsbar';
import { Text } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import delay from 'utils/delay';
import getIcon from 'utils/getIcon';

import { disckoContext } from './DisckoPage';

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */

interface ClientInfoFormModel {
  companyName?: string;
  companyWebsite?: string;
  contactLastname?: string;
  contactFirstname?: string;
  contactPhone?: string;
  contactEmail?: string;
  contactType?: string;
  contactLocation?: string;
  companyLocation?: string;
  contactJobPosition?: string;
  contactLinkedinProfile?: string;
}

const schema: Yup.ObjectSchema<ClientInfoFormModel> = Yup.object().shape({
  contactFirstname: Yup.string().when(['isContactFirstnameRequired'], ([isContactFirstnameRequired], schema) => {
    return isContactFirstnameRequired ? schema.required('Ce champ est obligatoire.') : schema;
  }),

  contactLastname: Yup.string().when(['isContactLastnameRequired'], ([isContactLastnameRequired], schema) => {
    return isContactLastnameRequired ? schema.required('Ce champ est obligatoire.') : schema;
  }),
  companyWebsite: Yup.string().when(['isCompanyWebsiteRequired'], ([isCompanyWebsiteRequired], schema) => {
    return isCompanyWebsiteRequired ? schema.required('Ce champ est obligatoire.') : schema;
  }),

  contactPhone: Yup.string().when(['isContactPhoneRequired'], ([isContactPhoneRequired], schema) => {
    return isContactPhoneRequired ? schema.required() : schema;
  }),

  contactEmail: Yup.string()
    .email('Cette adresse est invalide.')
    .when(['isContactEmailRequired'], ([isContactEmailRequired], schema) => {
      return isContactEmailRequired ? schema.required('Ce champ est obligatoire.') : schema;
    }),

  contactType: Yup.string(),
  // contactLocation n'est obligatoire que si isContactLocationRequired est true
  companyLocation: Yup.string().when(
    ['isCompanyLocationRequired', 'contactType'],
    ([isCompanyLocationRequired, contactType], schema) => {
      return isCompanyLocationRequired && contactType === 'COMPANY' ? schema.required() : schema;
    },
  ),
  companyName: Yup.string().when(
    ['isCompanyNameRequired', 'contactType'],
    ([isCompanyNameRequired, contactType], schema) => {
      return isCompanyNameRequired && contactType === 'COMPANY' ? schema.required() : schema;
    },
  ),
  contactLocation: Yup.string().when(
    ['isContactLocationRequired', 'contactType'],
    ([isContactLocationRequired, contactType], schema) => {
      return isContactLocationRequired && contactType === 'PERSON' ? schema.required() : schema;
    },
  ),
  contactJobPosition: Yup.string().when(
    ['isContactJobPositionRequired', 'contactType'],
    ([isContactJobPositionRequired, contactType], schema) => {
      return isContactJobPositionRequired && contactType === 'COMPANY' ? schema.required() : schema;
    },
  ),
  contactLinkedinProfile: Yup.string().when(
    ['isContactLinkedinProfileRequired', 'contactType'],
    ([isContactLinkedinProfileRequired, contactType], schema) => {
      return isContactLinkedinProfileRequired && contactType === 'COMPANY' ? schema.required() : schema;
    },
  ),
});

/* --------------------------------------------------- API calls -------------------------------------------------- */

interface ClientInfoFormProps {
  setNextStep?: (nextStep: string) => void;
}

interface ClientInfos {
  companyName?: string;
  companyWebsite?: string;
  contactLastname?: string;
  contactFirstname?: string;
  contactPhone?: string;
  contactEmail?: string;
  contactLocation?: string;
  companyLocation?: string;
  contactType?: string;
  contactJobPosition?: string;
  contactLinkedinProfile?: string;
}

export function ClientInfoForm({ setNextStep }: ClientInfoFormProps): JSX.Element {
  const { initRequirement, setRequirementParams, isLargeScreen, reset, uiSettings } = useContext(disckoContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [animation, setAnimation] = useState<'visible' | 'invisible'>('visible');

  const formZoneRef = useRef<HTMLDivElement>(null);
  const formzone = document.getElementById('formzone');

  const scrollPercentage: MutableRefObject<number> = useRef<number>(0);

  React.useEffect(() => {
    if (formzone !== null) {
      scrollPercentage.current = formzone.scrollTop / (formzone.scrollHeight - formzone.clientHeight);
    }
  }, [formzone?.scrollTop, formzone?.scrollHeight, formzone?.clientHeight, formzone]);

  // reset all the form values when entering this page
  useEffect(() => {
    reset();
  }, []);

  const [mobileStep, setMobileStep] = useState<number>(1);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(true);
  const [isCGUModalOpen, setIsCGUModalOpen] = useState<boolean>(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState<boolean>(false);
  const [swap, setSwap] = useState<boolean>(false);
  const {
    register,
    watch,
    control,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitted },
  } = useForm<ClientInfoFormModel>({
    resolver: yupResolver(schema),
    defaultValues: {
      contactType: uiSettings?.contactType === 'BOTH' ? 'PERSON' : uiSettings?.contactType,
    },
  });

  const watchCompanyName = watch('companyName');
  const watchContactLastname = watch('contactLastname');
  const watchContactFirstname = watch('contactFirstname');
  const watchContactEmail: string | undefined = watch('contactEmail');
  const watchContactPhone = watch('contactPhone');
  const watchContactType = watch('contactType');
  const watchContactLocation = watch('contactLocation');
  const watchCompanyLocation = watch('companyLocation');
  const watchContactJobPosition = watch('contactJobPosition');
  const watchContactLinkedinProfile = watch('contactLinkedinProfile');

  let isFormValid: boolean = true;

  let isMobileFormValid: boolean = true;

  if (
    (uiSettings?.contactType === 'BOTH' && watchContactType === undefined) ||
    (uiSettings?.isContactLocationRequired &&
      watchContactType === 'PERSON' &&
      (watchContactLocation === undefined || watchContactLocation === '')) ||
    (uiSettings?.isCompanyNameRequired &&
      watchContactType === 'COMPANY' &&
      (watchCompanyName === undefined || watchCompanyName === '')) ||
    (uiSettings?.isCompanyLocationRequired &&
      watchContactType === 'COMPANY' &&
      (watchCompanyLocation === undefined || watchCompanyLocation === '')) ||
    (uiSettings?.isContactJobPositionRequired &&
      watchContactType === 'COMPANY' &&
      (watchContactJobPosition === undefined || watchContactJobPosition === '')) ||
    (uiSettings?.isContactEmailRequired && (watchContactEmail === undefined || watchContactEmail === '')) ||
    (uiSettings?.isContactPhoneRequired && (watchContactPhone === undefined || watchContactPhone === '')) ||
    (uiSettings?.isContactFirstnameRequired && (watchContactFirstname === undefined || watchContactFirstname === '')) ||
    (uiSettings?.isContactLastnameRequired && (watchContactLastname === undefined || watchContactLastname === ''))
  ) {
    isFormValid = false;
  }

  if (
    (mobileStep >= 1 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        1,
      )) ||
    (mobileStep >= 2 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        2,
      )) ||
    (mobileStep >= 3 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        3,
      )) ||
    (mobileStep >= 4 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        4,
      )) ||
    (mobileStep >= 5 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        5,
      )) ||
    (mobileStep >= 6 &&
      handleMobileStepValidation(
        uiSettings?.contactType === 'PERSON' || (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
          ? uiSettings?.mobileStepsToGetPersonInfo
          : uiSettings?.mobileStepsToGetCompanyInfo,
        6,
      ))
  ) {
    isMobileFormValid = false;
  }

  function duration(): number {
    switch (uiSettings?.totalNumberOfQuestions) {
      case 6:
        return 2;
      case 12:
        return 3;
      case 18:
        return 5;
      case 24:
        return 8;
      default:
        return 4;
    }
  }

  /* ------------------------------------------ Go to the top mobile steps ------------------------------------------ */

  useEffect(() => {
    if (formZoneRef.current !== null) {
      formZoneRef.current.scrollTop = 0;
    }
  }, [mobileStep]);

  /* --------------------------------------------- Mobile Steps handler --------------------------------------------- */

  function maxMobileStep(): number {
    const maxCompanyMobileStep: number =
      uiSettings?.mobileStepsToGetCompanyInfo?.reduce((acc, step) => (step.step > acc ? step.step : acc), 0) ?? 0;
    const maxPersonMobileStep: number =
      uiSettings?.mobileStepsToGetPersonInfo?.reduce((acc, step) => (step.step > acc ? step.step : acc), 0) ?? 0;

    if (uiSettings?.contactType === 'COMPANY') {
      return maxCompanyMobileStep;
    }
    if (uiSettings?.contactType === 'PERSON') {
      return maxPersonMobileStep;
    }
    if (uiSettings?.contactType === 'BOTH') {
      if (watch('contactType') === 'COMPANY') {
        return maxCompanyMobileStep;
      }
      if (watch('contactType') === 'PERSON') {
        return maxPersonMobileStep;
      }
    }
    return 0;
  }

  function handleMobileStepValidation(mobileStepsToGetInfo: MobileStep[] | undefined, mobileStep: number): boolean {
    const mobileStepInfo: MobileStep | undefined = mobileStepsToGetInfo?.find((step) => step.step === mobileStep);
    if (mobileStepInfo !== undefined) {
      switch (mobileStepInfo.render) {
        case 'contactType':
          if (uiSettings?.contactType === 'BOTH' && watch('contactType') === undefined) {
            return true;
          }
          return false;
        case 'contactIdentity':
          if (
            uiSettings?.isContactFirstnameRequired &&
            (watch('contactFirstname') === undefined || watch('contactFirstname') === '')
          ) {
            return true;
          }
          if (
            uiSettings?.isContactLastnameRequired &&
            (watch('contactLastname') === undefined || watch('contactLastname') === '')
          ) {
            return true;
          }
          return false;
        case 'contactEmailPhone':
          if (
            uiSettings?.isContactEmailRequired &&
            (watch('contactEmail') === undefined || watch('contactEmail') === '')
          ) {
            return true;
          }
          if (
            uiSettings?.isContactPhoneRequired &&
            (watch('contactPhone') === undefined || watch('contactPhone') === '')
          ) {
            return true;
          }

          return false;
        case 'contactLocation':
          if (
            uiSettings?.isContactLocationRequired &&
            (watch('contactLocation') === undefined || watch('contactLocation') === '')
          ) {
            return true;
          }
          return false;
        case 'contactLinkedinProfile':
          if (
            uiSettings?.isContactLinkedinProfileRequired &&
            (watch('contactLinkedinProfile') === undefined || watch('contactLinkedinProfile') === '')
          ) {
            return true;
          }
          return false;
        case 'contactJobPosition':
          if (
            uiSettings?.isContactJobPositionRequired &&
            (watch('contactJobPosition') === undefined || watch('contactJobPosition') === '')
          ) {
            return true;
          }
          return false;
        case 'companyName':
          if (
            uiSettings?.isCompanyNameRequired &&
            (watch('companyName') === undefined || watch('companyName') === '')
          ) {
            return true;
          }
          return false;
        case 'companyLocation':
          if (
            uiSettings?.isCompanyLocationRequired &&
            (watch('companyLocation') === undefined || watch('companyLocation') === '')
          ) {
            return true;
          }
          return false;
        case 'companyWebsite':
          return false;
        default:
          return true;
      }
    }
    return true;
  }

  function handleMobileStepRendering(mobileStepsToGetInfo: MobileStep[] | undefined, mobileStep: number): JSX.Element {
    const mobileStepInfo: MobileStep | undefined = mobileStepsToGetInfo?.find((step) => step.step === mobileStep);
    if (mobileStepInfo !== undefined) {
      switch (mobileStepInfo.render) {
        case 'contactType':
          return handleMobileContactTypeSectionRendering();
        case 'contactIdentity':
          return handleMobileContactIdentitySectionRendering();
        case 'contactEmailPhone':
          return handleMobileEmailPhoneSectionRendering();
        case 'contactLocation':
          return handleMobileContactLocationRendering();
        case 'companyName':
          return handleMobileCompanyNameRendering();
        case 'companyLocation':
          return handleMobileCompanyLocationRendering();
        case 'companyWebsite':
          return handleMobileCompanyWebsiteSectionRendering();
        case 'contactJobPosition':
          return handleMobileContactJobPositionRendering();
        case 'contactLinkedinProfile':
          return handleMobileContactLinkedinProfileRendering();
        default:
          return <></>;
      }
    }
    return <></>;
  }

  function handleMobileContactIdentitySectionRendering(): JSX.Element {
    return (
      <>
        <Text content="Commençons par quelques informations sur vous !" size="xl" weight="bold" position="left" />

        {uiSettings?.isContactFirstnameRequired && (
          <Input
            label="Quel est votre prénom ?"
            placeholder="Jeanne"
            isMandatory
            {...register('contactFirstname')}
            error={errors.contactFirstname != null ? errors.contactFirstname.message : undefined}
            onSubmit={() => {
              setFocus('contactLastname');
            }}
          />
        )}
        {uiSettings?.isContactLastnameRequired && (
          <Input
            label="Quel est votre nom ?"
            placeholder="Forgeau"
            uppercase
            isMandatory
            {...register('contactLastname')}
            error={errors.contactLastname != null ? errors.contactLastname.message : undefined}
            onSubmit={() => {
              handleNextMobileStep();
              // TODO : gérer le focus en fonction de la configuration
              // setMobileFormInputToFocus('companyName');
            }}
          />
        )}
      </>
    );
  }

  function handleMobileEmailPhoneSectionRendering(): JSX.Element {
    return (
      <>
        {(uiSettings?.isContactEmailRequired || uiSettings?.isContactPhoneRequired) && (
          <>
            <Text content="Et pour que l'on puisse vous recontacter... 😊" size="xl" weight="bold" position="left" />
            {uiSettings?.isContactEmailRequired && (
              <Input
                rows={1}
                label="Email"
                placeholder="jeanne@discko.io"
                isMandatory
                inputMode="email"
                {...register('contactEmail')}
                error={errors.contactEmail != null && isSubmitted ? errors.contactEmail.message : undefined}
                onSubmit={() => {
                  setFocus('contactPhone');
                }}
              />
            )}
            {uiSettings?.isContactPhoneRequired && (
              <Input
                rows={1}
                label="Téléphone"
                placeholder="06 12 34 56 78"
                {...register('contactPhone')}
                inputMode="tel"
                isMandatory
                error={errors.contactPhone != null ? errors.contactPhone.message : undefined}
              />
            )}
          </>
        )}
      </>
    );
  }

  function handleMobileContactTypeSectionRendering(): JSX.Element {
    return (
      <>
        {uiSettings?.contactType === 'BOTH' && (
          <Controller
            {...register('contactType')}
            control={control}
            defaultValue={'PERSON'}
            render={({ field: { value, onChange } }) => (
              <RadioButtons
                label="Êtes-vous un particulier ou un professionnel ?"
                values={['PERSON', 'COMPANY']}
                valuesDisplay={['Particulier', 'Professionnel']}
                selected={value}
                error="Champ obligatoire"
                onChange={onChange}
                isMandatory
                disabled={isLoading}
              />
            )}
          />
        )}
      </>
    );
  }

  function handleMobileCompanyWebsiteSectionRendering(): JSX.Element {
    return (
      <>
        <Input
          rows={1}
          placeholder="https://discko.io"
          label="Si vous en avez un, quelle est l'adresse de votre site web ?"
          {...register('companyWebsite')}
          inputMode="url"
          error={errors.companyWebsite != null ? errors.companyWebsite.message : undefined}
          onSubmit={() => {
            handleNextMobileStep();
            setMobileFormInputToFocus('contactEmail');
          }}
        />
      </>
    );
  }

  function handleMobileCompanyNameRendering(): JSX.Element {
    return (
      <>
        <Input
          rows={1}
          isMandatory
          label="Quel est le nom de votre entreprise ?"
          placeholder="Discko"
          {...register('companyName')}
          error={errors.companyName != null ? errors.companyName.message : undefined}
          primaryColor={uiSettings?.primaryColor}
          secondaryColor={uiSettings?.secondaryColor}
        />
      </>
    );
  }

  function handleMobileContactLocationRendering(): JSX.Element {
    return (
      <>
        {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'PERSON' : true) &&
          uiSettings?.isContactLocationRequired && (
            <Input
              label="Quelle est votre adresse ?"
              placeholder="1 rue Louis Blanc, 75010 Paris"
              isMandatory
              {...register('contactLocation')}
              error={errors.contactLocation != null ? errors.contactLocation.message : undefined}
              primaryColor={uiSettings?.primaryColor}
              secondaryColor={uiSettings?.secondaryColor}
            />
          )}
      </>
    );
  }

  function handleMobileContactJobPositionRendering(): JSX.Element {
    return (
      <>
        {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
          uiSettings?.isContactJobPositionRequired && (
            <Input
              label="Quel est votre poste ?"
              placeholder="Responsable Marketing"
              isMandatory
              {...register('contactJobPosition')}
              error={errors.contactJobPosition != null ? errors.contactJobPosition.message : undefined}
              primaryColor={uiSettings?.primaryColor}
              secondaryColor={uiSettings?.secondaryColor}
            />
          )}
      </>
    );
  }

  function handleMobileContactLinkedinProfileRendering(): JSX.Element {
    return (
      <>
        {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
          uiSettings?.isContactLinkedinProfileRequired && (
            <Input
              label="Vous pouvez insérer un lien vers votre profil Linkedin :"
              placeholder="https://www.linkedin.com/in/jeanneforgeau/"
              {...register('contactLinkedinProfile')}
              error={errors.contactLinkedinProfile != null ? errors.contactLinkedinProfile.message : undefined}
              primaryColor={uiSettings?.primaryColor}
              secondaryColor={uiSettings?.secondaryColor}
            />
          )}
      </>
    );
  }

  function handleMobileCompanyLocationRendering(): JSX.Element {
    return (
      <>
        {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
          uiSettings?.isCompanyLocationRequired && (
            <Input
              label="Quelle est l'adresse de votre entreprise ?"
              placeholder="1 rue Louis Blanc, 75010 Paris"
              isMandatory
              {...register('companyLocation')}
              error={errors.companyLocation != null ? errors.companyLocation.message : undefined}
              primaryColor={uiSettings?.primaryColor}
              secondaryColor={uiSettings?.secondaryColor}
            />
          )}
      </>
    );
  }

  /* ---------------------------------------------------- Queries --------------------------------------------------- */

  const [clientInfo, setClientInfo] = useState<ClientInfos>();

  const { data, status, isRefetching } = useQuery({
    queryKey: ['requirement'],
    queryFn: async () => {
      return await initRequirement(clientInfo as GetGenericRequirementContactDto);
    },
    enabled: clientInfo !== undefined,
    cacheTime: 0,
  });

  useEffect(() => {
    if (status === 'error') setIsLoading(false);
    if (status !== 'success' || (isRefetching !== undefined && isRefetching)) return;

    setRequirementParams(data);

    setIsLoading(false);
    // Debute l'effet pour transitionner vers le nouveaux formulaire
    setSwap(true);
    // Change de formulaire apres 1000ms
    delay(1000)
      .then(() => {
        if (setNextStep != null) setNextStep(data.step);
        return true;
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
      });
  }, [status, data, isRefetching]);

  const onSubmitForm: SubmitHandler<ClientInfoFormModel> = async (data) => {
    setClientInfo({
      companyName: data.companyName,
      companyWebsite: data.companyWebsite,
      contactLastname: data.contactLastname,
      contactFirstname: data.contactFirstname,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
      companyLocation: data.companyLocation,
      contactLocation: data.contactLocation,
      contactJobPosition: data.contactJobPosition,
      contactLinkedinProfile: data.contactLinkedinProfile,
      contactType: data.contactType !== undefined ? data.contactType : uiSettings?.contactType,
    });
    setIsLoading(true);
  };

  function handleNextMobileStep(): void {
    if (isMobileFormValid && !isLoading && mobileStep < maxMobileStep()) {
      setAnimation('invisible');
      delay(700)
        .then(() => {
          setMobileStep(mobileStep + 1);
          setAnimation('visible');
          return true;
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          toast.error('Une erreur est survenue, merci de réessayer dans quelques instants.');
        });
    }
  }

  const [mobileFormInputToFocus, setMobileFormInputToFocus] = useState<
    'companyWebsite' | 'contactPhone' | 'companyName' | 'contactLastname' | 'contactFirstname' | 'contactEmail'
  >();

  useEffect(() => {
    if (mobileFormInputToFocus !== undefined) {
      setFocus(mobileFormInputToFocus);
    }
  }, [mobileStep]);

  return (
    <div className="flex flex-col flex-grow z-10 overflow-auto " style={{ background: uiSettings?.bgColor }}>
      <Modal title="Bienvenue !" isOpen={isWelcomeModalOpen} setIsOpen={setIsWelcomeModalOpen}>
        <Text
          size="lg"
          position="justify"
          content="Laissez-vous guider par des questions personnalisées afin de mieux définir votre projet !"
        />
        <Text
          size="lg"
          position="justify"
          content={`Cette solution génère des questions sur mesure pour affiner la synthèse finale qui nous sera envoyée. Assurez-vous d’aller au bout du questionnaire afin de garantir un résultat de qualité (durée moyenne : ${duration()} minutes).`}
        />
        <Text
          size="lg"
          position="justify"
          textDecoration="italic"
          content="En utilisant cette solution, vous acceptez que vos données soient temporairement utilisées par l’algorithme."
        />
        <Button
          content="C'est parti !"
          color={uiSettings?.primaryColor}
          width="full"
          onClick={() => {
            setIsWelcomeModalOpen(false);
          }}
          borderWidth="2"
          borderWidthOnHover="2"
        />
      </Modal>
      <Modal title="Conditions générales d'utilisation" isOpen={isCGUModalOpen} setIsOpen={setIsCGUModalOpen}>
        <FrontOfficeCGU />
      </Modal>
      <Modal title="Politique de confidentialité" isOpen={isPolicyModalOpen} setIsOpen={setIsPolicyModalOpen}>
        <PrivacyPolicyWidget />
      </Modal>
      <form
        className={concatClassNames(
          'flex flex-col gap-6 px-8',
          'transition all duration-700',
          swap ? 'opacity-0' : 'opacity-100',
          'items-stretch flex-grow',
          'overflow-auto',
          'justify-center',
        )}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        {/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */}
        {isLargeScreen === true && (
          <>
            <br />

            <div
              id="formzone"
              ref={formZoneRef}
              className={concatClassNames(
                'flex flex-col gap-4',
                // 'bg-white/70',
                'p-8 h-full overflow-auto',
                'rounded-lg',
                'relative',
              )}
              style={{ background: uiSettings?.bgColor }}
            >
              <Text
                content="Commençons par quelques informations sur vous !"
                size="xl"
                weight="bold"
                color={uiSettings?.primaryColor}
              />

              {uiSettings?.contactType === 'BOTH' && (
                <Controller
                  {...register('contactType')}
                  control={control}
                  defaultValue={'PERSON'}
                  render={({ field: { value, onChange } }) => (
                    <RadioButtons
                      label="Êtes-vous un particulier ou un professionnel ?"
                      values={['PERSON', 'COMPANY']}
                      valuesDisplay={['Particulier', 'Professionnel']}
                      selected={value}
                      error="Champ obligatoire"
                      onChange={onChange}
                      isMandatory
                      disabled={isLoading}
                    />
                  )}
                />
              )}
              <div className="grid grid-cols-2 gap-6">
                {uiSettings?.isContactFirstnameRequired && (
                  <Input
                    label="Quel est votre prénom ?"
                    placeholder="Jeanne"
                    isMandatory
                    {...register('contactFirstname')}
                    error={errors.contactFirstname != null ? errors.contactFirstname.message : undefined}
                    primaryColor={uiSettings?.primaryColor}
                    secondaryColor={uiSettings?.secondaryColor}
                  />
                )}
                {uiSettings?.isContactLastnameRequired && (
                  <Input
                    label="Quel est votre nom ?"
                    placeholder="Forgeau"
                    uppercase
                    isMandatory
                    {...register('contactLastname')}
                    error={errors.contactLastname != null ? errors.contactLastname.message : undefined}
                    primaryColor={uiSettings?.primaryColor}
                    secondaryColor={uiSettings?.secondaryColor}
                  />
                )}

                {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'PERSON' : true) &&
                  uiSettings?.isContactLocationRequired && (
                    <Input
                      label="Quelle est votre adresse ?"
                      placeholder="1 rue Louis Blanc, 75010 Paris"
                      isMandatory
                      {...register('contactLocation')}
                      error={errors.contactLocation != null ? errors.contactLocation.message : undefined}
                      primaryColor={uiSettings?.primaryColor}
                      secondaryColor={uiSettings?.secondaryColor}
                    />
                  )}
              </div>
              <div className="grid grid-cols-2 gap-6">
                {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
                  uiSettings?.isCompanyNameRequired && (
                    <Input
                      rows={1}
                      isMandatory
                      label="Quel est le nom de votre entreprise ?"
                      placeholder="Discko"
                      {...register('companyName')}
                      error={errors.companyName != null ? errors.companyName.message : undefined}
                      primaryColor={uiSettings?.primaryColor}
                      secondaryColor={uiSettings?.secondaryColor}
                    />
                  )}
                {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
                  uiSettings?.isCompanyLocationRequired && (
                    <Input
                      rows={1}
                      isMandatory
                      label="Quelle est l'adresse de votre entreprise ?"
                      placeholder="Discko"
                      {...register('companyLocation')}
                      error={errors.companyLocation != null ? errors.companyLocation.message : undefined}
                      primaryColor={uiSettings?.primaryColor}
                      secondaryColor={uiSettings?.secondaryColor}
                    />
                  )}
              </div>
              {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
                uiSettings?.isCompanyWebsiteRequired && (
                  <Input
                    rows={1}
                    placeholder="https://discko.io"
                    label="Si vous en avez un, quelle est l'adresse de votre site web ?"
                    {...register('companyWebsite')}
                    inputMode="url"
                    error={errors.companyWebsite != null ? errors.companyWebsite.message : undefined}
                    primaryColor={uiSettings?.primaryColor}
                    secondaryColor={uiSettings?.secondaryColor}
                  />
                )}

              {(uiSettings?.contactType === 'BOTH' ? watch('contactType') === 'COMPANY' : true) &&
                (uiSettings?.isContactLinkedinProfileRequired || uiSettings?.isContactJobPositionRequired) && (
                  <div className="grid grid-cols-2 gap-6">
                    {uiSettings?.isContactJobPositionRequired && (
                      <Input
                        rows={1}
                        placeholder="Responsable Marketing"
                        label="Quel est votre poste ?"
                        {...register('contactJobPosition')}
                        inputMode="url"
                        error={errors.contactJobPosition != null ? errors.contactJobPosition.message : undefined}
                        primaryColor={uiSettings?.primaryColor}
                        secondaryColor={uiSettings?.secondaryColor}
                        isMandatory
                      />
                    )}

                    {uiSettings?.isContactLinkedinProfileRequired && (
                      <Input
                        rows={1}
                        placeholder="https://www.linkedin.com/in/jeanneforgeau/"
                        label="Lien vers votre profil Linkedin :"
                        {...register('contactLinkedinProfile')}
                        inputMode="url"
                        error={
                          errors.contactLinkedinProfile != null ? errors.contactLinkedinProfile.message : undefined
                        }
                        primaryColor={uiSettings?.primaryColor}
                        secondaryColor={uiSettings?.secondaryColor}
                      />
                    )}
                  </div>
                )}
              {(uiSettings?.isContactEmailRequired || uiSettings?.isContactPhoneRequired) && (
                <>
                  <Text
                    content="Et pour que l'on puisse vous recontacter... 😊"
                    size="xl"
                    weight="bold"
                    color={uiSettings?.primaryColor}
                  />
                  <div className="grid grid-cols-2 gap-6">
                    {uiSettings?.isContactEmailRequired && (
                      <Input
                        rows={1}
                        label="Email"
                        placeholder="jeanne@discko.io"
                        isMandatory
                        inputMode="email"
                        {...register('contactEmail')}
                        error={errors.contactEmail != null && isSubmitted ? errors.contactEmail.message : undefined}
                        primaryColor={uiSettings?.primaryColor}
                        secondaryColor={uiSettings?.secondaryColor}
                      />
                    )}
                    {uiSettings?.isContactPhoneRequired && (
                      <Input
                        rows={1}
                        label="Téléphone"
                        placeholder="06 12 34 56 78"
                        {...register('contactPhone')}
                        inputMode="tel"
                        error={errors.contactPhone != null ? errors.contactPhone.message : undefined}
                        primaryColor={uiSettings?.primaryColor}
                        secondaryColor={uiSettings?.secondaryColor}
                        isMandatory
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-end items-center">
              <RichText
                fragments={[
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: 'En continuant, vous acceptez nos ',
                    color: uiSettings?.primaryColor,
                    size: 'sm',
                    whitespace: 'pre',
                  },
                  {
                    contentType: 'link',
                    position: 'justify',
                    content: "Conditions Générales d'Utilisation",
                    size: 'sm',
                    color: uiSettings?.primaryColor,
                    weight: 'bold',
                    textDecoration: 'underline-4',
                    onClick: () => {
                      setIsCGUModalOpen(true);
                    },
                  },
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: ' et notre ',
                    color: uiSettings?.primaryColor,
                    size: 'sm',
                    whitespace: 'pre',
                  },
                  {
                    contentType: 'link',
                    position: 'justify',
                    content: 'Politique de Confidentialité',
                    textDecoration: 'underline-4',
                    color: uiSettings?.primaryColor,
                    size: 'sm',
                    weight: 'bold',
                    onClick: () => {
                      setIsPolicyModalOpen(true);
                    },
                  },
                  {
                    contentType: 'span',
                    position: 'justify',
                    content: '.',
                    color: uiSettings?.primaryColor,
                    size: 'sm',
                  },
                ]}
              ></RichText>
            </div>

            <div className="flex flex-row gap-10 justify-around items-center ">
              <Stepsbar
                completionFrom="0%"
                completionTo={
                  uiSettings?.totalNumberOfQuestions === 6
                    ? '1/4'
                    : uiSettings?.totalNumberOfQuestions === 12
                    ? '1/6'
                    : uiSettings?.totalNumberOfQuestions === 18
                    ? '1/8'
                    : uiSettings?.totalNumberOfQuestions === 24
                    ? '1/10'
                    : '0%'
                }
                animate
                primaryColor={uiSettings?.primaryColor ?? '#000000'}
                secondaryColor={uiSettings?.secondaryColor ?? '#DBDBDB'}
              ></Stepsbar>
              <Button
                content="Suivant"
                color={uiSettings?.primaryColor}
                width="lg"
                type="submit"
                disabled={!isFormValid}
              />
            </div>
          </>
        )}
        {isLargeScreen === false && (
          <>
            <div
              className={concatClassNames(
                'flex flex-col gap-4',
                // 'bg-white/70',
                'px-8 py-4 overflow-auto',
                'rounded-lg',
                'transition-all duration-700',
                animation === 'invisible' ? 'opacity-0' : 'opacity-100',
              )}
              style={{ background: uiSettings?.bgColor }}
            >
              <div
                id="formzone"
                ref={formZoneRef}
                className={concatClassNames('flex flex-col gap-6', 'overflow-auto', 'items-stretch flex-grow')}
              >
                {mobileStep === 1 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    1,
                  )}
                {mobileStep === 2 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    2,
                  )}
                {mobileStep === 3 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    3,
                  )}
                {mobileStep === 4 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    4,
                  )}
                {mobileStep === 5 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    5,
                  )}
                {mobileStep === 6 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    6,
                  )}
                {mobileStep === 7 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    7,
                  )}
                {mobileStep === 8 &&
                  handleMobileStepRendering(
                    uiSettings?.contactType === 'PERSON' ||
                      (uiSettings?.contactType === 'BOTH' && watchContactType === 'PERSON')
                      ? uiSettings?.mobileStepsToGetPersonInfo
                      : uiSettings?.mobileStepsToGetCompanyInfo,
                    8,
                  )}
              </div>
              {isMobileFormValid && !isLoading && mobileStep < maxMobileStep() && (
                <div
                  className="flex justify-end "
                  onClick={() => {
                    handleNextMobileStep();
                  }}
                >
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </div>
              )}
              {isMobileFormValid && !isLoading && mobileStep === maxMobileStep() && (
                <button className="flex justify-end " type="submit" disabled={!isFormValid}>
                  {getIcon('arrowRight', uiSettings?.primaryColor ?? '#000000', 'lg')}
                </button>
              )}
              {!isMobileFormValid && !isLoading && (
                <div className="flex justify-end ">
                  {getIcon('arrowRight', uiSettings?.secondaryColor ?? '#b6b6b6', 'lg')}
                </div>
              )}
              {isLoading && (
                <div className={concatClassNames('flex justify-end')}>
                  <div className="h-fit w-fit animate-spin">
                    {getIcon('spinCircle', getTextColor(uiSettings?.secondaryColor ?? '#929292', true), 'lg')}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
