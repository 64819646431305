import { type CreateCheckoutSessionDto, StripeApi } from 'api';

interface createCheckoutSessionProps {
  accessToken: string;
  createCheckoutSession: CreateCheckoutSessionDto;
}

export class StripeService {
  private readonly stripeClient: StripeApi;

  constructor() {
    this.stripeClient = new StripeApi();
  }

  public async createCheckoutSession({
    accessToken,
    createCheckoutSession,
  }: createCheckoutSessionProps): Promise<string> {
    return await this.stripeClient.stripeControllerCreateCheckoutSession(
      {
        createCheckoutSessionDto: createCheckoutSession,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public async openBillingPortal(accessToken: string, returnUrl: string): Promise<string> {
    return await this.stripeClient.stripeControllerCreatePortalSession(
      {
        returnUrl,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
