export type IconColor =
  | 'black'
  | 'blue'
  | 'blue-400'
  | 'cyan'
  | 'gray-100'
  | 'gray-200'
  | 'gray-500'
  | 'gray'
  | 'green'
  | 'orange'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow'
  | 'gradient2-to'
  | 'pink';

export const iconColorStyle: Record<IconColor, string> = {
  black: '#000000',
  blue: '#2D3389',
  cyan: '#61AFFE',
  'gray-100': '#E5E5E5',
  'gray-200': '#929292',
  'gray-500': '#3A3A3A',
  gray: '#6D6D6D',
  green: '#12B76A',
  orange: '#F79009',
  purple: '#462689',
  red: '#D92D20',
  white: '#FFFFFF',
  yellow: '#FBD34B',
  'gradient2-to': '#6D3A8A',
  pink: '#D83389',
  'blue-400': '#61AFFE',
};

// TODO : find naming convention for icon size
export type IconSize = 'xxs' | 'xs' | 'sm' | 'smd' | 'md' | 'lg' | 'xl' | '1.5xl' | 'xxl' | '20' | 'xxxl' | '4xl';

export const iconSizeStyle: Record<IconSize, string> = {
  xxs: 'w-1 h-1',
  xs: 'w-2 h-2',
  sm: 'w-4 h-4',
  smd: 'w-5 h-5',
  md: 'w-6 h-6',
  lg: 'w-8 h-8',
  xl: 'w-12 h-12',
  '1.5xl': 'w-13 h-13',
  xxl: 'w-14 h-14',
  '20': 'w-20 h-20',
  xxxl: 'w-28 h-28',
  '4xl': 'w-40 h-40',
};

export type IconName =
  | 'alertTriangle'
  | 'api'
  | 'arrowRight'
  | 'arrowUpFromSquare'
  | 'arrowDown'
  | 'arrowUp'
  | 'batch'
  | 'books'
  | 'box'
  | 'button'
  | 'cartArrowDown'
  | 'cartArrowUp'
  | 'check'
  | 'circleCheck'
  | 'checkCircle'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'circle'
  | 'circleExclamation'
  | 'clipboard'
  | 'clock'
  | 'component'
  | 'database'
  | 'dateInput'
  | 'edit'
  | 'externalLink'
  | 'eye'
  | 'feature'
  | 'feather'
  | 'frontEnd'
  | 'gripDotsVertical'
  | 'hardDrive'
  | 'home'
  | 'html'
  | 'icons'
  | 'image'
  | 'image2'
  | 'input'
  | 'information'
  | 'lightbulbOn'
  | 'link'
  | 'list'
  | 'message'
  | 'more'
  | 'paperPlane'
  | 'penLine'
  | 'persona'
  | 'personae'
  | 'plug'
  | 'plus'
  | 'product'
  | 'puzzle'
  | 'reset'
  | 'save'
  | 'schema'
  | 'search'
  | 'select'
  | 'settings'
  | 'signature'
  | 'slash'
  | 'sort'
  | 'spinCircle'
  | 'star'
  | 'status'
  | 'text'
  | 'title'
  | 'trash'
  | 'Ui'
  | 'eye'
  | 'upRight'
  | 'x'
  | 'zone';

export interface IconProps {
  color: string;
  size: IconSize;
  strokeWidth?: number;
}
