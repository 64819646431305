/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function BooksIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      fill={color}
      className={concatClassNames(iconSizeStyle[size])}
    >
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m44.69,37.53c-.53,0-12.93-1.39-13.22-1.26s-10.27,6.06-10.27,6.06l23.82.68-.34-5.48Z"
      />
      <polygon className="cls-1" points="73.06 34.39 90.63 33.13 72.67 44.31 73.06 34.39" />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m82.53,111.15s6.46,2.6,7.26,1.62c.89-1.09-.57-4.26-.57-4.26l4.18,7.29-10.13-3.4-.73-1.25Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m104,106.87c3.29-1.72,1.2-6.48-2.49-6.3,0,0,5.73-3.47,7.2-2.89s2.66,4.42,2.51,5.24-4.7,3.54-4.7,3.54l-2.51.42Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m35.86,106.72c-5.15-.32-4.67-7.97-3.95-8.16,0,0-5.72-.78-6.84-.54s-1.42,6.9-.55,7.84c.87.94,8.24,1.21,8.24,1.21l3.1-.35Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m90.56,31.54c.58.07.9.26,1.14.44.25.2.34.32.49.65,0,.26-.07.47-.11.7l-.02.09-.11.13-.22.27-.44.53c-.58.44-1.2.86-1.78,1.28l-1.53,1.09-.51.36-.46.29c-2.17,1.34-4.4,2.56-6.62,3.77-1,.52-1.98,1.08-2.95,1.62-1.24.71-2.47,1.47-3.92,1.78-.46.07-.49-.11-.3-.57.16-.5.56-1.23,1.22-1.71,2.43-1.56,4.91-3,7.53-4.28,1.56-.78,3.02-1.86,4.49-2.84l.9-.56.65-.43.18-.12s.07-.04.08-.07c0-.03-.03-.04-.05-.05-.09-.03-.19-.04-.3-.05-.45-.05-1-.06-1.63-.05-1.75.06-3.65.12-5.45.28-1.41.13-2.78.4-4.17.62-.31.05-.6.12-.91.15-1.14.15-1.53-.02-1.83-.61-.07-.12-.15-.24-.2-.35-.14-.31.28-.93,1.11-1.12,2.47-.63,5.1-.92,7.76-1.12.99-.07,1.98-.13,2.97-.17l.96-.04c.48-.02.98-.05,1.44-.05.95,0,1.85,0,2.62.08l-.07.06Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m80.48,108.51s-6.36-.07-5.42,1.6,16.97,6.92,16.97,6.92l-9.7-4.74-1.85-3.78Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m79.86,103.04c.71,1.93,1.43,3.85,2.2,5.75.28.69.65,1.34.94,2.01l.17.38s.03.09.05.13c.09.08.04.07.41.24l1.78.77c1.21.51,2.44.99,3.68,1.44,1.1.41,2.24.7,3.34,1.04l2.15.58c.36.1.7.26,1.05.39.35.13.69.29,1.01.53.41.29.34.38-.12.65-.47.33-1.33.74-2.24.69-3.11-.47-6.03-1.5-8.89-2.78-.85-.39-1.74-.71-2.67-1.08-.13-.06-.2-.07-.38-.16l-.53-.27c-.37-.17-.56-.52-.77-.86l-.21-.41-.16-.33-.3-.66c-.84-1.92-1.65-3.84-2.43-5.78l-1.43-3.57c-.73-1.8-1.37-3.64-2.12-5.42-.57-1.39-1.27-2.69-1.88-4.06-.13-.3-.29-.59-.41-.9-.38-1.17-.3-1.62.24-2.06.12-.1.24-.22.37-.3.43-.26.99.08,1.48.84,1.38,2.27,2.38,4.83,3.44,7.39.79,1.9,1.51,3.84,2.26,5.77l-.06.03Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m84.04,91.67c1.55,4.14,3.16,8.26,4.9,12.32.64,1.48,1.35,2.92,2.03,4.37.59,1.26,1.18,2.51,1.82,3.72.32.6.65,1.2,1,1.76.17.3.36.53.53.79l.25.3c.06.08.1.09.15.15.13.07.19.08.5.08.28-.01.6-.04.92-.11.65-.12,1.32-.29,1.99-.5,1.34-.41,2.68-.93,4-1.47,2.31-.96,4.58-2.1,6.77-3.29,1.4-.78,2.81-1.56,4.21-2.34,1.4-.79,2.79-1.59,4.31-2.18,1.01-.27.98-.08.53.82-.22.46-.6,1.01-1.08,1.63-.51.59-1.19,1.15-1.99,1.64-1.43.86-2.87,1.71-4.34,2.49-1.48.77-2.98,1.5-4.51,2.2-1.53.69-3.08,1.34-4.68,1.93-1.61.57-3.21,1.12-5.07,1.39-.35.04-.72.08-1.13.08-.41,0-1.04-.09-1.51-.34-.27-.22-.62-.44-.77-.66-.18-.2-.36-.4-.48-.59-.29-.39-.53-.77-.77-1.15-.47-.76-.9-1.53-1.32-2.3-.83-1.55-1.59-3.11-2.31-4.68-1.86-4.1-3.54-8.27-5.16-12.45-1-2.57-1.95-5.16-2.9-7.75-1.44-3.92-2.79-7.9-4.13-11.82-1.05-3.05-2.16-6.01-3.14-9.06-.22-.67-.44-1.32-.64-2-.69-2.54-.68-3.42-.24-4.1.09-.15.16-.34.23-.44.22-.31,1,.6,1.65,2.39,1.88,5.36,3.84,10.96,5.82,16.6,1.48,4.19,2.99,8.4,4.6,12.56l-.05.03Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m35.02,37.73l-1.79-.21-1.48-.14s-.04,0-.07.02l-.21.1c-.43.22-.86.52-1.31.76-.8.43-1.62.86-2.44,1.27l-2.5,1.19c-.74.35-1.47.74-2.2,1.1-.92.5-1.93.95-3.08.84-.35-.03-.32-.11-.12-.41.2-.28.48-.95.95-1.25.4-.35.85-.56,1.3-.79l1.36-.71c.91-.48,1.84-.96,2.8-1.4,1.15-.53,2.24-1.26,3.38-1.91l.71-.39.38-.19c.15-.09.38-.16.57-.23.18,0,.38-.04.54-.03l.37.02.7.05,1.37.13,2.64.29c1.33.14,2.66.32,3.98.4,1.03.07,2.05.02,3.07.02.23,0,.44.01.67.04.42.06.69.13.87.26.18.16.27.33.34.56.03.1.07.2.09.3.02.16-.06.29-.22.43-.17.13-.42.3-.73.33-.93.2-1.87.22-2.81.18-.95-.03-1.9-.09-2.85-.18-1.41-.14-2.84-.28-4.26-.42v-.05Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m18.01,110.11c-.42.1-8.88.94-8.36,3.28.66,2.95,25.18,4.04,25.18,4.04,0,0-13.9-3.24-15.2-4.18s-1.62-3.13-1.62-3.13Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m39.3,117.09s3.45.86,9.51.39c5.33-.41-4.34-1.52-4.34-1.52l-5.17,1.13Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m17.05,91.77c.14-6.24.33-12.47.55-18.71.08-2.27.08-4.55.18-6.82.17-3.85.38-7.69.63-11.54l.42-5.77.26-2.89.15-1.45.09-.81.14-1.09.22-.6c.09-.18.1-.44.33-.52.5-.27,1.18-.29,1.86-.27l.88.03.68.04,2.68.16c1.78.11,3.54.21,5.3.28,2.25.13,4.51.2,6.77.28,2.26.08,4.5.1,6.76.4,1.41.35,1.24.38.06.97-.59.31-1.44.61-2.47.94-1.03.28-2.25.44-3.57.47-3.24.03-6.47.03-9.68-.19l-4.8-.33-2.1-.14s-.16-.06-.17.03l-.02.05s0-.01-.04.22l-.13,1.04-.23,2.1c-.14,1.41-.28,2.83-.42,4.25-.53,5.53-.59,11.34-.79,17.03-.22,6.26-.42,12.53-.56,18.8-.09,3.86-.16,7.72-.19,11.57v6.63s.04,3.3.04,3.3l.04,1.63c.02.26.02.55.05.79.03.23.02.35.1.5.12.19.27.27.47.41.26.16.54.3.84.43.61.26,1.27.44,1.92.62,2.13.53,4.33.8,6.52.98,2.19.18,4.39.25,6.6.27.97,0,1.92-.03,2.89-.04,3.61-.06,4.72-.08,5.58.64.19.15.45.28.57.41.42.4-1.04,1.3-3.79,1.49-3.76.31-7.6.25-11.49-.11-1.95-.19-3.91-.45-5.89-.86-.99-.22-1.99-.44-3.02-.77-.51-.17-1.04-.35-1.61-.6-.32-.15-.49-.22-1-.58-.39-.29-.7-.66-.99-1.04-.09-.3-.19-.58-.29-.88-.05-.15-.05-.3-.07-.45l-.04-.29-.1-1.03c-.15-1.62-.17-3.19-.19-4.78l-.02-4.74.07-9.45h.07Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m104.04,59.21c1.31,3.68,2.61,7.36,3.87,11.05.46,1.35,1,2.67,1.44,4.02,1.59,4.76,3.09,9.54,4.62,14.32.68,2.12,1.43,4.22,2.16,6.31.46,1.34.93,2.68,1.4,4.02.44,1.35.89,2.7.95,4.17,0,.93-.08.83-.73.26-.32-.29-.83-.68-1.25-1.21-.22-.27-.46-.55-.66-.87-.18-.33-.35-.69-.49-1.07-2.08-5.4-3.76-10.93-5.39-16.53-.96-3.36-2.28-6.74-3.44-10.1-1.29-3.7-2.57-7.4-3.91-11.09-.82-2.27-1.65-4.53-2.51-6.79-1.3-3.41-2.61-6.84-4.04-10.17-1.11-2.59-2.35-5.06-3.57-7.57-.27-.55-.54-1.09-.81-1.64-.48-1.04-.77-1.74-.86-2.28-.06-.55.03-.93.2-1.28.08-.16.13-.34.2-.45.13-.18.36-.09.74.2.39.28.86.79,1.25,1.54,2.46,4.38,4.61,9.21,6.61,14.11,1.49,3.64,2.88,7.34,4.27,11.03l-.06.03Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m23.03,102.6c0-.81.04-1.6.12-2.41l.07-.61.1-.73c.14-.68.32-1.34.65-1.87.1-.08.2-.17.3-.26l.04-.03h0s0-.01,0-.01h0s-.06-.09.06.08h0s0,0,0,0h.02s.09-.05.09-.05l.18-.07c.26-.08.46-.24.76-.24l.89-.02c.28,0,.37.01.57.02.81.03,1.61,0,2.41.05,1.41.09,2.82.21,4.22.33l2.11.19,2.2.26c.28.05.56.04.83.13.26.09.53.16.78.27.17.25.28.5.38.78.06.31.12.62.15.95.05.37.04.51.07.75l.04.65c.04.81.09,1.62.08,2.43-.02.4,0,.81-.1,1.22-.11.4-.24.81-.43,1.2-.25.49-.3.41-.6-.03-.14-.22-.42-.52-.65-.88-.12-.18-.27-.37-.36-.57-.06-.2-.11-.41-.13-.64-.07-.84-.15-1.69-.24-2.52-.02-.21-.06-.41-.07-.62l-.03-.3v-.15s-.01,0-.01-.01c0,0,.03-.04.03-.07,0-.03,0-.04-.12-.07-.03,0-.03-.01-.09-.02l-.28-.02-.55-.05c-.74-.06-1.49-.18-2.24-.29-1.93-.29-3.97-.24-5.96-.33l-1.73-.07h-.26s-.17-.03-.17-.03c-.1,0-.26-.05-.28.1-.05.08-.08.17-.11.27-.03.11-.05.15-.1.45-.1.47-.16.97-.21,1.46-.06.65-.1,1.3-.1,1.95,0,.63.04,1.38.12,1.6.01.05.03.1.05.15.06.12.22.11.33.15l.19.04.33.06,1.43.21c.96.13,1.92.24,2.89.31,1.57.12,3.12.09,4.67.07.34,0,.68-.03,1.02-.02.32,0,.58.01.8.04.22.03.39.11.54.17.29.13.46.32.59.59.06.12.15.23.18.34.13.35-.4.75-1.37.96-2.86.3-5.83.12-8.82-.2-.62-.07-1.24-.14-1.86-.23l-1.17-.18c-.65-.05-1.05-.42-1.52-.71-.49-.71-.67-1.91-.71-2.3-.05-.55-.09-1.08-.1-1.6h.06Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m51.49,39.58c-.31.84-.57,2.92-.19,3.4.6.75,13.6,1.56,14.08.54.29-.62.89-3.1.21-3.46-.22-.12-3.64-.15-7.07-.34s-6.89-.52-7.04-.13Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m78.77,51.91c.14.88.64,2.44,1.2,2.66.89.36,13.38-5.75,13.3-6.88-.05-.68-.7-2.84-1.47-2.83-.25,0-4.01,2.25-7.09,3.76s-6.01,2.87-5.95,3.28Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m82,60.01c.14.88.64,2.44,1.2,2.66.89.36,13.38-5.75,13.3-6.88-.05-.68-.7-2.84-1.47-2.83-.25,0-4.01,2.25-7.09,3.76-3.1,1.52-6.01,2.87-5.95,3.28Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m50.36,94.3c-.31.84-.18,3.02.2,3.49.6.75,15.49,1.14,16.12.25.39-.56.46-2.94-.22-3.31-.21-.11-4.24-.29-8.1-.25-4.3.05-7.85-.58-8-.18Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m50.19,102.52c-.31.84-.23,3.08.15,3.55.6.75,15.45,1.27,16.02.16.31-.61.63-2.92-.04-3.28-.21-.12-4.07-.13-7.96-.31s-8.02-.51-8.17-.12Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m22.68,56.47c-.31.84-.18,3.02.2,3.49.6.75,17.71,1.14,18.34.25.39-.56.46-2.94-.22-3.31-.21-.11-5.35-.29-9.21-.25-4.3.05-8.96-.58-9.11-.18Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m22.51,64.69c-.31.84-.23,3.08.15,3.55.6.75,17.67,1.27,18.24.16.31-.61.63-2.92-.04-3.28-.21-.12-5.18-.13-9.07-.31s-9.13-.51-9.28-.12Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m109.8,102.4v-.03s.02-.01-.02-.14c-.05-.19-.12-.39-.18-.59-.13-.4-.3-.8-.46-1.19-.18-.38-.36-.77-.57-1.1-.05-.08-.11-.18-.14-.23,0-.02-.02-.04-.03-.07-.03-.05-.06-.1-.1-.15-.05-.09-.15-.06-.22-.02l-.11.05-.33.13c-2.23.85-4.5,1.81-6.68,2.84-.48.23-.96.48-1.42.75l-.66.42c-.11.07-.21.15-.3.23-.14.1-.05.31-.02.44.02.07.05.15.07.2l.13.44.15.45.09.48c.05.33.15.64.16.98-.12.49-.16.54-.81.47-.34-.03-.69-.17-1.07-.37-.19-.1-.38-.23-.55-.37-.09-.08-.17-.16-.26-.24-.06-.09-.11-.19-.15-.3-.08-.19-.15-.38-.22-.58-.04-.11-.06-.18-.1-.32-.05-.18-.11-.34-.13-.52-.14-.68-.21-1.42.17-1.97.26-.58.93-.97,1.51-1.28.33-.18.66-.34.99-.5,1.28-.68,2.57-1.28,3.92-1.81.8-.31,1.59-.7,2.38-1.1l1.19-.6.61-.29.4-.18.5-.21c.37-.13.75-.25,1.08-.17h.13s.08.09.15-.02l.24.12c.17.07.32.18.46.3.58.45,1.12,1.21,1.33,1.59.56.92.96,1.84,1.31,2.81l.15.45.07.24c.03.1.06.21.09.32.12.44.18.87.1,1.29-.03.21-.18.39-.27.58l-.4.51c-.13.16-.34.32-.47.44-.13.12-.26.21-.39.31-1.6,1.13-3.29,1.81-4.95,2.56-1.29.56-2.55,1.19-3.9,1.65-.3.1-.58.23-.9.3-1.16.28-1.59.12-1.9-.56-.07-.16-.16-.31-.21-.47-.16-.55.23-1.05.95-1.33,2.13-.87,4.4-1.72,6.55-2.8,1.48-.7,3.47-2.2,2.98-1.86h.07Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m70.18,117.35c-2.95.97-5.98,1.47-9.01,1.64-3.03.17-6.05.06-9.05-.34-1.5-.21-2.98-.51-4.47-.86-.37-.09-.74-.2-1.11-.3l-.56-.16-.68-.24c-.15-.06-.33-.1-.46-.18l-.37-.25c-.08-.32-.41-.44-.42-.8-.04-.15-.08-.31-.1-.48l-.05-.4-.06-.57c-.26-2.99-.38-5.92-.51-8.88-.15-4.3-.33-8.6-.4-12.9-.23-15.15-.16-30.3.33-45.46.12-3.36.25-6.74.45-10.11.1-1.69.22-3.37.39-5.06.08-.84.19-1.69.31-2.55.15-.87.27-1.69.62-2.67l.26-.52c.1-.16.23-.32.35-.47.31-.24.57-.45.83-.64.27-.18.53-.28.8-.42.54-.3,1.07-.42,1.61-.62,1.07-.32,2.14-.54,3.2-.71,2.13-.32,4.26-.45,6.38-.47,2.12-.02,4.25.07,6.35.36,1.05.14,2.1.34,3.14.54,1.04.26,2.06.57,3.06.97,1.27.57,1.77.91,1.69,1-.08.11-.75,0-1.9.02-1.16.01-2.78.16-4.69.2-.95.03-1.98.07-3.07.11-1.09,0-2.23-.02-3.43-.03-2.2-.03-4.4.06-6.53.33-1.06.13-2.1.33-3.07.58-.44.15-.96.28-1.32.47l-.55.26c-.11.1-.24.19-.26.19,0,.02.03-.09-.09.26-.09.3-.17.63-.24.99-.14.72-.27,1.48-.37,2.25-.21,1.55-.36,3.14-.49,4.73-.26,3.19-.44,6.41-.6,9.63-.31,6.45-.53,12.94-.71,19.45-.3,10.53-.1,21.49.02,32.27l.16,8.88.12,4.43.1,2.21.07,1.09.04.54.04.34c.01.07.01.15.05.21.06.03.13.06.2.08l.35.13c.16.07.34.11.51.17.68.22,1.37.41,2.08.58,1.42.29,2.85.53,4.3.66,2.9.29,5.83.29,8.72-.01,1.78-.17,3.54-.48,5.26-.93.86-.21,1.7-.5,2.53-.79.39-.14.86-.36,1.17-.49.1-.05.23-.06.3-.15.06-.09.09-.2.12-.31.06-.27.12-.79.16-1.2.04-.44.06-.88.08-1.33.04-.9.06-1.8.07-2.71.03-1.81.02-3.63,0-5.45-.16-11.05-.31-22.16-.47-33.13-.11-8.53-.29-16.9-.34-25.34,0-1.86-.04-3.68-.03-5.55.03-3.47.11-5.76.31-7.37.21-1.6.51-2.52.92-3.25.19-.33.37-.78.53-.97.29-.32.52.23.73,1.49.22,1.27.37,3.24.37,5.78.02,7.53.05,15.26.07,23.1.07,7.84.15,15.79.23,23.76.06,5.93.12,11.87.18,17.81.02,2.98.03,5.96-.03,8.96-.06,1.51-.11,3-.28,4.58-.05.42-.09.77-.21,1.29-.16.69-.42,1.42-1.05,1.76-.14.1-.3.17-.46.24l-.42.17-.55.22c-.36.16-.74.28-1.11.39l-.03-.05Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m99.12,19.8c.14.12-.03.32-.33.57-.09.08-.41.2-.48.17-.13-.05-.27-.1-.38-.16-.09-.03-.69-.83-.91-1.11-.22-.26-.37-.56-.5-.86-.28-.6-.42-1.24-.59-1.88-.03-.14-.04-.29-.02-.44.08-.51.01-1-.1-1.5-.02-.08-.01-.17-.11-.24-.19-.12-.29-1.5-.21-1.68.17-.44.35-.88.53-1.3.03-.06,0-.13.12-.18.28-.1.25-.24.29-.37.08-.18.17-.35.31-.51.09-.08.26-.14.38-.2.12-.06.38.03.42.07.08.09.24.06.36.07.04,0,.1.01.13.02.08.04.83,1.08.78,1.16-.02.03.17,1.36.2,1.5.02.08-.04.16-.08.24-.03.04.19,1.09.19,1.13-.03.22.08.43-.13.68-.04.05-.03.09-.02.14.07.38.02.77.02,1.17,0,.2.06.39.09.59,0,.04-.02.08-.03.12-.01.05-.03.1-.02.14.1.46.03.97.2,1.42.03.1-.07.69,0,.8.03.06-.18.39-.12.44Z"
      />
      <path
        strokeWidth="0"
        stroke={color}
        className="cls-1"
        d="m102.28,24.73c-.12.11-.29-.05-.48-.34-.06-.09-.13-.39-.1-.45.06-.11.12-.22.18-.32.04-.04.76-.56,1.02-.72.24-.17.5-.27.75-.36.53-.18,1.05-.4,1.59-.54.12-.03.24-.04.37-.03.44.05.85-.04,1.26-.18.07-.02.14-.03.19-.12.08-.18,1.21-.39,1.37-.35.39.1.77.12,1.16.28.06.02.11-.01.17.08.14.22.25.17.37.19.16.02.32.05.49.11.08.03.17.13.26.21.08.07.08.34.06.38-.06.1.02.22.04.33,0,.04.02.09.02.12,0,.05-.79.8-.88.85-.01,0-.32.18-.64.3-.33.1-.67.21-.74.25-.07.04-.16,0-.24-.03-.04,0-1.02.34-1.06.32-.22,0-.41.11-.66-.06-.05-.03-.09-.02-.13,0-.36.08-.73.06-1.1.07-.19,0-.37.06-.56.08-.03,0-.07-.02-.11-.03-.05,0-.09-.03-.14-.02-.44.07-.9.04-1.32.18-.1.03-.63-.08-.74-.05-.06.01-.34-.2-.39-.15Z"
      />
    </svg>
  );
}
