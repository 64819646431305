import React from 'react';

import {
  type TextColor,
  type TextWeight,
  textColorStyle,
  textPositionStyle,
  textWeightStyle,
} from 'components/RichText/RichText';

import concatClassNames from 'utils/classNames';

export interface HTitleProps {
  text?: string;
  color?: string;
  position?: 'center' | 'left' | 'right' | 'justify';
  weight?: TextWeight;
  style?: string;
  strikethrough?: boolean;
  font?: 'font-circularStd-Medium' | 'font-circularStd-Light' | 'font-calistoga' | 'arial';
}

export function H1Title({ text, color = '#000000', position = 'right', weight = 'medium' }: HTitleProps): JSX.Element {
  return (
    <h1
      className={concatClassNames(
        'text-[30px] lg:text-[30px] xl:text-[48px] font-circularStd-Medium',
        textColorStyle[color],
        textPositionStyle[position],
        textWeightStyle[weight],
        'lg:leading-tight',
      )}
    >
      {text}
    </h1>
  );
}
