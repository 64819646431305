import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function CheckIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      className={concatClassNames(iconSizeStyle[size])}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 6L9 17L4 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
