/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormOrchestratorResponseDto
 */
export interface FormOrchestratorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof FormOrchestratorResponseDto
     */
    step: string;
    /**
     * 
     * @type {object}
     * @memberof FormOrchestratorResponseDto
     */
    message: object;
}

/**
 * Check if a given object implements the FormOrchestratorResponseDto interface.
 */
export function instanceOfFormOrchestratorResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "step" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function FormOrchestratorResponseDtoFromJSON(json: any): FormOrchestratorResponseDto {
    return FormOrchestratorResponseDtoFromJSONTyped(json, false);
}

export function FormOrchestratorResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormOrchestratorResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': json['step'],
        'message': json['message'],
    };
}

export function FormOrchestratorResponseDtoToJSON(value?: FormOrchestratorResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': value.step,
        'message': value.message,
    };
}

