/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoUserIdDto } from './DisckoUserIdDto';
import {
    DisckoUserIdDtoFromJSON,
    DisckoUserIdDtoFromJSONTyped,
    DisckoUserIdDtoToJSON,
} from './DisckoUserIdDto';

/**
 * 
 * @export
 * @interface GetDisckoCompanyDto
 */
export interface GetDisckoCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    companyStatus: GetDisckoCompanyDtoCompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    plan: GetDisckoCompanyDtoPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof GetDisckoCompanyDto
     */
    credits: number;
    /**
     * 
     * @type {Date}
     * @memberof GetDisckoCompanyDto
     */
    subscriptionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetDisckoCompanyDto
     */
    hasAccessToStandardFeaturesUntil: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetDisckoCompanyDto
     */
    hasAccessToPremiumFeaturesUntil: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isSubscribed: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    urlRedirect?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isOldPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    primaryColor: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    secondaryColor: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    bgColor: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    watermark?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    urlWebhook?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    activityDescription: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDisckoCompanyDto
     */
    requirementCategories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetDisckoCompanyDto
     */
    contactType: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactLastnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactFirstnameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactEmailRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactPhoneRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isCompanyNameRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isCompanyLocationRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isCompanyWebsiteRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactLinkedinProfileRequired: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    isContactJobPositionRequired: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetDisckoCompanyDto
     */
    totalNumberOfQuestions: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDisckoCompanyDto
     */
    synthesisItems?: Array<string>;
    /**
     * 
     * @type {Array<DisckoUserIdDto>}
     * @memberof GetDisckoCompanyDto
     */
    users: Array<DisckoUserIdDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetDisckoCompanyDto
     */
    hasStripeAccount: boolean;
}


/**
 * @export
 */
export const GetDisckoCompanyDtoCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetDisckoCompanyDtoCompanyStatusEnum = typeof GetDisckoCompanyDtoCompanyStatusEnum[keyof typeof GetDisckoCompanyDtoCompanyStatusEnum];

/**
 * @export
 */
export const GetDisckoCompanyDtoPlanEnum = {
    Classic: 'classic',
    Premium: 'premium',
    Null: 'null'
} as const;
export type GetDisckoCompanyDtoPlanEnum = typeof GetDisckoCompanyDtoPlanEnum[keyof typeof GetDisckoCompanyDtoPlanEnum];


/**
 * Check if a given object implements the GetDisckoCompanyDto interface.
 */
export function instanceOfGetDisckoCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "plan" in value;
    isInstance = isInstance && "credits" in value;
    isInstance = isInstance && "hasAccessToStandardFeaturesUntil" in value;
    isInstance = isInstance && "hasAccessToPremiumFeaturesUntil" in value;
    isInstance = isInstance && "isSubscribed" in value;
    isInstance = isInstance && "primaryColor" in value;
    isInstance = isInstance && "secondaryColor" in value;
    isInstance = isInstance && "bgColor" in value;
    isInstance = isInstance && "activityDescription" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "isContactLastnameRequired" in value;
    isInstance = isInstance && "isContactFirstnameRequired" in value;
    isInstance = isInstance && "isContactLocationRequired" in value;
    isInstance = isInstance && "isContactEmailRequired" in value;
    isInstance = isInstance && "isContactPhoneRequired" in value;
    isInstance = isInstance && "isCompanyNameRequired" in value;
    isInstance = isInstance && "isCompanyLocationRequired" in value;
    isInstance = isInstance && "isCompanyWebsiteRequired" in value;
    isInstance = isInstance && "isContactLinkedinProfileRequired" in value;
    isInstance = isInstance && "isContactJobPositionRequired" in value;
    isInstance = isInstance && "totalNumberOfQuestions" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "hasStripeAccount" in value;

    return isInstance;
}

export function GetDisckoCompanyDtoFromJSON(json: any): GetDisckoCompanyDto {
    return GetDisckoCompanyDtoFromJSONTyped(json, false);
}

export function GetDisckoCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDisckoCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyStatus': json['companyStatus'],
        'plan': json['plan'],
        'credits': json['credits'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : (new Date(json['subscriptionDate'])),
        'hasAccessToStandardFeaturesUntil': (new Date(json['hasAccessToStandardFeaturesUntil'])),
        'hasAccessToPremiumFeaturesUntil': (new Date(json['hasAccessToPremiumFeaturesUntil'])),
        'isSubscribed': json['isSubscribed'],
        'urlRedirect': !exists(json, 'urlRedirect') ? undefined : json['urlRedirect'],
        'isOldPrice': !exists(json, 'isOldPrice') ? undefined : json['isOldPrice'],
        'primaryColor': json['primaryColor'],
        'secondaryColor': json['secondaryColor'],
        'bgColor': json['bgColor'],
        'watermark': !exists(json, 'watermark') ? undefined : json['watermark'],
        'urlWebhook': !exists(json, 'urlWebhook') ? undefined : json['urlWebhook'],
        'activityDescription': json['activityDescription'],
        'requirementCategories': !exists(json, 'requirementCategories') ? undefined : json['requirementCategories'],
        'contactType': json['contactType'],
        'isContactLastnameRequired': json['isContactLastnameRequired'],
        'isContactFirstnameRequired': json['isContactFirstnameRequired'],
        'isContactLocationRequired': json['isContactLocationRequired'],
        'isContactEmailRequired': json['isContactEmailRequired'],
        'isContactPhoneRequired': json['isContactPhoneRequired'],
        'isCompanyNameRequired': json['isCompanyNameRequired'],
        'isCompanyLocationRequired': json['isCompanyLocationRequired'],
        'isCompanyWebsiteRequired': json['isCompanyWebsiteRequired'],
        'isContactLinkedinProfileRequired': json['isContactLinkedinProfileRequired'],
        'isContactJobPositionRequired': json['isContactJobPositionRequired'],
        'totalNumberOfQuestions': json['totalNumberOfQuestions'],
        'synthesisItems': !exists(json, 'synthesisItems') ? undefined : json['synthesisItems'],
        'users': ((json['users'] as Array<any>).map(DisckoUserIdDtoFromJSON)),
        'hasStripeAccount': json['hasStripeAccount'],
    };
}

export function GetDisckoCompanyDtoToJSON(value?: GetDisckoCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyStatus': value.companyStatus,
        'plan': value.plan,
        'credits': value.credits,
        'subscriptionDate': value.subscriptionDate === undefined ? undefined : (value.subscriptionDate.toISOString()),
        'hasAccessToStandardFeaturesUntil': (value.hasAccessToStandardFeaturesUntil.toISOString()),
        'hasAccessToPremiumFeaturesUntil': (value.hasAccessToPremiumFeaturesUntil.toISOString()),
        'isSubscribed': value.isSubscribed,
        'urlRedirect': value.urlRedirect,
        'isOldPrice': value.isOldPrice,
        'primaryColor': value.primaryColor,
        'secondaryColor': value.secondaryColor,
        'bgColor': value.bgColor,
        'watermark': value.watermark,
        'urlWebhook': value.urlWebhook,
        'activityDescription': value.activityDescription,
        'requirementCategories': value.requirementCategories,
        'contactType': value.contactType,
        'isContactLastnameRequired': value.isContactLastnameRequired,
        'isContactFirstnameRequired': value.isContactFirstnameRequired,
        'isContactLocationRequired': value.isContactLocationRequired,
        'isContactEmailRequired': value.isContactEmailRequired,
        'isContactPhoneRequired': value.isContactPhoneRequired,
        'isCompanyNameRequired': value.isCompanyNameRequired,
        'isCompanyLocationRequired': value.isCompanyLocationRequired,
        'isCompanyWebsiteRequired': value.isCompanyWebsiteRequired,
        'isContactLinkedinProfileRequired': value.isContactLinkedinProfileRequired,
        'isContactJobPositionRequired': value.isContactJobPositionRequired,
        'totalNumberOfQuestions': value.totalNumberOfQuestions,
        'synthesisItems': value.synthesisItems,
        'users': ((value.users as Array<any>).map(DisckoUserIdDtoToJSON)),
        'hasStripeAccount': value.hasStripeAccount,
    };
}

