import {
  GenericRequirementsApi,
  type GetGenericRequirementContactDto,
  type GetGenericRequirementDto,
  type GetGenericRequirementIdParamsDto,
  type GetGenericRequirementsDto,
} from 'api';
import { get } from 'http';

export class RequirementService {
  private readonly genericRequirementClient: GenericRequirementsApi;

  constructor() {
    this.genericRequirementClient = new GenericRequirementsApi();
  }

  public async getRequirement(requirementId: string, accessToken: string): Promise<GetGenericRequirementDto> {
    const getGenericRequirementDto: GetGenericRequirementDto =
      await this.genericRequirementClient.authRequirementControllerFindById(
        {
          id: requirementId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    getGenericRequirementDto.contact = JSON.parse(getGenericRequirementDto.contact);
    getGenericRequirementDto.projectType = JSON.parse(getGenericRequirementDto.projectType);
    getGenericRequirementDto.results = JSON.parse(getGenericRequirementDto.results);

    return getGenericRequirementDto;
  }

  public async findAll(accessToken: string): Promise<GetGenericRequirementsDto> {
    const getGenericRequirementsDto: GetGenericRequirementsDto =
      await this.genericRequirementClient.authRequirementControllerFindAll({
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
    getGenericRequirementsDto.requirements.forEach((requirement: GetGenericRequirementDto) => {
      requirement.contact = JSON.parse(requirement.contact);
      requirement.projectType = JSON.parse(requirement.projectType);
    });

    return getGenericRequirementsDto;
  }

  public async initRequirement(
    clientsInfo: GetGenericRequirementContactDto,
    companyId: string,
  ): Promise<GetGenericRequirementIdParamsDto> {
    return await this.genericRequirementClient.formGenericRequirementControllerInitRequirement({
      companyId,
      getGenericRequirementContactDto: clientsInfo,
    });
  }

  public async readRequirement(requirementId: string, accessToken: string): Promise<GetGenericRequirementDto> {
    const getGenericRequirementDto: GetGenericRequirementDto =
      await this.genericRequirementClient.authRequirementControllerRead(
        {
          id: requirementId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
    return getGenericRequirementDto;
  }

  public async commentRequirement(
    requirementId: string,
    comment: string,
    companyId: string,
  ): Promise<GetGenericRequirementDto> {
    const getGenericRequirementDto: GetGenericRequirementDto =
      await this.genericRequirementClient.formGenericRequirementControllerAddComment({
        id: requirementId,
        addCommentDto: { comment },
        companyId,
      });
    return getGenericRequirementDto;
  }
}
