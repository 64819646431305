/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function FeatherIcon({ color, size, strokeWidth }: IconProps): JSX.Element {
  return (
    <svg
      className={concatClassNames(iconSizeStyle[size])}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9209 17.33C29.4689 15.782 30.3386 13.6824 30.3386 11.4931C30.3386 9.30386 29.4689 7.20427 27.9209 5.65624C26.3728 4.1082 24.2733 3.23853 22.084 3.23853C19.8948 3.23853 17.7952 4.1082 16.2471 5.65624L6.96588 14.9375V26.625H18.6534L27.9209 17.33Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0909 11.5L2.84088 30.75"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1534 21.125H12.4659"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
