/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function PlugIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      className={concatClassNames(iconSizeStyle[size], color)}
    >
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m22.15,16.95c6.2.19,11.66,2.58,16.63,5.25,1.8.98,3.57,1.89,5.29,2.74,1.52.75,3.05,1.4,4.62,1.77,1.57.4,3.22.59,4.87.58,3.32-.02,6.69-.86,9.8-2.27,2.76-1.25,5.3-3.06,7.58-5.09,1.45-1.32,2.86-2.7,4.22-4.14.69-.71,1.34-1.46,2.05-2.16l2.15-2.11c.9-.88,1-.6.68.5-.16.55-.41,1.32-.76,2.21-.18.45-.37.94-.63,1.42-.27.47-.6.94-.96,1.41-.66.82-1.32,1.64-2.03,2.44-.35.4-.71.79-1.07,1.18l-1.16,1.11c-1.58,1.44-3.26,2.8-5.09,3.98-3.65,2.37-7.86,4.03-12.28,4.48-2.63.26-5.38.24-8.08-.37-2.71-.57-5.19-1.69-7.47-2.88-2.51-1.33-4.85-2.66-7.34-3.72-2.47-1.08-5.03-1.91-7.62-2.38-3.18-.53-6.48-.45-9.39.79-2.2.9-4.2,2.37-5.71,4.22-1.51,1.86-2.5,4.16-2.78,6.53-.44,3.69.93,7.44,3.6,9.68.3.24.59.48.89.71.32.2.67.37.99.57.59.41,1.16.6,1.62.82.45.22.78.52,1.11.65.62.33.95.72,1.21,1.18.12.21.29.41.36.58.11.29-.17.46-.83.61-.34.08-.76.1-1.27.1-.51.03-1.1-.09-1.74-.28-.47-.16-.96-.29-1.41-.5l-1.35-.72c-.22-.13-.46-.24-.67-.39l-.61-.48c-.4-.33-.83-.64-1.21-1.01-1.48-1.51-2.66-3.36-3.38-5.41-1.36-4.11-.92-8.79,1.19-12.63,1.52-2.88,3.93-5.24,6.73-6.86,2.83-1.63,6.14-2.25,9.24-2.24v.09Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m21.96,53.47c-.09-.28,3.39-6.36-1.13-8.61-3.62-1.81,1.56-1.15,3.22-.38s4.33,2.12,2.36,5.67-4.45,3.32-4.45,3.32Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m59.62,56.88c2.85,1.16,5.76,2.19,8.66,3.21.93.33,2.09-.37,2.31-1.31.24-1.05-.32-1.96-1.31-2.31-2.9-1.02-5.81-2.05-8.66-3.21-.91-.37-2.1.4-2.31,1.31-.25,1.09.33,1.91,1.31,2.31h0Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m55.43,69.31c2.85,1.16,5.76,2.19,8.66,3.21.93.33,2.09-.37,2.31-1.31.24-1.05-.32-1.96-1.31-2.31-2.9-1.02-5.81-2.05-8.66-3.21-.91-.37-2.1.4-2.31,1.31-.25,1.09.33,1.91,1.31,2.31h0Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m58.14,55.94s-8.52-12.94-20.67-11.44c-12.98,1.61-12.21,15.7-8.32,20.13,0,0-7.4-3.05-5-14.2,2.4-11.15,14.81-9.41,22.76-6.8,7.95,2.61,13.73,4.8,13.73,4.8l-2.51,7.51Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m14.71,48.58c-.08-1.83.68-3.6,2.01-4.8.48-.43,1.01-.86,1.62-1.12.56-.24,1.08-.35,1.59-.43.53-.05,1.04-.01,1.54.04.99.17,1.87.52,2.68.9.7.33,1.4.66,2.1.98.44.21.84.48,1.22.76.35.33.62.78.87,1.21.15.29-.07.54-.53.8-.45.24-1.24.64-1.83.66-.45-.09-.87-.21-1.27-.33l-1.06-.53c-.69-.35-1.34-.67-2-.95-.4-.17-.81-.23-1.21-.2-.42.03-.77.05-1.07.2-.68.3-1.31,1.03-1.49,1.93-.07.27-.07.56-.07.84.03.14.02.29.06.42.02.13.05.2.1.29.11.21.52.58.96.89.44.31.93.59,1.44.82.79.36,1.67.51,2.51.7.19.04.38.06.56.11.16.06.29.14.39.21.1.09.15.23.2.34.09.24.11.49.09.81,0,.15,0,.29-.02.41-.04.41-.47.76-1.16.99-.92.08-1.76-.15-2.62-.4-.85-.27-1.68-.63-2.52-1.1-.31-.17-.63-.37-.95-.59-.35-.26-.6-.45-1.07-.91-.4-.47-.76-1.01-.91-1.58-.14-.45-.22-.91-.26-1.38h.08Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m24.75,46.98c1.73-2.66,4.41-4.84,7.39-5.83,2.92-1.12,6.05-1.27,8.92-.97,2.1.24,4.17.53,6.11,1.13,2.42.72,4.72,1.6,6.97,2.49l3.34,1.31c.55.2,1.1.43,1.64.66l.83.34c.48.2.89.33,1.34.49l.9.5c.14.09.32.14.43.27.12.14.16.31.22.46.2.63.16,1.38.03,2.15-.06.38-.15.77-.24,1.15l-.22.86-.42,1.49c-.88,2.95-1.78,5.87-2.7,8.75-.59,1.85-1.16,3.71-1.81,5.54-.33.91-.75,1.8-1.16,2.68l-.65,1.31c-.24.43-.55.83-.86,1.23-.43.48-.69.66-.82.59-.14-.07-.18-.41-.32-1.01-.35-1.23-.59-3.3-.07-5.45,1.1-3.38,2.2-6.76,3.3-10.14.55-1.69,1.09-3.37,1.58-5.04l.35-1.24c.04-.14.03-.08.04-.11l.02-.04.05-.09c.03-.07.07-.15.09-.24,0-.1-.1-.08-.16-.13l-.06-.08s-.03-.02-.05-.02c0,0,0-.02,0-.02l-.12-.04-1.05-.32c-2.31-.85-4.54-1.8-6.82-2.56-2.27-.78-4.54-1.45-6.77-1.96-2.45-.55-4.92-.83-7.28-.56-2.35.28-4.66,1.02-6.44,2.5-2.22,1.8-3.74,4.57-4.04,7.5-.23,2.22.25,4.44,1.43,6.4,1.17,1.96,2.89,3.63,4.82,4.99,3.03,2.11,6.53,3.35,10.08,4.34.79.2,1.55.41,2.36.59,1.47.38,2.43.66,3.08,1.02.31.23.55.44.73.68.18.24.32.51.43.76.11.23.27.45.32.62.09.29-.19.47-.84.74-.65.28-1.62.45-2.78.24-3.47-.52-7-1.48-10.44-3.02-1.71-.8-3.42-1.68-5.02-2.83-.83-.52-1.59-1.18-2.37-1.81-.74-.7-1.5-1.38-2.16-2.2-1.03-1.15-1.87-2.55-2.55-4.02-.63-1.51-1.05-3.15-1.13-4.83-.18-3.27.72-6.51,2.46-9.25l.09.05Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m102.73,109.1c-5.77.93-11.62.42-17.09-1.25-2.01-.61-3.89-1.25-5.76-1.97-3.27-1.28-6.47-2.55-9.74-3.44-1.63-.42-3.28-.8-4.93-.94-1.65-.19-3.3-.15-4.91.1l-1.07.2c-.36.06-.69.21-1.03.31-.34.12-.7.2-1.02.36l-.98.47-.49.23-.43.27c-.3.17-.55.39-.82.6-.27.2-.5.46-.76.69-.23.26-.46.51-.68.78-.55.69-.98,1.48-1.34,2.29-.33.83-.58,1.68-.73,2.56-.17,1.74-.46,3.57-.06,5.46.22,1.17.03,1.15-.8.33-.22-.2-.44-.47-.7-.8-.27-.32-.54-.71-.81-1.17-.3-.45-.47-.99-.69-1.57-.1-.29-.14-.6-.24-.92-.03-.32-.03-.65,0-.98.13-2.37.91-4.71,2.26-6.72.66-1.01,1.5-1.92,2.46-2.71.48-.39,1.01-.72,1.56-1.04.53-.27,1.05-.49,1.58-.74,2.13-.88,4.41-1.37,6.65-1.42,2.24-.04,4.42.25,6.52.73,4.99,1.18,9.82,2.98,14.47,4.67,1.28.47,2.54.91,3.79,1.28,1.27.35,2.55.66,3.85.88,2.6.44,5.25.61,7.88.46,3.23-.21,6.46-.86,9.4-2.19,2.23-.99,4.31-2.3,6.08-3.98,1.78-1.67,3.29-3.64,4.37-5.8.84-1.67,1.38-3.46,1.55-5.23.13-1.76-.09-3.49-.84-4.92-.33-.63-.73-1.18-1.19-1.71-.83-1.01-1.59-1.54-2.03-2.06-.45-.53-.67-.98-.79-1.51-.05-.25-.16-.51-.17-.73-.02-.36.32-.53,1.01-.52.68,0,1.73.23,2.89.97.87.54,1.67,1.24,2.4,2.04.71.83,1.25,1.81,1.67,2.84.81,2.08.95,4.34.64,6.44-.63,4.22-2.71,7.9-5.36,10.96-1.97,2.28-4.44,4.21-7.12,5.61-2.68,1.41-5.55,2.36-8.46,2.87l-.02-.08Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m107.67,80.69s1.37-8.36,7.06-5.63c2.53,1.22-5.4-3.71-6.85-2.89-3.48,1.98-.2,8.52-.2,8.52Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m113.01,79.74h.05s.07-.01.11-.04c.03-.04.02.03.07-.11l.14-.34c.18-.47.33-.97.46-1.45.05-.18.07-.43.08-.55,0-.06,0-.11.03-.17.07-.16.15-.48-.07-.59-.02-.04-.06-.06-.09-.09l-.05-.04-.15-.09-.59-.36c-.4-.23-.81-.46-1.22-.66-.63-.31-1.3-.5-1.96-.64-.43-.09-.84-.21-1.24-.33-.4-.17-.78-.44-1.18-.68-.25-.17-.24-.5.03-.96.26-.45.84-1.17,1.51-1.4.56-.08,1.1-.07,1.62-.02.51.12,1,.26,1.47.43.95.34,1.85.8,2.69,1.37.15.1.23.16.45.28.24.13.46.27.69.38.58.35,1.08.85,1.3,1.5.22.65.07,1.43.01,2.12-.01.13-.04.26-.06.38l-.05.27c-.03.18-.08.34-.12.52-.09.34-.19.67-.3,1-.11.33-.23.65-.36.98l-.1.25c-.06.11-.1.23-.15.34-.09.23-.2.45-.32.65-.12.19-.26.36-.41.53-.4.25-.82.46-1.29.5-.24.03-.46.07-.7.06h-.36c-.09,0-.18,0-.27-.02-1.31-.11-2.5-.42-3.71-.69-.93-.22-1.89-.31-2.88-.64-.22-.06-.45-.13-.67-.24-.19-.11-.35-.24-.46-.38-.11-.15-.14-.32-.17-.45-.05-.32.03-.58.2-.85.08-.12.15-.24.23-.34.15-.16.34-.23.57-.32.22-.07.47-.19.75-.24,1.28.08,2.78.52,4.28.77.55.1,1.11.19,1.63.24.24.03.61.03.48.05.01,0,.03.02.06.03l.05.07Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m92.81,84.88c8.19.75,16.04-12.89,6.12-20.71-10.19-8.02-23.18-1.15-23.18-1.15l3.23-8.35s17,2.74,22.81,8.52c5.8,5.78,6.46,8.29,4.58,14.14s-4.92,7.76-8.21,8.3-5.34-.75-5.34-.75Z"
      />
      <path
        stroke={color}
        strokeWidth={2}
        className="cls-1"
        d="m104.84,73.98c.06-2.05-.45-4.06-1.7-5.76-1.19-1.75-2.84-3.27-4.84-4.47-1.47-.87-3.09-1.51-4.77-2.14-2.07-.81-4.25-1.49-6.48-2.17l-3.38-1.03c-.57-.17-1.13-.35-1.7-.6l-.8-.32c-.09-.03-.21-.08-.32-.11l-.29-.15c-.05-.02-.1-.06-.15-.07-.05,0-.09.04-.13.08-.13.15-.23.33-.33.52l-.14.29-.08.19-.14.33-.53,1.34c-1.02,2.71-1.83,5.55-2.59,8.36-.48,1.8-.9,3.63-1.36,5.44-.24.9-.55,1.79-.85,2.68l-.46,1.33c-.18.44-.41.86-.66,1.28-.72,1.03-.73.88-1.19-.24-.21-.56-.6-1.37-.71-2.28-.14-.92-.21-1.97-.06-3.06.74-3.46,1.67-6.87,2.72-10.27.54-1.7,1.12-3.38,1.78-5.09l.55-1.33c.29-.69.65-1.5,1.05-2.15.42-.66.89-1.16,1.49-1.42.31-.2.67-.1,1-.08l1.01.09.84.27c.14.05.29.07.43.11l.4.12.93.28c4.22,1.52,9.11,2.35,13.72,4.33,2.52,1.1,5.01,2.52,7.13,4.52.55.46,1.01,1.05,1.48,1.6.5.53.85,1.19,1.26,1.79.79,1.24,1.36,2.66,1.69,4.09.82,3.56.16,7.32-1.66,10.36-1.35,2.32-3.54,4.23-5.97,5.23-2.44,1.04-5.01,1.42-7.52,1.51-3.91.14-7.71-.28-11.43-1.13-.81-.21-1.64-.33-2.44-.6-1.49-.47-2.46-.83-3.05-1.32-.28-.27-.49-.52-.63-.78-.15-.27-.23-.55-.3-.81-.06-.24-.18-.48-.2-.65-.04-.3.28-.43.94-.61.67-.2,1.58-.22,2.63.08,3.14.77,6.33,1.47,9.53,1.84,3.17.34,6.45.4,9.24-.48,1.06-.28,1.99-.75,2.86-1.26.83-.56,1.57-1.2,2.16-1.97,1.23-1.6,1.89-3.69,1.92-5.71h.1Z"
      />
    </svg>
  );
}
