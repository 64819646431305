/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function ListIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4609 35.6992H42.0225"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63281 35.6992H6.65248"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4609 23.9004H42.0225"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63281 23.9004H6.65248"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4609 12.1035H42.0225"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.63281 12.1035H6.65248"
        stroke={color}
        strokeWidth="2.94942"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
