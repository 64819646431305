/* eslint-disable max-len */
import React from 'react';

import concatClassNames from 'utils/classNames';

import { type IconProps, iconColorStyle, iconSizeStyle } from './Icon.props';

export default function TextIcon({ color, size }: IconProps): JSX.Element {
  return (
    <svg className={iconSizeStyle[size]} viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.428 12.2371H2.63034C2.10885 12.2371 1.60872 12.4443 1.23997 12.813C0.871223 13.1818 0.664063 13.6819 0.664062 14.2034C0.664063 14.7249 0.871223 15.225 1.23997 15.5938C1.60872 15.9625 2.10885 16.1697 2.63034 16.1697H6.5629V26.0011C6.5629 26.5225 6.77006 27.0227 7.13881 27.3914C7.50755 27.7602 8.00768 27.9673 8.52917 27.9673C9.05066 27.9673 9.55079 27.7602 9.91954 27.3914C10.2883 27.0227 10.4955 26.5225 10.4955 26.0011V16.1697H14.428C14.9495 16.1697 15.4496 15.9625 15.8184 15.5938C16.1871 15.225 16.3943 14.7249 16.3943 14.2034C16.3943 13.6819 16.1871 13.1818 15.8184 12.813C15.4496 12.4443 14.9495 12.2371 14.428 12.2371ZM38.0233 0.439453H14.428C13.9065 0.439453 13.4064 0.646614 13.0376 1.01536C12.6689 1.38411 12.4617 1.88424 12.4617 2.40573C12.4617 2.92722 12.6689 3.42735 13.0376 3.7961C13.4064 4.16485 13.9065 4.37201 14.428 4.37201H24.2594V26.0011C24.2594 26.5225 24.4666 27.0227 24.8353 27.3914C25.2041 27.7602 25.7042 27.9673 26.2257 27.9673C26.7472 27.9673 27.2473 27.7602 27.616 27.3914C27.9848 27.0227 28.192 26.5225 28.192 26.0011V4.37201H38.0233C38.5448 4.37201 39.045 4.16485 39.4137 3.7961C39.7825 3.42735 39.9896 2.92722 39.9896 2.40573C39.9896 1.88424 39.7825 1.38411 39.4137 1.01536C39.045 0.646614 38.5448 0.439453 38.0233 0.439453Z"
        fill={color}
      />
    </svg>
  );
}
