import React from 'react';

import { textColorStyle, textPositionStyle, textWeightStyle } from 'components/RichText/RichText';

import concatClassNames from 'utils/classNames';

import { type HTitleProps } from './H1Title';

export function H3Title({
  text,
  color = '#000000',
  position = 'right',
  strikethrough = false,
  weight = 'normal',
  font = 'font-circularStd-Light',
}: HTitleProps): JSX.Element {
  return (
    <h3
      className={concatClassNames(
        'text-[24px] lg:text-[20px] xl:text-[24px]',
        textColorStyle[color],
        textPositionStyle[position],
        textWeightStyle[weight],
        strikethrough ? 'line-through' : '',
        font,
      )}
    >
      {text}
    </h3>
  );
}
