/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoUser } from './DisckoUser';
import {
    DisckoUserFromJSON,
    DisckoUserFromJSONTyped,
    DisckoUserToJSON,
} from './DisckoUser';
import type { RequirementsParametersDto } from './RequirementsParametersDto';
import {
    RequirementsParametersDtoFromJSON,
    RequirementsParametersDtoFromJSONTyped,
    RequirementsParametersDtoToJSON,
} from './RequirementsParametersDto';

/**
 * 
 * @export
 * @interface DisckoCompanyDto
 */
export interface DisckoCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof DisckoCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompanyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompanyDto
     */
    companyStatus: DisckoCompanyDtoCompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompanyDto
     */
    plan: DisckoCompanyDtoPlanEnum;
    /**
     * 
     * @type {number}
     * @memberof DisckoCompanyDto
     */
    credits: number;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompanyDto
     */
    subscriptionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompanyDto
     */
    hasAccessToStandardFeaturesUntil: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompanyDto
     */
    hasAccessToPremiumFeaturesUntil: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DisckoCompanyDto
     */
    isSubscribed: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompanyDto
     */
    customerStripeId?: string;
    /**
     * 
     * @type {RequirementsParametersDto}
     * @memberof DisckoCompanyDto
     */
    requirementsParameters: RequirementsParametersDto;
    /**
     * 
     * @type {Array<DisckoUser>}
     * @memberof DisckoCompanyDto
     */
    users: Array<DisckoUser>;
}


/**
 * @export
 */
export const DisckoCompanyDtoCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type DisckoCompanyDtoCompanyStatusEnum = typeof DisckoCompanyDtoCompanyStatusEnum[keyof typeof DisckoCompanyDtoCompanyStatusEnum];

/**
 * @export
 */
export const DisckoCompanyDtoPlanEnum = {
    Classic: 'classic',
    Premium: 'premium',
    Null: 'null'
} as const;
export type DisckoCompanyDtoPlanEnum = typeof DisckoCompanyDtoPlanEnum[keyof typeof DisckoCompanyDtoPlanEnum];


/**
 * Check if a given object implements the DisckoCompanyDto interface.
 */
export function instanceOfDisckoCompanyDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "plan" in value;
    isInstance = isInstance && "credits" in value;
    isInstance = isInstance && "hasAccessToStandardFeaturesUntil" in value;
    isInstance = isInstance && "hasAccessToPremiumFeaturesUntil" in value;
    isInstance = isInstance && "isSubscribed" in value;
    isInstance = isInstance && "requirementsParameters" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function DisckoCompanyDtoFromJSON(json: any): DisckoCompanyDto {
    return DisckoCompanyDtoFromJSONTyped(json, false);
}

export function DisckoCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisckoCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyStatus': json['companyStatus'],
        'plan': json['plan'],
        'credits': json['credits'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : (new Date(json['subscriptionDate'])),
        'hasAccessToStandardFeaturesUntil': (json['hasAccessToStandardFeaturesUntil'] === null ? null : new Date(json['hasAccessToStandardFeaturesUntil'])),
        'hasAccessToPremiumFeaturesUntil': (json['hasAccessToPremiumFeaturesUntil'] === null ? null : new Date(json['hasAccessToPremiumFeaturesUntil'])),
        'isSubscribed': json['isSubscribed'],
        'customerStripeId': !exists(json, 'customerStripeId') ? undefined : json['customerStripeId'],
        'requirementsParameters': RequirementsParametersDtoFromJSON(json['requirementsParameters']),
        'users': ((json['users'] as Array<any>).map(DisckoUserFromJSON)),
    };
}

export function DisckoCompanyDtoToJSON(value?: DisckoCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'companyStatus': value.companyStatus,
        'plan': value.plan,
        'credits': value.credits,
        'subscriptionDate': value.subscriptionDate === undefined ? undefined : (value.subscriptionDate.toISOString()),
        'hasAccessToStandardFeaturesUntil': (value.hasAccessToStandardFeaturesUntil === null ? null : value.hasAccessToStandardFeaturesUntil.toISOString()),
        'hasAccessToPremiumFeaturesUntil': (value.hasAccessToPremiumFeaturesUntil === null ? null : value.hasAccessToPremiumFeaturesUntil.toISOString()),
        'isSubscribed': value.isSubscribed,
        'customerStripeId': value.customerStripeId,
        'requirementsParameters': RequirementsParametersDtoToJSON(value.requirementsParameters),
        'users': ((value.users as Array<any>).map(DisckoUserToJSON)),
    };
}

