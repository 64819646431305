import { DisckoApisContext } from 'App';
import { type GetGenericRequirementDto } from 'api';

import React, { useContext, useEffect, useState } from 'react';
import { type QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { type NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import uuid from 'react-uuid';

import { useAuth0 } from '@auth0/auth0-react';

import { type RequirementSynthesis } from 'pages/RequirementForm/DisckoPage';

import { BasePage } from 'components/core/BasePage/BasePage';
import { Button } from 'components/core/Button/Button';
import { Chip } from 'components/core/Chip/Chip';
import { Text, type TextColor } from 'components/core/Text/Text';

import concatClassNames from 'utils/classNames';
import { convertStringToRequirement } from 'utils/convertStringToSynthesis';
import { CompletionType } from 'utils/getCompletionTypeChip';
import getIcon from 'utils/getIcon';

import { formatDate } from './RequirementsPage';

export function RequirementPage(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const navigate: NavigateFunction = useNavigate();
  const { requirementId = '' }: RequirementPageParams = useParams();

  const { requirementService } = useContext(DisckoApisContext);

  interface RequirementPageParams {
    requirementId?: string;
  }

  interface Tab {
    name: string;
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
  }

  interface ClientAnalysis {
    analyse: {
      evaluation?: {
        maturity?: string;
        engagement?: string;
        decision_makers?: string;
        score?: number;
        comment?: string;
      };
      upsell?: [{ service?: string; description?: string; value?: string }];
      personality?: string;
    };
  }

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  const projectScoreOptions: string[] = ['0', '1', '2', '3'];

  const projectScoreStyleOptions: string[] = [
    'Critique, manque de soutien stratégique',
    'Réalisable, mais objectifs flous et faible engagement',
    'Projet bien structuré avec ajustements à prévoir',
    'Projet idéal avec engagement fort et alignement stratégique',
  ];

  const projectScoreOptionsMap: Map<string, string> = new Map<string, string>();
  projectScoreOptionsMap.set('0', 'Critique, manque de soutien stratégique');
  projectScoreOptionsMap.set('1', 'Réalisable, mais objectifs flous et faible engagement');
  projectScoreOptionsMap.set('2', 'Projet bien structuré avec ajustements à prévoir');
  projectScoreOptionsMap.set('3', 'Projet idéal avec engagement fort et alignement stratégique');

  const projectScoreColorMap: Map<string, string> = new Map<string, string>();
  projectScoreColorMap.set('0', 'bg-red-500');
  projectScoreColorMap.set('1', 'bg-yellow-500');
  projectScoreColorMap.set('2', 'bg-green-300');
  projectScoreColorMap.set('3', 'bg-green-500');

  const projectScoreTextColorMap: Map<string, TextColor> = new Map<string, TextColor>();
  projectScoreTextColorMap.set('0', 'white');
  projectScoreTextColorMap.set('1', 'white');
  projectScoreTextColorMap.set('2', 'white');
  projectScoreTextColorMap.set('3', 'white');
  /* ---------------------------------------------------- States ---------------------------------------------------- */

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isRequirementOpen, setIsRequirementOpen] = useState<boolean>(true);
  const [isClientAnalysisOpen, setIsClientAnalysisOpen] = useState<boolean>(false);

  /* ----------------------------------------------------- Query ---------------------------------------------------- */

  const queryClient: QueryClient = useQueryClient();

  async function getRequirement(): Promise<GetGenericRequirementDto> {
    const accessToken: string = await getAccessTokenSilently();
    const getGenericRequirementDto: GetGenericRequirementDto = await requirementService.getRequirement(
      requirementId,
      accessToken,
    );
    return getGenericRequirementDto;
  }

  const [restructuredRequirement, setRestructuredRequirement] = useState<RequirementSynthesis>({
    synthesisContext: [],
    goals: [],
    constraints: [],
    successFactors: [],
    audience: [],
    abstract: '',
    clientMaturity: '',
    deadline: { deadlineCategory: 'flexible', date: '' },
    priceRange: 'abstain',
    projectType: 'Website',
    projectMaturity: 'IDEA',
    projectSubject: '',
    questions: [],
    step: '',
  });

  const completionTypeMap: Map<CompletionType, string> = new Map<CompletionType, string>();
  completionTypeMap.set(CompletionType.Agency, "l'agence");
  projectScoreOptionsMap.set(CompletionType.Client, 'le client');

  const [restructuredClientAnalysis, setRestructuredClientAnalysis] = useState<ClientAnalysis>();

  const { data: requirementData, status: requirementStatus } = useQuery<any, Error>(
    ['requirement', requirementId],
    getRequirement,
  );

  // /* ------------------------------------------------ readRequirement ----------------------------------------------- */

  async function readRequirement(): Promise<any> {
    const accessToken: string = await getAccessTokenSilently();
    const genericRequirement: GetGenericRequirementDto = await requirementService.readRequirement(
      requirementId,
      accessToken,
    );
    return genericRequirement;
  }

  console.log('requirementData', requirementData);

  // const { mutate: mutateReadRequirement } = useMutation(readRequirement, {
  //   onSuccess: (getRequirementDto: any) => {
  //     queryClient.setQueryData<any>(['requirement', requirementId], (oldData: any | undefined) => {
  //       oldData = getRequirementDto;
  //       return getRequirementDto;
  //     });
  //   },
  //   onError: () => {
  //     console.error('error');
  //   },
  // });

  // // set read status to true if the requirement is opened
  useEffect(() => {
    if (requirementStatus === 'success' && isFirstRender) {
      if (!requirementData.read) {
        void readRequirement();
      }
      setIsFirstRender(false);
    }
  }, [isFirstRender, requirementData, requirementStatus]);

  return (
    <BasePage>
      <>
        {isLargeScreen === true && (
          <div className={concatClassNames('h-full', 'w-full', 'flex', 'flex-col', 'relative', 'pb-4', '')}>
            <div className={concatClassNames('flex flex-col pt-2 pb-0 w-full h-full overflow-auto ')}>
              {requirementStatus === 'success' && (
                <div
                  className={concatClassNames(
                    'flex flex-row h-full px-12 pb-0 pt-12',
                    'gap-2',
                    'overflow-auto',
                    'bg-white m-4 rounded-lg',
                    // 'flex-grow items-stretch',
                  )}
                >
                  <div className={concatClassNames('w-[45%] sticky overflow-auto', 'flex flex-col', 'gap-4')}>
                    <div className={concatClassNames('h-fit w-full', 'flex flex-row')}>
                      <Button
                        content="Retour"
                        onClick={() => {
                          navigate('/requirements');
                        }}
                        iconName="chevronLeft"
                        iconPosition="left"
                        width="fit"
                        color="#FFFFFF"
                      />
                    </div>
                    <div className="overflow-auto flex flex-col gap-6 rounded-lg p-4 h-full">
                      <div className={concatClassNames('flex flex-row bg-blue-400 gap-4 p-4')}>
                        <img src="/imgs/buyer.png" className={concatClassNames('hidden xl:block xl:w-16 xl:h-16')} />
                        <div className="flex flex-col gap-1  w-full">
                          {requirementData.contact.companyName !== undefined &&
                            requirementData.contact.companyName !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center ')}>
                                <Text
                                  color="white"
                                  size="sm"
                                  position="left"
                                  whitespace="nowrap"
                                  content="Entreprise :"
                                />
                                <Text
                                  size="sm"
                                  position="left"
                                  weight="bold"
                                  color="white"
                                  content={requirementData.contact.companyName}
                                />
                              </div>
                            )}
                          {requirementData.contact.companyLocation !== undefined &&
                            requirementData.contact.companyLocation !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Adresse :" color="white" />
                                <Text
                                  size="sm"
                                  color="white"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.contact.companyLocation}
                                />
                              </div>
                            )}
                          {requirementData.contact.companyWebsite !== undefined &&
                            requirementData.contact.companyWebsite !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <Text
                                  size="sm"
                                  position="left"
                                  whitespace="nowrap"
                                  content="Site web :"
                                  color="white"
                                />
                                <div
                                  onClick={() => window.open(requirementData.contact.companyWebsite, '_blank')}
                                  className="cursor-pointer"
                                >
                                  <Text
                                    size="sm"
                                    color="white"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.contact.companyWebsite}
                                    textDecoration="underline"
                                  />
                                </div>
                              </div>
                            )}
                          {requirementData.contact.contactType === 'COMPANY' && <br />}
                          {requirementData.contact.contactLastname !== undefined &&
                            requirementData.contact.contactLastname !== '' && (
                              <div className={concatClassNames('flex flex-row justify-start')}>
                                <Text color="white" size="sm" position="left" whitespace="nowrap" content="Nom :" />
                                <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                                  <Text
                                    size="sm"
                                    weight="bold"
                                    color="white"
                                    uppercase
                                    content={requirementData.contact.contactLastname}
                                  />
                                </div>
                              </div>
                            )}
                          {requirementData.contact.contactFirstname !== undefined &&
                            requirementData.contact.contactFirstname !== '' && (
                              <div className={concatClassNames('flex flex-row justify-start')}>
                                <Text color="white" size="sm" position="left" whitespace="nowrap" content="Prénom :" />
                                <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                                  <Text
                                    size="sm"
                                    position="left"
                                    weight="bold"
                                    color="white"
                                    content={requirementData.contact.contactFirstname}
                                  />
                                </div>
                              </div>
                            )}
                          {requirementData.contact.contactEmail !== undefined &&
                            requirementData.contact.contactEmail !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-start')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Email :" color="white" />
                                <Text
                                  size="sm"
                                  position="left"
                                  color="white"
                                  weight="bold"
                                  // whitespace="pre-wrap"
                                  textBreak="all"
                                  content={requirementData.contact.contactEmail}
                                />
                              </div>
                            )}
                          {requirementData.contact.contactPhone !== undefined &&
                            requirementData.contact.contactPhone !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <Text
                                  size="sm"
                                  position="left"
                                  whitespace="nowrap"
                                  content="Téléphone :"
                                  color="white"
                                />
                                <Text
                                  size="sm"
                                  color="white"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.contact.contactPhone}
                                />
                              </div>
                            )}
                          {requirementData.contact.contactLocation !== undefined &&
                            requirementData.contact.contactLocation !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Adresse :" color="white" />
                                <Text
                                  size="sm"
                                  color="white"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.contact.contactLocation}
                                />
                              </div>
                            )}
                          {requirementData.contact.contactJobPosition !== undefined &&
                            requirementData.contact.contactJobPosition !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Poste :" color="white" />
                                <Text
                                  size="sm"
                                  color="white"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.contact.contactJobPosition}
                                />
                              </div>
                            )}
                          {requirementData.contact.contactLinkedinProfile !== undefined &&
                            requirementData.contact.contactLinkedinProfile !== '' && (
                              <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                <img
                                  src="/imgs/linkedin.png"
                                  className="w-6 h-6 bg-white cursor-pointer"
                                  onClick={() => window.open(requirementData.contact.contactLinkedinProfile, '_blank')}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div>
                        {'Complétée le '}
                        {formatDate(requirementData.finishedAt)}
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <Chip
                          label={requirementData.projectType.type}
                          bgColor={requirementData.projectType.color}
                          paddingHorizontal="md"
                          paddingTop="sm"
                          paddingBottom="sm"
                          textPosition="center"
                          borderColor={requirementData.projectType.color}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full ">
                    <div className="flex flex-row items-center justify-between gap-6 pb-4">
                      <div className="flex flex-row w-full">
                        <div
                          key={uuid()}
                          onClick={() => {
                            setIsRequirementOpen(true);
                            setIsClientAnalysisOpen(false);
                          }}
                          className={concatClassNames(
                            'border-black ',
                            'px-4 py-2',
                            'cursor-pointer',
                            'rounded-full',
                            isRequirementOpen ? 'bg-black' : '',
                          )}
                        >
                          <Text
                            content="Expression de besoin"
                            size="lg"
                            color={isRequirementOpen ? 'white' : 'gray-400'}
                          />
                        </div>
                        {requirementData.results.analysis !== undefined && (
                          <div
                            key={uuid()}
                            onClick={() => {
                              setIsClientAnalysisOpen(true);
                              setIsRequirementOpen(false);
                            }}
                            className={concatClassNames(
                              'border-black ',
                              'px-4 py-2',
                              'cursor-pointer',
                              'rounded-full',
                              isClientAnalysisOpen ? 'bg-black' : '',
                            )}
                          >
                            <Text content="Analyse" size="lg" color={isClientAnalysisOpen ? 'white' : 'gray-400'} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" p-8 overflow-auto flex-grow items-stretch w-full">
                      {isRequirementOpen && (
                        <div className="flex flex-col gap-4">
                          <div className="">
                            <div className={concatClassNames('flex flex-col', 'gap-4')}>
                              <div className=" bg-blue-400 px-4 py-1">
                                <Text
                                  size="lg"
                                  content="Informations générales exprimées par le prospect"
                                  weight="bold"
                                  color="white"
                                />
                              </div>

                              {requirementData.results.requirement.projectDescription !== undefined && (
                                <div className={concatClassNames('flex flex-col gap-1 items-start')}>
                                  <Text size="sm" position="left" whitespace="nowrap" content="Besoin exprimé :" />
                                  <Text
                                    size="sm"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.results.requirement.projectDescription}
                                  />
                                </div>
                              )}
                              <div className={concatClassNames('flex flex-row gap-1 items-center')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Catégorie de projet :" />
                                <Chip
                                  label={requirementData.projectType.type}
                                  bgColor={requirementData.projectType.color}
                                  paddingHorizontal="md"
                                  paddingTop="sm"
                                  paddingBottom="sm"
                                  textSize="sm"
                                  textPosition="center"
                                  borderColor={requirementData.projectType.color}
                                />
                              </div>
                              {requirementData.results.requirement.comment !== undefined && (
                                <div className={concatClassNames('flex flex-col gap-1 items-start')}>
                                  <Text size="sm" position="left" whitespace="nowrap" content="Commentaire ajouté :" />
                                  <Text
                                    size="sm"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.results.requirement.comment}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-4 w-[100%]">
                              {requirementData.results.synthesis.context !== undefined && (
                                <div className="h-fit">
                                  <div className=" ">
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Contexte" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/landscape.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.context.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.goals !== undefined && (
                                <div className="h-fit">
                                  <div className=" ">
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Objectifs" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center ">
                                      <img src="/imgs/mission.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.goals?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.audience !== undefined && (
                                <div className="">
                                  <div className="h-fit">
                                    <div className="bg-blue-400 px-4 py-1 ">
                                      <Text size="lg" content="Utilisateurs cibles" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/student.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.audience?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.stakeholders !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Parties impliquées" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/stakeholders.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.stakeholders?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col gap-4 w-[100%]">
                              {requirementData.results.synthesis.constraints !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Contraintes identifiées" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/barrier.gif" className="w-16 h-16" />

                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.constraints?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.successFactors !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Facteurs clefs de succès" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/like.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.successFactors?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.references !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Exemples & références" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/sweet-dreams.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.references?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.solutions !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Solution(s) souhaitée(s)" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/solution.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.solutions?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {requirementData.results.synthesis.kpis !== undefined && (
                                <div>
                                  <div>
                                    <div className="bg-blue-400 px-4 py-1">
                                      <Text size="lg" content="Indicateurs de suivi" weight="bold" color="white" />
                                    </div>
                                    <div className="flex flex-row gap-4 items-center">
                                      <img src="/imgs/analyse.gif" className="w-16 h-16" />
                                      <ul className="list-disc list-inside justify-start text-justify p-4">
                                        {requirementData.results.synthesis.kpis?.map((e, index) => (
                                          <li key={uuid()} className="text-sm">
                                            {e}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {/* 
                          {requirementData.results.requirement.comment !== undefined && (
                            <>
                              <Text
                                color="black"
                                size="lg"
                                weight="bold"
                                content="Commentaire ajouté par le prospect:"
                              />
                              <Text
                                size="sm"
                                content={requirementData.results.requirement.comment}
                                position="justify"
                              />
                            </>
                          )} */}
                        </div>
                      )}
                      {isClientAnalysisOpen && (
                        <div className="w-full flex flex-col gap-4">
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                              <div className={concatClassNames('flex flex-row gap-6 items-center')} key={uuid()}>
                                <div className="flex flex-row gap-1 items-center">
                                  {projectScoreOptions.map((e, index) => (
                                    <div
                                      className={concatClassNames(
                                        requirementData.results.analysis.analyse.evaluation?.score?.toString() === e
                                          ? 'h-8 w-8'
                                          : 'h-2 w-2',
                                        'rounded-full flex items-center justify-center align-middle',
                                        projectScoreColorMap.get(e.toString() ?? '') ?? '',
                                      )}
                                      key={uuid()}
                                    >
                                      {requirementData.results.analysis.analyse.evaluation?.score?.toString() === e && (
                                        <Text
                                          size="lg"
                                          position="left"
                                          weight="bold"
                                          color={projectScoreTextColorMap.get(
                                            requirementData.results.analysis.analyse.evaluation?.score?.toString() ??
                                              '',
                                          )}
                                          content={requirementData.results.analysis.analyse.evaluation?.score?.toString()}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>

                                <Text
                                  size="lg"
                                  weight="bold"
                                  position="left"
                                  content={projectScoreOptionsMap.get(
                                    requirementData.results.analysis.analyse.evaluation?.score?.toString() ?? '',
                                  )}
                                />
                              </div>
                            </div>
                            <div className="">
                              <Text
                                size="md"
                                weight="bold"
                                position="justify"
                                content={requirementData.results.analysis.analyse.evaluation?.comment}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row gap-4">
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-green-400 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Maturité" />
                                </div>
                                <div className="bg-green-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.evaluation?.maturity}
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="bg-pink-500 px-4 py-1">
                                  <Text position="justify" size="lg" weight="bold" content="Décideurs" color="white" />
                                </div>
                                <div className="bg-pink-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.evaluation?.decision_makers}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="bg-purple-300 px-4 py-1">
                                  <Text size="lg" position="left" weight="bold" content="Personnalité" />
                                </div>
                                <div className="bg-purple-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.personality}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-indigo-500 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Idées pour aller plus loin" color="white" />
                                </div>
                                <ul className="list-inside justify-start text-justify p-4 space-y-4 bg-indigo-100">
                                  {requirementData.results.analysis.analyse.upsell?.map((e, index) => (
                                    <>
                                      <div key={uuid()} className="text-sm flex flex-col gap-2">
                                        <li className="font-bold text-white bg-indigo-400 w-fit px-2 py-1">
                                          {e.service}
                                        </li>
                                        <div> {e.description}</div>
                                        <div> {e.value}</div>
                                      </div>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isLargeScreen === false && (
          <div className={concatClassNames('h-full', 'w-full', 'flex', 'flex-col', 'relative')}>
            <div className={concatClassNames('flex flex-col w-full h-full overflow-auto ')}>
              {requirementStatus === 'success' && (
                <div
                  className={concatClassNames(
                    'flex flex-col h-full w-full',
                    'gap-2',
                    'overflow-auto',
                    'bg-white rounded-lg',
                  )}
                >
                  <div className={concatClassNames('w-full', 'flex flex-col')}>
                    <div className={concatClassNames('h-fit w-fit', 'flex flex-row')}>
                      <Button
                        content="Retour"
                        onClick={() => {
                          navigate('/requirements');
                        }}
                        iconName="chevronLeft"
                        iconPosition="left"
                        width="fit"
                        color="#FFFFFF"
                      />
                    </div>
                    <div className="overflow-auto flex flex-col gap-6 rounded-lg p-4 h-full">
                      <div className={concatClassNames('flex flex-col gap-4')}>
                        <div className="italic text-xs md:text-sm">
                          {'Complétée le '}
                          {formatDate(requirementData.finishedAt)}
                        </div>
                        <div className={concatClassNames('flex flex-row bg-blue-400  gap-4  p-4')}>
                          <img src="/imgs/buyer.png" className="w-16 h-16 hidden sm:flex" />

                          <div className="flex flex-col gap-1  w-full">
                            {requirementData.contact.companyName !== undefined &&
                              requirementData.contact.companyName !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-center ')}>
                                  <Text
                                    color="white"
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Entreprise :"
                                  />
                                  <Text
                                    size="sm"
                                    position="left"
                                    weight="bold"
                                    color="white"
                                    content={requirementData.contact.companyName}
                                  />
                                </div>
                              )}
                            {requirementData.contact.companyLocation !== undefined &&
                              requirementData.contact.companyLocation !== '' && (
                                <div className={concatClassNames('flex flex-row justify-start')}>
                                  <Text
                                    color="white"
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Adresse :"
                                  />
                                  <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                                    <Text
                                      size="sm"
                                      weight="bold"
                                      color="white"
                                      uppercase
                                      content={requirementData.contact.companyLocation}
                                    />
                                  </div>
                                </div>
                              )}
                            {requirementData.contact.companyWebsite !== undefined &&
                              requirementData.contact.companyWebsite !== '' && (
                                <div className={concatClassNames('flex flex-row justify-start')}>
                                  <Text
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Site web :"
                                    color="white"
                                  />
                                  <div
                                    onClick={() => window.open(requirementData.contact.companyWebsite, '_blank')}
                                    className="flex flex-row gap-2 px-2 ml-4 rounded-lg w-fit cursor-pointer"
                                  >
                                    <Text
                                      size="sm"
                                      color="white"
                                      position="left"
                                      weight="bold"
                                      content={requirementData.contact.companyWebsite}
                                      textDecoration="underline"
                                    />
                                    {getIcon('arrowUpFromSquare', '#FFFFFF', 'sm')}
                                  </div>
                                </div>
                              )}
                            {requirementData.contact.contactType === 'COMPANY' && <br />}
                            {requirementData.contact.contactLastname !== undefined &&
                              requirementData.contact.contactLastname !== '' && (
                                <div className={concatClassNames('flex flex-row justify-start')}>
                                  <Text color="white" size="sm" position="left" whitespace="nowrap" content="Nom :" />
                                  <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                                    <Text
                                      size="sm"
                                      weight="bold"
                                      color="white"
                                      uppercase
                                      content={requirementData.contact.contactLastname}
                                    />
                                  </div>
                                </div>
                              )}
                            {requirementData.contact.contactFirstname !== undefined &&
                              requirementData.contact.contactFirstname !== '' && (
                                <div className={concatClassNames('flex flex-row justify-start')}>
                                  <Text
                                    color="white"
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Prénom :"
                                  />
                                  <div className="flex flex-row gap-1 px-2 ml-4 rounded-lg w-fit">
                                    <Text
                                      size="sm"
                                      position="left"
                                      weight="bold"
                                      color="white"
                                      content={requirementData.contact.contactFirstname}
                                    />
                                  </div>
                                </div>
                              )}
                            {requirementData.contact.contactEmail !== undefined &&
                              requirementData.contact.contactEmail !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-start')}>
                                  <Text size="sm" position="left" whitespace="nowrap" content="Email :" color="white" />
                                  <Text
                                    size="sm"
                                    position="left"
                                    color="white"
                                    weight="bold"
                                    // whitespace="pre-wrap"
                                    textBreak="all"
                                    content={requirementData.contact.contactEmail}
                                  />
                                </div>
                              )}
                            {requirementData.contact.contactPhone !== undefined &&
                              requirementData.contact.contactPhone !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                  <Text
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Téléphone :"
                                    color="white"
                                  />
                                  <Text
                                    size="sm"
                                    color="white"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.contact.contactPhone}
                                  />
                                </div>
                              )}
                            {requirementData.contact.contactLocation !== undefined &&
                              requirementData.contact.contactLocation !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                  <Text
                                    size="sm"
                                    position="left"
                                    whitespace="nowrap"
                                    content="Adresse :"
                                    color="white"
                                  />
                                  <Text
                                    size="sm"
                                    color="white"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.contact.contactLocation}
                                  />
                                </div>
                              )}
                            {requirementData.contact.contactJobPosition !== undefined &&
                              requirementData.contact.contactJobPosition !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                  <Text size="sm" position="left" whitespace="nowrap" content="Poste :" color="white" />
                                  <Text
                                    size="sm"
                                    color="white"
                                    position="left"
                                    weight="bold"
                                    content={requirementData.contact.contactJobPosition}
                                  />
                                </div>
                              )}
                            {requirementData.contact.contactLinkedinProfile !== undefined &&
                              requirementData.contact.contactLinkedinProfile !== '' && (
                                <div className={concatClassNames('flex flex-row gap-4 items-center')}>
                                  <img
                                    src="/imgs/linkedin.png"
                                    className="w-6 h-6 bg-white cursor-pointer"
                                    onClick={() =>
                                      window.open(requirementData.contact.contactLinkedinProfile, '_blank')
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full ">
                    <div className=" p-8 overflow-auto flex-grow items-stretch w-full">
                      <div className="flex flex-col gap-4">
                        <div className="">
                          <div className={concatClassNames('flex flex-col', 'gap-4')}>
                            <div className=" bg-blue-400 px-4 py-1">
                              <Text
                                size="lg"
                                content="Informations générales exprimées par le prospect"
                                weight="bold"
                                color="white"
                                position="left"
                              />
                            </div>

                            {requirementData.results.requirement.projectDescription !== undefined && (
                              <div className={concatClassNames('flex flex-col gap-1 items-start')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Besoin exprimé :" />
                                <Text
                                  size="sm"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.results.requirement.projectDescription}
                                />
                              </div>
                            )}
                            <div className={concatClassNames('flex flex-row gap-1 items-center')}>
                              <Text size="sm" position="left" whitespace="nowrap" content="Catégorie de projet :" />
                              <Chip
                                label={requirementData.projectType.type}
                                bgColor={requirementData.projectType.color}
                                paddingHorizontal="md"
                                paddingTop="sm"
                                paddingBottom="sm"
                                textSize="sm"
                                textPosition="center"
                                borderColor={requirementData.projectType.color}
                              />
                            </div>
                            {requirementData.results.requirement.comment !== undefined && (
                              <div className={concatClassNames('flex flex-col gap-1 items-start')}>
                                <Text size="sm" position="left" whitespace="nowrap" content="Commentaire ajouté :" />
                                <Text
                                  size="sm"
                                  position="left"
                                  weight="bold"
                                  content={requirementData.results.requirement.comment}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-col gap-4">
                          <div className="flex flex-col gap-4 w-[100%]">
                            {requirementData.results.synthesis.context !== undefined && (
                              <div className="h-fit">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Contexte" weight="bold" color="white" />
                                  </div>

                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/landscape.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.context.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.goals !== undefined && (
                              <div className="h-fit">
                                <div className=" ">
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Objectifs" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/mission.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.goals?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.audience !== undefined && (
                              <div className="">
                                <div className="h-fit">
                                  <div className="bg-blue-400 px-4 py-1 ">
                                    <Text size="lg" content="Utilisateurs cibles" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/student.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.audience?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.stakeholders !== undefined && (
                              <div>
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Parties impliquées" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/stakeholders.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.stakeholders?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-4 w-[100%]">
                            {requirementData.results.synthesis.constraints !== undefined && (
                              <div>
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Contraintes identifiées" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/barrier.gif" className="w-16 h-16" />

                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.constraints?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.successFactors !== undefined && (
                              <div>
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Facteurs clefs de succès" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/like.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.successFactors?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.references !== undefined && (
                              <div>
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Exemples & références" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/sweet-dreams.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.references?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.solutions !== undefined && (
                              <div className="h-fit">
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Solution(s) souhaitée(s)" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/solution.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.solutions?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                            {requirementData.results.synthesis.kpis !== undefined && (
                              <div className="h-fit">
                                <div>
                                  <div className="bg-blue-400 px-4 py-1">
                                    <Text size="lg" content="Indicateurs de suivi" weight="bold" color="white" />
                                  </div>
                                  <div className="flex flex-col md:flex-row gap-4 items-center py-4">
                                    <img src="/imgs/analyse.gif" className="w-16 h-16" />
                                    <ul className="list-disc list-inside justify-start text-justify p-4">
                                      {requirementData.results.synthesis.kpis?.map((e, index) => (
                                        <li key={uuid()} className="text-sm">
                                          {e}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* {restructuredRequirement.comment !== undefined && (
                            <>
                              <Text
                                color="black"
                                size="lg"
                                weight="bold"
                                content="Commentaire ajouté par le prospect:"
                              />
                              <Text size="sm" content={restructuredRequirement.comment} position="justify" />
                            </>
                          )} */}
                      </div>

                      <hr></hr>
                      {requirementData.results.analysis !== undefined && (
                        <div className="w-full flex flex-col gap-4 py-4">
                          <div className="hidden md:flex">
                            <Text content="Analyse client" />
                          </div>
                          <div className="w-full flex flex-col gap-4">
                            <div className="flex flex-col gap-4">
                              <div className="flex flex-col gap-1">
                                <div
                                  className={concatClassNames('flex flex-col md:flex-row gap-6  items-center')}
                                  key={uuid()}
                                >
                                  <div className="flex flex-row gap-1 items-center">
                                    {projectScoreOptions.map((e, index) => (
                                      <div
                                        className={concatClassNames(
                                          requirementData.results.analysis.analyse.evaluation?.score?.toString() === e
                                            ? 'h-8 w-8'
                                            : 'h-2 w-2',
                                          'rounded-full flex items-center justify-center align-middle',
                                          projectScoreColorMap.get(e.toString() ?? '') ?? '',
                                        )}
                                        key={uuid()}
                                      >
                                        {requirementData.results.analysis?.analyse.evaluation?.score?.toString() ===
                                          e && (
                                          <Text
                                            size="lg"
                                            position="left"
                                            weight="bold"
                                            color={projectScoreTextColorMap.get(
                                              requirementData.results.analysis?.analyse.evaluation?.score?.toString() ??
                                                '',
                                            )}
                                            content={requirementData.results.analysis?.analyse.evaluation?.score?.toString()}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                  <Text
                                    size="lg"
                                    weight="bold"
                                    position="center"
                                    content={projectScoreOptionsMap.get(
                                      requirementData.results.analysis?.analyse.evaluation?.score?.toString() ?? '',
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="">
                                <Text
                                  size="sm"
                                  weight="bold"
                                  position="justify"
                                  content={requirementData.results.analysis?.analyse.evaluation?.comment}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1">
                              <div className={concatClassNames('flex flex-row gap-6 items-center')} key={uuid()}>
                                <div className="flex flex-row gap-1 items-center">
                                  {projectScoreOptions.map((e, index) => (
                                    <div
                                      className={concatClassNames(
                                        requirementData.results.analysis.analyse.evaluation?.score?.toString() === e
                                          ? 'h-8 w-8'
                                          : 'h-2 w-2',
                                        'rounded-full flex items-center justify-center align-middle',
                                        projectScoreColorMap.get(e.toString() ?? '') ?? '',
                                      )}
                                      key={uuid()}
                                    >
                                      {requirementData.results.analysis.analyse.evaluation?.score?.toString() === e && (
                                        <Text
                                          size="lg"
                                          position="left"
                                          weight="bold"
                                          color={projectScoreTextColorMap.get(
                                            requirementData.results.analysis.analyse.evaluation?.score?.toString() ??
                                              '',
                                          )}
                                          content={requirementData.results.analysis.analyse.evaluation?.score?.toString()}
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>

                                <Text
                                  size="lg"
                                  weight="bold"
                                  position="left"
                                  content={projectScoreOptionsMap.get(
                                    requirementData.results.analysis.analyse.evaluation?.score?.toString() ?? '',
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row gap-4">
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-green-400 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Maturité" />
                                </div>
                                <div className="bg-green-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.evaluation?.maturity}
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="bg-pink-500 px-4 py-1">
                                  <Text position="justify" size="lg" weight="bold" content="Décideurs" color="white" />
                                </div>
                                <div className="bg-pink-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.evaluation?.decision_makers}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="bg-purple-300 px-4 py-1">
                                  <Text size="lg" position="left" weight="bold" content="Personnalité" />
                                </div>
                                <div className="bg-purple-100 p-4">
                                  <Text
                                    size="sm"
                                    position="justify"
                                    content={requirementData.results.analysis.analyse.personality}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 md:w-[50%]">
                              <div>
                                <div className="bg-indigo-500 px-4 py-1">
                                  <Text size="lg" weight="bold" content="Idées pour aller plus loin" color="white" />
                                </div>
                                <ul className="list-inside justify-start text-justify p-4 space-y-4 bg-indigo-100">
                                  {requirementData.results.analysis.analyse.upsell?.map((e, index) => (
                                    <>
                                      <div key={uuid()} className="text-sm flex flex-col gap-2">
                                        <li className="font-bold text-white bg-indigo-400 w-fit px-2 py-1">
                                          {e.service}
                                        </li>
                                        <div> {e.description}</div>
                                        <div> {e.value}</div>
                                      </div>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </BasePage>
  );
}
