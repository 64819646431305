/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactParameters
 */
export interface ContactParameters {
    /**
     * 
     * @type {string}
     * @memberof ContactParameters
     */
    contactType: ContactParametersContactTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactLastname: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactFirstname: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactPhone: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    companyName?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    companyWebsite?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    companyLocation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactLinkedinProfile?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactParameters
     */
    contactJobPosition?: boolean;
}


/**
 * @export
 */
export const ContactParametersContactTypeEnum = {
    Person: 'PERSON',
    Company: 'COMPANY',
    Both: 'BOTH'
} as const;
export type ContactParametersContactTypeEnum = typeof ContactParametersContactTypeEnum[keyof typeof ContactParametersContactTypeEnum];


/**
 * Check if a given object implements the ContactParameters interface.
 */
export function instanceOfContactParameters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "contactLastname" in value;
    isInstance = isInstance && "contactFirstname" in value;
    isInstance = isInstance && "contactEmail" in value;
    isInstance = isInstance && "contactPhone" in value;

    return isInstance;
}

export function ContactParametersFromJSON(json: any): ContactParameters {
    return ContactParametersFromJSONTyped(json, false);
}

export function ContactParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactType': json['contactType'],
        'contactLocation': !exists(json, 'contactLocation') ? undefined : json['contactLocation'],
        'contactLastname': json['contactLastname'],
        'contactFirstname': json['contactFirstname'],
        'contactEmail': json['contactEmail'],
        'contactPhone': json['contactPhone'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyWebsite': !exists(json, 'companyWebsite') ? undefined : json['companyWebsite'],
        'companyLocation': !exists(json, 'companyLocation') ? undefined : json['companyLocation'],
        'contactLinkedinProfile': !exists(json, 'contactLinkedinProfile') ? undefined : json['contactLinkedinProfile'],
        'contactJobPosition': !exists(json, 'contactJobPosition') ? undefined : json['contactJobPosition'],
    };
}

export function ContactParametersToJSON(value?: ContactParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactType': value.contactType,
        'contactLocation': value.contactLocation,
        'contactLastname': value.contactLastname,
        'contactFirstname': value.contactFirstname,
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'companyName': value.companyName,
        'companyWebsite': value.companyWebsite,
        'companyLocation': value.companyLocation,
        'contactLinkedinProfile': value.contactLinkedinProfile,
        'contactJobPosition': value.contactJobPosition,
    };
}

