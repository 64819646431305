import React from 'react';

interface ScrollbarCustomizerProps {
  color: string;
  hoverColor: string;
}

export function ScrollbarCustomizer({ color, hoverColor }: ScrollbarCustomizerProps): JSX.Element {
  React.useEffect(() => {
    document.documentElement.style.setProperty('--scrollbar-thumb-color', color);
    document.documentElement.style.setProperty('--scrollbar-thumb-hover-color', hoverColor);
  }, [color, hoverColor]);

  return <></>;
}

export default ScrollbarCustomizer;
