/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormOrchestratorQuestionAnswerDto } from './FormOrchestratorQuestionAnswerDto';
import {
    FormOrchestratorQuestionAnswerDtoFromJSON,
    FormOrchestratorQuestionAnswerDtoFromJSONTyped,
    FormOrchestratorQuestionAnswerDtoToJSON,
} from './FormOrchestratorQuestionAnswerDto';

/**
 * 
 * @export
 * @interface ReceiveQuestionsInputsDto
 */
export interface ReceiveQuestionsInputsDto {
    /**
     * 
     * @type {Array<FormOrchestratorQuestionAnswerDto>}
     * @memberof ReceiveQuestionsInputsDto
     */
    questions?: Array<FormOrchestratorQuestionAnswerDto>;
    /**
     * 
     * @type {number}
     * @memberof ReceiveQuestionsInputsDto
     */
    numberOfQuestionsLeftToAsk?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceiveQuestionsInputsDto
     */
    requirementId: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiveQuestionsInputsDto
     */
    step: string;
}

/**
 * Check if a given object implements the ReceiveQuestionsInputsDto interface.
 */
export function instanceOfReceiveQuestionsInputsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "requirementId" in value;
    isInstance = isInstance && "step" in value;

    return isInstance;
}

export function ReceiveQuestionsInputsDtoFromJSON(json: any): ReceiveQuestionsInputsDto {
    return ReceiveQuestionsInputsDtoFromJSONTyped(json, false);
}

export function ReceiveQuestionsInputsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReceiveQuestionsInputsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questions': !exists(json, 'questions') ? undefined : ((json['questions'] as Array<any>).map(FormOrchestratorQuestionAnswerDtoFromJSON)),
        'numberOfQuestionsLeftToAsk': !exists(json, 'numberOfQuestionsLeftToAsk') ? undefined : json['numberOfQuestionsLeftToAsk'],
        'requirementId': json['requirementId'],
        'step': json['step'],
    };
}

export function ReceiveQuestionsInputsDtoToJSON(value?: ReceiveQuestionsInputsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questions': value.questions === undefined ? undefined : ((value.questions as Array<any>).map(FormOrchestratorQuestionAnswerDtoToJSON)),
        'numberOfQuestionsLeftToAsk': value.numberOfQuestionsLeftToAsk,
        'requirementId': value.requirementId,
        'step': value.step,
    };
}

