/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DisckoUser } from './DisckoUser';
import {
    DisckoUserFromJSON,
    DisckoUserFromJSONTyped,
    DisckoUserToJSON,
} from './DisckoUser';
import type { GenericRequirement } from './GenericRequirement';
import {
    GenericRequirementFromJSON,
    GenericRequirementFromJSONTyped,
    GenericRequirementToJSON,
} from './GenericRequirement';
import type { RequirementsParameters } from './RequirementsParameters';
import {
    RequirementsParametersFromJSON,
    RequirementsParametersFromJSONTyped,
    RequirementsParametersToJSON,
} from './RequirementsParameters';

/**
 * 
 * @export
 * @interface DisckoCompany
 */
export interface DisckoCompany {
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    name: string;
    /**
     * 
     * @type {Array<DisckoUser>}
     * @memberof DisckoCompany
     */
    users: Array<DisckoUser>;
    /**
     * 
     * @type {number}
     * @memberof DisckoCompany
     */
    credits: number;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    companyStatus: DisckoCompanyCompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    customerStripeId?: string;
    /**
     * 
     * @type {Array<GenericRequirement>}
     * @memberof DisckoCompany
     */
    requirements: Array<GenericRequirement>;
    /**
     * 
     * @type {RequirementsParameters}
     * @memberof DisckoCompany
     */
    requirementParam: RequirementsParameters;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompany
     */
    subscriptionDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompany
     */
    hasAccessToStandardFeaturesUntil: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DisckoCompany
     */
    hasAccessToPremiumFeaturesUntil: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DisckoCompany
     */
    isSubscribed: boolean;
    /**
     * 
     * @type {object}
     * @memberof DisckoCompany
     */
    plan: object;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    price: string;
    /**
     * 
     * @type {boolean}
     * @memberof DisckoCompany
     */
    isOldPrice: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisckoCompany
     */
    subscriptionId?: string;
}


/**
 * @export
 */
export const DisckoCompanyCompanyStatusEnum = {
    Trial: 'TRIAL',
    ExpiredTrial: 'EXPIRED_TRIAL',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type DisckoCompanyCompanyStatusEnum = typeof DisckoCompanyCompanyStatusEnum[keyof typeof DisckoCompanyCompanyStatusEnum];


/**
 * Check if a given object implements the DisckoCompany interface.
 */
export function instanceOfDisckoCompany(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "credits" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "requirements" in value;
    isInstance = isInstance && "requirementParam" in value;
    isInstance = isInstance && "subscriptionDate" in value;
    isInstance = isInstance && "hasAccessToStandardFeaturesUntil" in value;
    isInstance = isInstance && "hasAccessToPremiumFeaturesUntil" in value;
    isInstance = isInstance && "isSubscribed" in value;
    isInstance = isInstance && "plan" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "isOldPrice" in value;

    return isInstance;
}

export function DisckoCompanyFromJSON(json: any): DisckoCompany {
    return DisckoCompanyFromJSONTyped(json, false);
}

export function DisckoCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisckoCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'users': ((json['users'] as Array<any>).map(DisckoUserFromJSON)),
        'credits': json['credits'],
        'companyStatus': json['companyStatus'],
        'customerStripeId': !exists(json, 'customerStripeId') ? undefined : json['customerStripeId'],
        'requirements': ((json['requirements'] as Array<any>).map(GenericRequirementFromJSON)),
        'requirementParam': RequirementsParametersFromJSON(json['requirementParam']),
        'subscriptionDate': (json['subscriptionDate'] === null ? null : new Date(json['subscriptionDate'])),
        'hasAccessToStandardFeaturesUntil': (json['hasAccessToStandardFeaturesUntil'] === null ? null : new Date(json['hasAccessToStandardFeaturesUntil'])),
        'hasAccessToPremiumFeaturesUntil': (json['hasAccessToPremiumFeaturesUntil'] === null ? null : new Date(json['hasAccessToPremiumFeaturesUntil'])),
        'isSubscribed': json['isSubscribed'],
        'plan': json['plan'],
        'price': json['price'],
        'isOldPrice': json['isOldPrice'],
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
    };
}

export function DisckoCompanyToJSON(value?: DisckoCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'users': ((value.users as Array<any>).map(DisckoUserToJSON)),
        'credits': value.credits,
        'companyStatus': value.companyStatus,
        'customerStripeId': value.customerStripeId,
        'requirements': ((value.requirements as Array<any>).map(GenericRequirementToJSON)),
        'requirementParam': RequirementsParametersToJSON(value.requirementParam),
        'subscriptionDate': (value.subscriptionDate === null ? null : value.subscriptionDate.toISOString()),
        'hasAccessToStandardFeaturesUntil': (value.hasAccessToStandardFeaturesUntil === null ? null : value.hasAccessToStandardFeaturesUntil.toISOString()),
        'hasAccessToPremiumFeaturesUntil': (value.hasAccessToPremiumFeaturesUntil === null ? null : value.hasAccessToPremiumFeaturesUntil.toISOString()),
        'isSubscribed': value.isSubscribed,
        'plan': value.plan,
        'price': value.price,
        'isOldPrice': value.isOldPrice,
        'subscriptionId': value.subscriptionId,
    };
}

