/* tslint:disable */
/* eslint-disable */
/**
 * Specks API
 * APIs for specks SaaS
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCheckoutSessionDto
 */
export interface CreateCheckoutSessionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutSessionDto
     */
    plan: CreateCheckoutSessionDtoPlanEnum;
}


/**
 * @export
 */
export const CreateCheckoutSessionDtoPlanEnum = {
    Classic: 'classic',
    Premium: 'premium'
} as const;
export type CreateCheckoutSessionDtoPlanEnum = typeof CreateCheckoutSessionDtoPlanEnum[keyof typeof CreateCheckoutSessionDtoPlanEnum];


/**
 * Check if a given object implements the CreateCheckoutSessionDto interface.
 */
export function instanceOfCreateCheckoutSessionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plan" in value;

    return isInstance;
}

export function CreateCheckoutSessionDtoFromJSON(json: any): CreateCheckoutSessionDto {
    return CreateCheckoutSessionDtoFromJSONTyped(json, false);
}

export function CreateCheckoutSessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutSessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan': json['plan'],
    };
}

export function CreateCheckoutSessionDtoToJSON(value?: CreateCheckoutSessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan': value.plan,
    };
}

