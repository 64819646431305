import React, { useContext } from 'react';

import { Button } from 'components/core/Button/Button';
import { Text } from 'components/core/Text/Text';
import { Title } from 'components/core/Title/Title';

import { disckoContext } from './DisckoPage';

/* --------------------------------------------------- API calls -------------------------------------------------- */

export function DisckoFormEndRequirement(): JSX.Element {
  const { requirementParams, uiSettings } = useContext(disckoContext);

  return (
    <div className="mx-6 flex flex-col justify-center items-center h-full gap-4">
      <Title content="Merci !" textColor={uiSettings?.primaryColor} />
      <Text color={uiSettings?.primaryColor} content="Nous revenons vers vous dans les meilleurs délais." />

      {requirementParams?.urlRedirect !== undefined && requirementParams.urlRedirect !== '' && (
        <div className="flex flex-col items-center gap-4">
          <Text
            color={uiSettings?.primaryColor}
            content="Vous pouvez également prendre rendez-vous avec nous dès aujourd'hui à la date qui vous convient :"
          />

          <Button
            content="Prenez rendez-vous avec nous dès aujourd'hui"
            height="fit"
            type="button"
            width="fit"
            color={uiSettings?.primaryColor}
            onClick={() => window.open(requirementParams?.urlRedirect, '_blank')}
          />
        </div>
      )}
    </div>
  );
}
