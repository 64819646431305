import { type RequirementSynthesis } from 'pages/RequirementForm/DisckoPage';

function extractElementsFromSynthesis(inputString: string): string[][] {
  const cleanedString: string = inputString
    // eslint-disable-next-line no-useless-escape
    .replace(/[\[\]]/g, '')
    .replace(/:/g, '')
    .replace(/"/g, '');

  // Étape 2: Séparer les sections

  const sections: string[] = cleanedString
    .split(/(Objectifs|Utilisateurs cibles|Contraintes|Facteurs de succès|Conclusion)/)
    .filter(Boolean);

  // const extractedClientContext: string[] = sections[sections.indexOf('Contexte') + 1]
  //   .trim()
  //   .split('|')
  //   .map((s) => s.trim());

  const extractedObjective: string[] = sections[sections.indexOf('Objectifs') + 1]
    .trim()
    .split('|')
    .map((s) => s.trim());
  const extractedTargetUsers: string[] = sections[sections.indexOf('Utilisateurs cibles') + 1]
    .trim()
    .split('|')
    .map((s) => s.trim());
  const extractedConstraints: string[] = sections[sections.indexOf('Contraintes') + 1]
    .trim()
    .split('|')
    .map((s) => s.trim());
  const extractedKeySuccessFactors: string[] = sections[sections.indexOf('Facteurs de succès') + 1]
    .trim()
    .split('|')
    .map((s) => s.trim());
  const extractedAbstract: string = sections[sections.indexOf('Conclusion') + 1].trim();

  return [
    extractedObjective,
    extractedTargetUsers,
    extractedConstraints,
    extractedKeySuccessFactors,
    [extractedAbstract],
  ];
}

export function setSynthesisToContext(inputString: string, contextRequirement: RequirementSynthesis): void {
  const extractedElements: string[][] = extractElementsFromSynthesis(inputString);
  contextRequirement.goals = extractedElements[0];
  contextRequirement.audience = extractedElements[1];
  contextRequirement.constraints = extractedElements[2];
  contextRequirement.successFactors = extractedElements[3];
  contextRequirement.abstract = extractedElements[4][0];
}

export function convertStringToSynthesis(
  response: any,
  contextRequirement: RequirementSynthesis,
): RequirementSynthesis {
  return {
    synthesisContext: response.context,
    goals: response.goals,
    constraints: response.constraints,
    successFactors: response.successFactors,
    audience: response.audience,
    abstract: response.abstract,
    kpis: response.kpis,
    references: response.references,
    solutions: response.solutions,
    stakeholders: response.stakeholders,
    clientMaturity: contextRequirement.clientMaturity,
    priceRange: contextRequirement.priceRange,
    projectType: contextRequirement.projectType,
    projectMaturity: contextRequirement.projectMaturity,
    deadline: contextRequirement.deadline,
    projectSubject: contextRequirement.projectSubject,
    questions: contextRequirement.questions,
    step: contextRequirement.step,
    comment: contextRequirement.comment,
  };
}

export function convertStringToRequirement(inputString: string): RequirementSynthesis {
  const jsonRequirement: any = JSON.parse(inputString);

  if (typeof jsonRequirement.requirement !== 'object' && String(jsonRequirement.requirement).startsWith('[')) {
    const jsonSynthesis: RequirementSynthesis = {
      synthesisContext: [],
      goals: [],
      constraints: [],
      successFactors: [],
      audience: [],
      abstract: '',
      priceRange: jsonRequirement.priceRange,
      projectType: jsonRequirement.projectType,
      projectMaturity: jsonRequirement.projectMaturity,
      deadline: {
        deadlineCategory: jsonRequirement.deadlineCategory,
        date: jsonRequirement.deadlineDate,
      },
      projectSubject: jsonRequirement.project,
      questions: jsonRequirement.questions,
      step: jsonRequirement.step,
      comment: jsonRequirement.comment,
    };
    setSynthesisToContext(jsonRequirement.requirement, jsonSynthesis);
    return jsonSynthesis;
  } else {
    let jsonSynthesis: any;
    try {
      jsonSynthesis = JSON.parse(jsonRequirement.requirement);
    } catch (error) {
      jsonSynthesis = jsonRequirement.requirement;
    }

    const requirement: RequirementSynthesis = {
      synthesisContext: jsonSynthesis.requirement.Contexte,
      goals: jsonSynthesis.requirement.Objectifs,
      constraints: jsonSynthesis.requirement.Contraintes,
      successFactors: jsonSynthesis.requirement.Facteurs_de_succes,
      audience: jsonSynthesis.requirement.Utilisateurs_cibles,
      abstract: jsonSynthesis.requirement.Conclusion,
      clientMaturity: jsonRequirement.clientMaturity,
      priceRange: jsonRequirement.priceRange,
      projectType: jsonRequirement.projectType,
      projectMaturity: jsonRequirement.projectMaturity,
      deadline: {
        deadlineCategory: jsonRequirement.deadlineCategory,
        date: jsonRequirement.deadlineDate,
      },
      projectSubject: jsonRequirement.project,
      questions: jsonRequirement.questions,
      step: jsonRequirement.step,
      comment: jsonRequirement.comment,
    };
    return requirement;
  }
}
