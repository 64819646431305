/* eslint-disable @typescript-eslint/restrict-template-expressions */

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { DisckoApisContext } from 'App';
import { type CreateCheckoutSessionDtoPlanEnum, type GetDisckoCompanyDto } from 'api';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { type QueryClient, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useAuth0 } from '@auth0/auth0-react';

import { Button } from 'components/core/Button/Button';
import { Chip } from 'components/core/Chip/Chip';

import concatClassNames from 'utils/classNames';

export function PlanManagement(): JSX.Element {
  const { stripeService, companyService } = useContext(DisckoApisContext);
  const { getAccessTokenSilently, user } = useAuth0();
  const queryClient: QueryClient = useQueryClient();

  /* -------------------------------------------- Desktop or mobile view -------------------------------------------- */

  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();
  React.useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsLargeScreen(true);
    } else {
      setIsLargeScreen(false);
    }
  }, [window.innerWidth]);

  // à supprimer après

  /* ------------------------------------------ Controls the checkbox state ----------------------------------------- */

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPlan, setSelectedPlan] = useState<CreateCheckoutSessionDtoPlanEnum>('classic');
  const selectedPlanRef = useRef<CreateCheckoutSessionDtoPlanEnum>('classic');

  /* --------------------------------------------- Get User Informations -------------------------------------------- */

  async function getCompany(): Promise<GetDisckoCompanyDto> {
    const accessToken: string = await getAccessTokenSilently();
    return await companyService.getCompanyByUserId(accessToken);
  }

  const { data: companyData, status: companyStatus } = useQuery<GetDisckoCompanyDto>(['company'], getCompany);

  /* -------------------------------------------- Open the billing portal ------------------------------------------- */

  async function openBillingPortal(): Promise<string> {
    const accessToken: string = await getAccessTokenSilently();
    return await stripeService.openBillingPortal(accessToken, window.location.origin + '/settings');
  }

  const { data: billingData, status: billingStatus } = useQuery<string, Error>(
    ['billing', user?.sub],
    openBillingPortal,
  );

  async function createCheckoutSession(plan: CreateCheckoutSessionDtoPlanEnum): Promise<string> {
    const accessToken: string = await getAccessTokenSilently();
    return await stripeService
      .createCheckoutSession({
        accessToken,
        createCheckoutSession: {
          plan,
        },
      })
      .then(
        (res) => {
          setIsLoading(false);
          return res;
        },
        (err) => {
          setIsLoading(false);
          toast.error('Une erreur est survenue, veuillez réessayer.');
          throw new Error(err);
        },
      );
  }

  const [clickedOnSubscribe, setClickedOnSubscribe] = useState<boolean>(false);

  const { data: createCheckoutSessionData, status: createCheckoutSessionStatus } = useQuery(
    ['createCheckoutSession', selectedPlan],
    async () => createCheckoutSession(selectedPlan),
    {
      enabled: clickedOnSubscribe,
    },
  );

  useEffect(() => {
    if (createCheckoutSessionStatus === 'success' && clickedOnSubscribe) {
      if (createCheckoutSessionData !== '') window.open(createCheckoutSessionData, '_blank');
      setClickedOnSubscribe(false);
    }
    if (createCheckoutSessionStatus === 'error') {
      console.error('Error creating checkout session');
      toast.error('Une erreur est survenue, veuillez réessayer.');
    }
  }, [clickedOnSubscribe, createCheckoutSessionStatus, selectedPlan]);

  // useEffect(() => {
  //   if (clickedOnSubscribe) {
  //     // Utiliser la valeur mémorisée dans la référence pour l'appel API
  //     createCheckoutSession()
  //       .then((url) => {
  //         if (url) {
  //           window.open(url, '_blank');
  //           return url;
  //         } else {
  //           throw new Error('No URL returned');
  //         }
  //       })
  //       .catch((err) => {
  //         console.error('Error creating checkout session', err);
  //         toast.error('Une erreur est survenue, veuillez réessayer.');
  //       });
  //     setClickedOnSubscribe(false);
  //   }
  // }, [clickedOnSubscribe]); // Le useEffect est déclenché uniquement après que le clic a eu lieu

  function handleSelectPlan(plan: CreateCheckoutSessionDtoPlanEnum): void {
    setClickedOnSubscribe(true);
    setSelectedPlan(plan);
    // selectedPlanRef.current = plan;
    setIsLoading(true);
  }

  const plans: Array<{
    id: CreateCheckoutSessionDtoPlanEnum;
    name: string;
    expirationDate?: Date | undefined;
    baseline: string;
    description: JSX.Element;
    price: string;
  }> = [
    {
      id: 'classic',
      name: 'Standard',
      baseline: 'Pour transformer chaque premier contact en opportunité',
      expirationDate: companyData?.hasAccessToStandardFeaturesUntil,
      description: (
        <div className="max-w-md mx-auto text-justify">
          <ul className="list-none list-inside text-gray-200 space-y-2 text-start ">
            <li>✅ Usage illimité</li>
            <li>✅ Personnalisation du formulaire et de la synthèse obtenue</li>
            <li>✅ Intégration simplifiée avec votre site vitrine</li>
            <li>✅ Intégration avec Make pour faire le lien avec votre CRM</li>
          </ul>
        </div>
      ),
      price: '60€HT/mois',
    },
    {
      id: 'premium',
      name: 'Premium',
      expirationDate: companyData?.hasAccessToPremiumFeaturesUntil,
      baseline: 'Pour une expérience unique et personnalisée, à votre image',
      description: (
        <div className="max-w-md mx-auto  text-justify">
          {/* <p className="text-gray-200 mb-4">Profitez des options de personnalisation avancées :</p> */}
          <ul className="list-none list-inside text-gray-200 space-y-2 text-start ">
            <li>
              ✅{` Toutes les fonctionnalités de l'abonnement `}
              <span className="font-bold">Standard</span>
            </li>

            <li>✅{` Personnalisation des couleurs`}</li>
            <li>✅{` Version en marque blanche en supprimant la mention "propulsé par Discko".`}</li>
          </ul>
          {/* <p className="text-gray-200 mt-4">Offrez une expérience unique à vos utilisateurs.</p> */}
        </div>
      ),
      price: '90€HT/mois',
    },
  ];

  return (
    <div className="flex flex-col items-start py-10 px-4">
      {companyStatus === 'success' && (
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-bold">
              {companyData.isSubscribed ? 'Votre abonnement' : 'Choisissez votre abonnement'}
            </h1>
            {companyData.hasStripeAccount && (
              <div className="w-fit">
                <Button
                  onClick={async () => {
                    window.open(billingData, '_blank');
                  }}
                  color="#42A5F5"
                  content="Facturation & abonnement"
                  iconName="arrowUpFromSquare"
                />
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 md:grid-cols-2 gap-6">
            {plans.map((plan) => (
              <div
                key={plan.id}
                className={concatClassNames(
                  `border flex flex-col gap-4`,
                  `rounded-lg p-6 w-full h-full`,
                  ` text-left shadow-md ${'bg-blue-gray-50 text-black'}`,
                  'justify-between',
                  companyData.plan === plan.id && companyData.isSubscribed ? 'cursor-not-allowed' : 'cursor-pointer',
                  companyData.plan === plan.id && companyData.isSubscribed ? 'border-2 border-black' : '',
                )}
              >
                <div className="relative">
                  <div
                    className={concatClassNames(
                      !companyData.isSubscribed &&
                        plan.expirationDate &&
                        new Date(plan.expirationDate).getTime() > 0 &&
                        new Date(plan.expirationDate) > new Date()
                        ? 'visible'
                        : 'hidden',
                      'pb-2',
                    )}
                  >
                    <Chip
                      // eslint-disable-next-line max-len
                      label={`Vous avez accès à cet abonnement jusqu'au ${plan.expirationDate?.toLocaleDateString()} inclus.`}
                      bgColor="blue-100"
                      textSize="sm"
                      paddingHorizontal="sm"
                      paddingTop="sm"
                      paddingBottom="sm"
                    />
                  </div>
                  <div
                    className={concatClassNames(
                      companyData.isSubscribed ? (companyData.plan === plan.id ? 'visible' : 'invisible') : 'hidden',
                      'pb-2',
                    )}
                  >
                    <Chip
                      label={`Abonnement actuel`}
                      bgColor="blue-100"
                      textSize="sm"
                      paddingHorizontal="sm"
                      paddingTop="sm"
                      paddingBottom="sm"
                    />
                  </div>
                  <h2 className="text-lg font-semibold ">{plan.name}</h2>
                  <h3
                    className="text-md font-sem
                  ibold "
                  >
                    {plan.baseline}
                  </h3>
                </div>
                <div>
                  <h2 className="text-xl font-bold ">{plan.price}</h2>
                  <h3 className="text-md font-semibold ">Sans engagement</h3>
                </div>

                <p className="text-gray-800 mb-4">{plan.description}</p>

                <Button
                  onClick={() => {
                    handleSelectPlan(plan.id);
                  }}
                  height="fit"
                  disabled={companyData.isSubscribed && companyData.plan === plan.id}
                  loading={isLoading && selectedPlan === plan.id}
                  color="#42A5F5"
                  content={
                    !companyData.isSubscribed
                      ? 'Souscrire'
                      : companyData.plan === plan.id
                      ? 'Abonnement actuel'
                      : companyData.plan !== null
                      ? 'Changer de plan'
                      : 'Souscrire'
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
