import {
  DisckoCompaniesApi,
  type GetDisckoCompaniesDto,
  type GetDisckoCompanyDto,
  type GetFullDisckoCompaniesDto,
  type GetIsDisckoCompanyValidDto,
  type GetUiCompanyDataDto,
} from 'api';

export class CompanyService {
  private readonly companyClient: DisckoCompaniesApi;

  constructor() {
    this.companyClient = new DisckoCompaniesApi();
  }

  public async getCompanyByUserId(accessToken: string): Promise<GetDisckoCompanyDto> {
    const getDisckoCompaniesDto: GetDisckoCompaniesDto = await this.companyClient.disckoCompanyControllerFindByUserId({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        // 'Content-Type': 'application/json',
      },
    });
    return getDisckoCompaniesDto.companies[0];
  }

  public async getCompanies(accessToken: string): Promise<GetFullDisckoCompaniesDto> {
    const getDisckoCompaniesDto: GetFullDisckoCompaniesDto = await this.companyClient.disckoCompanyControllerFindAll({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return getDisckoCompaniesDto;
  }

  public async isCompanyIsAllowedToUseDiscko(companyId: string): Promise<boolean> {
    const getDisckoCompanyDto: GetIsDisckoCompanyValidDto =
      await this.companyClient.unauthDisckoCompanyControllerFindById({
        id: companyId,
      });
    return getDisckoCompanyDto.isAllowedToUseDiscko;
  }

  public async isAdminCompany(accessToken: string): Promise<boolean> {
    const isAdminString: string = await this.companyClient.disckoCompanyControllerIsAdmin({
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return isAdminString === 'true';
  }

  public async getCompanyUiData(companyId: string): Promise<GetUiCompanyDataDto> {
    return await this.companyClient.unauthDisckoCompanyControllerGetUiCompanyData({
      id: companyId,
    });
  }

  public async addCreditsToCompany(
    accessToken: string,
    companyId: string,
    credits: number,
  ): Promise<GetDisckoCompanyDto> {
    return await this.companyClient.disckoCompanyControllerAddFreeCredits(
      { id: companyId, creditsToAdd: credits },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
